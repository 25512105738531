import React, { useState } from 'react'
import '@mui/x-data-grid-generator'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BallotIcon from '@mui/icons-material/Ballot'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import StudentViewModel from './studentViewMod'
import StudentUpdateModel from './studentUpdateModel'

function Viewstudentinnovation() {

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true)
  }

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }

  const [modalIsOpen1, setModalIsOpen1] = useState(false)

  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true)
  }

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false)
  }

  const rows = [
    {
      id: 1,
      name: 'Vaibhav',
      startupname: 'VPsolutions',
      email: 'vaibhav@VPsolutions.com',
      phoneno: '9876543210',
      state: 'Maharashtra',
      district: 'Nashik',
      city: 'Nashik Road',
      status: 'Single',
    },
    {
      id: 2,
      name: 'Mayuri',
      startupname: 'Mauhub',
      email: 'mayuri@mauhub.com',
      phoneno: '7654321098',
      state: 'Maharashtra',
      district: 'Pune',
      city: 'Kothrude',
      status: 'Married',
    },
    {
      id: 3,
      name: 'Priyanka',
      startupname: 'Rajwadiswag',
      email: 'Priyanka@rajwadiswag.com',
      phoneno: '8765432109',
      state: 'Rajasthan',
      district: 'Udaypur',
      city: 'Udaypur',
      status: 'Complicated',
    },
    {
      id: 4,
      name: 'Savita',
      startupname: 'SHtechservices',
      email: 'savita@shtechservices.com',
      phoneno: '8654321098',
      state: 'Maharashtra',
      district: 'Osmanabad',
      city: 'Kothrude',
      status: 'Married',
    },
    {
      id: 5,
      name: 'Atul',
      startupname: 'Trucktravia',
      email: 'atul@trucktravia.com',
      phoneno: '8865432109',
      state: 'Maharashtra',
      district: 'Ghoti',
      city: 'Kothrude',
      status: '6',
    },
  ]

  const columns = [
    { field: 'id', headerName: 'Sr.No.', width: 60 },
    { field: 'name', headerName: 'Name', width: 120 },
    { field: 'startupname', headerName: 'Startup Name', width: 120 },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'phoneno', headerName: 'Phone no.', width: 120 },
    { field: 'state', headerName: 'State', width: 110 },
    { field: 'district', headerName: 'District', width: 110 },
    { field: 'city', headerName: 'City', width: 110 },
    { field: 'status', headerName: 'Status', width: 110 },
    
    { field: 'action', headerName: 'Action', sortable: false, width: 200,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            )

          return alert(JSON.stringify(thisRow, null, 4))
        }

        return (
          <>
            <Button onClick={setModalIsOpenToTrue}>
              <VisibilityIcon />
            </Button>
            {/* <Button onClick={setModalIsOpenToTrue1}>
              <BallotIcon />
            </Button>
            <Button onClick={onClick}>
              <ThumbUpIcon />
            </Button> */}
            {/* <Button onClick={onClick}>
              <ThumbDownIcon />
            </Button> */}
          </>
        )
      },
    },
  ]

  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Student Innovators Details<Button className="addbtn"> Add</Button>
          </h2>
          <div style={{ height: '75vh', width: '100%' }}>
            <div className="d-flex justify-content-end">
              
            </div>
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
              pageSize={8}
              components={{ Toolbar: GridToolbar }}
            />
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
          <div className="text-center">
              <div className="d-block">
                <h3>Update Startup Details</h3>
              </div>
              <div className="d-flex justify-content-end">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <StudentViewModel />
          </div>
        </Modal>
        
      </div>
      <div>
        <Modal isOpen={modalIsOpen1} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block">
                <h3>Update Startup Details</h3>
              </div>
              <div className="d-flex justify-content-end">
                <Button onClick={setModalIsOpenToFalse1}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <StudentUpdateModel />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Viewstudentinnovation