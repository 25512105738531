import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../startup/informationIcon";
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Upload from "../assets/images/navbar/upload.png";

const serverUrl = environment.baseUrl;
const MarketLinkage = (props) => {
  const authToken =sessionStorage.getItem("token");
 // console.log(JSON.parse(sessionStorage.getItem('marketlinkDetails')))

 let initialFormObj={ 
  marketDetails: [{
    name_organization:'',
    type_organization:'',
    sector:'',
    type_of_document:'',
    uploads:''
    }]
 }
  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name_organization",values.name_organization)
    formData.append("type_organization",values.type_organization)
    formData.append("sector",values.sector)
    formData.append("type_of_document",values.type_of_document)
    formData.append("uploads",values.uploads)
    fetch(serverUrl +"/marketLinkage/addmarketLinkage", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken,
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          .alert.show('Oh look, an alert!') 
          props.setValue(props.value+1)
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values
      ) =>
      handleSubmitFinacialForm(values)
    }
    validationSchema={Yup.object({
       
        marketDetails: Yup.array().of(
          Yup.object().shape({
            name_organization: Yup.string().required('Name of Organization is required'),
            type_of_document: Yup.string().required('Type of Organization is required'),
            sector: Yup.string().required('Sector is required'),
            type_organization: Yup.string().required('Type of Document is required'),
            uploads: Yup.string().required('Image Upload is required'),
           
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('marketlinkDetails'))||initialFormObj}

      render={({ values, setFieldValue }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Market Linkage
            </h2>
        </div>
       
          <FieldArray
            name="marketDetails"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>



                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"20%"}}>Name of Organization</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"20%"}} align="left">Type of Organization</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Sector </TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"20%"}} align="left">Type of Document </TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Uploads </TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>

      



                {values.marketDetails && values.marketDetails.length > 0 ? (


                  values.marketDetails.map((friend, index) => (
                    
                     

                    <TableBody  className="data-bg-color d-flex justify-content-between">

              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"30%"}}>
                  <Field type="text" className="txt-color" name={`marketDetails.${index}.name_organization`} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name={`marketDetails.${index}.name_organization`} /><br></br>
                    </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"30%"}}>
                          <Field type="text" className="txt-color" name={`marketDetails.${index}.type_organization`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`marketDetails.${index}.type_organization`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"30%"}}>
                          <Field type="text" className="txt-color" name={`marketDetails.${index}.sector`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`marketDetails.${index}.sector`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"30%"}}>
                          <Field type="text" className="txt-color" name={`marketDetails.${index}.type_of_document`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`marketDetails.${index}.type_of_document`} /><br></br>
                        </div>
              </TableCell>
            
             <TableCell align="center" className="d-flex align-items-center" style={{width:"30%"}}>
             <input id="file" name={`marketDetails.${index}.uploads`} accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue(`marketDetails.${index}.uploads`, e.target.files[0]);
                      }} /> 
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name={`marketDetails.${index}.uploads`} /><br></br>
                        </div>
             </TableCell>

              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
          
              </TableBody>  
      
   


                     
                    
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("marketlinkDetails", JSON.stringify(values))}}   
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>

              </div>
            )}
          />
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default MarketLinkage;
