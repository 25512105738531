import React, { useState, useEffect } from "react";
import PurchaseOrderAwarded from "../../assets/images/Initiative/InitiativeMaharashtraStartup/trophy.png";
// import { Button } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import { environment } from "../../api";

// import CloseIcon from '@mui/icons-material/Close'
// import Modal from 'react-modal'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import NewAreaModal from "./NewAreaModal";
import LoginModal from '../../component/loginModal'

function MahaStartUpMiddleContent() {
  const serverUrl = environment.baseUrl;
  const loginUrl = environment.LoginLink
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  
  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true);
  };

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false);
  };

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 768;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    fetch(serverUrl + "/startupWeek/allStartupWeek")
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUsers(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);
  return (
    <>
      <div className="mahaGrand-main pt-5">
        <div className="container">
          <div className="row d-flex justify-content-center pad-banner-all">
            <div
              className={`${
                isMobile
                  ? "d-none"
                  : "col-lg-3 col-md-3 col-sm-3 d-lg-block d-sm-none d-md-none justify-content-center"
              }`}
            >
             {/* <Modal isOpen={modalIsOpen1} className="ModOverlay" >
                          <div className="viewModTest">
                          <div className="text-center">
                            <div className='row'>
                               <div className='col-lg-10'>
                               <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 pt-0 colorformhrading titlewadd">
                               <h5>You will be Redirected to existing Portal to access your account</h5>
                              </div>
                               </div>
                               <div className='col-lg-2 pt-4'>
                               <span className="d-flex justify-content-end alg-top pt-4"> <Button onClick={setModalIsOpenToFalse1}>
                                  <CloseIcon/>
                                </Button>
                              </span>
                               </div>
                            </div>

                          
                              
                            </div>
                            <LoginModal />
                          </div>
                        </Modal> */}
              <div className="card col-lg-10 col-md-10 col-sm-10 apply-head-mahaStartup  border-2 ">
                <div
                //  onClick={handleShow}  
                 className="card-body d-flex justify-content-center  align-items-center cursor-pointer">
                  <div className="col-lg-8 col-md-8 col-sm-8 ">
                    <a href="#" style={{color:'#fff'}}>
                    <span className="textColourMenu  regular_font font-20-24 font-style-normal font-weight-400">
                      Apply now
                    </span>
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                  <a href="#" style={{color:'#fff'}}>
                    <span className="Arrow-apply align-items-end">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                    </a>
                  </div>
                </div>
                <div className="list-group list-group-flush">
                  <a
                    href="#mahaStartupWeek"
                    className="list-group-item list-group-item-action"
                  >
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Maharashtra Startup Week
                    </span>
                  </a>
                  <a
                    href="#testimonials"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Testimonials
                    </span>
                  </a>
                  <a
                    href="#idFAQ"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      FAQs
                    </span>
                  </a>
                  <a
                    href="#ourPartner"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Our Partner
                    </span>
                  </a>

                  <a
                    href="#idContact"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Contact
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-7">

{/* ============= */}

{/* 
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be Redirected to existing Portal to access your account.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary"  target="_blank" href={loginUrl} >
           Ok
          </Button>
        </Modal.Footer>
      </Modal> */}


{/* =========== */}

              <div className="row mb-4 winner-annouced-main">
                <div className="col-lg-8 col-md-12 col-sm-8 d-flex align-items-center justify-content-center">
                  <div className="d-grid">
                    <h3 className="font-36-47 font-18-23 font-20-26 font-weight-700 d-flex justify-content-center pt-2 pb-4">
                      Winners Announced!
                    </h3>
                    <div className="pinkbtn">
                      <Button className="btn-primary regular_font font-style-normal font-weight-normal font-20-24">
                        <a>
                          <Link to="/StartupWeekWinner">
                            View
                            <span className="inno-arrow">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 16 16"
                                height="25px"
                                width="35px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                              </svg>
                            </span>
                          </Link>
                        </a>
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-4 d-sm-none d-md-none d-lg-block"
                  id="mahaStartupWeek"
                >
                  <div className="img-align">
                    <img
                      src={PurchaseOrderAwarded}
                      className="PurchaseOrderAwarded-img"
                      alt="PurchaseOrderAwarded-img"
                    ></img>
                  </div>
                </div>
              </div>
              {users.map((user) => (
                <>
                  <div className="row">
                    <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pt-2 pb-4">
                      {user.SWtitle}
                    </h2>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                      {user.SWdescription}
                    </p>
                  </div>
                  {/* <div className="row">
                            <h2 className="font-28-36  font-20-26 font-style-normal font-weight-600">{user.MGCtitle}Maharshtra Grand Challenge</h2>
                            <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'><b className='grand-text-bold'>{user.MGCdescription}Maharashtra Grand Challenge</b> is an initiative by Maharashtra State Innovation Society to bring Innovation in Governance. Through this initiative, innovation society intends to address key challenges faced by the various department of Maharashtra by supporting and accelerating the innovative ideas and solutions. In the current edition Maharashtra State Innovation Society in association with Fisheries Department calling for innovative ideas and solutions to solve problems faced by Fisheries department.</p>
                        </div>
                        <div className="row">
                        <h2 className="font-28-36  font-20-26 font-style-normal font-weight-600">{user.Eligibilitytitle}Eligibility</h2>
                        <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>{user.Eligibilitydescription}Applications are open for both domestic and foreign individuals, teams, organizations, for-profit companies, non-profit organisations, academic institutions and government agencies.</p>
                        </div>
                        <div className="row">
                        <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>Foreign national, team, institution, companies and organisation shall abide by the rules and regulations of Government of India and Government of Maharashtra. They also must fulfil all the tax norms prescribed by the Government of India and Government of Maharashtra.</p> */}
                  {/* </div> */}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MahaStartUpMiddleContent;
