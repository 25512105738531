import React from 'react'
import Card from 'react-bootstrap/Card';

function MahaHackthonMiddleContent() {
    return (
        <>
            <div className="mahaGrand-main pt-5">
                <div className="container">
                    <div className="row d-flex justify-content-center pad-banner-all pt-4">
                        <div className="col-lg-3 col-md-3 col-sm-3 d-sm-none d-md-none d-lg-block justify-content-center">
                            <div className="card col-lg-10 col-md-10 col-sm-10 apply-head  border-2">
                                {/* <div className="card-body d-flex justify-content-center  align-items-center">
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <span className="regular_font font-20-24 font-style-normal font-weight-400">
                      Apply now
                    </span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <span className="Arrow-apply align-items-end">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </div>
                </div> */}
                                <div className="list-group list-group-flush">
                                    <a
                                        href="#winners"
                                        className="list-group-item list-group-item-action"
                                    >
                                        <span className="regular_font font-18-23 font-style-normal font-weight-400">
                                            Winners
                                        </span>
                                    </a>
                                    <a
                                        href="#updates"
                                        className="list-group-item list-group-item-action"
                                    >
                                        {' '}
                                        <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                            Latest Updates
                                        </span>
                                    </a>
                                    <a
                                        href="#tracks"
                                        className="list-group-item list-group-item-action"
                                    >
                                        {' '}
                                        <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                            Hackathon Tracks
                                        </span>
                                    </a>
                                    <a
                                        href="#idEventSch"
                                        className="list-group-item list-group-item-action"
                                    >
                                        {' '}
                                        <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                            Event Schedule
                                        </span>
                                    </a>
                                    <a
                                        href="#speaker"
                                        className="list-group-item list-group-item-action"
                                    >
                                        {' '}
                                        <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                            Speaker
                                        </span>
                                    </a>
                                    <a
                                        href="#idFAQ"
                                        className="list-group-item list-group-item-action"
                                    >
                                        {' '}
                                        <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                            FAQs
                                        </span>
                                    </a>
                                    <a
                                        href="#idPartner"
                                        className="list-group-item list-group-item-action"
                                    >
                                        {' '}
                                        <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                            Partner
                                        </span>
                                    </a>
                                    <a
                                        href="#idContact"
                                        className="list-group-item list-group-item-action"
                                    >
                                        {' '}
                                        <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                            Contact
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-7">
                            <div className="row" id="winners">
                                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                                    Winners
                                </h2>
                                <div className='row'>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-3' style={{ color: "#07d5c0" }}>
                                                <h5>
                                                    Track A: Vaccination &#38; Immunization
                                                </h5>
                                                <h5 className='pt-3'>
                                                    <a className='mahaHackA' style={{ color: "#07d5c0" }} href="https://youtu.be/FC3WC3-MKzU">Eureka</a>
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-3' style={{ color: "#65c600" }}>
                                                <h5>
                                                    Track B: Affordable Diagnostic Tools
                                                </h5>
                                                <h5 className='pt-3'>
                                                    <a className='mahaHackA' style={{ color: "#65c600" }} href="https://youtu.be/1iQPOz45RHg">Nidaan, </a>
                                                    <a className='mahaHackA' style={{ color: "#65c600" }} href="https://youtu.be/gBodMzGorho">TB Detectors, </a>
                                                    <a className='mahaHackA' style={{ color: "#65c600" }} href="https://youtu.be/dU14PzxEoy4">RapiTB Hackfest</a>
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-3' style={{ color: "#ff901c" }}>
                                                <h5>
                                                    Track C: Responsive Emergency Services
                                                </h5>
                                                <h5 className='pt-3'>
                                                    <a className='mahaHackA' style={{ color: "#ff901c" }} href="https://youtu.be/SC_ZEHoukKc">Team Sandesh</a>
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-3' style={{ color: "#ff0071" }}>
                                                <h5>
                                                    Track D: Medical Devices for Improving Neonatal Care
                                                </h5>
                                                <h5 className='pt-3'>
                                                   <a className='mahaHackA' style={{ color: "#ff0071" }} href="https://youtu.be/z-F0Wiiexrc">Mom’s Sparsh</a>, 
                                                   <a className='mahaHackA' style={{ color: "#ff0071" }} href="https://youtu.be/izvIQi4dH3Q">Vital Kare</a> 
                                                    </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3" id='updates'>
                                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                                    Latest Updates and Useful resources
                                    <ul className='pt-3'>
                                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                            <a className='mahaHackA' href="https://drive.google.com/file/d/1ShRZEom62hPv3OLG1OT57O4_oGB9Nn_R/view">Go through Participant’s Handbook</a>
                                        </li>
                                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                            <a className='mahaHackA' href="https://docs.google.com/presentation/d/1ft7qO08Gf1iPVWR-jsI2nP2oT9s_xnQ40fakw3S-9cY/edit?ts=608118a6#slide=id.gd41ada0c22_2_108">Detailed Schedule</a>
                                        </li>
                                    </ul>
                                </h2>

                                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                                    Healthcare Hackathon
                                </h2>
                                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                    <b className="grand-text-bold">A Hackathon</b>{' '}
                                    is an event in which people with different backgrounds and expertise form teams,
                                    collaborate within a limited time frame, and focus on a specific problem or idea
                                    in healthcare to come up with innovative, disruptive ideas and solutions.
                                    By bringing together diverse minds alike in their interest for solving healthcare’s
                                    biggest challenges, problems can be diagnosed from multiple different perspectives.
                                </p>
                                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                                    Maharashtra Health Hackathon (MH<sup>2</sup>)
                                </h2>
                                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                    <b className="grand-text-bold">Maharashtra Health Hackathon (MH<sup>2</sup>)</b>{' '}
                                    is a first of its kind initiative to promote innovative solutions for addressing key
                                    challenges faced by the Government Department in the state of Maharashtra. The key objective
                                    is to make MH2 a collaborative platform for innovators, academia, investors, industry, and the
                                    state government, for building an innovation-oriented mindset for innovators to solve the state's
                                    most pressing problems by developing the idea into a product.
                                </p>
                                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                                    Eligibility
                                </h2>
                                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                    We are seeking a diverse group of participants based in Maharashtra.
                                    All participants with different levels of experience, professional and academic backgrounds,
                                    diverse skill sets are welcome to apply; what matters most will be your commitment to making
                                    an impact and your willingness to collaborate. Prior medical background is not essential, we
                                    are looking for problem solvers from versatile backgrounds including but not limited to engineering,
                                    medicine, design, life sciences, management etc.
                                </p>
                                <p>Minimum age to be eligible to apply: 13 years, as on 23rd April 2021.</p>
                                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                                    Best Idea in each track gets

                                    <ul className='pt-3'>
                                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                            Mentorship for developing Proof of Concept
                                        </li>
                                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                            Pre-incubation Offers
                                        </li>
                                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                            Opportunities to pitch to investors
                                        </li>
                                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                                            Upto $5000 Cloud Credits
                                        </li>
                                    </ul>
                                </h2>
                                <p style={{ color: "#4154f1" }}><b>LAST DATE OF APPLICATION : 14TH APRIL 2021 @ 11.59 PM</b></p>
                            </div>
                            <div className="row" id="tracks">
                                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                                    Hackathon Tracks
                                </h2>
                                <div className='row'>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-4' style={{ color: "#07d5c0" }}>
                                                <h5>
                                                    Track A: Vaccination &#38; Immunization
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-4' style={{ color: "#65c600" }}>
                                                <h5>
                                                    Track B: Affordable Diagnostic Tools
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-4' style={{ color: "#ff901c" }}>
                                                <h5>
                                                    Track C: Responsive Emergency Services
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-md-6 text-center'>
                                        <Card className='text-center m-2 mahaHackCard'>
                                            <Card.Body className='p-4' style={{ color: "#ff0071" }}>
                                                <h5>
                                                    Track D: Medical Devices for Improving Neonatal Care
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MahaHackthonMiddleContent