import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { environment } from '../../api';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function MahaHackthonFaq() {
    const serverUrl = environment.baseUrl;
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(serverUrl + "/MhHealthFaq/allMhFaq")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setUsers(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>

            <div className='FAQ-main mt-4 bg-color-white' id="idFAQ">
                <div className="container">
                    <div className="row d-flex justify-content-center my-3">
                        <div className="offset-lg-3 col-lg-7 col-md-12 col-sm-7">
                            <div className="container con" id="idFAQ">
                                <div>
                                    <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">FAQ's</h2>
                                    {users.map(user => (

                                        <Accordion expanded={expanded === user} onChange={handleChange(user)}>
                                        {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}> */}
                                            <AccordionSummary className="w-100" expandIcon={<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 2L13 13L24 2" stroke="#595959" stroke-width="3" />
                                            </svg>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography className='font-style-normal font-weight-400 font-18-22 font-14-17  d-flex'>
                                                    <span className='font-style-normal font-weight-400 font-18-22 font-14-17 '>
                                                        {user.question}
                                                        {/* Where is the challenge taking place? */}
                                                    </span>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography >
                                                    <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>
                                                        {/* This is a virtual event. Teams will work together throughout the 
                                                        Three-day intense event using tools such as Zoom, Google Drive, and/or Slack etc. */}
                                                        {user.answer}
                                                    </p>
                                                    {/* <ul className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>
                    <li>Be incorporated as a private limited company (as defined in the Companies Act, 2013) OR registered as a partnership firm (under Section 59 of the Partnership Act, 1932) OR as a limited liability partnership (under the Limited Liability Partnership Act, 2008) in India</li>
                    <li>Have been incorporated/registered within the last seven years (in the case of startups in the biotechnology and/or social sector the period shall be up to ten years)</li>
                    <li>Not have exceeded a turnover of Rs.25 Cr in any of the financial years since its incorporation/registration</li>
                    <li>Be working towards innovation, development or improvement of products or processes or services, or if it is a scalable business model with a high potential of employment generation or wealth creation.Furthermore, startups must have a “minimum viable product” and be ready to demonstrate “proof of concept”. Idea-stage ventures shall not be considered at this point.</li>
                 </ul> */}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                      ))}
                                    {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 2L13 13L24 2" stroke="#595959" stroke-width="3"/>
  </svg>}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography ><span className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>What are the focus areas?</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>Startups in sectors that align with the mandate of the Government will be given a preference. Accordingly, seven sectors have been identified along with an additional eighth miscellaneous category for startups.</p>
              <ul className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>
                <li>Agriculture</li>
                <li>Education</li>
                <li>Governance</li>
                <li>Healthcare</li>
                <li>Mobility</li>
                <li>Skilling</li>
                <li>Smart Infrastructure</li>
                <li>Sustainability - Clean Energy</li>
                <li>Sustainability - Waste Management</li>
                <li>Sustainability - Water Management</li>
                <li>Miscellaneous</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 2L13 13L24 2" stroke="#595959" stroke-width="3"/>
  </svg>}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography >
              <span className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>What are the benefits of applying to the Maharashtra Startup Week?</span>
            </Typography>
            
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>24 startups shall receive Government work orders of up to Rs.15 lakhs each. The Government shall provide startups with access to personnel, data and other means of handholding while the startups pilot their solutions over a period of 6 to 12 months.</p>
              <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>Additionally, the Maharashtra State Innovation Society (MSInS) shall partner with multiple investors and incubators for the purpose of the Maharashtra Startup Week. These partner organisations shall consider the top 100 finalists for funding and mentorship. Select startups apart from the 24 winners shall also avail benefits like access to exhibition space and networking with VCs/ ecosystem partners, access to specific ecosystem events, free credits from our partners, international linkages and any other benefits as decided by MSInS.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 2L13 13L24 2" stroke="#595959" stroke-width="3"/>
  </svg>}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography><span className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>How will startups be evaluated?</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>Startups will be evaluated on the following parameters</p>
              <ul className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>
                <li>Unique Value Proposition</li>
                <li>Leadership Team</li>
                <li>Social Impact</li>
                <li>Sustainability & Scalability</li>
                <li>Relevance for government</li>
                <li>Technology Advantage (if any)</li>
              </ul>
              <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>Post screening, 100 startups will be shortlisted for the pitches which will take place during the Maharashtra Startup Week. The shortlisted startups will pitch to a panel that includes relevant government department officials, industry, academia and investors.</p>
            <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>The shortlisted startups would be allowed 10 minutes to present their pitch and another 10 minutes for any Q&A. The results will be declared on the last day of the Maharashtra Startup Week.</p>
            </Typography>
          </AccordionDetails>
        </Accordion> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MahaHackthonFaq