import React from 'react'
import { useEffect, useState } from 'react';
import CopyrightEligibility from './CopyrightEligibility';
// import Header from '../component/HeaderAdmin';
import Header from '../component/SuperadminHeader';
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Accordion } from 'react-bootstrap'
import ViewDomesticPatent from './Domesticpatent';
import ViewinternationalPatent from './internationalpatent';
import ViewCopyright from './Copyright'
import ViewTrademark from './Trademark';
import ViewDesign from './Design';
import ViewGrant from './PostGrant';
import ViewQuality from './QualityTesting';
import { environment } from '../api';
import ViewPost from './PostFiling';
// import ViewPostfilling from './PostFilling';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}
export default function IPRadmin() {
  const [value, setValue] = React.useState(0)

  const [value1, setValue1] = React.useState(0)
  const [value2, setValue2] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange1 = (event, newValue) => {
    setValue1(newValue)
  }
  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
  };

  const [isShown1, setIsShown1] = useState(false);

  const handleClick1 = event => {
    setIsShown1(current => !current);
  };

  const [isShown4, setIsShown4] = useState(false);

  const handleClick4 = event => {
    setIsShown4(current => !current);
  };

  const [isShown2, setIsShown2] = useState(false);

  const handleClick2 = event => {
    setIsShown2(current => !current);
  };
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const serverUrl = environment.baseUrl;
  // const [value, setValue] = React.useState(0);
  const [users, setUsers] = useState([]);
  const [mentor, setMentors] = useState([]);
  const [incubator, setIncubators] = useState([]);
  const [investor, setInvestor] = useState([]);
  const [nri, setNri] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });



  useEffect(() => {
    fetch(serverUrl + "/basicDetailsStartup/allBasicCount")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUsers(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

    fetch(serverUrl + "/mentorDetail/allmentorCount")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setMentors(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    fetch(serverUrl + "/nri/allnriCount")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setNri(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    fetch(serverUrl + "/incubatorDetail/getAllCount")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setIncubators(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    fetch(serverUrl + "/investor/allInvestorCount")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setInvestor(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])



  // const [showPanel, setShowPanel,] = useState(false);
  // const handleClick2 = () => setShowPanel(true);

  //   const [showPanel1, setShowPanel1] = useState(false);
  //   const panel1 = () => setShowPanel1(true);

  return (
    <>
      {/* <Header /> */}
      <div className=" basicFormMain">
        <Box
        // sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
        >
          <div className="row">
            <div className="col-sm-3 col-lg-3">
              <div className="leftsideacc">
                <div className="user_info p-3">
                  <div className="row">
                    <div className="col-sm-4 col-lg-4 d-flex align-items-center">
                      <div className="user_img">
                        {/* <img
                          src={userPic}
                          className="img-fluid"
                          alt="user Picture"
                        /> */}
                      </div>
                    </div>
                    <div className="col-sm-8 col-lg-8">
                      <div className="user_text arrow-hide">
                        <div className="user_titile">
                          Jayanth Rao
                          <p className="user_service">Super Admin</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tuza first Accordion a ithe finaicial cha */}
                <Accordion className="px-3 pt-3">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      onClick={handleClick}
                    > Financial Assistance</Accordion.Header>
                    <Accordion.Body>
                      {/* hyachya body madhe tuze IPR che dusara start hoto */}
                      <Accordion className="px-0 pt-0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>IPR</Accordion.Header>
                          <Accordion.Body
                          >
                            {/* IPR chya body madhe tze domasti n all tab yetil baki he tab che change nko karus kahich */}
                            <div >
                              <Tabs
                                className="w-100"
                                orientation="vertical"
                                variant="scrollable"
                                value={value} // value mule tula tuzya tab loaction la jata yete
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                              // sx={{ borderRight: 1, borderColor: 'divider' }}
                              >
                                <Tab
                                  className="textColor side-menu"
                                  label="Domestic Patent"
                                  {...a11yProps(0)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="International Patent"
                                  {...a11yProps(1)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="Copyright"
                                  {...a11yProps(2)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="Trademark"
                                  {...a11yProps(3)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="Design"
                                  {...a11yProps(4)}
                                />
                              </Tabs>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header
                      onClick={handleClick1}
                    >IPR Reimburesement</Accordion.Header>

                    <Accordion.Body>
                      <Tabs
                        className="w-100"
                        orientation="vertical"
                        variant="scrollable"
                        value={value1} // value mule tula tuzya tab loaction la jata yete
                        onChange={handleChange1}
                        aria-label="Vertical tabs example"
                      // sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="textColor side-menu"
                          label="Post Filling"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Post Grant"
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header
                      onClick={handleClick2}
                    >Quality Testing</Accordion.Header>

                    <Accordion.Body>
                      <div className="scrollcms">
                        <Tabs
                          className="w-100"
                          orientation="vertical"
                          variant="scrollable"
                          value={value2}
                          onChange={handleChange2}
                          aria-label="Vertical tabs example1"
                        // sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                          
                        </Tabs>


                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="scrollTab">

                {/* {showPanel ? <>
              <div>
                <h3>This is Financial Assistant</h3>
              </div>
              </> : null}
              {showPanel1 ? <>
              <div>
                <h3>This is IPR</h3>
              </div>
              </> : null} */}
                {!isShown && !isShown1 && !isShown2 && (
                  <>
                    <h1>GM..........</h1>
                    

                  </>
                )
                }
                {isShown && (
                  <>
                    {/* ha tuza Finanacial - IPR cha zone start hoto a hyachi value bagh  n index imp a */}
                    <TabPanel value={value} index={0} >
                      <ViewDomesticPatent />
                      {/* Tab 0 for edit  test first tab for Domastic */}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <ViewinternationalPatent />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <ViewCopyright/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <ViewTrademark/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <ViewDesign/>
                    </TabPanel>
                  </>
                )}
                {isShown1 && (
                  <>
                    <TabPanel value={value1} index={0}>
                    <ViewPost/>
                    </TabPanel>
                    <TabPanel value={value1} index={1}>
                    <ViewGrant/>
                    </TabPanel>

                  </>
                )}
                {isShown2 && (
                  <> <TabPanel value={value2} index={0}>
                     {/* <CopyrightEligibility></CopyrightEligibility> */}
                    <ViewQuality/>
                    </TabPanel>
                  </>
                )}
                {/* {isShown2 && (
                  <>
                   <TabPanel value={value3} index={3} id={copyright}>
                <CopyrightEligibility></CopyrightEligibility>
                </TabPanel>
                </>
                    )} */}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}
