import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const FeaturedResourseaftersearchAddM = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitAnnoucementForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("title",values.title)
    formData.append("link",values.link)
    formData.append("date",values.date)
    formData.append("description",values.description)
    function fetchData(){
      fetch(serverUrl + "/featureResource/allfeature")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    setUsers(data);
  },
)
}
    fetch(serverUrl +"/featureResource/addfeature", 
    {
      method: "POST",
      body: formData,
    
      
    })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
  }
  

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
    onSubmit={(values, {resetForm}
    ) =>
   { console.log(values )
    handleSubmitAnnoucementForm(values)
    resetForm({values:''}) 
  }
  }
    
    validationSchema={Yup.object({
      title: Yup.string().required('Enter Title is Required'),
      link: Yup.string().required('Upload Pdf is Required'),
      date: Yup.string().required('Enter Date is Required'),
      description: Yup.string().required('Enter Description is Required'),
       })}
      initialValues={{ 
        title: '',
        link: '',
        date:'',
        description:'',
       }}

      render={({ values, setFieldValue }) => (
        <Form >
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Title" className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>

                  {/* <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Link{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Link Name" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>                   
                  </div> */}

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  PDF File Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="link" aaccept="application/pdf,application/vnd.ms-excel"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("link", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div> 


                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Date <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="date" placeholder="Enter Date" className="textInput" name="date" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="date" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                       <Field as="textarea" placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" />
                    </div>
                  </div>
                 
             </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default FeaturedResourseaftersearchAddM;
