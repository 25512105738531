import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { environment } from '../api';
import axios from "axios";

function UserApprovalModel(props) {
  const serverUrl = environment.baseUrl;
 function  handleClick(screenerId) {

    if(window.confirm("Are you sure Approve Record !")){
    // let postData="screenerId="+screenerId+"&token=dfjkhsdfaksjfh3756237&ngoId=rakesh"; 
    let status = {
      id:props.rowData.userDetails._id,
      isStatus:"Active"
    };

    let _targetPostURL=serverUrl +`/users/updateOneRegUser/${props.rowData.userDetails._id}`;
   
    axios.put(serverUrl +`/users/updateOneRegUser/${props.rowData.userDetails._id}`,{
      isStatus:"Active"}
      // {
      //   method: 'put',
      //   url: _targetPostURL,
      //   data: status,
      //   headers: {'Content-Type': 'application/x-www-form-urlencoded' }
      //   }
  
    ).then(res=>{
      if(res.data.status===1){
        //alert("Updated Successfully")
        window.location.reload();
  
      }
                                
  
    })
    .catch(e=>{
    });
  }
  }

  

    return  <div className="container">
    <div className="row ">
      <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="justify-content-center d-flex py-4">
          <Stack direction="row" spacing={2}>
            {/* <Button variant="contained">Previous</Button> */}
            <Button
              variant="contained"
              type="submit"
              className="btn-color-next no-shadow"
              onClick={() =>handleClick()}
              // onClick={handleSubmitAnnoucementForm()}
            >
              Approve
            </Button>
          </Stack>

          
        </div>
    </div>
    </div>
    </div>
}

export default UserApprovalModel