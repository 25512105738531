import React from 'react'
import Header from '../../component/Header';
import Footer from '../../component/footer';

import Startupyatra from './startupYatraMiddleContent';


function startupyatraMain() {
  return (
    <>
    <Header/>
    <Startupyatra/>
    <Footer/>
    </>
  )
}

export default startupyatraMain