import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";

import * as Yup from "yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InformationIcon from "../../startup/informationIcon";
import { environment } from "../../api";
const serverUrl = environment.baseUrl;
const EventScheduleAddM = ({setUsers, setModalIsOpenToFalse3}) => {
 
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  function handleSubmitEventScheduleForm(values) {
    console.log(values, "hii");
    const formData = new FormData();
    formData.append("date_from", values.date_from);
    formData.append("date_to", values.date_to);
    formData.append("description", values.description);
    formData.append("image", values.image);
    function fetcheventshedule(){
            fetch(serverUrl + "/schedule/allSchedule")
      .then((res) => res.json())
      .then(
        (data) => {
          console.log(data, "dataaaaaaaaaaaa")
          setIsLoaded(true);
          setUsers(data);
        },
      )
    }
    console.log(formData);
    fetch(serverUrl + "/schedule/addSchedule", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => fetcheventshedule())
      .then((res) => setModalIsOpenToFalse3())
         .catch((err) => {
        console.log(err);
      });
      alert("Form Submitted Successfully");
    // const answer = window.confirm("Are you sure?");
    // if (answer) {
    //   // Save it!
    //   console.log("Thing was saved to the database.");
    //   alert("Form Submitted Successfully");
    // } else {
    //   // Do nothing!
    //   alert("Please fill out all fields");
    //   console.log("Thing was not saved to the database.");
    // }
  }
  console.log("called");

  return (
    <div className="container">
      <div className="row ">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {/* {message} */}
            <Formik
            onSubmit={(values, {resetForm}
            ) =>
           { console.log(values )
            handleSubmitEventScheduleForm(values)
            resetForm({values:''}) 
          }
          }
            validationSchema={Yup.object({
              date_from: Yup.string().required("Select From Date is required"),
              date_to: Yup.string().required("Select To Date is required"),
              description: Yup.string().required(
                "Enter Description is required"
              ),
              image: Yup.string().required("Image is required"),
            })}
            initialValues={{
              date_from: "",
              date_to: "",
              description: "",
              image: "",
            }}
            render={({ values, setFieldValue, handleSubmit }) => (
              <Form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                      Date From <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field
                      type="date"
                      placeholder="Select From date"
                      className="textInput"
                      name="date_from"
                    />
                    <div className="text-danger">
                      <ErrorMessage className="error" name="date_from" />
                      <br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                      Date To <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field
                      type="date"
                      placeholder="Select To date"
                      className="textInput"
                      name="date_to"
                    />
                    <div className="text-danger">
                      <ErrorMessage className="error" name="date_to" />
                      <br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                      Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field
                      as="textarea"
                      placeholder="Enter Description"
                      className="textInput"
                      name="description"
                    />
                    <div className="text-danger">
                      <ErrorMessage className="error" name="description" />
                      <br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("image", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>
                </div>
                <div className="justify-content-center d-flex py-4">
                  <Stack direction="row" spacing={2}>
                    {/* <Button variant="contained">Previous</Button> */}
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-next no-shadow"
                    >
                      Add
                    </Button>
                  </Stack>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default EventScheduleAddM;
