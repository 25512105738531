import React, { useState, useEffect }  from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Arrow from "../../assets/images/FundResourc/arrow-visit.png";
import Pdf from "../../assets/images/EcoSystem/922916महिला उद्योजकता कक्ष.pdf";
import MaharashtraWomenEntrepreneurshipScorecard from '../../assets/document/Maharashtra_Women_Entrepreneurship_Scorecard.pdf';
import ReportonDevelopingWomenEntrepreneurshipPolicyforMaharashtra from '../../assets/document/Report_on_Developing_Women_Entrepreneurship_Policy_for_Maharashtra.pdf';
import { BsArrowUpRight } from "react-icons/bs";
import {Link} from "react-router-dom";
import { environment } from '../../api';

export default function Wec() {
  const serverUrl = environment.baseUrl;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(serverUrl +"/WEC/allWEC")
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setUsers(data);
                console.log(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
  }, [])

  return (
    <div className="container topPaddingWEC">
      <div className="wecDetails">
        <div className="row no-gutters d-none d-lg-flex ">
          <div className="col-md-3 d-flex wecLink flex-item nopadding">
            <div className="linkdata ">
              <h5 className="mb-0 font-22-26 font-20-26">Links</h5>
              <i className="BsArrowUpRight " />
              <h6 className="font-18-22 font-14-17">
                <a href={Pdf} target="_blanc">
                  <span className="wec-arrow">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      className="d-sm-inline d-none"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                      />
                    </svg>
                  </span>{" "}
                  Women Entrepreneurship Cell Goverment Resolution
                </a>
              </h6>
              <h6 className="font-18-22 font-14-17">
                {" "}For any queries, please feel free to reach out to <a href="/">monika@msins.in</a>
              </h6>
            </div>
          </div>
          <div className="col-md-9 d-flex justify-content-center part1 flex-item ">
            <div className="wecContent">
              <h2 className="title my-2 font-20-26 font-28-36 py-2">
                Women Entrepreneurship Cell
              </h2>
              <div className="font-18-22 font-14-17 pb-3 justify">
                <p className="justify">
                  In January 2021, Maharashtra State Innovation Society set up a 
                <b>Women Entrepreneurship Cell (WEC)</b> with a clear one-point focus
                  of planning and undertaking initiatives for spurring women
                  entrepreneurship in the state of Maharashtra. WEC will work in
                  collaboration with public as well as private sector
                  organizations and bring them on a single platform by listing
                  their existing women-focused entrepreneurship schemes,
                  initiatives, and programs.It shall also enable sharing of best
                  practices amongst women entrepreneurs and partner
                  organizations and promote evidence-based policy making for
                  women entrepreneurship.
                    </p>
                <p>
                  Incubation plays a pivotal role in establishing a strong
                  foundation for early stage entrepreneurs, hence MSInS has
                  established a dedicated women focused incubator in the state
                  in<b> Partnership with SNDT Women’s university, Mumbai.</b> As per
                  the mandate of the Maharashtra State Innovative Startup Policy
                  2018, MSInS is supporting and scaling state incubators through
                  a grant-in-aid of INR 5 Cr each over a stipulated time. We
                  strongly believe that setting up an incubator at SNDT Women’s
                  University will play a strategic role in supporting women
                  entrepreneurs in the state. The SNDT incubation center could
                  be envisaged as a hub of women centric entrepreneurial
                  activity and can run dedicated accelerator programs, provide
                  necessary technical / business mentoring, investor connects
                  and market access.
                </p>
                <p className="pb-3">
                  The Cell shall undertake various initiatives to support
                  state’s women entrepreneurs and such mechanism ensures a more
                  conducive environment for women to take up entrepreneurship.
                  The Cell has planned various activities to support women led
                  start-ups through different programs like Acceleration
                  programs, Mentorship and Incubation support, Financial
                  assistance & Compliance support. The Cell entails to provide a
                  holistic support and thus programs like Hirkani Maharashtrachi
                  have been designed to support women from rural backgrounds.
                </p>
                <hr className="wecHrLine " />
                <p className="pt-2">
                  Women entrepreneurs are also eligible to take benefit from
                  existing and future initiatives of MSInS:
                </p>

                <ul>
                  <li>
                    {" "}The Startup Week where 24 startups are eligible for
                    work orders of INR 15 lakhs each
                  </li>
                  <li>
                    {" "}Innovation Society-supported incubators being set up
                    across the State
                  </li>
                  <li>
                    {" "}Patent Support: Grants of up to INR 2 lakhs for
                    domestic patents and up to INR 10 lakhs for international{" "}
                    patents
                  </li>
                  <li>
                    {" "}Quality Testing & Certification: Grants of up to INR 2
                    lakhs for quality testing & certification.
                  </li>
                </ul>

                <p className="mb justify">
                  A woman entrepreneur for the purpose of WEC may be any “Any
                  enterprise/business/team/start-up working on innovative
                  solutions and have a woman
                  founder/co-founder(s)/director(s)/CXO(s)/partner(s).
                </p>

                <h2 className="title my-2 font-20-26 font-28-36 py-2">
                Women Entrepreneurship Scorecard and Developing a Women Entrepreneurship Policy for Maharashtra
              </h2>
              <p className="mb justify">
              Discover the dynamic landscape of women entrepreneurship in Maharashtra through the collaborative efforts of the Maharashtra State Innovation Society (MSInS) and the Global Alliance with Mass Entrepreneurship (GAME). This partnership has culminated in the creation of a comprehensive state index on women's entrepreneurship, serving as the foundation for crafting strategic recommendations to shape a robust Women Entrepreneurship Policy for Maharashtra. The project involves primary and secondary research, analysis of international policies, and engaging interviews with key stakeholders such as startups, civil society organizations, and government bodies to gather insights, resulting in a finalized set of reports on recommendations for prospective Women Entrepreneurship Policy and Scorecard. These reports, now available, offer valuable insights for stakeholders to comprehend and enhance the women entrepreneurship ecosystem in Maharashtra. 
                </p>
                <p>Launched on 06/03/2024.</p>
                <p>Report Links:</p>
                <p>
                <Link to={MaharashtraWomenEntrepreneurshipScorecard} without rel="noopener noreferrer" target="_blank">
                Maharashtra Women Entrepreneurship Scorecard
                </Link>
                </p>
                <p>
                <Link to={ReportonDevelopingWomenEntrepreneurshipPolicyforMaharashtra} without rel="noopener noreferrer" target="_blank">
                Report on Developing Women Entrepreneurship Policy for Maharashtra
                </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters d-lg-none d-block">
         
          <div className="col-md-12 d-flex justify-content-center part1 flex-item">
            <div className="wecContent">
              <h2 className="title my-2 font-20-26 font-28-36 ">
                Women Entrepreneurship Cell
              </h2>
              <div className="font-18-22 font-14-17 pb-3 justify">
                <p className="justify">
                  In January 2021, Maharashtra State Innovation Society set up a <b>
                  Women Entrepreneurship Cell (WEC)</b> with a clear one-point focus
                  of planning and undertaking initiatives for spurring women
                  entrepreneurship in the state of Maharashtra. WEC will work in
                  collaboration with public as well as private sector
                  organizations and bring them on a single platform by listing
                  their existing women-focused entrepreneurship schemes,
                  initiatives, and programs.It shall also enable sharing of best
                  practices amongst women entrepreneurs and partner
                  organizations and promote evidence-based policy making for
                  women entrepreneurship.
                </p>
                <p>
                  Incubation plays a pivotal role in establishing a strong
                  foundation for early stage entrepreneurs, hence MSInS has
                  established a dedicated women focused incubator in the state
                  in Partnership with SNDT Women’s university, Mumbai. As per
                  the mandate of the Maharashtra State Innovative Startup Policy
                  2018, MSInS is supporting and scaling state incubators through
                  a grant-in-aid of INR 5 Cr each over a stipulated time. We
                  strongly believe that setting up an incubator at SNDT Women’s
                  University will play a strategic role in supporting women
                  entrepreneurs in the state. The SNDT incubation center could
                  be envisaged as a hub of women centric entrepreneurial
                  activity and can run dedicated accelerator programs, provide
                  necessary technical / business mentoring, investor connects
                  and market access.
                </p>
                <p className="pb-3">
                  The Cell shall undertake various initiatives to support
                  state’s women entrepreneurs and such mechanism ensures a more
                  conducive environment for women to take up entrepreneurship.
                  The Cell has planned various activities to support women led
                  start-ups through different programs like Acceleration
                  programs, Mentorship and Incubation support, Financial
                  assistance & Compliance support. The Cell entails to provide a
                  holistic support and thus programs like Hirkani Maharashtrachi
                  have been designed to support women from rural backgrounds.
                </p>
                <hr className="wecHrLine " />
                <p className="pt-2">
                  Women entrepreneurs are also eligible to take benefit from
                  existing and future initiatives of MSInS:
                </p>

                <ul>
                  <li>
                    {" "}The Startup Week where 24 startups are eligible for
                    work orders of INR 15 lakhs each
                  </li>
                  <li>
                    {" "}Innovation Society-supported incubators being set up
                    across the State
                  </li>
                  <li>
                    {" "}Patent Support: Grants of up to INR 2 lakhs for
                    domestic patents and up to INR 10 lakhs for international{" "}
                    patents
                  </li>
                  <li>
                    {" "}Quality Testing & Certification: Grants of up to INR 2
                    lakhs for quality testing & certification.
                  </li>
                </ul>

                <p className="mb">
                  A woman entrepreneur for the purpose of WEC may be any “Any
                  enterprise/business/team/start-up working on innovative
                  solutions and have a woman
                  founder/co-founder(s)/director(s)/CXO(s)/partner(s).
                </p>
                <h2 className="title11 my-2 font-20-26 font-28-36 ">
                Women Entrepreneurship Scorecard and Developing a Women Entrepreneurship Policy for Maharashtra
              </h2>
              <p className="mb justify">
              Discover the dynamic landscape of women entrepreneurship in Maharashtra through the collaborative efforts of the Maharashtra State Innovation Society (MSInS) and the Global Alliance with Mass Entrepreneurship (GAME). This partnership has culminated in the creation of a comprehensive state index on women's entrepreneurship, serving as the foundation for crafting strategic recommendations to shape a robust Women Entrepreneurship Policy for Maharashtra. The project involves primary and secondary research, analysis of international policies, and engaging interviews with key stakeholders such as startups, civil society organizations, and government bodies to gather insights, resulting in a finalized set of reports on recommendations for prospective Women Entrepreneurship Policy and Scorecard. These reports, now available, offer valuable insights for stakeholders to comprehend and enhance the women entrepreneurship ecosystem in Maharashtra. 
                </p>
                <p>Launched on 06/03/2024.</p>
                <p>Report Links:</p>
                <p>
                <Link to={MaharashtraWomenEntrepreneurshipScorecard} without rel="noopener noreferrer" target="_blank">
                Maharashtra Women Entrepreneurship Scorecard
                </Link>
                </p>
                <p>
                <Link to={ReportonDevelopingWomenEntrepreneurshipPolicyforMaharashtra} without rel="noopener noreferrer" target="_blank">
                Report on Developing Women Entrepreneurship Policy for Maharashtra
                </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex wecLink flex-item nopadding">
            <div className="linkdata ">
              <h5 className="mb-0 font-22-26 font-20-26">Links</h5>
              <i className="BsArrowUpRight " />
              <h6 className="font-18-22 font-14-17">
                <a href={Pdf} target="_blanc">
                  <span className="wec-arrow">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      className="d-sm-inline d-none"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                      />
                    </svg>
                  </span>{" "}
                  Women Entrepreneurship Cell Goverment Resolution
                </a>
              </h6>
              <h6 className="font-18-22 font-14-17">
                {" "}For any queries, please feel free to reach out to <a href="/">monika@msins.in</a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
}
