import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Form } from 'react-bootstrap-v5'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'

function StartupApprovalModel() {
  return (
    <>
      <Form>
        <div className='row'>
            <div>
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600">
                  Department
                </h2>
            </div>
        </div>
        <FormGroup>

          <FormControlLabel
            control={<Checkbox />}
            label="Directorate of Skill Delvelopment, Employment and Enterpreneurship"
          />
          <FormControlLabel
            control={<Checkbox/>}
            label="PwD"
          />
          <FormControlLabel
            control={<Checkbox/>}
            label="MSSDS"
          />
          <FormControlLabel
            control={<Checkbox/>}
            label="MSInS"
          />
          <FormControlLabel
            control={<Checkbox/>}
            label="Agriculture"
          />
           <FormControlLabel
            control={<Checkbox  />}
            label="Environment"
          />
        </FormGroup>
        <div className="justify-content-center d-flex py-4">
          <Stack direction="row" spacing={2}>
            {/* <Button variant="contained">Previous</Button> */}
            <Button
              variant="contained"
              type="submit"
              className="btn-color-next no-shadow"
            >
              Save
            </Button>
          </Stack>
        </div>
      </Form>
    </>
  )
}

export default StartupApprovalModel
