export default function validate(data) {
  let errors = {};
  if (!data.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!data.password) {
    errors.password = 'Password is required';
  } else if (data.password.length < 8) {
    errors.password = 'Password must be 8 or more characters';
  }
  if (!data.first_name) {
    errors.first_name = 'first name is required';
  }
  if (!data.last_name) {
    errors.last_name = 'last name is required';
  }
  if (!data.mobile_no) {
    errors.mobile_no = 'mobile number is required';
  }
  return errors;
   
  // return errors;
};
