import React from 'react'
import { Link } from "react-router-dom";
import Header from '../../component/Header';
import Footer from '../../component/footer';

function pressNote() {
  return (
    <>
    <Header/>
    <div className="mahaGrand-main intellectual-main">
      <div className="container">
        <div className="row d-flex justify-content-center my-3">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row" id="granchallenge">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
              Startup India Yatra
              </h2>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
              Maharashtra State Innovaion Society organised Maharashtra leg of Startup India Yatra in collaboration with Startup India and Department of Industrial Policy and Promotion (DIPP), Government of India. The Yatra was inaugurated 3rd October 2018, from Raj Bhavan in the presence of Shri Ch. Vidyasagar Rao, Hon’ble Governor of Maharashtra, Shri Devendra Fadnavis - Hon’ble Chief Minister of Maharashtra, Shri Suresh Prabhu - Hon’ble Union Minister and Shri Vinod Tawde - Hon’ble Minister.
                {/* <ol>
                  <li>The Startup Week where 24 startups are eligible for work orders of INR 15 lakhs each</li>
                  <li>Innovation Society-supported incubators being set up across the State</li>
                  <li>Patent Support: Grants of up to INR 2 lakhs for domestic patents and up to INR 10 lakhs for international patents</li>
                  <li>Quality Testing &#38; Certification: Grants of up to INR 2 lakhs for quality testing &#38; certification</li>
                </ol> */}
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                Startup India Yatra has been initiated by Invest India (The National Investment Promotion and Facilitation Agency) in line with the Hon’ble Prime Minister’s Startup India vision, which offers a platform for disruptive ideas from tier 2-3 cities. Startup culture has taken a hold and is producing many entrepreneurs with innovative ideas in these cities.
                
                  
               </p>
               <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
               
                The main attraction of Yatra is boot camps comprising of train the trainer workshop, ideation workshop, pitching session and motivational talks to scout young entrepreneurs. The yatra also had a Van which travelled through 26 districts in Maharashtra. The purpose of this van was to spread awareness regarding the Startup Yatra and encourage students and young entrepreneurs to participate in the Bootcamp sessions and pitch their ideas. The Bootcamps which were organized in 14 colleges/universities in 14 districts of Maharashtra. Participants were trained through ideation workshop to pitch their ideas to the jury panels. Best ideas were selected from each bootcamp which competed in the Grand Finale.
                
                  
               </p>
               <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
               The Grand Finale in Nagpur had an Accelerator Bootcamp on 1st November where the participants were given relevant training to help them pitch better. The second day had idea pitching sessions. Yatra grand finale was attended by Shri Nitin Gadkari- Hon’ble Union Minister, Shri Chandrashekhar Bawankule - Hon’ble Guardian Minister of Nagpur.
               </p>
               <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
               We have sensitised more than 40 colleges, their respective faculty on promotion of entrepreneurship and innovation amongst college students. Over 3000 people registered for the bootcamps and approximately 1000 teams pitched their ideas in front of jury. Around 300 of the best ideas from all over Maharashtra competed at the Grand Finale. 19 winners were recognized and awarded among multiple categories, each receiving cash prizes totalling to Rs 10,50,000.
               </p>
               <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
               Further, pre-incubation offers were given to 101 teams by our incubation partners like UnLtd India, TCS DISQ, MAGIC, RIDL etc. wherein they are supported by providing infrastructure, mentor service, etc to develop a viable product to cater the mass. 

               </p>
               </p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  )
}

export default pressNote