import React, { useState, useEffect } from 'react'
// import { NavLink } from "react-router-dom";
import { Link, NavLink } from 'react-router-dom'
import NavLogo from '../assets/images/navbar/navbar_logo.png'
import NavLogoWhite from '../assets/images/navbar/navbar_lg_black.png'
import NavImg from '../assets/images/navbar/nav-img.png'
import bussinessplan from '../assets/images/Initiative/bussinessplan.png'
import grandchallenge from '../assets/images/Initiative/grandchallenge.png'
import healthhack from '../assets/images/Initiative/healthhack.png'
import Intellectual from '../assets/images/Initiative/qualitytesting.png'
import startup from '../assets/images/Initiative/startup.png'
import startupyatra from '../assets/images/Initiative/startupyatra.png'
import dropdownIcon from '../assets/images/Initiative/dropdownIcon.png'
import verticalarrow from '../assets/images/Initiative/verticalarrow.png'
import qualitytesting from '../assets/images/Initiative/qualitytesting.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap'

const lang_btn = {
  borderRadius: '18px',
  height: '33px',
}

const icon_btn = {
  color: 'white',
  fontSize: '20px',
}

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'hin',
    name: 'हिन्दी',
    country_code: 'fr',
  },

  {
    code: 'mar',
    name: 'मराठी',
    dir: 'rtl',
    country_code: 'sa',
  },
]


export default function NavigationSuperadmin() {
  const [userToken, setToken] = useState(false)
  const removeToken = (userToken) => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("_id");
    sessionStorage.removeItem("admin");
    setToken(null);
};

    const [btnClass, setBtnClass] = useState(false)
  const currentLanguageCode = cookies.get('i18next') || 'en'
  console.log(currentLanguageCode)
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  console.log(currentLanguage)

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('example.Msins')
  }, [currentLanguage, t])

  const [click, setClick] = React.useState(false)
  const [isLoggedin, setIsLoggedin] = useState(false)
  let navigate = useNavigate()
  const handleClick = () => setClick(!click)
  const Close = () => setClick(false)
  const logout = () => {
    sessionStorage.removeItem('email')
    setIsLoggedin(false)
    alert('Logout Successfull')
    navigate('/Superadmin', { replace: true })


    
    // window.location.replace('http://3.109.215.92/Login')
  }
  const [isNavCollapsed, setIsNavCollapsed] = useState(true)

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)
  return (
    <>
      <div className="w-100">
        <div
          className={click ? 'main-container' : ''}
          onClick={() => Close()}
        />
        <nav className="navbar navbar-expand-lg py-3">
          <div className="nav-container w-100">
            <NavLink exact to="/" className="nav-logo">
              <img src={NavLogo} alt="navlogo" className="img-fluid whiteogo" />
            </NavLink>
           
            <div className="nav-item navRightIconPad d-lg-none">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                <a className="nav-links" href="/">
                  <i>
                    {' '}
                    <img style={{ icon_btn }} src={NavImg} />
                  </i>
                </a>
              </NavLink>
            </div>

            <div
              className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
              id="navbarsExample09"
            >
             

              <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                
                {/* <li className="nav-item">
                  <NavLink
                    exact
                    to="/About"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      About
                    </span>
                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        About
                      </span>
                    </span>
                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li> */}
                
                <li style={{ width: '100%' }}>
                {/* <NavDropdown
                    title="Initiative"
                    className="mainInitative initiative-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                    <div 
                   
                    id="style-4">
                      <NavDropdown.Item>
                        <Link to={'/MaharashtraStartupMain'} className="button">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Startup Week</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item> */}
                      {/* <NavDropdown.Item>
                        <Link to={'/mahaGrandMain'} className="button">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-6">
                                <h6>Maharashtra Grand Challenge</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item> */}
                      {/* <NavDropdown.Item href="#action/3.3">
                        <Link
                          to='/mahaHackathonMain'
                         
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Maharashtra Health Hackathon</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item> */}

                      {/* ==================== */}
                      {/* <NavDropdown
                    title="Financial Assistance"
                    className="subinitative initiativeSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown
                    title="Intellectual Property Rights"
                    className="subSubinitative initiativeSubSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                       <NavDropdown.Item href="/Login">
                        <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Apply Now</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Intellectual">
                        <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Details</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>

                  </NavDropdown>

                  <NavDropdown
                    title="Quality Testing and Certification"
                    className="subSubinitative initiativeSubSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                       <NavDropdown.Item href="/Login" >
                      <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Apply Now</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/QualityTesting">
                        <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Details</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>
                  </NavDropdown>
                   </NavDropdown> */}
                    
                      {/* <NavDropdown.Item href="#action/3.5">
                        <Link
                          to={'/Startupyatra'}
                          
                          className="button"
                        >
                          <div className="row px-2 pt-1">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12 pt-1">
                                <h6>Startup Yatra</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.6">
                        <Link
                          to={'/businessplan'}
                          
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Business Plan Competition</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item> */}
                      {/* <NavDropdown.Item href="">
                        <Link
                          to={'/Incubator'}
                          onClick={click ? handleClick : null}
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Incubators Supported</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item> */}
                      {/* <NavDropdown.Item href="">
                        <Link
                          to={'/Initiative'}
                          onClick={click ? handleClick : null}
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Upcoming & Past Events</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item> */}
                    {/* </div>
                  </NavDropdown>*/}
                </li> 
                {/* <li className="nav-item">
                  <NavLink
                    exact
                    to="/Ecosystem"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      Ecosystem
                    </span>
                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Ecosystem
                      </span>
                    </span>
                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/Resources"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      {' '}
                      Resources
                    </span>
                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Resources
                      </span>
                    </span>

                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li> */}
                {/* <li className="nav-item navContactLeftPadd">
                  <NavLink
                    exact
                    to="/Contact"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      {' '}
                      Contact Us
                    </span>

                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Contact Us
                      </span>
                    </span>
                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item navContactLeftPadd">
                  <NavLink
                    exact
                    to="/DistrictLogin"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      {' '}
                      District Login
                    </span>

                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                      District Login
                      </span>
                    </span>
                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
              </li> */}
              </ul>
           
            </div>
           
             
            <div className="d-sm-block">
              <NavLink
                exact
                to="/Superadmin"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {/* <a className="nav-links-btn regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block pr-3" href="/">
                  Logout
                  <i> <img  style={{ icon_btn }}  src={NavImg}/></i>
                </a> */}
                <a
                  className="d-flex justify-content-end align-items-end nav-links-btn regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block pr-3"
                  href="/"
                  onClick={removeToken}
                  // onClick={logout}
                >
                  Logout
                  {/* <i> <img  style={{ icon_btn }}  src={NavImg}/></i> */}
                </a>
              </NavLink>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
