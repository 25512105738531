import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import moment from "moment";

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }

function PreviousEventsViewM(props) {
    const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {/* <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
    >
     
      <Tab className="txt-main-style" label="Basic details" {...a11yProps(0)} />
      <Tab className="txt-main-style" label="Other Details" {...a11yProps(1)} />
      
    </Tabs> */}
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-3">
            <div className="">
              <label>
                {' '}
                <b>Title :</b>
              </label>
            </div>
          </div>
          <div className="col-md-9">
            <div className="">
              <span>
              {props.rowData.upEvents.title}
              </span>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-3">
            <div className="">
              <label>
                {' '}
                <b>Date :</b>
              </label>
            </div>
          </div>
          <div className="col-md-9">
          
            <span>{moment(props.rowData.upEvents.current_date).utc().format('YYYY-MM-DD')}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-3">
            <div className="">
              <label>
                {' '}
                <b>Card Background :</b>
              </label>
            </div>
          </div>
          <div className="col-md-9">
            <span>
            {props.rowData.upEvents.bgCard}
            </span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-3">
            <div className="">
              <label>
                {' '}
                <b>Text Color :</b>
              </label>
            </div>
          </div>
          <div className="col-md-9">
            <span>{props.rowData.upEvents.txtColor}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-3">
            <div className="">
              <label>
                {' '}
                <b>Button Background :</b>
              </label>
            </div>
          </div>
          <div className="col-md-9">
            <span>{props.rowData.upEvents.btnbg}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-3">
            <div className="">
              <label>
                {' '}
                <b>Image :</b>
              </label>
            </div>
          </div>
          <div className="col-md-9">
          <img src={props.rowData.upEvents.image} style={{width:'20%', height:'50%'}} />
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-3">
            <div className="">
              <label>
                {' '}
                <b>Description :</b>
              </label>
            </div>
          </div>
          <div className="col-md-9">
            <span>{props.rowData.upEvents.description}</span>
          </div>
        </div>
        
      </TabPanel>
    </Box>
  )
}

export default PreviousEventsViewM