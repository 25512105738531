import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { environment } from '../api'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'

function LoginModal() {
    const loginUrl = environment.LoginLink
 
  return (
    <>
   
    <div className="justify-content-center d-flex py-4">
          <Stack direction="row" spacing={2}>
            {/* <Button variant="contained">Previous</Button> */}
             

            <a href={loginUrl} target="_blank">
             <Button
              variant="contained"
              type="submit"
              className="btn-color-next no-shadow text-white"
             
            >
              Ok
            </Button></a>
          </Stack>
        </div>
        </>
  )
}

export default LoginModal;