import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const KeyInitiativeUpdateM = (props, {setModalIsOpenToFalse2}) => {
  const [isLoaded, setIsLoaded] = useState(false);
// console.log(props)

  function handleSubmitKeyInitiativeForm(values){
    
    const formData = new FormData();
    formData.append("title",values.title)
    formData.append("description",values.description)
    formData.append("cardBackground",values.cardBackground)
    formData.append("textColor",values.textColor)
    formData.append("image",values.image)
    formData.append("link",values.link)
    function fetchData(){
      fetch(serverUrl + "/aboutKey/allKeyInitiative")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    fetch(serverUrl +`/aboutKey/${props.rowData.keyInitiative._id}`,
            {
              method: "PUT",
              body: formData,
            
              
            })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("Are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitKeyInitiativeForm(values)
    }
    validationSchema={Yup.object({
      title: Yup.string().required('Enter Title is required'),
      description: Yup.string().required('Enter description is required'),
      // cardBackground: Yup.string().required('Select Card Background color is required'),
      // textColor: Yup.string().required('Select Text color is required'),
      image: Yup.string().required('Image  is required'),
      link: Yup.string().required('Enter Link is Required'),
       })}
      initialValues={{ 
        title:props.rowData.keyInitiative.title,
      cardBackground: props.rowData.keyInitiative.cardBackground,
      description: props.rowData.keyInitiative.description,
      textColor: props.rowData.keyInitiative.textColor,
      image: props.rowData.keyInitiative.image,
      link: props.rowData.keyInitiative.link,
       }}
      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Title " className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>

        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Color{" "}
                      {/* <span className="text-danger">*</span> */}
                      <InformationIcon />
                    </label>
                    <Field type="color"                    
                      onChange={handleChange}
                      placeholder="Enter Card Type" className="textInput" name="textColor" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="textColor" /><br></br>
                    </div>                    */}
                  </div>   
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Card Background{" "}
                      {/* <span className="text-danger">*</span> */}
                      <InformationIcon />
                    </label>
                    <Field type="color"                    
                      onChange={handleChange}
                      placeholder="Enter Card Type" className="textInput" name="cardBackground" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="cardBackground" /><br></br>
                    </div>                    */}
                  </div>                                
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea"
                      onChange={handleChange}
                     placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" /><br></br>
                    </div>
                  </div>  
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Link{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Link " className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>                   
                  </div>                
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img src={values.image} style={{width:'20%', height:'50%'}} /><br></br>

                        <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" 
                    
                     onChange={(e) => {
                      console.log(e.target.files[0])
                         setFieldValue("image", e.target.files[0]);
                         handleChange();
                      }} 

                      required/>     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" />
                    </div>
                  </div>                 
                                
        </div>
        <div className="justify-content-center d-flex">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default KeyInitiativeUpdateM;
