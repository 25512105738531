import React from 'react'
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;

export default function DepartmentAddM() {

  const [sector, setSector] = useState();
  const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
  const [userstate, setUserstate] = useState([]);
  const [state, setState] = useState();
  useEffect(() => {
    fetch(serverUrl + "/stateList/allState")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUserstate(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []) 

  const handleState = (values) => {
    const getState = values.target.value;
    setSector(getState);
}

const [domainExpert, setDomainExpert] = useState([]);
const [experts, setExperts] = useState();
useEffect(() => {
  fetch(serverUrl + "/domainExpert/allDomainExperts")
    .then(res => res.json())
    .then(
      (data) => {
        setIsLoaded(true);
        setDomainExpert(data);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
}, []) 

const handleDomainExpert = (values) => {
  const getDomainExpert = values.target.value;
  setSector(getDomainExpert);
}





  function handleSubmitBusinessModelAddForm(values) {
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name", values.title)
    fetch(serverUrl + "/department/addDepartment",
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          accept: "application/json",

        },
        body: JSON.stringify(values, null, 2)

      })
      .then(res => res.json())
      .then(res => console.log(res))
      .catch((err) => { console.log(err) })
    const answer = window.confirm("are you sure?");
    if (answer) {
      // Save it!
      console.log("Thing was saved to the database.");
      alert("Form Submitted Successfully");

    } else {
      // Do nothing!
      alert("Please fill out all fields");
      console.log("Thing was not saved to the database.");
    }
  }


  return <div className="container">
    <div className="row ">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {/* {message} */}
        <Formik
          onSubmit={(values
          ) =>
            handleSubmitBusinessModelAddForm(values)
          }
          validationSchema={Yup.object({
            name: Yup.string().required('Enter Name is Required'),

          })}
          initialValues={{
            name: '',
            state:'',
            domain_of_experties:'',
            department_website:'',
            details:'',
            select_department:'',
            department_type:'',

          }}

          render={({ values, setFieldValue, handleChange }) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <label className="pb-2 label-txt">
                     Department Name{" "}
                    <span className="text-danger">*</span>
                    {/* <InformationIcon /> */}
                  </label>
                  <Field type="text" placeholder="Enter Department Name" className="textInput" name="name" />
                  <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 pl-2">
                  {/* <h6>STATE</h6> */}
                  <label className="pb-2 label-txt">
                      State <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                  <select type="select" name="state" className="form-control form-select size"
                    value={values.state}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("state", e.target.value)
                      handleState(e)
                    }}
                  >
                    <option value={""}>Select State</option>
                      {
                        userstate.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                  <div className="text-danger">
                    <ErrorMessage className="error" name="State" /><br></br>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 pe-3">
                  {/* <h6>STATE</h6> */}
                 
                  <label className="pb-2 label-txt">
                  Domain of Expertise <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                  <select type="select" name="domain_of_experties" className="form-control form-select size"
                    value={values.domain_of_experties}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("state", e.target.value)
                      handleState(e)
                    }}
                  >
                    <option value={""}>Select State</option>
                      {
                        domainExpert.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                  <div className="text-danger">
                    <ErrorMessage className="error" name="domain_of_experties" /><br></br>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 pl-2">
                  <label className="pb-2 label-txt">
                    Department Website{" "}
                  </label>
                  <Field type="text" placeholder="Enter website" className="textInput" name="department_website" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <label className="pb-2 label-txt">
                    Detail{" "}
                  </label>
                  <Field type="text" placeholder="Enter detail" className="textInput" name="details" />
                </div>
                <div className="col-lg-6 col-md-6 pl-2">
                  <label className="pb-2 label-txt">
                    Department Type{" "}
                  </label><br />
                  <Field type="radio" name="department_type" value="sub department" />
                  <label className='pb-2 ms-3'>
                    Sub Department
                  </label><br />
                  <Field type="radio" name="department_type" value="main department" />
                  <label className='pb-2 ms-3'>
                    Main Department
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/* <h6>STATE</h6> */}
                  <label className="pb-2 label-txt">
                    Select Department{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <select name="select_department" className="form-control size"
                  // value={state}
                  // onChange={(e) => {
                  //   console.log(e.target.value, "8777")
                  //   // setstate("state", e.target.value)
                  //   handleCategory(e)
                  // }}
                  >
                    <option>--Select Department--</option>
                    {/* {
                      users.map((user) => {
                        return (
                          <option key={user._id} value={user._id}> {user.name} </option>
                        )
                      })
                    } */}
                  </select>
                  <div className="text-danger">
                    <ErrorMessage className="error" name="select_department" /><br></br>
                  </div>
                </div>
              </div>
              <div className="justify-content-center d-flex py-2">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>

            </Form>
          )}
        />
      </div>
    </div>
  </div>
}
