import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../startup/informationIcon";
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const serverUrl = environment.baseUrl;
const BasicDetails = (props) => {
    console.log(JSON.parse(sessionStorage.getItem('user_id')), 'incubatorDetails')
    const authToken =sessionStorage.getItem("token");
    console.log(authToken, "sessionTkennnnnn");
    
  let initialFormObj={ 
 name_of_individual_firm:'',
 type_of_incubator:'',
 category:'',

teamDetails: [{
  name:'',
  designation:'',
  email:'',
  phone_number:'',
  qualification:''
      }],
 state:'',
 district:'',
 city:'',
 incubation_status:'',
 focus_industries:'',
 date_of_incorporation:'',
 website_url:'',
 address:'',
 government_fund:'',
   
   }
   const [sector, setSector] = useState();
   const [error, setError] = useState(null);
   const [isLoaded, setIsLoaded] = useState(false);

   const [userstate, setUserstate] = useState([]);
  const [state, setState] = useState();
  useEffect(() => {
    fetch(serverUrl + "/stateList/allState")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUserstate(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []) 
  const handleState = (values) => {
    const getState = values.target.value;
    setSector(getState);
}

  const [userdistrict, setUserDistrict] = useState([]);
const [district, setDistrict] = useState();
useEffect(() => {
  fetch(serverUrl + "/districtList/allDistricts")
    .then(res => res.json())
    .then(
      (data) => {
        setIsLoaded(true);
        setUserDistrict(data);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
}, []) 
 const handleDistrict= (values) => {
  const getState = values.target.value;
  setSector(getState);
}
  function handleSubmitBasicDetailsForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name_of_individual_firm",values.name_of_individual_firm)
    formData.append("type_of_incubator",values.type_of_incubator)
    formData.append("category",values.category)
    formData.append("name",values.name)
    formData.append("designation",values.designation)
    formData.append("email",values.email)
    formData.append("phone_number",values.phone_number)
    formData.append("qualification",values.qualification)
    formData.append("state",values.state)
    formData.append("district",values.district)
    formData.append("city",values.city)
    formData.append("incubation_status",values.incubation_status)
    formData.append("focus_industries",values.focus_industries)
    formData.append("date_of_incorporation",values.date_of_incorporation)
    formData.append("website_url",values.website_url)
    formData.append("address",values.address)
    formData.append("government_fund",values.government_fund)
    fetch(serverUrl +"/incubatorDetail/addIncubatorDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values
      ) =>
      handleSubmitBasicDetailsForm(values)
    }
    validationSchema={Yup.object({
      name_of_individual_firm: Yup.string().required('Name of individual / firm is required'),
   type_of_incubator: Yup.string().required('Type of Incubato is required'),
   category: Yup.string().required('Category  is required'),
  
   state: Yup.string().required('State is required'),
   district: Yup.string().required('District is required'),
   city: Yup.string().required('City is required'),   
   incubation_status: Yup.string().required('Select Incubation Status is required'),
   focus_industries: Yup.string().required('Focus Industries  is required'),
   date_of_incorporation: Yup.string().required('Date of Incorporation  is required'),
   //sector: Yup.string().required('Website URL is required'),
   website_url: Yup.string().required('Website URL is required'),
   address: Yup.string().required('Address is required'),
   government_fund: Yup.string().required('Government Funded is required'),
       
        teamDetails: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('Name is required'),
            designation: Yup.string().required('Designation is required'),
            email: Yup.string().required('Email is required').email('Email is invalid'),
            phone_number: Yup.string().required('Phone number is required'),
            qualification: Yup.string().required('Qualification is required'),
              
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('user_id'))||initialFormObj}

      render={({ values,  setFieldValue, handleChange}) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
              Basic Details
            </h2>
        </div>
        <div className="row">
        <div className="col-md-4 p-2">
                    <label className="pb-2 label-txt">
                      Name of individual / firm{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Name of individual / firm" className="textInput" name="name_of_individual_firm" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name_of_individual_firm" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-md-4 p-2">
                    <label className="pb-2 label-txt">
                      Type of Incubator <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="select"  className="form-select" name="type_of_incubator">
                    <option selected>Select</option>
                      <option value="Business Sector">Business Sector</option>
                      <option value="Section Sector">Section Sector</option>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="type_of_incubator" />
                    </div>
                  </div>
                  <div className="col-md-4 p-2">
                    <label className="pb-2 label-txt">
                      Category <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select" name="category">
                    <option selected>Select</option>
                      <option value="Category I">Category I</option>
                      <option value="Category II">Category II</option>
                      <option value="Category III">Category III</option>
                      <option value="Category IV">Category IV</option>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="category" />
                    </div>
                  </div>
        </div>
          <FieldArray
            name="teamDetails"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>



                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt" style={{width:"15%"}}>Name</TableCell>
                <TableCell className="table-txt" align="left" style={{width:"15%"}}>Designation</TableCell>
                <TableCell className="table-txt" align="left" style={{width:"20%"}}>E-mail address</TableCell>
                <TableCell className="table-txt" align="left" style={{width:"20%"}}>Phone number</TableCell>
                <TableCell className="table-txt" align="left" style={{width:"20%"}}>Qualification</TableCell>
                <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             

            </TableRow>
          </TableHead>

      



                {values.teamDetails && values.teamDetails.length > 0 ? (


                  values.teamDetails.map((friend, index) => (
                    
                     

                    <TableBody  className="data-bg-color d-flex justify-content-between">

              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"15%"}}>
                  <Field type="text" className="txt-color" name={`teamDetails.${index}.name`} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name={`teamDetails.${index}.name`} /><br></br>
                    </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                          <Field type="text" className="txt-color" name={`teamDetails.${index}.designation`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`teamDetails.${index}.designation`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                          <Field type="email" className="txt-color" name={`teamDetails.${index}.email`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`teamDetails.${index}.email`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
           
                          <Field type="tel" className="txt-color" name={`teamDetails.${index}.phone_number`}  pattern="[789]{1}[0-9]{9}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" minlength="10"/>
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`teamDetails.${index}.phone_number`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                          <Field type="text" className="txt-color" name={`teamDetails.${index}.qualification`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`teamDetails.${index}.qualification`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
              </TableBody>  
                  ))
                ) : (
                 <></>
                )}
                
                </Table>
      </TableContainer>

              </div>
            )}
          />

          <div className="row">
          <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      Incubation Status <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select" name="incubation_status">
                    <option selected>Select</option>
                      <option value="New Incubator">New Incubator</option>
                      <option value="Expnad existing facilities">
                        Expnad existing facilities
                      </option>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="incubation_status" />
                    </div>
                  </div>
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      Focus industries<span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Focus industries" className="textInput" name="focus_industries" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="focus_industries" /><br></br>
                    </div> 
                    </div>
                    <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      Date of Incorporation
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="date"  className="textInput" name="date_of_incorporation" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="date_of_incorporation" /><br></br>
                    </div> 
                  </div>
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      Website URL<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Website URL" className="textInput" name="website_url" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="website_url" /><br></br>
                    </div> 
                  </div>
          </div>
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd pt-5">
                  Location of Registered Office
              </h2>
              <div className="row">
                    <div className="col-md-12 pt-4">
                      <label className="pb-2 label-txt">
                        Address<span className="text-danger">*</span>{" "}
                        <InformationIcon />
                      </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="address" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="address" /><br></br>
                    </div> 
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-4 pt-4">
                  <label className="pb-2 label-txt">
                      State <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                  <select type="select" name="state" className="form-control form-select size"
                    value={values.state}
                    onChange={(e) => {
                      handleChange(e);
                      handleState(e)
                    }}
                  >
                    <option value={""}>Select State</option>
                      {
                        userstate.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="state" />
                    </div>
                  </div>
                  <div className="col-md-4 pt-4">
                  <label className="pb-2 label-txt">
                      District<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                     <select type="select" name="district" className="form-control form-select size"
                    value={values.district}
                    onChange={(e) => {
                      handleChange(e);
                      handleDistrict(e)
                    }}
                  >
                    <option value={""}>Select District</option>
                      {
                        userdistrict.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="district" />
                    </div>
                  </div>
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      City <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter City" className="textInput" name="city" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="city" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 pb-4">
                      <label className="pb-2 label-txt">
                      Government Funded<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field component="div" name="government_fund">
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-1 col-md-1">
                          <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes22"
                            defaultChecked={values.government_fund === "yes"}
                            name="government_fund"
                            value="yes"
                          />
                          <label htmlFor="radioYes22">Yes</label>
                          </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                                            <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo22"
                            defaultChecked={values.government_fund === "no"}
                            name="government_fund"
                            value="no"
                          />
                          <label htmlFor="radioNo22">No</label>
                          </div>
                          </div>
                          </div>
                        </Field>
                        <div className="text-danger">
                    <ErrorMessage className="error" name="government_fund" />
                    </div>
                    </div>

                   
                   
                    <div className="justify-content-center d-flex py-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow"
                          onClick={()=>{sessionStorage.setItem("user_id", JSON.stringify(values))}}   
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                       
                      </Stack>
                    </div>
                  </div>
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default BasicDetails;
