import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from './informationIcon';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Upload from "../assets/images/navbar/upload.png";

const serverUrl = environment.baseUrl;
const Uploads = (props) => {
  const authToken =sessionStorage.getItem("token");
  let initialFormObj={ 
    is_your_startup_DPIIT_register: '',
  company_logo: '',
  udyog_aadhar: '',
  company_GSTIN: '',
  company_registration_ID: '',
  company_PAN_card: '',

    relevant_doc: [{
      relevantDoc:'',
     
      }]
   }
  const [fileName, setFileName] = useState({
    logo: "",
    panCard: "",
    aadharCard: "",
    gstin : "",
    id : "",
    documents : ""
  });

  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("is_your_startup_DPIIT_register",values.is_your_startup_DPIIT_register)
    formData.append("company_logo",values.company_logo)
    formData.append("udyog_aadhar",values.udyog_aadhar)
    formData.append("company_GSTIN",values.company_GSTIN)
    formData.append("company_registration_ID",values.company_registration_ID)
    formData.append("company_PAN_card",values.company_PAN_card)
    formData.append("relevantDoc",values.relevantDoc)
    fetch(serverUrl +"/upload/adduploadDoc", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values
      ) =>
      handleSubmitFinacialForm(values)
    }
    validationSchema={Yup.object({
      is_your_startup_DPIIT_register: Yup.string().required('Is your startup DPIIT registered is required'),
    company_logo: Yup.string().required('Company Logo is required'),
    company_PAN_card: Yup.string().required('Company PAN Card is required'),
    udyog_aadhar: Yup.string().required('SCompany PAN Card is required'),
    company_GSTIN: Yup.string().required('Company GSTIN is required'),
    company_registration_ID: Yup.string().required('Company Registration ID is required'),
        relevant_doc: Yup.array().of(
          Yup.object().shape({
            relevantDoc: Yup.string().required('Upload relevant documents is required'),
              
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('uploadDetails'))||initialFormObj}


      render={({ values, setFieldValue }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Uploads
            </h2>
        </div>
        <div className="row">
        <div className="col-md-6 pt-4">
                  <label className="pb-2 label-txt">
                  Is your startup DPIIT registered?<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field component="div" name="is_your_startup_DPIIT_register">
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-3 col-md-3">
                          <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes3"
                            defaultChecked={values.is_your_startup_DPIIT_register === "Yes"}
                            name="is_your_startup_DPIIT_register"
                            value="Yes"
                          />
                          <label htmlFor="radioYes3">Yes</label>
                          </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                                            <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo3"
                            defaultChecked={values.is_your_startup_DPIIT_register === "No"}
                            name="is_your_startup_DPIIT_register"
                            value="No"
                          />
                          <label htmlFor="radioNo3">No</label>
                          </div>
                          </div>
                          </div>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`is_your_startup_DPIIT_register`} />
                        </div>

                     </div>
                 </div>
                 <label className="pb-2 label-txt pt-4">List of documents</label>
                 <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Company Logo
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="company_logo" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("company_logo", e.target.files[0]);
                      }} />   
                </div>
                <div className="text-danger">
                          <ErrorMessage className="text-danger" name="company_logo" />
                           </div>
                </div>
                <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Company PAN Card 
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="company_PAN_card" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("company_PAN_card", e.target.files[0]);
                      }} /> 
                </div>
                <div className="text-danger">
                        <ErrorMessage className="text-danger" name="company_PAN_card" />
                      </div>
                </div>
                <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Udyog Aadhar 
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="udyog_aadhar" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("udyog_aadhar", e.target.files[0]);
                      }} /> 
                </div>
                <div className="text-danger">
                        <ErrorMessage className="text-danger" name="udyog_aadhar" />
                      </div>
                </div>
                <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Company GSTIN 
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="company_GSTIN" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("company_GSTIN", e.target.files[0]);
                      }} /> 
                </div>
                <div className="text-danger">
                        <ErrorMessage className="text-danger" name="company_GSTIN" />
                      </div>
                </div>
                <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Company Registration ID 
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="company_registration_ID" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("company_registration_ID", e.target.files[0]);
                      }} />                 
                </div>
                <div className="text-danger">
                        <ErrorMessage className="text-danger" name="company_registration_ID" />
                      </div>
                </div>
          <FieldArray
            name="relevant_doc"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3 pt-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>
                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"90%"}}>Upload relevant documents</TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>
                {values.relevant_doc && values.relevant_doc.length > 0 ? (
                  values.relevant_doc.map((friend, index) => (
                    <TableBody  className="data-bg-color d-flex justify-content-between">
              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell align="center" className="d-flex align-items-center " style={{width:"90%"}}>
              <input id="file" name={`relevant_doc.${index}.relevantDoc`} accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue(`relevant_doc.${index}.relevantDoc`, e.target.files[0]);
                      }} />               
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name={`relevant_doc.${index}.relevantDoc`} /><br></br>
                        </div>
             </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
              </TableBody>  
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow"
                          onClick={()=>{sessionStorage.setItem("uploadDetails", JSON.stringify(values))}}   
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>

              </div>
            )}
          />
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default Uploads;
