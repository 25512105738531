import React, { useState, useEffect } from 'react'
// import { NavLink } from "react-router-dom";
import { Link, NavLink } from 'react-router-dom'
import NavLogo from '../assets/images/navbar/navbar_logo.png'
import NavLogoWhite from '../assets/images/navbar/navbar_lg_black.png'
import NavImg from '../assets/images/navbar/nav-img.png'
import bussinessplan from '../assets/images/Initiative/bussinessplan.png'
import grandchallenge from '../assets/images/Initiative/grandchallenge.png'
import healthhack from '../assets/images/Initiative/healthhack.png'
import Intellectual from '../assets/images/Initiative/qualitytesting.png'
import startup from '../assets/images/Initiative/startup.png'
import startupyatra from '../assets/images/Initiative/startupyatra.png'
import dropdownIcon from '../assets/images/Initiative/dropdownIcon.png'
import verticalarrow from '../assets/images/Initiative/verticalarrow.png'
import qualitytesting from '../assets/images/Initiative/qualitytesting.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap'

const lang_btn = {
  borderRadius: '18px',
  height: '33px',
}

const icon_btn = {
  color: 'white',
  fontSize: '20px',
}

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'hin',
    name: 'हिन्दी',
    country_code: 'fr',
  },

  {
    code: 'mar',
    name: 'मराठी',
    dir: 'rtl',
    country_code: 'sa',
  },
]
export default function Navigation() {
  const [btnClass, setBtnClass] = useState(false)
  const currentLanguageCode = cookies.get('i18next') || 'en'
  console.log(currentLanguageCode)
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  console.log(currentLanguage)

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('example.Msins')
  }, [currentLanguage, t])

  const [click, setClick] = React.useState(false)
  const [isLoggedin, setIsLoggedin] = useState(false)
  let navigate = useNavigate()
  const handleClick = () => setClick(!click)
  const Close = () => setClick(false)
  const logout = () => {
    sessionStorage.removeItem('email')
    setIsLoggedin(false)
    alert('Logout Successfull')
    navigate('/Login', { replace: true })
    // window.location.replace('http://3.109.215.92/Login')
  }
  const [isNavCollapsed, setIsNavCollapsed] = useState(true)

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)
  return (
    <>
      <div className="w-100">
        <div
          className={click ? 'main-container' : ''}
          onClick={() => Close()}
        />
        <nav className="navbar navbar-expand-lg">
          <div className="nav-container">
            <NavLink exact to="/home" className="nav-logo">
              <img src={NavLogo} alt="navlogo" className="img-fluid whiteogo" />
            </NavLink>
            {/* <div className="language-select d-none d-sm-block">
              <div className="d-flex justify-content-end align-items-center language-select-root">
                <NavDropdown
                  title="En"
                  className="language-dropdown fas fa-globe languageEn d-contents"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item>
                   <span className="dropdown-item-text">language</span>
                  </NavDropdown.Item>
                  {languages.map(({ code, name, country_code }) => (
                    <NavDropdown.Item key={country_code}>
                      <a
                        href="#"
                        className={classNames('dropdown-item', {
                        disabled: currentLanguageCode === code,
                        })}
                        onClick={() => {
                          i18next.changeLanguage(code)
                        }}
                      >
                        <span
                          className={`flag-icon flag-icon-${country_code} mx-2`}
                          style={{
                            opacity: currentLanguageCode === code ? 0.5 : 1,
                          }}
                        />
                        {name}
                      </a>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </div>
            </div> */}
            <div className="nav-item navRightIconPad d-lg-none">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                <a className="nav-links" href="/">
                  <i>
                    {' '}
                    <img style={{ icon_btn }} src={NavImg} />
                  </i>
                </a>
              </NavLink>
            </div>

            <div
              className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
              id="navbarsExample09"
            >
              {/* <div className="dropdown">
              <button
                className="btn en_btn dropdown-toggle"
                style={{ lang_btn }}
                type="button"
                data-toggle="dropdown"
              >
                <i className="fas fa-globe" />&nbsp;En<span className="caret" />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a href="/">English</a>
                </li>
                <li>
                  <a href="/">Marathi</a>
                </li>
                <li>
                  <a href="/">Hindi</a>
                </li>
              </ul>
            </div> */}

              <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {/* <li className="nav-item d-lg-none">
                  <NavLink
                    exact
                    to="/Login"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      Login
                    </span>
                    <sapn className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Login
                      </span>
                    </sapn>
                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li> */}

                {/* <li className="nav-item d-lg-none">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links "
               onClick={click ? handleClick : null}
              >
              
                <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">Register</span>
                <sapn className="w-75  d-lg-none">
                <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">Register</span>
                </sapn>
                <span className="w-25 mobIconRight d-lg-none">
             <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337" className="iconWeb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>             
             </span>
              </NavLink>
            </li> */}
                {/* <li className="nav-item d-lg-none">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links "
               onClick={click ? handleClick : null}
              >
              
                <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">Register</span>
                <sapn className="w-75  d-lg-none">
                <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">Register</span>
                </sapn>
                <span className="w-25 mobIconRight d-lg-none">
             <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337" className="iconWeb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>             
             </span>
              </NavLink>
            </li> */}
                {/* <li className="nav-item">
             <NavLink
               exact
               to="/mentor"
               activeClassName="active"
               className="nav-links"
               onClick={click ? handleClick : null}
             >
             
                 <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">About</span>
              <span className="w-75  d-lg-none">
                 <span className="title_plex_font font-20-26  font-style-normal font-weight-600">About</span>
             </span>
             <span className="w-25 mobIconRight d-lg-none">
             <svg
              width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337" className="iconWeb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>             
             </span>
              
               <div>
               
               </div>
             </NavLink>
           </li> */}
                {/* <li className="nav-item">
             <NavLink
               exact
               to="/incubator"
               activeClassName="active"
               className="nav-links"
               onClick={click ? handleClick : null}
             >
             
                 <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">Incubator</span>
              <span className="w-75  d-lg-none">
                 <span className="title_plex_font font-20-26  font-style-normal font-weight-600">Incubator</span>
             </span>
             <span className="w-25 mobIconRight d-lg-none">
             <svg
              width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337" className="iconWeb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>             
             </span>
              
               <div>
               
               </div>
             </NavLink>
           </li> */}
                {/* <li className="nav-item">
             <NavLink
               exact
               to="/mentor"
               activeClassName="active"
               className="nav-links"
               onClick={click ? handleClick : null}
             >
             
                 <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">Mentor</span>
              <span className="w-75  d-lg-none">
                 <span className="title_plex_font font-20-26  font-style-normal font-weight-600">Mentor</span>
             </span>
             <span className="w-25 mobIconRight d-lg-none">
             <svg
              width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337" className="iconWeb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>             
             </span>
              
               <div>
               
               </div>
             </NavLink>
           </li> */}
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/About"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      About
                    </span>
                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        About
                      </span>
                    </span>
                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li>
                <li style={{ width: '100%' }}>
                  <NavDropdown
                    title="Initiative"
                    className="mainInitative initiative-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                    <div 
                    // className="thin-scroll" 
                    id="style-4">
                      <NavDropdown.Item>
                        <Link to={'/MaharashtraStartupMain'} className="button">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Startup Week</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Link to={'/mahaGrandMain'} className="button">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-6">
                                <h6>Maharashtra Grand Challenge</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">
                        <Link
                          to='/mahaHackathonMain'
                          // onClick={() =>
                          //   window.open(
                          //     'https://msins.in/public/maharashtra-health-hackathon',
                          //     '_blank',
                          //   )
                          // }
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Maharashtra Health Hackathon</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>

                      {/* ==================== */}
                      <NavDropdown
                    title="Financial Assistance"
                    className="subinitative initiativeSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown
                    title="Intellectual Property Rights"
                    className="subSubinitative initiativeSubSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                       <NavDropdown.Item href="/Login">
                        <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Apply Now</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Intellectual">
                        <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Details</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>

                  </NavDropdown>

                  <NavDropdown
                    title="Quality Testing and Certification"
                    className="subSubinitative initiativeSubSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                    id="basic-nav-dropdown"
                  >
                       <NavDropdown.Item href="/Login" >
                      <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Apply Now</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/QualityTesting">
                        <div className="row px-2">
                          <div className="d-flex  align-items-center">
                             <div className="col-md-12">
                                <h6>Details</h6>
                             </div>
                           </div>
                        </div>
                      </NavDropdown.Item>
                  </NavDropdown>
                   </NavDropdown>
                      {/* ================ */}
                      <NavDropdown.Item href="#action/3.5">
                        <Link
                          to={'/Startupyatra'}
                          
                          className="button"
                        >
                          <div className="row px-2 pt-1">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12 pt-1">
                                <h6>Startup Yatra</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.6">
                        <Link
                          to={'/businessplan'}
                          
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Business Plan Competition</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="">
                        <Link
                          to={'/Incubator'}
                          onClick={click ? handleClick : null}
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Incubators Supported</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="">
                        <Link
                          to={'/Initiative'}
                          onClick={click ? handleClick : null}
                          className="button"
                        >
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                <h6>Upcoming & Past Events</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                  {/* ##################### */}
                   <li>
                   <div class="mobNavigationDropdown d-lg-none d-md-block d-sm-block">

<div class="accordion" id="accordionExample">
<div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
        Initiative
        </span>
        </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
                  <NavLink
                    exact
                    to="/MaharashtraStartupMain"
                    activeClassName="active"
                    className="nav-links py-1 pl-4"
                    onClick={click ? handleClick : null}
                  >
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Startup Week 2021</h6>
                      </span>
                    </span>
                  </NavLink>     
                  <NavLink
                    exact
                    to="/mahaGrandMain"
                    activeClassName="active"
                    className="nav-links py-1"
                    onClick={click ? handleClick : null}
                  >
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Maharashtra Grand Challenge</h6>
                      </span>
                    </span>
                  </NavLink> 
                  <NavLink
                    exact
                    to='/mahaHackathonMain'
                    //       onClick={() =>
                    //         window.open(
                    //           'https://msins.in/public/maharashtra-health-hackathon',
                    //           '_blank',
                    //         )
                    //       }
                    // activeClassName="active"
                    className="nav-links py-1"

                  >
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Maharashtra Health Hackathon</h6>
                      </span>
                    </span>
                  </NavLink> 
            <div class="accordion" id="sub-accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header finacialPadd" id="sub-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                        <h6>Financial Assistance</h6>
                        </span>
                        </button>
                    </h2>
                    <div id="sub-collapseOne" class="accordion-collapse collapse intellectualPadd" aria-labelledby="sub-headingOne" data-bs-parent="#collapseOne">
                        <div class="accordion-body">
                            {/* <strong>This is the first item's accordion body.</strong> */}


                            <div class="accordion-item">
                    <h2 class="accordion-header finacialPadd" id="sub-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo" aria-expanded="false" aria-controls="sub-collapseTwo">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                        <h6>Intellectual Property Rights</h6>
                        </span>
                        </button>
                    </h2>
                    <div id="sub-collapseTwo" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo" data-bs-parent="sub-collapseOne">
                        <div class="accordion-body">
                        <NavLink
                    exact
                    to="/Login"
                    activeClassName="active"
                    className="nav-links py-1"
                    onClick={click ? handleClick : null}
                  >
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Apply Now</h6>
                      </span>
                    </span>
                        </NavLink>
                        <NavLink
                     exact
                    to={'/Intellectual'}
                          
                          
                    className="nav-links py-1"
                  >
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Details</h6>
                      </span>
                    </span>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header finacialPadd" id="sub-headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseThree" aria-expanded="false" aria-controls="sub-collapseTwo">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                        <h6>Quality Testing and Certification</h6>
                        </span>
                        </button>
                    </h2>
                    <div id="sub-collapseThree" class="accordion-collapse collapse" aria-labelledby="sub-headingThree" data-bs-parent="sub-collapseOne">
                        <div class="accordion-body">
                        <NavLink
                    exact
                    to="/Login"
                    className="nav-links py-1"
                    onClick={click ? handleClick : null}
                  >
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Apply Now</h6>
                      </span>
                    </span>
                        </NavLink>
                        <NavLink
                     exact
                    to={'/QualityTesting'}
                          
                    className="nav-links py-1">
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Details</h6>
                      </span>
                    </span>
                        </NavLink>
                        </div>
                    </div>
                </div>

                        </div>
                    </div>
                </div>
               
            </div>
            <NavLink
                    exact
                    to={'/Startupyatra'}
                          
                    activeClassName="active"
                    className="nav-links py-1"

                  >
                    <span className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Startup Yatra</h6>
                      </span>
                    </span>
                  </NavLink> 
                  <NavLink
                    exact
                    to={'/businessplan'}
                          
                    activeClassName="active"
                    className="nav-links py-1"

                  >
                    <sapn className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Business Plan Competition</h6>
                      </span>
                    </sapn>
                  </NavLink> 
                  <NavLink
                    exact
                    to="/Incubator"
                    activeClassName="active"
                    className="nav-links py-1 pl-4"
                    onClick={click ? handleClick : null}
                  >
                    <sapn className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Incubators Supported</h6>
                      </span>
                    </sapn>
                  </NavLink>  
                  <NavLink
                    exact
                    to="/Initiative"
                    activeClassName="active"
                    className="nav-links py-1 pl-4"
                    onClick={click ? handleClick : null}
                  >
                    <sapn className="w-100  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                      <h6 className='mobTextColor'>Upcoming & Past Events</h6>
                      </span>
                    </sapn>
                  </NavLink>  
        </div>
    </div>

</div>



</div>
                  </div> 
                   </li>   
                  {/* ###################### */}
                 
                </li>

                <li className="nav-item">
                  <NavLink
                    exact
                    to="/Ecosystem"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-16-19  font-style-normal font-weight-500 d-lg-block d-none">
                      Ecosystem
                    </span>
                    <span className="w-75  d-lg-none d-md-block d-sm-block">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Ecosystem
                      </span>
                    </span>
                    <span className="w-25 mobIconRight d-lg-none d-md-block d-sm-block">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li>
                
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/Resources"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      {' '}
                      Resources
                    </span>
                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Resources
                      </span>
                    </span>

                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item navContactLeftPadd">
                  <NavLink
                    exact
                    to="/Contact"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                      {' '}
                      ContactUs
                    </span>

                    <span className="w-75  d-lg-none">
                      <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Contact Us
                      </span>
                    </span>
                    <span className="w-25 mobIconRight d-lg-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                </li>
                {/* <li style={{ width: '50%' }}>
                  <NavDropdown
                    title="test"
                    className="test-dropdown "
                    id="basic-nav-dropdown"
                  >
                    
                      <NavDropdown.Item>
                        <NavLink
                          exact
                          to={"../"}
                          activeClassName="active"
                          className="nav-links"
                          onClick={click ? handleClick : null}
                        >
                          <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                            SuperAdmin
                          </span>
                          <span className="w-75  d-lg-none">
                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                              SuperAdmin
                            </span>
                          </span>
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          exact
                          to={"../startup"}
                          activeClassName="active"
                          className="nav-links"
                          onClick={click ? handleClick : null}
                        >
                          <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                            Startup
                          </span>
                          <span className="w-75  d-lg-none">
                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                            Startup
                            </span>
                          </span>
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          exact
                          to={"../incubator"}
                          activeClassName="active"
                          className="nav-links"
                          onClick={click ? handleClick : null}
                        >
                          <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                            Incubator
                          </span>
                          <span className="w-75  d-lg-none">
                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                            Incubator
                            </span>
                          </span>
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          exact
                          to={"../mentor"}
                          activeClassName="active"
                          className="nav-links"
                          onClick={click ? handleClick : null}
                        >
                          <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                          Mentor
                          </span>
                          <span className="w-75  d-lg-none">
                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                            Mentor
                            </span>
                          </span>

                          
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          exact
                          to={"../investor"}
                          activeClassName="active"
                          className="nav-links"
                          onClick={click ? handleClick : null}
                        >
                          <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">
                          Investor
                          </span>
                          <span className="w-75  d-lg-none">
                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                            Investor
                            </span>
                          </span>

                          <span className="w-25 mobIconRight d-lg-none">
                            <svg
                              width="24"
                              height="18"
                              viewBox="0 0 24 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                                className="iconWeb"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </NavLink>
                      </NavDropdown.Item>
                      
                    
                  </NavDropdown>
                </li> */}
                {/* <li className="nav-item d-none d-sm-block">
              <NavLink
                exact
                to="/Login"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
                <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">Login</span>
                 <sapn className="w-75  d-lg-none">
                 <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">Login</span>
                 </sapn>
                 <span className="w-25 mobIconRight d-lg-none">
             <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337" className="iconWeb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>             
             </span>
              </NavLink>
            </li> */}

                {/* <li className="nav-item d-none d-sm-block">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links "
               onClick={click ? handleClick : null}
              >
              
                <span className="regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block">Register</span>
                <sapn className="w-75  d-lg-none">
                <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">Register</span>
                </sapn>
                <span className="w-25 mobIconRight d-lg-none">
             <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337" className="iconWeb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>             
             </span>
              </NavLink>
            </li> */}
              </ul>

              {/* <ul  className={click ? "d-flex leftrightpadd nav-menu active" : "d-flex leftrightpadd nav-menu"}>
          <li className="nav-item">
              <NavLink
                exact
                to="/Login"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
                Login
              </NavLink>
            </li>

          <li className="nav-item ">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links "
               onClick={click ? handleClick : null}
              >
                Register
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
               <a className="nav-links" href="/">
                      <i> <img  style={{ icon_btn }}  src={NavImg}/></i>
                     
                      
                    </a> 
              </NavLink>
            </li>
          </ul> */}
            </div>
            {/* <div className="d-sm-block">
              <NavLink
                exact
                to="/Register"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                <a className="nav-links" href="/">
                  <i>
                    {' '}
                    <img style={{ icon_btn }} src={NavImg} />
                  </i>
                </a>
              </NavLink>
            </div> */}
           
            <div className="d-sm-block">
              <NavLink
                exact
                to="/Login"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {/* <a className="nav-links-btn regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block pr-3" href="/">
                  Logout
                  <i> <img  style={{ icon_btn }}  src={NavImg}/></i>
                </a> */}
                <a
                  className="nav-links-btn regular_font font-18-22  font-style-normal font-weight-500 d-none d-sm-block pr-3"
                  href="/"
                  onClick={logout}
                >
                  Logout
                  {/* <i> <img  style={{ icon_btn }}  src={NavImg}/></i> */}
                </a>
              </NavLink>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
