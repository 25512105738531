import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function EcosystemViewM(props) {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      
    </Box>
    <TabPanel value={value} index={0}>
      <div className="row">
        <div className="col-md-2">
          <div className="">
            <label>
              {" "}
              <b> Title :</b>
            </label>
          </div>
        </div>
        <div className="col-md-10">
        <div className="">
            <span>{props.rowData.ecoSystem.title}</span>
        </div>
        </div>
        </div>
        <hr></hr>
        <div className="row">
        <div className="col-md-2">
          <div className="">
            <label>
              {" "}
              <b> Website Url :</b>
            </label>
          </div>
        </div>
        <div className="col-md-10">
            <span>{props.rowData.ecoSystem.link}</span>
        </div>
        </div>
        <hr></hr>
        <div className="row">
        <div className="col-md-2">
          <div className="">
            <label>
              {" "}
              <b> Count :</b>
            </label>
          </div>
        </div>
        <div className="col-md-10">
            <span>{props.rowData.ecoSystem.count}</span>
        </div>
        </div>
        <hr></hr>

        
        <div className="row">
        <div className="col-md-2">
          <div className="">
            <label>
              {" "}
              <b> Image :</b>
            </label>
            
          </div>
          
        </div>
        <div className="col-md-10">
        <img src={props.rowData.ecoSystem.image} style={{width:'20%', height:'100%'}} /><br></br>
        </div>
        </div>
        
        
      
    </TabPanel>
   
  </Box>
  )
}

export default EcosystemViewM