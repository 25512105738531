import { useEffect, useState } from "react";
import axios from "axios";
import { useRef} from "react";
import { BsFiles } from "react-icons/bs";
import AllmentorList from './mentorList'
// import Table from "./Table/index";
// import Sort from "../../Resources/searchpag/Sort";
// import Genre from "../../Resources/searchpag/Genre";
import Pagination from "../../searchpagination/Pagination/index";
// import Search from "../../Resources/searchpag/Search/winnerSearch";
// import DropdownSearch from "../../Resources/searchpag/Search/mentorSearch"
// import "./sPage.scss";
import { environment } from '../../api';
import Grid from "@material-ui/core/Grid";

function MentorProfile() {
    const serverUrl = environment.baseUrl;
    
    const textEl = useRef(null);

    const copyToClipboard = async e => {
      const wdsl =window.getSelection().removeAllRanges();
      console.log(wdsl);
      var range = document.createRange();
      range.selectNode(textEl.current);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    };
    const authToken =sessionStorage.getItem("token");
    const [obj, setObj] = useState({});
	// const [sort, setSort] = useState({ sort: "rating", order: "desc" });
	// const [filterGenre, setFilterGenre] = useState([]);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
    const [city, setCity] = useState("");
    const [sector, setSector] = useState("");
    

	useEffect(() => {
        
		const getAllMovies = async () => {
			try {
				const url = `${serverUrl +"/mentor/allmentor"}?page=${page},&name=${search}&city=${city}&sector=${sector}`;
				const { data } = await axios.get(url);
                console.log(data, "dtrsddsdfshtgxcvdgfx");
				setObj(data);
			} catch (err) {
				console.log(err);
			}
		};

		getAllMovies();
	}, [ page, search, city, sector]);
    
  return (
    <>
    <div className="wrapper" style={{backgroundColor:'#fff'}}>
        <h2 className="d-flex justify-content-center title_plex_font font-28-36 font-20-26 topPadding pb-4">
          <b>Meet To Mentors</b>
        </h2>
    <div className="container">
        <div className="row d-flex">
        <div className="col-lg-12 col-md-12 col-sm-">
            {/* <Search setSearch={(search) => setSearch(search)}  setCity={(city) => setCity(city)} mentorList={obj.mentorList}  /> */}

            {/* <DropdownSearch setCity={(city) => setCity(city)} mentorList={obj.mentorList}  /> */}
            <div className="row d-flex">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
             </div>
            </div>
            </div>
            {/* <div className="col-lg-5 col-md-5 col-sm-5 sectroCheckbox"> */}
            {/* <Genre
                    filterGenre={filterGenre}
                    categorys={obj.categorys ? obj.categorys : []}
                    setFilterGenre={(category) => setFilterGenre(category)}
                /> */}
                {/* </div> */}
        </div>
        <div className="body">
            
            {/* <div className="filter_container">
               
                <Sort sort={sort} setSort={(sort) => setSort(sort)} />
            </div> */}
            <div className="table_container">
                <AllmentorList mentorList={obj.mentorList ? obj.mentorList : []} setSearch={(search) => setSearch(search)}  setCity={(city) => setCity(city)} setSector={(sector) => setSector(sector)} />
                <Pagination
                    page={page}
                    limit={obj.limit ? obj.limit : 0}
                    total={obj.total ? obj.total : 0}
                    setPage={(page) => setPage(page)}
                />
            </div>
        </div>
    </div>
</div>
<div className='mahaContactus-main'>
        <h3 className='whant-heading font-28-36  font-18-23 d-flex justify-content-center'>Want to get in touch?</h3>
               <p className='whant-p font-22-26 font-14-17'>You can connect to mentors through email or LinkedIn profile given in mentor 
               details section. To connect to any other mentors or details please contact us at &nbsp;
               <a href="mailto:team@msins.in">
                <u className="linkbold" onClick={copyToClipboard} ref={textEl}>team@msins.in </u></a><BsFiles/> </p>
       </div> 
</>

  )
}

export default MentorProfile
