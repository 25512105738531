import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from '../startup/informationIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";



const serverUrl = environment.baseUrl;

export default function PatentApplicationForm(props) {

    // console.log(JSON.parse(localStorage.getItem('ipDetails')))

    let initialFormObj = {
        do_you_have_IP: ''

    }


    function handleSubmitFinacialForm(values) {
        console.log(values, "hii")
        const formData = new FormData();
        formData.append("do_you_have_IP", values.do_you_have_IP)
        fetch(serverUrl + "/ip/addIpDetail",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                body: JSON.stringify(values, null, 2)


            })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch((err) => { console.log(err) })
        props.setValue(props.value + 1)


    }

    return <div className="container">
        <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12">

                <Formik
                    onSubmit={(values
                    ) =>
                        handleSubmitFinacialForm(values)
                    }
                    validationSchema={Yup.object({
                        do_you_have_IP: Yup.string().required('Please Select Have you appointed CEO for Incubation is required'),
                    })}

                    initialValues={JSON.parse(localStorage.getItem('ipDetails')) || initialFormObj}

                    render={({ values, setFieldValue }) => (
                        <Form >

                            {/*     Patent Application Form starts here         */}

                            <div className="row pb-3">
                                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                                    Post Grant
                                </h2>
                            </div>



                            <div className="row">
                                <div className="col-lg-4 col-md-4 p-2">
                                    <label className="pb-2 label-txt">
                                        Date of grant of the Invention{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <p>&#40;Must be after September 2019&#41;</p>
                                    <Field type="date" placeholder="Select From date" className="textInput" name="date_from" />
                                    <p className="mt-2 text-danger">Select Date</p>
                                    <div className="text-danger">
                                        <ErrorMessage className="error" name="date_from" /><br></br>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 p-2">
                                    <label className="pb-2 label-txt">
                                        Jurisdiction of the grant of the Patent<span className="text-danger">*</span>
                                        {/* <InformationIcon /> */}
                                    </label>
                                    <p>&#40;For each granted patent,new form needs to be filled up&#41;
                                    </p>
                                    <Field component="div" name="do_you_have_IP">
                                        <div class="radiobuttons d-flex">
                                            <div className="col-lg-5 col-md-5">
                                                <div class="rdio rdio-primary radio-inline">
                                                    <input
                                                        type="radio"
                                                        id="radioYes"
                                                        defaultChecked={values.do_you_have_IP === "yes"}
                                                        name="do_you_have_IP"
                                                        value="yes"
                                                    />
                                                    <label htmlFor="radioYes">India&#40;Domestic&#41;</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div class="rdio rdio-primary radio-inline">
                                                    <input
                                                        type="radio"
                                                        id="radioNo"
                                                        defaultChecked={values.do_you_have_IP === "no"}
                                                        name="do_you_have_IP"
                                                        value="no"
                                                    />
                                                    <label htmlFor="radioNo">International</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Field>
                                    <p className="mt-2 text-danger">Select Option</p>
                                    <div className="text-danger">
                                        <ErrorMessage className="error" name="do_you_have_IP" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 p-2">
                                    <label className="pb-2 label-txt">
                                        Patent Registration Number<span className="text-danger">*</span>
                                    </label>
                                    <Field type="text" placeholder="" className="textInput" name="uniqueness_factor" />
                                    <p className="mt-2 text-danger">Enter Patent Registration Number</p>
                                    <div className="text-danger">
                                        <ErrorMessage className="text-danger" name="uniqueness_factor" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 p-2">
                                    <label className="pb-2 label-txt">
                                        Please select country of grant from the list given below
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Field as="select" className="form-select" name="startup_current_stage">
                                        <option selected>Select Country</option>
                                        <option value="Ideation">Ideation</option>
                                        <option value="Scaling Up">Scaling Up</option>
                                        <option value="Mature">Mature</option>
                                    </Field>
                                    <p className="mt-2 text-danger">Select Country</p>
                                    <div className="text-danger">
                                        <ErrorMessage className="text-danger" name="startup_current_stage" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 p-2">
                                    <label className="pb-2 label-txt">
                                        Please give corresponding Indian Patent Application Number<span className="text-danger">*</span>
                                    </label>
                                    <Field type="text" placeholder="" className="textInput" name="application_number" />
                                    <p className="mt-2 text-danger">Enter corresponding Indian Patent Application number</p>
                                    <div className="text-danger">
                                        <ErrorMessage className="text-danger" name="application_number" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-12 col-md-12 col-sm-12 p-2">
                                    <label className="pb-2 label-txt">
                                        Please explain the reason for applying in above country.
                                        How does it help your startup? How is it a part of your buisness model.
                                    </label><br />
                                    <textarea name="" id="" cols="" rows="3" className="col-lg-12 col-md-12 col-sm-12">

                                    </textarea>
                                </div>
                            </div>

                            <div className="justify-content-center d-flex pt-4">
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="btn-color-draft no-shadow"
                                        onClick={() => { localStorage.setItem("ipDetails", JSON.stringify(values)) }}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="btn-color-next no-shadow"
                                    >
                                        Save &amp; Next
                                    </Button>
                                </Stack>
                            </div>
                            {/*     Patent Application Form ends here         */}
                        </Form>
                    )}
                />
            </div>
        </div>
    </div>



}
