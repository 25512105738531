import React from 'react'
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import InformationIcon from "../../startup/informationIcon";
import { environment } from '../api';
const serverUrl = environment.baseUrl;

export default function DefaultSectors2019() {
    function handleSubmitBusinessModelAddForm(values) {
        console.log(values, "hii")
        const formData = new FormData();
        formData.append("name", values.title)
        fetch(serverUrl + "/businessM/allbusinessModel",
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    accept: "application/json",

                },
                body: JSON.stringify(values, null, 2)

            })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch((err) => { console.log(err) })
        const answer = window.confirm("are you sure?");
        if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Submitted Successfully");

        } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
        }
    }


    return <div className="container">
        <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                {/* {message} */}
                <Formik
                    onSubmit={(values
                    ) =>
                        handleSubmitBusinessModelAddForm(values)
                    }
                    validationSchema={Yup.object({
                        name: Yup.string().required('Enter Name is Required'),

                    })}
                    initialValues={{
                        name: '',

                    }}

                    render={({ values, setFieldValue }) => (
                        <Form>
                            <h2 className='font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd'>Default Sectors 2019</h2>
                            <div className='container mt-5 p-5 pb-3 bg-light'>
                                <h5 className='fw-bold'>Select Default Sectors</h5>
                                <hr />
                                <div className="row">
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="agriculture" 
                                            className="me-2"/>
                                            Agriculture
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="biotech" 
                                            className="me-2"/>
                                            Biotechnology
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="energy" 
                                            className="me-2"/>
                                            Clean Energy
                                        </label>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="cyberSecurity" 
                                            className="me-2"/>
                                            Cyber Security
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="education" 
                                            className="me-2"/>
                                            Education
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="fintech" 
                                            className="me-2"/>
                                            FinTech
                                        </label>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="governance" 
                                            className="me-2"/>
                                            Governance
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="healthcare" 
                                            className="me-2"/>
                                            Healthcare
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="IT" 
                                            className="me-2"/>
                                            IT
                                        </label>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="miscellaneous" 
                                            className="me-2"/>
                                            Miscellaneous
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="mobility" 
                                            className="me-2"/>
                                            Mobility
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="rural" 
                                            className="me-2"/>
                                            Rural Development
                                        </label>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="skilling" 
                                            className="me-2"/>
                                            Skilling
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="smartinfra" 
                                            className="me-2"/>
                                            Smart Infra
                                        </label>
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="development" 
                                            className="me-2"/>
                                            Social Development
                                        </label>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-4 col-lg-4'>
                                        <label className="pb-2">
                                            <Field type="checkbox" name="checked" value="sustainability" 
                                            className="me-2"/>
                                            Sustainability &#40;Air, Water and 
                                            &nbsp;&nbsp;&nbsp;&nbsp;Waste Management&#41;
                                        </label>
                                    </div>

                                </div>

                                <div className="justify-content-end d-flex py-2 mt-4">
                                    <Stack direction="row" spacing={2}>
                                        {/* <Button variant="contained">Previous</Button> */}
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            className="btn-color-next no-shadow"
                                        >
                                            Save & Submit
                                        </Button>
                                    </Stack>
                                </div>

                            </div>
                        </Form>
                    )}
                />
            </div>
        </div>
    </div>
}
