import React from 'react'
import Media1 from '../../assets/images/Resourcesbanner/Media/media1.png';
import Media from '../../assets/images/Resourcesbanner/Media/media.png';
import Arrow from '../../assets/images/innovation/arrow.png';
import { Button } from 'react-bootstrap-v5';
import { BsArrowLeft } from "react-icons/bs";
// import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';


export default function MediaBanner() {
  const navigate = useNavigate();
  const handleClick = () => {
    // 👇️ replace set to true
    navigate('/Resources', {replace: true});
  };
  return (
    <div className='Medianodal'>
        <div className='row d-flex'>
        <div className="col-sm-12 col-lg-12 col-md-12 btn-go-back">

          {/* <Button className="btnGoBack mt-4" onClick={handleClick}><span ><BsArrowLeft/></span> */}
          {/* <Link to="/Resources" style={{ textDecoration: 'none', color:'#000', cursor:'pointer' }} className="gobackMoboSpace" > */}
            {/* &nbsp;&nbsp;Go Back */}
                  {/* </Link> */}
          {/* </Button> */}


          
              </div>
              {/* <div className="col-sm-12 col-lg-12 col-md-12 mediaTitle d-none d-sm-flex">
         
              </div> */}
            <div className='mediaBanner'>
            <img className='img-fluid' src={Media} alt="Media2" />
            <span className='mediaTitle d-lg-block d-none'>MSInS in Media</span>
            </div>
        
        </div>
   </div>
  )
}
