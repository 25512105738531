import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from '../startup/informationIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";



const serverUrl = environment.baseUrl;

export default function FinancialInformationForm(props) {
    // console.log(JSON.parse(localStorage.getItem('ipDetails')))

    let initialFormObj = {
        do_you_have_IP: ''

    }


    function handleSubmitFinacialForm(values) {
        console.log(values, "hii")
        const formData = new FormData();
        formData.append("do_you_have_IP", values.do_you_have_IP)
        fetch(serverUrl + "/ip/addIpDetail",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                body: JSON.stringify(values, null, 2)


            })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch((err) => { console.log(err) })
        props.setValue(props.value + 1)


    }

    return <div className="container">
        <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12">

                <Formik
                    onSubmit={(values
                    ) =>
                        handleSubmitFinacialForm(values)
                    }
                    validationSchema={Yup.object({
                        do_you_have_IP: Yup.string().required('Please Select Have you appointed CEO for Incubation is required'),
                    })}

                    initialValues={JSON.parse(localStorage.getItem('ipDetails')) || initialFormObj}

                    render={({ values, setFieldValue }) => (
                        <Form >

                            {/*     Financial Information tab starts here         */}
                            <div className="row pb-5">
                                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                                    Post Grant
                                </h2>
                            </div>

                            <h6 className="fw-bold">Please state total expenditure<span className="text-danger">*</span></h6>


                            <FieldArray
                                name="turnover_details"
                                render={(arrayHelpers) => (
                                    <div>


                                        <TableContainer component={Paper} className="no-shadow">
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead className="head-bg-color">
                                                    <TableRow className="">
                                                        <TableCell
                                                            className="bg-black text-light">Total Expenditure
                                                        </TableCell>
                                                        <TableCell
                                                            className="bg-black text-light">
                                                        </TableCell>
                                                        <TableCell
                                                            className="bg-black text-light">
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="fw-bold" style={{ width: "9%" }}>
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell className="fw-bold" style={{ width: "33%" }}>
                                                            Category
                                                        </TableCell>
                                                        <TableCell className="fw-bold" style={{ width: "58%" }}>
                                                            Total Amount Incurred
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            1
                                                        </TableCell>
                                                        <TableCell>
                                                            Professional Fees
                                                        </TableCell>
                                                        <TableCell>
                                                            <Field type="text" placeholder="" className="textInput" name="t_amount_1" />
                                                            <p className="text-danger mt-2">Enter Professional Fees&#40;in Rupees&#41;</p>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            2
                                                        </TableCell>
                                                        <TableCell>
                                                            Official&#40;Government&#41; fees
                                                        </TableCell>
                                                        <TableCell>
                                                            <Field type="text" placeholder="" className="textInput" name="t_amount_2" />
                                                            <p className="text-danger mt-2">Enter Government Fees&#40;in Rupees&#41;</p>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <h6 className="fw-bold mt-4">Please upload all the invoices of Official &#40;government&#41;
                                            fees incurred during the entire patent application process</h6>


                                        <TableContainer component={Paper} className="no-shadow mt-5">
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead className="head-bg-color">
                                                    <TableRow className="">
                                                        <TableCell
                                                            className="bg-black text-light">Name of the document
                                                        </TableCell>
                                                        <TableCell
                                                            className="bg-black text-light">Upload Invoice &#40;Allowable file format - JPEG,PNG,DOC,PDF&#41;
                                                        </TableCell>
                                                        <TableCell
                                                            className="bg-black text-light">Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="" style={{ width: "40%" }}>
                                                            <Field type="text" placeholder="" className="textInput" name="doc_name_1" />
                                                        </TableCell>
                                                        <TableCell className="" style={{ width: "50%" }}>
                                                            <input type="file" name="" id="" />
                                                        </TableCell>
                                                        <TableCell className="" style={{ width: "10%" }}>
                                                            <div className="d-flex justify-content-start">
                                                                <span className="add-btn-text label-txt">
                                                                    <img
                                                                        src={AddMember}
                                                                        className="img-fluid"
                                                                        alt="Add button"
                                                                        onClick={() => arrayHelpers.push("")}
                                                                    ></img>
                                                                </span>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <h6 className="fw-bold mt-4">Please upload all the invoices of Professional
                                            fees incurred during the entire patent application process</h6>

                                        <TableContainer component={Paper} className="no-shadow mt-5">
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead className="head-bg-color">
                                                    <TableRow className="">
                                                        <TableCell
                                                            className="bg-black text-light">Name of the document
                                                        </TableCell>
                                                        <TableCell
                                                            className="bg-black text-light">Upload Invoice &#40;Allowable file format - JPEG,PNG,DOC,PDF&#41;
                                                        </TableCell>
                                                        <TableCell
                                                            className="bg-black text-light">Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="" style={{ width: "40%" }}>
                                                            <Field type="text" placeholder="" className="textInput" name="doc_name_2" />
                                                        </TableCell>
                                                        <TableCell className="" style={{ width: "50%" }}>
                                                            <input type="file" name="" id="" />
                                                        </TableCell>
                                                        <TableCell className="" style={{ width: "10%" }}>
                                                            <div className="d-flex justify-content-start">
                                                                <span className="add-btn-text label-txt">
                                                                    <img
                                                                        src={AddMember}
                                                                        className="img-fluid"
                                                                        alt="Add button"
                                                                        onClick={() => arrayHelpers.push("")}
                                                                    ></img>
                                                                </span>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <div className="justify-content-center d-flex pt-4 mt-4">
                                            <Stack direction="row" spacing={2}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className="btn-color-draft no-shadow"
                                                    onClick={() => { localStorage.setItem("financialDetails", JSON.stringify(values)) }}
                                                >
                                                    Save as Draft
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className="btn-color-next no-shadow"
                                                >
                                                    Save &amp; Next
                                                </Button>
                                            </Stack>
                                        </div>

                                    </div>


                                )}
                            />

                            {/*     Financial Information tab ends here         */}



                        </Form>
                    )}
                />
            </div>
        </div>
    </div>



}
