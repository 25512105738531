import React, { useEffect, useState } from "react";
import { environment } from '../api';
import {useParams} from "react-router-dom"

export default function Personalinformation({id}) {
  const authToken =sessionStorage.getItem("token");
  const serverUrl = environment.baseUrl;
  

console.log(sessionStorage.getItem("_id"),"id")
     const [users, setUsers] = useState([]);
     const [error, setError] = useState(null);
     const [isLoaded, setIsLoaded] = useState(false);
     useEffect(() => {
       fetch(serverUrl +`/auth/userdetail/${id}`,
       {headers: {
        "auth-token":authToken
        
      }}
       )
         .then(res => res.json())
         .then(
           data => {
             setIsLoaded(true);
             setUsers(data);
           },
           error => {
             setIsLoaded(true);
             setError(error);
           }
         );
     }, []);

  return (
    <div className="bannermain">
    <div className="container d-flex justify-content-center pt-3">
      <div className="col-lg-11 col-md-11 col-lg-11 shadow p-3 mb-5 bg-body rounded viewBackgroundColour">
      <div className="row ">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
              Personal Details
            </h2>
          </div>
          
          <form method="POST" enctype="multipart/form-data">
            <div className="row">
              <div className="col-md-2 pt-4">
                
                <label className="pb-2 label-txt">
                First Name
                  <span className="text-danger">*</span>
                 
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.first_name}</span>
                   </div>
                   
                   
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Last Name
                  <span className="text-danger">*</span>
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.last_name}</span>
                   </div>
                   </div>

                   <div className="row">
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Email-address
                  <span className="text-danger">*</span>
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.email}</span>
                   </div>
                   
                   
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Phone Number
                  <span className="text-danger">*</span>
                 
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.mobile_no}</span>
                   </div>
                   </div>
                   <div className="row">
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
               User Type
                  <span className="text-danger">*</span>
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.type}</span>
                   </div>
                   
                   
              {/* <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Phone Number
                  <span className="text-danger">*</span>
                  <InformationIcon />
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>918899776789</span>
                   </div> */}
                   </div>
              
              
              
              
             
              <div>
                {/* <StartupAddmore /> */}
              </div>
              {/* <div className="justify-content-center d-flex py-4">
                  <Stack direction="row" spacing={2}>
                    
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-draft no-shadow" 
                    >
                      Save as Draft
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-next no-shadow" onClick={()=>{props.setValue(props.value+1)}}
                    >
                      Save &amp; Next
                    </Button>
                  </Stack>
                </div> */}
           
            {/*  </form> */}
            {/* <div className=""> */}
            
          </form>
           {/* ))}  */}
        </div>
      </div>
      </div>
    </div>
  </div>
  )
}
