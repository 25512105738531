import React, { Suspense, useState } from 'react'
import logo from './logo.svg';
import './App.css';
import i18n from './i18n';
import LocaleContext from './LocaleContext';

import Main from "./Main";
// import Navbar from './component/Navbar';
// import ScrollToTop from './component/scrollTop';

function App() {
  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

  const loadingMarkup = (
    <div className="py-4 text-center">
      <h3>Loading..</h3>
    </div>
  );
  return (
    
    <>
    {/* <div className="App">
      <header className="App-header">
      <Navbar title='Maharashtra'/>
      <Example></Example>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
        <b>M S I N S</b>
        </h1>
        <p>This website is in under construction.</p>
      </header>
    </div> */}
    <Suspense fallback={loadingMarkup}>
      <React.StrictMode
      >
        {/* <Navbar></Navbar> */}
        {/* <ScrollToTop> */}
        <Main />
        {/* </ScrollToTop> */}
      </React.StrictMode>
    </Suspense>
    
    </>
  );
}

export default App;