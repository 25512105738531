import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

export default function IncubatorViewMod(props) {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(props.rowData.MarketLinkages);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <Tab className="txt-main-style" label="Basic details" {...a11yProps(0)} />
          <Tab className="txt-main-style" label="Business Plan" {...a11yProps(1)} />
          <Tab className="txt-main-style" label="Leadership Team" {...a11yProps(2)} />
          <Tab className="txt-main-style" label="Sector Expertise" {...a11yProps(3)} />
          <Tab className="txt-main-style" label="Market Linkages" {...a11yProps(4)} />
          <Tab className="txt-main-style" label="Infrastructure" {...a11yProps(5)} />
          
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> Name of individual :</b>
              </label>
              <span>{props.rowData.IncubatorDetail.name_of_individual_firm}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Email Id :</b> 
              </label>
              <span>{props.rowData.IncubatorDetail.email}</span>
              </div>
            <hr></hr>
            </div>
          </div>
       

        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Contact No :</b> <span>{props.rowData.IncubatorDetail.phone_number}</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Name of firm:</b> <span>{props.rowData.IncubatorDetail.name_of_individual_firm}</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
       <h5><b>Point of Contact details</b></h5>
       <hr></hr>
       <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> <span>{props.rowData.IncubatorDetail.name}</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation:</b> <span>{props.rowData.IncubatorDetail.designation}</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Email ID :</b> <span>{props.rowData.IncubatorDetail.email}</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Contact numbers:</b> <span>{props.rowData.IncubatorDetail.phone_number}</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>	Qualification :</b> <span>{props.rowData.IncubatorDetail.name_of_individual_firm}</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
         
        </div>
       <h5><b>Location of Registered Office</b></h5>
       <hr></hr>
       <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Address :</b> <span>{props.rowData.IncubatorDetail.qualification}</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>State:</b> <span>{props.rowData.IncubatorDetail.state}</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>District :</b> <span>{props.rowData.IncubatorDetail.district}</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>City:</b> <span>{props.rowData.IncubatorDetail.city}</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>	Government Funded :</b> <span>{props.rowData.IncubatorDetail.name_of_individual_firm}</span>
              </label>
              </div>
            <hr></hr>
            </div>
            <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Logo:</b> <span>{props.rowData.IncubatorDetail.government_fund}</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Proposed Project Cost :</b> 
              </label>
              <span>{props.rowData.BusinessPlan.proposed_Project_cost}</span>
              {/* <span>{props.rowData.IncubatorDetail.name_of_individual_firm}</span> */}

            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Document List :</b>
            </label>
            {/* <span className="text-justify">
            {props.rowData.BusinessPlan.list_of_document}
            </span> */}
          </div>
          <hr></hr>
          </div>
          </div>
          <h5><b>List of Documents</b></h5>
          <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Budget Plan :</b> 
              </label>
              <span>{props.rowData.BusinessPlan.budgetplan}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Capital Investment :</b>
            </label>
            <span className="text-justify">
            {props.rowData.BusinessPlan.capital_investment}
            </span>
          </div>
          <hr></hr>
          </div>

          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Operational Model :</b>
            </label>
            <span className="text-justify">
            {props.rowData.BusinessPlan.operational_model}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          <h5><b>Location of Registered Office</b></h5>
          <div className="row">
           {props.rowData.BusinessPlan.relevant_doc.map((relevant_doc)=>{ 
           
            return ( 
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload other relevant documents  :</b>
            </label>
            <span className="text-justify">
            {relevant_doc.relevant_doc}
            </span>
          </div>
          <hr></hr>
          </div>
            )
          })} 
          </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Have you appointed CEO for Incubator?</b> 
              </label>
              <span>{props.rowData.LeadershipTeam.have_you_appointed_CEO_for_incubation}</span>
            </div>
            <hr></hr>

            <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              <span>{props.rowData.LeadershipTeam.CEO_name}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation :</b>
            </label>
            <span className="text-justify">
            {props.rowData.LeadershipTeam.CEO_designation}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Years of Professional Experience :</b> 
              </label>
              <span>{props.rowData.LeadershipTeam.CEO_professional_experience}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>E-Mail Address :</b>
            </label>
            <span className="text-justify">
            {props.rowData.LeadershipTeam.CEO_email_address}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>Upload Profile / Relevant Documents :</b> 
              </label>
              <span>{props.rowData.LeadershipTeam.CEO_upload_profile}</span>
            </div>
            <hr></hr>
          </div>
          
          </div>
    
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Have you appointed Manager for Incubator? </b> 
            </label>
            <span>{props.rowData.LeadershipTeam.have_you_appointed_Manager_for_incubation}</span>
          </div>
          <hr></hr>

          <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              <span>{props.rowData.LeadershipTeam.manager_name}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation :</b>
            </label>
            <span className="text-justify">
            {props.rowData.LeadershipTeam.manager_designation}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Years of Professional Experience :</b> 
              </label>
              <span>{props.rowData.LeadershipTeam.manager_professional_experience}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>E-Mail Address :</b>
            </label>
            <span className="text-justify">
            {props.rowData.LeadershipTeam.manager_email_address}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>Upload Profile / Relevant Documents :</b> 
              </label>
              <span>{props.rowData.LeadershipTeam.manager_upload_profile}</span>
            </div>
            <hr></hr>
          </div>
          
          </div>
          </div>
        </div>
        <h5><b>Board of Director Details</b></h5>

         {props.rowData.LeadershipTeam.board_of_director_details.map((board_of_director_details)=>{
           return ( 
           <>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              <span>{board_of_director_details.name}</span>
            </div>
            <hr></hr>
          </div>

          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Designation :</b> 
              </label>
              <span>{board_of_director_details.designation}</span>
            </div>
            <hr></hr>
          </div>

          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Relevant Work Experience :</b> 
              </label>
              <span>{board_of_director_details.relevant_work_experience}</span>
            </div>
            <hr></hr>
          </div>

          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Qualification :</b> 
              </label>
              <span>{board_of_director_details.qualification}</span>
            </div>
            <hr></hr>
          </div>

          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Upload Profile / Relevant Documents :</b> 
              </label>
              <span>{board_of_director_details.upload_profile}</span>
            </div>
            <hr></hr>
          </div>
          
          </div>
          </>
          )
           })} 
        
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>No. of Startups Incubated :</b> 
              </label>
              <span>{props.rowData.SectorExpertise.no_of_startup_incubated}</span>
            </div>
            <hr></hr>  
          </div>
       </div>
          <h5><b>Startup</b></h5>
          <hr></hr>  
           {props.rowData.SectorExpertise.expertiesDetail.map((expertiesDetail)=>{
           return (
            <>
            
          <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name of Startup :</b> 
              </label>
              <span>{expertiesDetail.name_of_startup}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Sector :</b>
            </label>
            <span className="text-justify">
            {expertiesDetail.sector}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Website URL :</b> 
              </label>
              <span>{expertiesDetail.website_URL}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Testimonials(if any) :</b>
            </label>
            <span className="text-justify">
            {expertiesDetail.testimonials}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Remarks for Current Status :</b> 
              </label>
              <span>{expertiesDetail.remark_for_current_status}</span>
            </div>
            <hr></hr>
          </div>
          
          </div>
          </>
           )
           })} 
           <div className="row">
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Sector Strength :</b> 
            </label>
            <span> {props.rowData.SectorExpertise.sector_strength}</span>
          </div>
          <hr></hr>
        </div>
        </div>
        
       
      </TabPanel>
      <TabPanel value={value} index={4}>
       {props.rowData.MarketLinkages.marketDetails.map((marketDetails)=>{ 
           
           return ( 
      <>      
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Organization Name :</b>{" "}
                
              </label>
              <span>{marketDetails.name_organization}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Type :</b> 
              <span>{marketDetails.type_organization}</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Sectors:</b>{" "}
                
              </label>
              <span>{marketDetails.sector}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Document Type :</b> 
            </label>
            <span>{marketDetails.type_of_document}</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Document :</b> 
              </label>
              <span>{marketDetails.uploads}</span>
            </div>
            <hr></hr>
          </div>
        </div>
        </>
            )
      })} 
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Program Duration :</b>{" "}
                
              </label>
              <span>{props.rowData.Infrastructure.program_duration}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>What type of infrastructure you provided? :</b> 
            </label>
            <span>{props.rowData.Infrastructure.What_type_infrastructure_provide}</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Address :</b> 
              </label>
              {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>State :</b> 
            </label>
            {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
          </div>
          <hr></hr>
        </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>District :</b>{" "}
                
              </label>
              {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>City :</b> 
            </label>
            {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
          </div>
          <hr></hr>
        </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Area in sqft :</b>{" "}
               
              </label>
              {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Additional Access Provided :</b> 
            </label>
            {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
          </div>
          <hr></hr>
        </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Specify if Other(Additional Access) :</b> 
              </label>
              {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Virtual Access Provided :</b> 
            </label>
            {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Specify if Other(Virtual Access) :</b> 
              </label>
              {/* <span>{props.rowData.incubatorDetail.name_of_individual_firm}</span> */}
            </div>
            <hr></hr>
          </div>
          
        </div>
      </TabPanel>
      
    </Box>
  )
}
