import React from "react";
import Header from "../component/Header";
import Footer from "../component/footer";

function Terms() {
  return (
    <>
      <Header />
      <div className="mahaGrand-main intellectual-main">
        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                  Terms
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  This official website of the "MSInS" has been developed to
                  provide information to the general public. Though all efforts
                  have been made to ensure the accuracy of the content on this
                  website, the same should not be construed as a statement of
                  law or used for any legal purposes. In no event will Start-up
                  India will be liable for any expense, loss or damage
                  including, without limitation, indirect or consequential loss
                  or damage, or any expense, loss or damage whatsoever arising
                  from use, or loss of use, of data, arising out of or in
                  connection with the use of this website.Links to other
                  websites that have been included on this website are provided
                  for public convenience only. It is not responsible for the
                  contents or reliability of linked websites and does not
                  necessarily endorse the view expressed within them. We cannot
                  guarantee the availability of such linked pages at all times.
                  Material featured on this website may be reproduced free of
                  charge. However, the material has to be reproduced accurately
                  and not to be used in a derogatory manner or in a misleading
                  context. Wherever the material is being published or issued to
                  others, the source must be prominently acknowledged. However,
                  the permission to reproduce this material shall not extend to
                  any material which is identified as being copyright of a third
                  party. Authorization to reproduce such material must be
                  obtained from the Departments/copyright holders concerned.
                  These terms and conditions shall be governed by and construed
                  in accordance with the Indian Laws. Any dispute arising under
                  these terms and conditions shall be subject to the exclusive
                  jurisdiction of the courts of India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms;
