import React from 'react'
import Header from "../component/Header";
import Footer from "../component/footer";

function TenderNotification() {
  const styles = {
    tr: {
      border: "1px solid #e5e8ec"
    },
  }
  return (
    <>
    <Header />
    <>
    
    <div className="mahaGrand-main intellectual-main">
    
      <div className="container">
      <div className="row d-flex justify-content-center">
        
          {/* <Button className="btnGoBack" onClick={handleClick}><span><BsArrowLeft/></span>&nbsp;&nbsp;Go Back</Button> */}
      
      </div>
        <div className="row d-flex justify-content-center my-3 d-none d-lg-block d-md-none d-sm-none">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row" id="granchallenge">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
              Tender Notifications
              </h2>

              <table class="table table-bordered mt-5">
                <thead>
                  <tr>
                    <th className="justify-content-center d-flex">Sr No.</th>
                    <th scope="col">Tender ID</th>
                    <th scope="col">Tender Reference Number</th>
                    <th scope="col">Tender Title</th>
                    <th scope="col">Tender Category</th>
                    <th scope="col">Published Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                {/* {users.map((user,num) => ( */}
                <tbody>
                  <tr style={styles.tr}>
                    <th scope="row" className="justify-content-center d-flex" >
                      1
                    </th>
                    <td> 2021_SDEDM_735268_1 </td>
                    <td> MSInnoS/2020-21/C.R.51/T-3/500 </td>
                    <td> Maharashtra Startup Yatra </td>
                    <td> Services </td>
                    <td>02-11-2021 16:00</td>
                    <td>Completed</td>
                  </tr>
                  <tr style={styles.tr}>
                    <th scope="row" className="justify-content-center d-flex">
                      2
                    </th>
                    <td> 2021_SDEDM_671118_1</td>
                    <td> MSInnoS/2020-21/C.R.8 /Admin/T-2/400-1 </td>
                    <td> Recruitment Agency </td>
                    <td> Services </td>
                    <td>30-03-2021 12:21</td>
                    <td>Completed</td>
                  </tr>
                  <tr style={styles.tr}>
                    <th scope="row" className="justify-content-center d-flex">
                      3
                    </th>
                    <td> 2024_MSSDS_1057850_1</td>
                    <td> MSSDS/AEP/2024 </td>
                    <td> Tender for selection of eligible manpower agency to provide Contractual Staff for MSSDS and Allied Offices </td>
                    <td> Services </td>
                    <td>24-07-2024  10:00:00</td>
                    <td><center><a href='https://mahatenders.gov.in/nicgep/app' target="_blank">Live</a></center></td>
                  </tr>
                </tbody>
               
              </table>
             
            </div>
          </div>
          <p className="font-style-normal font-weight-400 font-18-22 font-14-17 d-flex justify-content-center pt-4"> <a href="https://mahatenders.gov.in/nicgep/app" target="_blanc" className="">Know More</a></p>
        </div>
        <div className="row d-flex justify-content-center my-3 d-lg-none d-md-block d-sm-block">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="mob-scroll-horizontal">
            <div className="row" id="granchallenge">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
              Tenders Notifications
              </h2>
              <table class="table table-bordered mt-5">
                <thead>
                  <tr style={styles.tr}>
                    <th className="justify-content-center d-flex">Sr No.</th>
                    <th scope="col">Tender ID</th>
                    <th scope="col">Tender Reference Number</th>
                    <th scope="col">Tender Title</th>
                    <th scope="col">Tender Category</th>
                    <th scope="col">Published Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                {/* {users.map((user,num) => ( */}
                <tbody>
                  <tr style={styles.tr}>
                    <th scope="row" className="justify-content-center d-flex">
                      1
                    </th>
                    <td> 2021_SDEDM_735268_1 </td>
                    <td> MSInnoS/2020-21/C.R.51/T-3/500 </td>
                    <td> Maharashtra Startup Yatra </td>
                    <td> Services </td>
                    <td>02-11-2021 16:00</td>
                    <td>Completed</td>
                  </tr>
                  <tr style={styles.tr}>
                    <th scope="row" className="justify-content-center d-flex">
                      2
                    </th>
                    <td> 2021_SDEDM_671118_1</td>
                    <td> MSInnoS/2020-21/C.R.8 /Admin/T-2/400-1 </td>
                    <td> Recruitment Agency </td>
                    <td> Services </td>
                    <td>30-03-2021 12:21</td>
                    <td>Completed</td>
                  </tr>
                  <tr style={styles.tr}>
                    <th scope="row" className="justify-content-center d-flex">
                      3
                    </th>
                    <td> 2024_MSSDS_1057850_1</td>
                    <td> MSSDS/AEP/2024 </td>
                    <td> Tender for selection of eligible manpower agency to provide Contractual Staff for MSSDS and Allied Offices </td>
                    <td> Services </td>
                    <td>24-07-2024  10:00:00</td>
                    <td><center><a href='https://mahatenders.gov.in/nicgep/app' target="_blank">Live</a></center></td>
                  </tr>
                  
                 
                </tbody>
               
              </table>
              
            </div>
            </div>
            <p className="font-style-normal font-weight-400 font-18-22 font-14-17 d-flex justify-content-center pt-4"><a href="https://mahatenders.gov.in/nicgep/app" target="_blanc" className="">Know More</a></p>
          </div>
        </div>
      </div>
    </div>
    </>
    <Footer />
    </>
  )
}

export default TenderNotification