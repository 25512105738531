import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../startup/informationIcon";
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Upload from "../assets/images/navbar/upload.png";

const serverUrl = environment.baseUrl;
const Sectorexpertise = (props) => {
  const authToken =sessionStorage.getItem("token");
   // console.log(JSON.parse(sessionStorage.getItem('sectorDetails')))

 let initialFormObj={ 
  no_of_startup_incubated: '',

  expertiesDetail: [{
    name_of_startup:'',
    sector:'',
    website_URL:'',
    testimonials:'',
    remark_for_current_status:''
    }],
    sector_strength: ''
 }

  function handleSubmitSectorexpertiseForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("no_of_startup_incubated",values.no_of_startup_incubated)
    formData.append("name_of_startup",values.name_of_startup)
    formData.append("sector",values.sector)
    formData.append("website_URL",values.website_URL)
    formData.append("testimonials",values.testimonials)
    formData.append("websiteremark_for_current_status_URL",values.remark_for_current_status)
    formData.append("sector_strength",values.sector_strength)
    fetch(serverUrl +"/sectorExperties/addsectorExpert", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken,
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1) 
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values
      ) =>
      handleSubmitSectorexpertiseForm(values)
    }
    validationSchema={Yup.object({
      no_of_startup_incubated: Yup.string().required('No. of Startups Incubated is required'),
      
        expertiesDetail: Yup.array().of(
          Yup.object().shape({

            name_of_startup: Yup.string().required('Name of Startup is required'),
            sector: Yup.string().required('Sector is required'),
            website_URL: Yup.string().required('Website URL is required'),
            testimonials: Yup.string().required('Testimonials(if any) is required'),
            remark_for_current_status: Yup.string().required('Remarks for Current Status is required'),
              
          })
        ),
        sector_strength: Yup.string().required('Sector strength is required'),

           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('sectorDetails'))||initialFormObj}

      render={({ values }) => (
        <Form >
        <div className="row">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Sector Expertise
            </h2>
        </div>
        <div className="row"> 
          <div className="col-lg-6 col-md-6 col-sm-6">
            <label className="pb-2 label-txt">
          No. of Startups Incubated <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
          <Field type="text" placeholder="Enter No. of Startups Incubated" className="textInput" name="no_of_startup_incubated" />
                            <div className="text-danger">
                            <ErrorMessage className="text-danger" name="no_of_startup_incubated" />
                          </div>
          </div>
        </div>
          <FieldArray
            name="expertiesDetail"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>



                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"15%"}}>Name of Startup</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"10%"}} align="left">Sector</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Website URL</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"25%"}} align="left">Testimonials(if any)</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"25%"}} align="left">Remarks for Current Status</TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>

      



                {values.expertiesDetail && values.expertiesDetail.length > 0 ? (


                  values.expertiesDetail.map((friend, index) => (
                    
                     

                    <TableBody  className="data-bg-color d-flex justify-content-between">

              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"15%"}}>
                  <Field type="text" className="txt-color" name={`expertiesDetail.${index}.name_of_startup`} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name={`expertiesDetail.${index}.name_of_startup`} /><br></br>
                    </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                          <Field type="text" className="txt-color" name={`expertiesDetail.${index}.sector`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`expertiesDetail.${index}.sector`} /><br></br>
                        </div>
              </TableCell>
             

              <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"15%"}}>
                  <Field type="text" className="txt-color" name={`expertiesDetail.${index}.website_URL`} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name={`expertiesDetail.${index}.website_URL`} /><br></br>
                    </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"25%"}}>
                          <Field type="text" className="txt-color" name={`expertiesDetail.${index}.testimonials`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`expertiesDetail.${index}.testimonials`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"25%"}}>
                          <Field type="text" className="txt-color" name={`expertiesDetail.${index}.remark_for_current_status`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`expertiesDetail.${index}.remark_for_current_status`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
          
              </TableBody>  
      
   


                     
                    
                  ))
                ) : (
                 <></>
                )}
               
                </Table>
      </TableContainer>
      <br></br>

              </div>
            )}
          />
           <div className="row"> 
           <div className="col-lg-6 col-md-6 col-sm-6">
           <label className="pb-2 label-txt">
           Sector strength <span className="text-danger">*</span><InformationIcon /> </label>
          <Field type="text" placeholder="Enter Sector strength" className="textInput" name="sector_strength" />
            <div className="text-danger">
            <ErrorMessage className="text-danger" name="sector_strength" />
          </div>
          </div>
        </div>

        <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("sectorDetails", JSON.stringify(values))}}   
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default Sectorexpertise;
