import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };


export default function ModalLink(props) {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
       
        <Tab className="txt-main-style" label="Basic details" {...a11yProps(0)} />
       
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b> First Name:</b>
            </label>
            <span>{props.rowData.userDetails.first_name}</span>
          </div>
          <hr></hr>
        </div>
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Last Name :</b> 
            </label>
            <span>{props.rowData.userDetails.last_name}</span>
            </div>
          <hr></hr>
          </div>
        </div>
     

      <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Email :</b> <span>{props.rowData.userDetails.email}</span>
            </label>
            </div>
          <hr></hr>
          </div>
       
        <div className="col-md-6">
        <div className="">
          <label>
            {" "}
            <b>Mobile Number:</b> <span>{props.rowData.userDetails.mobile_no}</span>
          </label>
          </div>
          <hr></hr>
        </div>
      </div>
    
     <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Type :</b> <span>{props.rowData.userDetails.type}</span>
            </label>
            </div>
          <hr></hr>
          </div>     
      </div>    
    </TabPanel>
   
  </Box>
  )
}
