import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import skilllogo from "../assets/images/login/skill-logo.png";
import india from "../assets/images/contact/india.png";
import apple from "../assets/images/login/Apple.png";
import facebook from "../assets/images/login/facebook.png";
import Windows from "../assets/images/login/Windows.png";
import Slack from "../assets/images/login/Slack.png";
import styles from '../../src/assets/scss/component/register.scss'
// import GoogleLogin from "./GoogleLogin";
// import Recaptcha from "react-recaptcha";
// import axios from 'axios'
// import React, { useState } from 'react'
import { Button } from "react-bootstrap-v5";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ReCAPTCHA from "react-google-recaptcha";
import Gmail from "../assets/images/login/gmail.png";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
// import ReactDOM from "react-dom";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BsFillCaretDownFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { environment } from '../api';
import { useNavigate } from "react-router-dom";
import validate from './LoginFormValidationRules';
window.recaptchaOptions = {
  useRecaptchaNet: true
};
const Register = (callback,props) => {
  const serverUrl = environment.baseUrl;
  const reactChaUrl =environment.REACT_APP_API_URL;
  let navigate = useNavigate();
  // const recaptchaRef = React.useRef();

  const captchaRef = useRef(null)

  const [data, setData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
    mobile_no:"",
    type:"",
	});
	const [error, setError] = useState("");
	const [msg, setMsg] = useState("");


  const [btnColor, setBtnColor] = useState("#F6F6F6");
  const [showText, setShowText] = useState(true);
  
	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
    const token = captchaRef.current.getValue();
        captchaRef.current.reset();
		try {
      const url = serverUrl +`/users`;
			const { data: res } = await axios.post(url, data, reactChaUrl, {token});
      alert("Registration Successful Please Active Admin Side.")
			// setMsg(res.message);
      navigate("/login", { replace: true });
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};














 

//   let handleSubmit = async (e) => {
//     if (e) e.preventDefault();
//     setErrors(validate(first_name,last_name,mobile_no,email,password));
//     setIsSubmitting(true);
//     try {
//       console.log(first_name);
//       // let res = await fetch("http://192.168.1.36:8080/admin/register", {
//         let res = await fetch(serverUrl + "/admin/register",
//           {
//         method: "POST",
//         // mode: 'no-cors',
//         body: JSON.stringify({
//           first_name: first_name,
//           last_name: last_name,
//           mobile_no: mobile_no,
//           // conf_password:conf_password,
//           email: email,
//           password: password,
//           // code:code,
//           type:type
//         }),
//         headers: {
//           "Access-Control-Allow-Origin": "*",
// "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
//           "Content-Type": "application/json",
//           accept: "application/json"
//         },
//       });
      
//       if (res.status === 200) {
//         setfName("");
//         setlName("");
//         setMobile("");
//         // setconf_Password("");
//         // setCode("");
//         setEmail("");
//         setPassword("");
//         setType("");
//         // setMessage("User created successfully");
//       }
//       navigate("/login", { replace: true });
//       //  else {
//         // setMessage("Some error occured");
//       // }
//     } catch (err) {
//       console.log(err);
//     }
//   };

  return (
    <div className="main-register">
      <div className="">
        <div className="row ">
          <div className="col-lg-5 col-md-5 col-sm-5 justify-content-center d-flex bg-1">
            <div className="">
              <div className="row registerData">
                {/* ............Go back ............ */}
                <span className="back-head gobackRegBtn font-18-22" >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="20px"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                  <Link to="/" style={{ textDecoration: 'none', color: '#000'  }}>
                  <span className="gobackMoboSpace" > Go Back</span>
                  </Link>
                  
                </span>
                {/* ................Register guidelines Accordian.................... */}
                <div className="d-lg-none d-md-none" onClick={() => setShowText(!showText)}>
                  <Accordion className="d-lg-none">
                    <div className="">
                      <AccordionSummary
                        expandIcon={<BsFillCaretDownFill />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => {
                          btnColor === "#F6F6F6"
                            ? setBtnColor("#F6F6F6")
                            : setBtnColor("white");
                        }}
                        style={{ backgroundColor: btnColor }}
                      >
                        <Typography className="registerAccordian">
                          {" "}
                          <p className="font-14-17 pt-3 ">
                            View Registration Guide
                          </p>{" "}
                        </Typography>
                      </AccordionSummary>
                    </div>

                    <AccordionDetails className="guide-Register font-14-17 px-3">
                      <Typography className="font-14-17">
                        <p className="mid-para mt-3">
                          Registration with Maharashtra State Innovation Society
                          (MSInS) helps you:{" "}
                        </p>
                        <p className="mid-para mt-3">
                          (a) To apply to various programs and schemes launched
                          by MSInS
                          <br />
                          (b) To receive updates about programs and
                          opportunities in the startup ecosystem through a
                          monthly newsletter
                        </p>
                        <p className="mid-para mt-3">
                          To register with MSInS, please go through the
                          following steps
                        </p>
                     

                        <p className="mt-3 steps-head">Step 1 - Register</p>
                        <ul className="">
                          <li>
                            Click on the “Register” button on www.msins.in (top
                            right of web-page)
                          </li>

                          <li>Fill your Details and Captcha</li>

                          <li>
                            In the User Type drop-down box, select the
                            appropriate category (For e.g.: to register as a
                            startup company, please select “startup”)
                          </li>

                          <li>
                            After you have filled the required information,
                            click on “Register”.
                          </li>
                        </ul>

                        

                        <p className="mt-3 steps-head">
                          Step 2 - Email Verification
                        </p>
                        <ul className="">
                          <li>
                            You must have now received an email for verification
                            (from info@msins.in).
                          </li>

                          <li>
                            Open the email and click on the “Verify” button. You
                            will be redirected to the website with a message
                            “Verification Completed”.
                          </li>

                          <li>
                            Note: Incase you do not receive the said email,
                            kindly check your Spam folder.
                          </li>
                        </ul>

                       
                        <p className="mt-3 steps-head">Step 3: Login</p>
                        <ul className="">
                          <li>
                            Click on the ‘Login’ button on www.msins.in (top
                            right of web-page)
                          </li>

                          <li>Enter your registered Email ID and Password.</li>

                          <li>
                            In-case you forgot your password, you can click on
                            ‘Forgot Password’ to reset your password.
                          </li>
                        </ul>
                       

                        <p className="mt-3 steps-head">
                          Step 4: Creation of Business Profile
                        </p>
                        <ul className="">
                          <li>
                            The next screen will prompt you to create a business
                            profile. Business Profile asks information about
                            your startup under the following sections: Personal
                            details, Basic, Team information, IP, Business
                            Model, Financials, and Document Upload.
                          </li>

                          <li>
                            The fields marked with asterisk (*) are compulsory
                            for completion of business profile You may save your
                            business profile as a draft at each stage and come
                            back later to complete the business profile.
                          </li>

                          <li>
                            Data being sought in the business profile will help
                            check your startup’s eligibility for various MSInS
                            programs. Hence, it is requested that the latest
                            information be filled in the Business Profile
                            Section. All information shall be kept confidential.
                          </li>

                          <li>
                            Completion of Business Profile is mandatory to
                            complete registration with MSInS
                          </li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* ..........................Accordian end............................ */}

                {showText && (
                  <div>
                    <div className="containMoboRegister">
                      <div>
                        <h3 className="Register-head py-3 font-34-44 font-30-42 font-20-26">
                          Register your account
                        </h3>
                      </div>
                      <div className="row">
                      <div className='col-md-4 col-sm-3 col-4 col-lg-4 align-self-sm-center'>
                        <div className="registertxtImgPanel1">
                          <div className="white-block ">
                            <p className="font-18-22 py-2 px-1 font-13-16">
                             Register With
                              {/* <div className='d-lg-none d-md-block d-sm-block'>
                              <Link to="https://www.startupindia.gov.in"> 
                            <img
                              className="
                           register-img pt-1 "
                              src={skilllogo}
                            />
                            </Link>
                            </div> */}
                            </p>
                            
                          </div>
                        </div>
                        
                        </div>
                        <div className='col-md-8 col-sm-9 col-8 col-lg-8 align-self-sm-center'>
                        <div className="registerLogopanel">
                          
                        <Link to="https://www.startupindia.gov.in"> 
                            <img
                              className="
                           register-img pt-1 "
                              src={skilllogo}
                            />
                            </Link>
                           
                        {/* <a href="https://www.startupindia.gov.in/"> */}
                          
                          {/* </a> */}
                        <Link to="/">
                            <img
                              className=" imgLoginIcons"
                              src={Gmail}
                            />
                             {/* <GoogleLogin /> */}
                          </Link>
                          <Link to="/">
                          <img
                            className="
                           imgLoginIcons"
                            src={Windows}
                          />
                          </Link>
                          <Link to="/">
                          <img
                            className="
                           imgLoginIcons"
                            src={apple}
                          />
                          </Link>
                          {/* <a href=""> */}
                          <Link to="https://www.facebook.com/MSInSociety">
                            <img
                              className="
                           imgLoginIcons"
                              src={facebook}
                            />
                          </Link>
                         
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 mr-section">
                      <form  onSubmit={handleSubmit} method="POST" >
                        <div className="containMoboRegister">
                          <div className="col-sm-12 py-2">
                            <input
                              type="text"
                              className="form-control formInputMobile  "
                              id="inputfname"
                              placeholder="First Name"
                              name="first_name"
                              onChange={handleChange}
                              value={data.first_name || ""}
                              required
                            
                            />
                             {/* {<errors className="first_name"></errors> && (
                    <p className="help is-danger invalid-feedback ">{errors.first_name}</p>
                      
                         
                  )} */}
                          </div>

                          <div className="col-sm-12 py-2">
                            <input
                              type="text"
                              className="form-control formInputMobile "
                              id="inputlname"
                              placeholder="Last Name"
                              name="last_name"
                              onChange={handleChange}
							value={data.last_name}
							required
                              
                            />
                             {/* {<errors className="last_name"></errors> && (
                    <p className="help is-danger invalid-feedback ">{errors.last_name}</p>
                      
                         
                  )} */}
                          </div>
                          <div className="col-sm-12 py-2">
                            <input
                              type="email"
                              className="form-control formInputMobile"
                              id="inputemail"
                              placeholder="Enter Email"
                              name="email"
                              onChange={handleChange}
                              value={data.email}
                              required    />
                               {/* {<errors className="email"></errors> && (
                    <p className="help is-danger invalid-feedback ">{errors.email}</p>
                      
                         
                  )} */}
                          </div>
                          <div className="col-sm-12 py-2">
                            <div className="row codeFlagMoboNo">
                              <div className="col-sm-3 codeFlagInput">
                                <div className="form-group has-search formInputMobile">
                                <ReactPhoneInput
                                    defaultCountry="in"
                                  
                                  placeholder="code"
                                  
                                  enableSearchField
                                  // value={code}
                                  // onChange={e => setCode(e.target.value)}
                                  
                                 /> 
                                  {/* <span className="form-control-feedback">
                                    <img className="flagIcon" src={india} />
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control formInputMobile"
                                    placeholder="Code"
                                    name="code"
                                  /> */}
                                </div>
                              </div>
                              <div className="col-sm-9 moboNoInput">
                                <input
                                  type="tel"
                                  pattern="[789]{1}[0-9]{9}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" minlength="10"
                                  className="form-control formInputMobile"
                                  placeholder="Enter number"
                                  name="mobile_no"
                                  onChange={handleChange}
							value={data.mobile_no}
							required
                                  
                                  
                                />
                                {/* {<errors className="mobile_no"></errors> && (
                    <p className="help is-danger invalid-feedback ">{errors.mobile_no}</p>
                      
                         
                  )} */}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 py-2">
                            <select
                              className="form-select formInputMobile"
                              aria-label="Default select example"
                              name="type"
                              onChange={handleChange}
							value={data.type}
							required >
                              <option value="">Select a type</option>
                              <option value="Startup">Startup</option>
                              <option value="Investor">Investor</option>
                              <option value="NRI">NRI</option>
                              <option value="Mentor">Mentor</option>
                              <option value="Incubator">Incubator</option>
                            </select>
                          </div>
                          <div className="col-sm-12 py-2">
                            <input
                              type="password"
                              className="form-control formInputMobile"
                              id="inputemail"
                              placeholder="Enter Password"
                              name="password"
                              onChange={handleChange}
							value={data.password}
							required
                              
                            />
                              {/* {<errors className="password"></errors> && (
                    <p className="help is-danger invalid-feedback ">{errors.password}</p>
                      
                         
                  )} */}
                          </div>
                          {/* <div className="col-sm-12 py-1">
                            <input
                              type="password"
                              className="form-control formInputMobile"
                              id="inputemail"
                              name="conf_pass"
                              placeholder="Confirm Password"
                              value={conf_password}
                              onChange={(e) => setconf_Password(e.target.value)}
                            />
                          </div> */}
                          <div className="col-sm-6 pb-3 py-2">
                            <ReCAPTCHA
                            ref={captchaRef}
                              // ref={recaptchaRef}
                              sitekey="6LcpfFgjAAAAADx_jeWYdtPZM3d3-l-RXsZEjEU6"
                              // sitekey={process.env.REACT_APP_SITE_KEY}
                            />
                          </div>
                        <div className="circle"></div>
                          <div className="bluebtn d-none d-sm-block">
                          {error && <div className={styles.error_msg}><div className="text-danger p-1 mb-3 d-flex justify-content-left">{error}</div></div>}
					               	{msg && <div className={styles.success_msg}>{msg}</div>}
                            <Button className="btn-primary" type="submit">
                              {/* <Link to=""> */}
                                <span className="regular_font font-20-24 font-style-normal font-weight-400">
                                  Register
                                </span>
                                <span className="inno-arrow">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 16 16"
                                    height="25px"
                                    width="35px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                    />
                                  </svg>
                                </span>
                              {/* </Link> */}
                            </Button>
                          </div>
                          <div>
                            <p className="font-18-22 font-14-17">
                              Already have an account? &nbsp;
                              <Link to="/Login"><a className="login-link" >Login</a></Link>
                            </p>
                          </div>
                        </div>
                        <div className="mobobluebtnpx ">
                          <div className="mob-primarybtn d-sm-none d-md-none d-lg-none flex-item ">
                            <Link to="">
                              <div className="d-flex justify-content-center align-items-center py-2">
                                <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                  {" "}
                                  Sign up
                                </span>
                                <div className="inno-arrow">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 16 16"
                                    height="25px"
                                    width="35px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
            {/* .................Web Register guideline..................... */}
          <div className="col-lg-7 col-md-7 col-sm-7 bg-2  d-none d-sm-block d-md-block d-lg-block">
            <div className="row mt-4 custom-scroll mt-section guideDetails  ">
              <div className="font-18-22 mr-2 guidelinesText">
              <div className="Register-head guide font-24-29">Registration Guide</div>
              <p className="mid-para mt-3">
                Registration with Maharashtra State Innovation Society (MSInS)
                helps you:{" "}
              </p>
              <p className="mid-para mt-3">
                (a) To apply to various programs and schemes launched by MSInS
                <br />
                (b) To receive updates about programs and opportunities in the
                startup ecosystem through a monthly newsletter
              </p>
              <p className="mid-para mt-3">
                To register with MSInS, please go through the following steps
              </p>
              <hr className="hr-line" />

              <p className="mt-3 steps-head">Step 1 - Register</p>
              <ul className="list-step">
                <li>
                  Click on the “Register” button on www.msins.in (top right of
                  web-page)
                </li>

                <li>Fill your Details and Captcha</li>

                <li>
                  In the User Type drop-down box, select the appropriate
                  category (For e.g.: to register as a startup company, please
                  select “startup”)
                </li>

                <li>
                  After you have filled the required information, click on
                  “Register”.
                </li>
              </ul>

              <hr className="hr-line" />

              <p className="mt-3 steps-head">Step 2 - Email Verification</p>
              <ul className="list-step">
                <li>
                  You must have now received an email for verification (from
                  info@msins.in).
                </li>

                <li>
                  Open the email and click on the “Verify” button. You will be
                  redirected to the website with a message “Verification
                  Completed”.
                </li>

                <li>
                  Note: Incase you do not receive the said email, kindly check
                  your Spam folder.
                </li>
              </ul>

              <hr className="hr-line" />

              <p className="mt-3 steps-head">Step 3: Login</p>
              <ul className="list-step">
                <li>
                  Click on the ‘Login’ button on www.msins.in (top right of
                  web-page)
                </li>

                <li>Enter your registered Email ID and Password.</li>

                <li>
                  In-case you forgot your password, you can click on ‘Forgot
                  Password’ to reset your password.
                </li>
              </ul>
              <hr className="hr-line" />

              <p className="mt-3 steps-head">
                Step 4: Creation of Business Profile
              </p>
              <ul className="list-step">
                <li>
                  The next screen will prompt you to create a business profile.
                  Business Profile asks information about your startup under the
                  following sections: Personal details, Basic, Team information,
                  IP, Business Model, Financials, and Document Upload.
                </li>

                <li>
                  The fields marked with asterisk (*) are compulsory for
                  completion of business profile You may save your business
                  profile as a draft at each stage and come back later to
                  complete the business profile.
                </li>

                <li>
                  Data being sought in the business profile will help check your
                  startup’s eligibility for various MSInS programs. Hence, it is
                  requested that the latest information be filled in the
                  Business Profile Section. All information shall be kept
                  confidential.
                </li>

                <li>
                  Completion of Business Profile is mandatory to complete
                  registration with MSInS
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
