import React from 'react'
import { Link } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/footer';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import carousel1 from '../../';
import carousel1 from '../assets/images/Initiative/938589Internship_001.jpg';
import carousel2 from '../assets/images/Initiative/633212Internship_002.jpg';
import carousel3 from '../assets/images/Initiative/221470Internship_003.jpg';
import carousel4 from '../assets/images/Initiative/581512Internship_004.jpg';
import carousel5 from '../assets/images/Initiative/529386Internship_005.jpg';

function InnovationDepartment() {
  return (
    <>
    <Header/>
    <div className="mahaGrand-main intellectual-main">
    <div>
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        interval={5000}
      >
        <div>
          
          <img src={carousel1}/>
          {/* <p className="legend">Legend 1</p> */}
        </div>
        <div>
          <img src={carousel2} />
          {/* <p className="legend">Legend 2</p> */}
        </div>
        <div>
          <img src={carousel3}/>
          {/* <p className="legend">Legend 3</p> */}
        </div>
        <div>
          <img src={carousel4}/>
          {/* <p className="legend">Legend 3</p> */}
        </div>
        <div>
          <img src={carousel5}/>
          {/* <p className="legend">Legend 3</p> */}
        </div>
      </Carousel>
      
    </div>
      <div className="container">
        <div className="row d-flex justify-content-center my-3">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row" id="granchallenge">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
              Application for Internship with Skills, Employment, Entrepreneurship and Innovation Department
              </h2>
              <h5>INVITATION FOR INTERNS</h5>

              <h5>Background</h5>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
              Department of Skills, Employment, Entrepreneurship and Innovation, Government of Maharashtra is working towards bringing together students, youth, job seekers, employers, trainers and entrepreneurs all under one umbrella. Maharashtra makes the third largest contribution to India’s population – thus becoming a large contributor to the India’s demographic dividend. To create a holistic skilling and entrepreneurship ecosystem in the state, the department run various initiatives and policies through its various field offices namely – Commissionerate of Skills, Employment, Entrepreneurship and Innovation (https://www.mahaswayam.gov.in), Maharashtra State Skill Development Society (MSSDS) (https://kaushalya.mahaswayam.gov.in), Maharashtra State Innovation Society (MSInS) (https://msins.in), Directorate of Vocational Education and Training (DVET) (https://www.dvet.gov.in/mr/), Maharashtra State Board of Vocational Education Examination (MSBVE) (http://www.msbve.gov.in/) and Maharashtra State Skills University (MSSU) (http://mssu.ac.in).
                <h5>Secretariat of Skills, Employment, Entrepreneurship, and Innovation: </h5>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                The Secretariat is principal decision-making authority for managing the skilling ecosystem in Maharashtra. It is responsible for policy making, budgeting, control of expenditure, framing legislation, rules & regulations and monitoring of all 6 verticals of the Department. 
                
                  
             </p>
             <h5>Commissionerate of Skills, Employment, Entrepreneurship, and Innovation: </h5>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                The Commissionerate facilitates employment and provide self-employment guidance for the youth to enhance their incomes, thus promoting inclusive growth. It brings together around 33 lakh job aspirants and employer database of more than 67000 public and private establishments
                
                  
             </p>
             <h5>Maharashtra State Skill Development Society (MSSDS): </h5>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                To achieve this Skill Development Mission in the state of Maharashtra, Maharashtra State Skill Development Society has been registered on 15th February 2011. The Society is the Nodal Agency for planning, coordination, execution & monitoring of Skill Development Initiative of Government of Maharashtra.
                
                  
             </p>
             <h5>Maharashtra State Innovation Society (MSInS): </h5>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                Innovation Society is working for the effective implementation of “Maharashtra State Innovative Startup Policy 2018” and for the study and development of innovative ecosystems in the state. The society aims to create a conducive environment for entrepreneurs in Maharashtra by creating a promote startup environment, simplifying regulatory structure and creating employment.  

                
                  
             </p>
             <h5>Directorate Of Vocational Education & Training (DVET): </h5>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                DVET is committed to provide quality administration and vocational education and training services to educational institutes, Industrial Training Institutes and NGOs in Maharashtra State to meet the needs of industry, business to service sector and society at large.

                
                  
             </p>
             {/* <h5>Secretariat of Skills, Employment, Entrepreneurship, and Innovation: </h5> */}
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                Maharashtra State Board of Vocational Education Examination (MSBVE): Maharashtra State Board of Skill, Vocational Education and Training is a State Board established for the regulations of the matters pertaining to Skill, Vocational Education & Training and Entrepreneurship Education as per the National Skill Qualification Framework and other Skill Courses in the State of Maharashtra. The Board will strive to create highly skilled and employable youth and to provide a job-ready workforce to the industry.     The Secretariat is principal decision-making authority for managing the skilling ecosystem in Maharashtra. It is responsible for policy making, budgeting, control of expenditure, framing legislation, rules & regulations and monitoring of all 6 verticals of the Department. 
                
                  
             </p>
             <h5>Maharashtra State Skills University (MSSU): </h5>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                Maharashtra State Skills University is an upcoming university established under Maharashtra Act VII of 2022. 

The Department of Skills, Employment, Entrepreneurship and Innovation, Government of Maharashtra provides a unique opportunity for young professionals interested in development sector to gain first-hand experience of working in Government Departments and their field offices and directly contribute towards making change at scale. The Department will provide capacity building and support for professional development and growth of each intern. We are seeking to hire interns with a passion for improving government performance and resolving social problems. 

                
                  
             </p>
             <h5>Roles and Responsibilities:</h5>
            <p>The responsibilities would vary from project to project and would majorly frequently include:</p>  
             <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
             Assist in making policies and programs
Identifying bottlenecks in government schemes and suggest reforms through research on best practices. 
Providing innovative and feasible yet high impact solutions to identified challenges.
Assisting department to brainstorm and identify solutions to produce meaningful change.
Identifying expert agencies and institutions for building knowledge partnerships and technical collaboration. 
Providing information technology, social media marketing, project management, data analytics and legal support for the effective functioning of the Department and its field offices. 
</p>

<h5>Preferred Skills:</h5>
<ol>
                    <li>Passion for improving government performance and solving problems related to skilling, employment, livelihood generation, entrepreneurship and innovation.</li>
                    <li>Self-motivated with an orientation towards results. </li>
                    <li>Excellent written and verbal communications.</li>
                    <li>Strong project management capabilities.</li>
                    <li>Sound quantitative and analytical skills, with experience generating and using data to drive insights and communicating those insights to others.</li>
                    <li> Ability to navigate complex organizations, develop trust with senior leaders, and build relationships among diverse groups of stakeholders.</li>
                    <li>Awareness and sensitivity to the needs and concerns of individuals from diverse cultures, backgrounds, and orientations, and ability to apply that to their work.</li>
                    <li>Willingness to travel is required.</li>
                    <li>Proficiency in Marathi will be added advantage.</li>
                  </ol>
                  <h5>Preferred Qualification:</h5>
                <ol>
                  <li>A Graduate or Masters (pursuing or completed) in fields like Economics/Public Policy/Management/ Engineering/Mathematics/Computer Science/ Information Technology/ Data Management/ Media /Communication & allied disciplines along with strong IT and presentation skills. </li>
                  <li>Past association with skilling, livelihoods, entrepreneurship, employment, social media marketing, field work, proficiency in documentation would be an added advantage.</li>
                  {/* <li>Excellent verbal, written, interpersonal skills, good time management & organizational skills. </li>
                  <li>The candidates must be proficient in Microsoft office, data analytics, data visualization software, etc.</li>
                  <li>For media & communication fellows, digital marketing expertise, content management systems, social media platforms, graphic designing (Adobe Photoshop, Adobe Illustrator, Adobe InDesign, Illustrator, Corel Draw, Crello, Canva, etc.) is desirable which will help in dissemination of initiatives and through print & digital media.</li> */}
                  </ol>

<h5>Note</h5>
<p className="font-style-normal font-weight-400 font-18-22 font-14-17">
The shortlisted candidates would be operating from Mumbai under the guidance of Principal Secretary, Department of Skills, Employment, Entrepreneurship and Innovation and Commissioner, Skills, Employment, Entrepreneurship, and Innovation. Office is located at 2nd Floor, 2nd Floor Mantralaya, Madam Cama Road, Hutatma Rajguru Chowk, Mumbai 400032.
However, the candidates may be deployed at any of the Field offices in Mumbai region depending on the need of the projects.
Upper age limit to apply as Young Interns is 28 years as on 30th August 2022.
Candidates should provide two references with their contact details.
Applications will be accepted throughout the year, however, the first batch of interns will be shortlisted and offered internships within a month. Duration of the internship can be accommodated as per the availability of the candidate post their selection. It can vary from a minimum of 6 weeks up to 1 year.
</p>
  <h5>Remuneration:</h5> 

 <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
 Stipend will depend upon the Qualifications and Experience of the candidate
Travel cost for official work outside Mumbai will be borne by the office. 
Certificates will be given to interns on satisfactory performance over a minimum internship period of 6 weeks.
Interested candidates are invited to submit the duly filled Application Form as given in the Annexure 1, a Statement of Purpose* along with detailed CV at Email ID: application.seeid@gmail.com 
Shortlisted candidates shall be contacted for interview.
</p>
<p className="font-style-normal font-weight-400 font-18-22 font-14-17">
* Statement of purpose can be written in English or Marathi. No queries or correspondence regarding issue of call letters for interview/selection of candidates for the post will be entertained at any stage and canvassing will lead to the candidate being debarred from consideration of the post. Statement of purpose should include following questions:
</p>
<ol>
    <li>Reason for applying for this program? (100 words)</li>
    <li>Areas in which you would like to contribute? (250 words)</li>
    <li> What are your two core strengths? (150 words) </li>
    </ol> 
</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  )
}

export default InnovationDepartment