import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from "../api";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const serverUrl = environment.baseUrl;
const ApplyDomesticPatent = (props) => {
  // console.log(JSON.parse(localStorage.getItem('ipDetails')))

  let initialFormObj = {
    // DPIIT_Number1: "",
  };

  function handleSubmitDomesticPatent(values) {
    console.log(values, "hii");
    const formData = new FormData();
    // formData.append("DPIIT_Number1", values.DPIIT_Number1);
  //   fetch(serverUrl + "/ip/addIpDetail", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       accept: "application/json",
  //     },
  //     body: JSON.stringify(values, null, 2),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => console.log(res))
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   props.setValue(props.value + 1);
  }
  return (
    <div className="container">
      <div className="row ">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Formik
            onSubmit={(values) => handleSubmitDomesticPatent(values)}
            validationSchema={Yup.object({
              DPIIT_Number1: Yup.string().required(
                "Number required"
              ),
              DPIIT_Certificate: Yup.string().required(
                "Please Select file"
              ),
            })}
            initialValues={
              JSON.parse(localStorage.getItem("ipDetails")) || initialFormObj
            }
            render={({ values }) => (
              <Form>
                <div className="row pb-3">
                  <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                    DPIIT Registration
                  </h2>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <TableContainer component={Paper} className="no-shadow">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="head-bg-color">
                          <TableRow className="d-flex justify-content-between ">
                            <TableCell
                              className="table-txt align-items-center"
                              style={{ width: "20%" }}
                            >
                              DPIIT No.
                            </TableCell>
                            <TableCell
                              className="table-txt align-items-center"
                              style={{ width: "20%" }}
                              align="left"
                            >
                              Total Benefits Availed
                            </TableCell>
                            <TableCell
                              className="table-txt align-items-center"
                              style={{ width: "30%" }}
                              align="left"
                            >
                              Benefit Under Approvel
                            </TableCell>
                            <TableCell
                              className="table-txt align-items-center "
                              style={{ width: "20%" }}
                              align="left"
                            >
                              Available Limit For Benefits
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="data-bg-color d-flex justify-content-between">
                          <TableRow className="w-100 d-flex justify-content-between">
                            <TableCell component="th" scope="row">
                              <div>-</div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div>-</div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div>-</div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div>-</div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="text-danger">
                      <ErrorMessage className="error" name="do_you_have_IP" />
                    </div>
                    </div>
                </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                      <label className="pb-2 label-txt">
                        DPIIT Number
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="number"
                        plceholder="Enter DPIIT Number"
                        className="textInput"
                        name="DPIIT_Number1"
                      />
                      <div className="text-danger">
                        <ErrorMessage
                          className="text-danger"
                          name="DPIIT_Number"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                      <label className="pb-2 label-txt">
                        Upload DPIIT Certificate
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="file"
                        plceholder="DPIIT_Certificate"
                        className="textInput"
                        name="DPIIT_Certificate"
                      />
                      <div className="text-danger">
                        <ErrorMessage
                          className="text-danger"
                          name="DPIIT_Certificate"
                        />
                      </div>
                    </div>
                    </div>

                <div className="justify-content-center d-flex pt-4">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-draft no-shadow"
                      onClick={() => {
                        localStorage.setItem(
                          "ipDetails",
                          JSON.stringify(values)
                        );
                      }}
                    >
                      Save as Draft
                    </Button>
                    {/* <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-next no-shadow"
                    >
                      Save &amp; Next
                    </Button> */}
                  </Stack>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default ApplyDomesticPatent