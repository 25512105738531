import React from 'react'
import {useEffect,useState} from 'react';
import Header from '../component/SuperadminHeader';
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import { Accordion } from 'react-bootstrap'
import ViewStartup from './viewstartup'
import ViewMentor from './viewmentor'
import ViewInvestor from './viewinvestor'
import ViewIncubator from './viewincubator'
import ViewNri from './viewnri'
import ViewUserVerify from './viewUserVerifyInactive';
import ViewUserActiveVerify from './viewUserVerifyActive';
import Viewstudentinnovation from './viewstudentinnovation'
import ViewLandingBanner from '../cms/landingBanner/viewlandingbanner'
import ViewOurLeadership from '../cms/ourLeadership/viewourLeadership'
import ViewAnnounce from '../cms/Announcement/viewannounce'
// import ViewfeatureEvent from '../cms/featureEvent/viewfeatureEvent'
import ViewEcosystem from '../cms/ecosystem/viewEcosystem'
import ViewExecutiveTeam from '../cms/executiveTeam/viewExecutiveTeam'
import ViewGoverningCouncil from '../cms/governingCouncil/viewGoverningCouncil'
import ViewGeneralBody from '../cms/generalBody/viewGeneralBody'
import ViewstartupWeekWinner from '../cms/startupWeekWinner/viewStartupWinner'
import ViewGuidline from '../cms/guideline/viewGuideline';
import ViewFeaturedResources from '../cms/FeaturedResourcesaftersearch/viewFeaturedresourseaftersearch'
import ViewFundingSources from '../cms/fundingSources/viewFundingSources'
import ViewOurPartner from '../cms/ourPartner/ViewOurPartner'
import ViewOurPartnerHackthon from "../cms/ourPartnerHackthon/ViewOurPartnerHackthon"
import ViewMeetMentor from '../cms/mentor/ViewMentor'
import ViewAllIncubator from '../cms/allincubator/ViewIncubator'
// import ViewWec from '../cms/WEC/ViewWec'
import userPic from '../assets/images/bannerslider/MobHomepage321.png'
import ViewContactQuery from '../cms/contactUsQuery/ViewContactQuery';
import ViewContactFeedback from '../cms/contactUsFeedback/ViewContactFeedback';
import ViewContactSuggestions from '../cms/contactUsSuggestions/ViewContactSuggestions';
import ViewFeaturedVideos from '../cms/featuredVideos/viewFeaturedVideos';
import ViewStartupFAQs from '../cms/startupFAQs/viewStartupFAQs';
import ViewGrandChallengeFAQ from '../cms/grandChallengeFAQ/viewGrandChallengeFAQ';
 import ViewEventSchedule from '../cms/eventSchedule/viewEventSchedule';
// import ViewEcosystemCard from '../cms/ecosystemCard/viewEcosystemCard';
import ViewMedia from '../cms/media/viewMedia';
import ViewTestimonials from '../cms/testimonials/viewTestimonials';
import ViewUpcomingEvents from '../cms/Event/upcomingEvents/viewUpcoming';
import ViewPreviousEvents from '../cms/Event/previousEvents/viewPrevious'
// import ViewGrandChallengeContent from '../cms/grandChallengeContent/ViewGrandChallengeContent';
import ViewKeyInitiative from '../cms/keyInitiative/ViewKeyInitiative';
import ViewAccelerator from './viewaccelerator';
import ViewYatraWinners from '../cms/yatraWinners/viewYatraWinners';
import ViewIntellectualProperty from '../cms/intellectualProperty/viewIntellectualProperty';
import ViewQualityTesting from '../cms/QualityTesting/viewQualityTesting';
import ViewMaharashtraHackthonFAQ from '../cms/maharashtraHackthonFAQ/viewMaharashtraHackthonFAQ'
import ViewDivision from '../masters/divisions/ViewDivision';
import ViewSector from '../masters/sector/ViewSector';
import ViewBusinessModel from '../masters/businessModel/ViewBusinessModel'; 
import Viewstages from '../masters/Stage/ViewStages';
import ViewState from '../masters/state/ViewState';
import ViewDistrict from '../masters/district/ViewDistrict';
import ViewCountry from '../masters/country/ViewCountry';
// import ViewEcosystemMenubar from "../cms/ecoMenubar/viewEcosystemMenubar";
import ViewNewsLetter from "./viewnewsletteruser";
import ViewDepartment from '../masters/department/ViewDepartment';
import ViewUserType from '../masters/userType/ViewUserType';
import ViewQualitytestingsector from '../masters/qualityTesting/ViewQualityTesting';
import ViewAdminuser from '../masters/adminUserManagement/ViewAdminUserManagement';
import ViewDomestic from '../FinancialAssistance/Domesticpatent';
import ViewInternational from '../FinancialAssistance/Internantionalpatent';
import ViewCopyright from '../FinancialAssistance/copyright';
import ViewDesign from '../FinancialAssistance/design';
import ViewTrademark from '../FinancialAssistance/tradmarks';
import ViewFilling from '../FinancialAssistance/postfilling';
import ViewGrant from '../FinancialAssistance/postgrant';
import ViewAllocateDomestic from '../AlloacateApplications/DomesticPatentForm';
import ViewAllocateInternational from '../AlloacateApplications/InternationalPatentForm';
import ViewAllocateCopyright from '../AlloacateApplications/CopyrightForm';
import ViewAllocateTrademark from '../AlloacateApplications/TrademarkForm';
import ViewAllocateDesign from '../AlloacateApplications/DesignForm';
import ViewAllocateFilling from '../AlloacateApplications/PostFilingForm';
import ViewAllocateGrant from '../AlloacateApplications/PostGrantForm';
import ViewBPC from '../BusinessPlanCompetition/DistrictForm';
import ViewDistrictAdmin from '../BusinessPlanCompetition/BPCAdminUserForm';
import ViewDistrictSectors from '../BusinessPlanCompetition/DistrictSector2019';
import ViewDefaultSectors from '../BusinessPlanCompetition/DefaultSectors2019';
import ViewStudentList from '../BusinessPlanCompetition/StudentApplicantList';
import {Chart} from "react-google-charts";
import { environment } from '../api';


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export default function Dashboard() {
  const authToken =sessionStorage.getItem("token");
  
  const [value, setValue] = React.useState(0)
  const [value1, setValue1] = React.useState(0)
  const [value2, setvalue4] = React.useState(0)
  const [value3, setValue3] = React.useState(0)
  const [value4, setValue4] = React.useState(0)
  const [value5, setValue5] = React.useState(0)
  const [value7, setValue7] = React.useState(0)
  const [value8, setValue8] = React.useState(0)
  const [value9, setValue9] = React.useState(0)
  const [value10, setValue10] = React.useState(0)
  const [value11, setValue11] = React.useState(0)
  const [value12, setValue12] = React.useState(0)
  const [value13, setValue13] = React.useState(0)
  const [value14, setValue14] = React.useState(0)
  const [value15, setValue15] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChange1 = (event, newValue) => {
    setValue1(newValue)
  }
  const handleChange2 = (event, newValue) => {
    setvalue4(newValue)
  }
  const handleChange3 = (event, newValue) => {
    setValue3(newValue)
  }
  const handleChange4 = (event, newValue) => {
    setValue4(newValue)
  }
  const handleChange5 = (event, newValue) => {
    setValue5(newValue)
  }
  const handleChange7 = (event, newValue) => {
    setValue7(newValue)
  }
  const handleChange8 = (event, newValue) => {
    setValue8(newValue)
  }
  const handleChange9 = (event, newValue) => {
    setValue9(newValue)
  }
  const handleChange10 = (event, newValue) => {
    setValue10(newValue)
  }
  const handleChange11 = (event, newValue) => {
    setValue11(newValue)
  }
  const handleChange12 = (event, newValue) => {
    setValue12(newValue)
  }
  const handleChange13 = (event, newValue) => {
    setValue13(newValue)
  }
  const handleChange14 = (event, newValue) => {
    setValue14(newValue)
  }
  const handleChange15 = (event, newValue) => {
    setValue15(newValue)
  }
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
  };
  const [isShown1, setIsShown1] = useState(false);

  const handleClick1 = event => {
    setIsShown1(current => !current);
  };
  const [isShown2, setIsShown2] = useState(false);

  const handleClick2 = event => {
    setIsShown2(current => !current);
  };
  const [isShown3, setIsShown3] = useState(false);

  const handleClick3 = event => {
    setIsShown3(current => !current);
  };
  const [isShown4, setIsShown4] = useState(false);

  const handleClick4 = event => {
    setIsShown4(current => !current);
  };
  const [isShown5, setIsShown5] = useState(false);

  const handleClick5 = event => {
    setIsShown5(current => !current);
  };
  const [isShown7, setIsShown7] = useState(false);

  const handleClick7 = event => {
    setIsShown7(current => !current);
  };
  const [isShown8, setIsShown8] = useState(false);

  const handleClick8 = event => {
    setIsShown8(current => !current);
  };
  const [isShown9, setIsShown9] = useState(false);

  const handleClick9 = event => {
    setIsShown9(current => !current);
  };
  const [isShown10, setIsShown10] = useState(false);

  const handleClick10 = event => {
    setIsShown10(current => !current);
  };
  const [isShown11, setIsShown11] = useState(false);

  const handleClick11 = event => {
    setIsShown11(current => !current);
  };
  const [isShown12, setIsShown12] = useState(false);

  const handleClick12 = event => {
    setIsShown12(current => !current);
  };
  const [isShown13, setIsShown13] = useState(false);

  const handleClick13 = event => {
    setIsShown13(current => !current);
  };
  const [isShown14, setIsShown14] = useState(false);

  const handleClick14 = event => {
    setIsShown14(current => !current);
  };
  const [isShown15, setIsShown15] = useState(false);

  const handleClick15 = event => {
    setIsShown15(current => !current);
  };

  const serverUrl = environment.baseUrl;
  const [users, setUsers] = useState([]);
  const [announcement, setAnnouncement] = useState([]);
  // const [incubator, setIncubators] = useState([]);
  // const [investor, setInvestor] = useState([]);
  // const [nri, setNri] = useState([]);
  const [ecosystem, setEcosystem] = useState([]);
  const [ourpartner, setOurPartner] = useState([]);
  // const [keyInitiative, setKeyInitiative] = useState([]);
  const [keyInitatives, setkeyInitatives] = useState([]);
  const [executive, setExecutive] = useState([]);
  const [governing, setGoverning] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
	
//  const data1 = [
//     ["Task", "Hours per Day"],
//     ["Work", 11],
//     ["Eat", 2],
//     ["Commute", 2],
//     ["Watch TV", 2],
//     ["Sleep", 7],
//   ];
//    const options = {
//     title: "My Daily Activities",
//   };
//  const data2 = [
//       ["Year", "Sales"],
//       ["2014", 1000,],
//       ["2015", 1170,],
//       ["2016", 660,],
//       ["2017", 1030,],
//     ];
    
    // const options2 = {
    //   chart: {
    //     title: "Company Performance",
    //     subtitle: "Sales, Expenses, and Profit: 2014-2017",
    //   },
    // };
	
  useEffect(() => {
    fetch( serverUrl + "/cmsCount/countbanner",
    // {headers: {
    //   "auth-token":authToken
      
    // }}
    )
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setUsers(data); 
                  console.log(data,"testtttt"); 
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
            fetch( serverUrl + "/cmsCount/countAnnoucemnet",
            // {headers: {
            //   "auth-token":authToken
              
            // }}
            )
                  .then(res => res.json())
                  .then(
                      (data) => {
                          setIsLoaded(true);
                          setAnnouncement(data);  
                      },
                      (error) => {
                          setIsLoaded(true);
                          setError(error);
                      }
                  )
                  fetch( serverUrl + "/cmsCount/countEcosystem",
            // {headers: {
            //   "auth-token":authToken
              
            // }}
            )
                  .then(res => res.json())
                  .then(
                      (data) => {
                          setIsLoaded(true);
                          setEcosystem(data);  
                      },
                      (error) => {
                          setIsLoaded(true);
                          setError(error);
                      }
                  )

                  fetch( serverUrl + "/cmsCount/countOur",
            // {headers: {
            //   "auth-token":authToken
              
            // }}
            )
                  .then(res => res.json())
                  .then(
                      (data) => {
                          setIsLoaded(true);
                          setOurPartner(data);  
                      },
                      (error) => {
                          setIsLoaded(true);
                          setError(error);
                      }
                  )

                  fetch( serverUrl + "/cmsCount/keyInitatives",
            // {headers: {
            //   "auth-token":authToken
              
            // }}
            )
                  .then(res => res.json())
                  .then(
                      (data) => {
                          setIsLoaded(true);
                          setkeyInitatives(data);  
                      },
                      (error) => {
                          setIsLoaded(true);
                          setError(error);
                      }
                  )

                  fetch( serverUrl + "/cmsCount/executives",
            // {headers: {
            //   "auth-token":authToken
              
            // }}
            )
                  .then(res => res.json())
                  .then(
                      (data) => {
                          setIsLoaded(true);
                          setExecutive(data);  
                      },
                      (error) => {
                          setIsLoaded(true);
                          setError(error);
                      }
                  )
                
          
          // fetch( serverUrl + "/mentorDetail/allmentorCount",
          // {headers: {
          //   "auth-token":authToken
            
          // }}
          // )
          // .then(res => res.json())
          // .then(
          //     (data) => {
          //         setIsLoaded(true);
          //         setMentors(data);
          //     },
          //     (error) => {
          //         setIsLoaded(true);
          //         setError(error);
          //     }
          // )
          // fetch( serverUrl + "/nri/allnriCount",
          // {headers: {
          //   "auth-token":authToken
            
          // }})
          // .then(res => res.json())
          // .then(
          //     (data) => {
          //         setIsLoaded(true);
          //         setNri(data);
          //     },
          //     (error) => {
          //         setIsLoaded(true);
          //         setError(error);
          //     }
          // )
          // fetch(serverUrl +"/incubatorDetail/getAllCount",
          // {headers: {
          //   "auth-token":authToken
            
          // }})
          // .then(res => res.json())
          // .then(
          //     (data) => {
          //         setIsLoaded(true);
          //         setIncubators(data);
          //     },
          //     (error) => {
          //         setIsLoaded(true);
          //         setError(error);
          //     }
          // )
          // fetch(serverUrl +"/investor/allInvestorCount",
          // {headers: {
          //   "auth-token":authToken
            
          // }})
          // .then(res => res.json())
          // .then(
          //     (data) => {
          //         setIsLoaded(true);
          //         setInvestor(data);
          //     },
          //     (error) => {
          //         setIsLoaded(true);
          //         setError(error);
          //     }
          // )
          
    }, [])

  
  return (
    <>
    <Header/>
      <div className=" basicFormMain">
        <Box
        // sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
        >
          <div className="row">
            <div className="col-sm-3 col-lg-3">
              <div className="leftsideacc">
                <div className="user_info p-3">
                  <div className="row">
                    {/* <div className="col-sm-4 col-lg-4 d-flex align-items-center">
                      <div className="user_img">
                        <img
                          src={userPic}
                          className="img-fluid"
                          alt="user Picture"
                        />
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-lg-12 ">
                      <div className="user_text arrow-hide">
                        <div className="user_titile d-flex justify-content-center">
                        Super Admin
                          {/* Jayanth Rao */}
                          {/* <p className="user_service">Super Admin</p> */}
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>

                <Accordion className="px-3 pt-3">
                  {/* <Accordion.Item eventKey="0">
                    <Accordion.Header onClick={handleClick}> User Portal Management</Accordion.Header>
                    <Accordion.Body>
                    <div className="scrollcms">
                      <Tabs
                        className="w-100"
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        // sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="textColor side-menu"
                          label="Startups"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Incubators"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Mentors"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Investors"
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="NRI"
                          {...a11yProps(4)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Students Innovators"
                          {...a11yProps(5)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Accelerator Listing"
                          {...a11yProps(6)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Inactive User Verify"
                          {...a11yProps(7)}
                        />
                        
                         <Tab
                          className="textColor side-menu"
                          label="Active User Verify"
                          {...a11yProps(8)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="NewsLetter Users"
                          {...a11yProps(9)}
                        />
                      </Tabs>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header onClick={handleClick1}>CMS</Accordion.Header>
                    
                    <Accordion.Body>
                    <div className="scrollcms">
                      <Tabs
                        className="w-100"
                        orientation="vertical"
                        variant="scrollable"
                        value={value1}
                        onChange={handleChange1}
                        aria-label="Vertical tabs example1"
                        // sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="textColor side-menu"
                          label="Landing Banner"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Annoucements"
                          {...a11yProps(1)}
                        />
                        {/* <Tab
                          className="textColor side-menu"
                          label="Featured Events"
                          {...a11yProps(2)}
                        /> */}
                        <Tab
                          className="textColor side-menu"
                          label="Ecosystem"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Our Partners"
                          {...a11yProps(3)}
                        />
                         {/* <Tab
                          className="textColor side-menu"
                          label="Our Partners Hackthon"
                          {...a11yProps(4)}
                        /> */}
                        <Tab
                          className="textColor side-menu"
                          label="Key Initiatives"
                          {...a11yProps(4)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Leadership"
                          {...a11yProps(5)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Executive Team"
                          {...a11yProps(6)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Governing Council"
                          {...a11yProps(7)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="General Body"
                          {...a11yProps(8)}
                        /> 
                        {/* <Tab
                          className="textColor side-menu"
                          label="Ecosystem Card"
                          {...a11yProps(10)}
                        /> */}
                        <Tab
                          className="textColor side-menu"
                          label="Funding Programs"
                          {...a11yProps(9)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Startup Week Winner"
                          {...a11yProps(10)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Featured videos"
                          {...a11yProps(11)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Featured Resources"
                          {...a11yProps(12)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Contact Grievance"
                          {...a11yProps(13)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Contact Feedback"
                          {...a11yProps(14)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Contact Suggestion"
                          {...a11yProps(15)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Mentor"
                          {...a11yProps(16)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Incubator"
                          {...a11yProps(17)}
                        />
                        {/* <Tab
                          className="textColor side-menu"
                          label="WEC"
                          {...a11yProps(20)}
                        /> */}
                        <Tab
                          className="textColor side-menu"
                          label="Initiative Upcoming Event"
                          {...a11yProps(18)}
                        />
                       <Tab
                          className="textColor side-menu"
                          label="Initiative Previous Event"
                          {...a11yProps(19)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Grand Challenge Faq"
                          {...a11yProps(20)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Media Listing"
                          {...a11yProps(21)} 
                        />
                        {/* <Tab
                          className="textColor side-menu"
                          label="Event Schedule"
                          {...a11yProps(25)}
                        /> */}
                        {/* <Tab
                          className="textColor side-menu"
                          label="Grand Challenge Content"
                          {...a11yProps(26)}
                        /> */}
                        <Tab
                          className="textColor side-menu"
                          label="Testimonials"
                          {...a11yProps(22)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Startup Faq"
                          {...a11yProps(23)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Guideline"
                          {...a11yProps(24)}
                        />
                         <Tab
                          className="textColor side-menu"
                          label="intellectual Property"
                          {...a11yProps(25)}
                        />
                         <Tab
                          className="textColor side-menu"
                          label="Quality Testing"
                          {...a11yProps(26)}
                        />

                         <Tab
                          className="textColor side-menu"
                          label="Yatra Winners"
                          {...a11yProps(27)}
                        />

                       <Tab
                          className="textColor side-menu"
                          label="MH Hackthon FAQ"
                          {...a11yProps(28)}
                        />
                        {/* <Tab
                          className="textColor side-menu"
                          label="Event Shedule"
                          {...a11yProps(29)}
                        /> */}
                      </Tabs>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="2">
                    <Accordion.Header onClick={handleClick2}>Masters</Accordion.Header>
                    
                    <Accordion.Body>
                    <div className="scrollcms">
                      <Tabs
                        className="w-100"
                        orientation="vertical"
                        variant="scrollable"
                        value={value2}
                        onChange={handleChange2}
                        aria-label="Vertical tabs example1"
                        // sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="textColor side-menu"
                          label="Countries"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="state"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="District"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Cities"
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Divisions"
                          {...a11yProps(4)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Departments"
                          {...a11yProps(5)}
                        />
                         <Tab
                          className="textColor side-menu"
                          label="Sectors"
                          {...a11yProps(6)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Stages"
                          {...a11yProps(7)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Business Model Type"
                          {...a11yProps(8)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="User Type"
                          {...a11yProps(9)}
                        /> 
                        <Tab
                          className="textColor side-menu"
                          label="Quality Testing Sectors"
                          {...a11yProps(10)}
                        />
                      </Tabs>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="3" className='tab-hide'>
                    <Accordion.Header onClick={handleClick3}>Admin User Management</Accordion.Header>
                    
                    <Accordion.Body>
                   
                      <Tabs
                        className="w-100"
                        value={value3}
                        onChange={handleChange3}
                      >
                      </Tabs>
                     
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="4">
                    <Accordion.Header onClick={handleClick4}>Financial Assistance</Accordion.Header>
                    <Accordion.Body>
                    <Accordion className="px-0 pt-0">
                    
                      </Accordion>
                      <Accordion className="px-0 pt-0">
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>IPR</Accordion.Header>
                          <Accordion.Body>

                            <div className="scrollcms">
                              <Tabs
                                className="w-100"
                                orientation="vertical"
                                variant="scrollable"
                                value={value4} 
                                onChange={handleChange4}
                                aria-label="Vertical tabs example"
                             
                              >
                                <Tab
                                  className="textColor side-menu"
                                  label="Domestic Patent"
                                  {...a11yProps(0)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="International Patent"
                                  {...a11yProps(1)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="Copyright"
                                  {...a11yProps(2)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="Trademark"
                                  {...a11yProps(3)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="Design"
                                  {...a11yProps(4)}
                                />
                              </Tabs>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion className="px-0 pt-0">
                        <Accordion.Item eventKey="6">
                          <Accordion.Header onClick={handleClick5}>IPR Reimbursement</Accordion.Header>
                          <Accordion.Body>
                            <div >
                              <Tabs
                                className="w-100"
                                orientation="vertical"
                                variant="scrollable"
                                value={value5}
                                onChange={handleChange5}
                                aria-label="Vertical tabs example">
                                <Tab
                                  className="textColor side-menu"
                                  label="Post filling"
                                  {...a11yProps(0)}
                                />
                                <Tab
                                  className="textColor side-menu"
                                  label="Post Grant"
                                  {...a11yProps(1)}
                                />
                                
                              </Tabs>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="7" >
                    <Accordion.Header onClick={handleClick7}>Allocate Applications</Accordion.Header>
                    <Accordion.Body>
                    <div className="scrollcms">
                      <Tabs
                        className="w-100"
                        orientation="vertical"
                        variant="scrollable"
                        value={value7}
                        onChange={handleChange7}
                        aria-label="Vertical tabs example1"
                        // sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="textColor side-menu"
                          label="Domestic Patent"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="International Patent"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Copyright"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Trademark"
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Design"
                          {...a11yProps(4)}
                        />
                         <Tab
                          className="textColor side-menu"
                          label="Post Filling"
                          {...a11yProps(5)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Post Grant"
                          {...a11yProps(6)}
                        />
                      </Tabs>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="8">
                    <Accordion.Header onClick={handleClick8}>Business Plan Comepetition</Accordion.Header>
                    <Accordion.Body>
                    <div className="scrollcms">
                      <Tabs
                        className="w-100"
                        orientation="vertical"
                        variant="scrollable"
                        value={value8}
                        onChange={handleChange8}
                        aria-label="Vertical tabs example1"
                        // sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="textColor side-menu"
                          label="BPC Districts"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="District Wise Admin"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="District Sectors 2019"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Default Sectors 2019"
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="textColor side-menu"
                          label="Student Applicant List"
                          {...a11yProps(4)}
                        />
                         
                      </Tabs>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="9" className='tab-hide'>
                    <Accordion.Header onClick={handleClick9}>User Logs</Accordion.Header>
                    <Accordion.Body>
                   =
                      <Tabs
                        className="w-100"
                        value={value9}
                        onChange={handleChange9}
                      >
                      </Tabs>
                  =
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="10" className='tab-hide'>
                    <Accordion.Header onClick={handleClick10}>User Logs</Accordion.Header>
                    <Accordion.Body>
                 
                      <Tabs
                        className="w-100"
                        value={value10}
                        onChange={handleChange10}
                      >
                      </Tabs>
                      
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="11" className='tab-hide'>
                    <Accordion.Header onClick={handleClick11}>User Logs</Accordion.Header>
                    <Accordion.Body>
                    
                      <Tabs
                        className="w-100"
                        value={value11}
                        onChange={handleChange11}
                      >
                      </Tabs>
                     
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="12" className='tab-hide'>
                    <Accordion.Header onClick={handleClick12}>User Logs</Accordion.Header>
                    <Accordion.Body>
                  
                      <Tabs
                        className="w-100"
                        value={value12}
                        onChange={handleChange12}
                      >
                      </Tabs>
                     
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="13" className='tab-hide'>
                    <Accordion.Header onClick={handleClick13}>User Logs</Accordion.Header>
                    <Accordion.Body>
                   
                      <Tabs
                        className="w-100"
                        value={value13}
                        onChange={handleChange13}
                      >
                      </Tabs>
                      
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="14" className='tab-hide'>
                    <Accordion.Header onClick={handleClick14}>User Logs</Accordion.Header>
                    <Accordion.Body>
                    
                      <Tabs
                        className="w-100"
                        value={value14}
                        onChange={handleChange14}
                      >
                      </Tabs>
                     
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="15" className='tab-hide'>
                    <Accordion.Header onClick={handleClick15}>User Logs</Accordion.Header>
                    <Accordion.Body>
                    
                      <Tabs
                        className="w-100"
                        value={value15}
                        onChange={handleChange15}
                      >
                      </Tabs>
                     
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="scrollTab">
                {!isShown && !isShown1 && !isShown2 && !isShown3 && !isShown4 && !isShown5 && !isShown7 && !isShown8 && !isShown9 && !isShown10 && !isShown11 && !isShown12 && !isShown13 && !isShown14 && !isShown15 &&( 
                  <>
                  {/* ---------------- */}
                  <div class="row dashboardCards">
                  {/* <div className='row pt-4'>
                      <div class="col-md-6 col-sm-6 px-3 item">
                      <Chart
                          chartType="PieChart"
                          data={data1}
                          options={options}
                          width={"100%"}
                          height={"400px"}
                        />
                      </div>
                      <div class="col-md-6 col-sm-6 px-3 item">
                      <Chart
                          chartType="PieChart"
                          data={data1}
                          options={options}
                          width={"100%"}
                          height={"400px"}
                        />
                      </div>
                      
                    </div> */}
                    {/* <div className='row pt-4'>
                      <div class="col-md-12 col-sm-12 px-3 item">
                      <Chart
                            chartType="Bar"
                            width="100%"
                            height="400px"
                            data={data2}
                            options={options2}
                          />
                      </div>
                      
                      
                    </div> */}
                    <div className='row pt-4'>
                      <div class="col-md-4 col-sm-6 px-3 item">
                        <div class="card item-card card-block">
                        <h5 class="card-title  mt-3 mb-3 ml-4">Landing Banner</h5>
                        <CardActions>
                          <Button size="small">{users.count}</Button>
                        </CardActions>
                    </div>
                      </div>
                      <div class="col-md-4 col-sm-6 px-3 item">
                        <div class="card item-card card-block">
                      
                          <h5 class="card-title  mt-3 mb-3 pl-3">Announcement</h5>
                          <CardActions>
                            <Button size="small">{announcement.count}</Button> 
                          </CardActions>
                    </div>
                      </div>
                      <div class="col-md-4 col-sm-6 px-3 item">
                        <div class="card item-card card-block">
                        
                          <h5 class="card-title  mt-3 mb-3">Ecosystem</h5>
                          <CardActions>
                            <Button size="small">{ecosystem.count}</Button>
                          </CardActions>
                    </div>
                      </div>
                      </div>
                      <div className='row pt-4'>
                      <div class="col-md-4 col-sm-6 px-3 item">
                        <div class="card item-card card-block">
                        
                          <h5 class="card-title  mt-3 mb-3">Our Partner</h5>
                          <CardActions>
                          <Button size="small">{ourpartner.count}</Button>
                          </CardActions>
                    </div>
                      </div>
                      <div class="col-md-4 col-sm-6 px-3 item">
                        <div class="card item-card card-block">
                        
                          <h5 class="card-title  mt-3 mb-3">Key Initiative</h5>
                          <CardActions>
                            <Button size="small">{keyInitatives.count}</Button>
                          </CardActions>
                    </div>
                      </div>
                      <div class="col-md-4 col-sm-6 px-3 item">
                        <div class="card item-card card-block">
                        
                          <h5 class="card-title  mt-3 mb-3"> Executive Team</h5>
                          <CardActions>
                            <Button size="small">{executive.count}</Button>
                          </CardActions>
                    </div>
                      </div>
                    </div>
                    
                  </div>
                {/* -------------- */}
                  
                  </>

                )
                }
                {
                  isShown && (
                <>
                <TabPanel value={value} index={0}>
                  <ViewStartup />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ViewIncubator/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ViewMentor/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <ViewInvestor/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <ViewNri/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <Viewstudentinnovation/>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <ViewAccelerator/>
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <ViewUserVerify/>
                </TabPanel>
                <TabPanel value={value} index={8}>
                  <ViewUserActiveVerify/>
                </TabPanel>
                <TabPanel value={value} index={9}>
                  <ViewNewsLetter/>
                </TabPanel>
                

                </>
                )}
                {
                  isShown1 &&(
                    <>
                <TabPanel value={value1} index={0}>
                  <ViewLandingBanner/>
                </TabPanel>
                <TabPanel value={value1} index={1}>
                  <ViewAnnounce/>
                </TabPanel>
                {/* <TabPanel value={value1} index={2}>
                  <ViewfeatureEvent/>
                </TabPanel> */}
                <TabPanel value={value1} index={2}>
                  <ViewEcosystem/>
                </TabPanel>
                <TabPanel value={value1} index={3}>
                <ViewOurPartner/>
                </TabPanel>
                {/* <TabPanel value={value1} index={4}>
                <ViewOurPartnerHackthon/>
                </TabPanel> */}
                <TabPanel value={value1} index={4}>
                <ViewKeyInitiative/>
                </TabPanel>
                <TabPanel value={value1} index={5}>
                <ViewOurLeadership/>
                </TabPanel>
                <TabPanel value={value1} index={6}>
                  <ViewExecutiveTeam/>
                </TabPanel>
                <TabPanel value={value1} index={7}>
                  <ViewGoverningCouncil/>
                </TabPanel>
                <TabPanel value={value1} index={8}>
                  <ViewGeneralBody/>
                </TabPanel>
                {/* <TabPanel value={value1} index={10}>
                  <ViewEcosystemCard/>
                </TabPanel> */}
                <TabPanel value={value1} index={9}>
                  <ViewFundingSources/>
                </TabPanel>
                <TabPanel value={value1} index={10}>
                  <ViewstartupWeekWinner/>
                </TabPanel>
                <TabPanel value={value1} index={11}>
                  <ViewFeaturedVideos/>
                </TabPanel>
                <TabPanel value={value1} index={12}>
                  <ViewFeaturedResources/>
                </TabPanel>
                <TabPanel value={value1} index={13}>
                  <ViewContactQuery/>
                </TabPanel>
                <TabPanel value={value1} index={14}>
                  <ViewContactFeedback/>
                </TabPanel>
                <TabPanel value={value1} index={15}>
                  <ViewContactSuggestions/>
                </TabPanel>
                <TabPanel value={value1} index={16}>
                  <ViewMeetMentor/>
                </TabPanel>
                <TabPanel value={value1} index={17}>
                  <ViewAllIncubator/>
                </TabPanel>
                {/* <TabPanel value={value1} index={20}>
                  <ViewWec/>
                </TabPanel> */}
                <TabPanel value={value1} index={18}>
                  <ViewUpcomingEvents/>
                </TabPanel>
                <TabPanel value={value1} index={19}>
                  <ViewPreviousEvents/>
                </TabPanel>
                <TabPanel value={value1} index={20}>
                  <ViewGrandChallengeFAQ/>
                </TabPanel>
                <TabPanel value={value1} index={21}>
                  <ViewMedia/>
                </TabPanel>
                {/* 
                <TabPanel value={value1} index={26}>
                  <ViewGrandChallengeContent/>
                </TabPanel> */}
                <TabPanel value={value1} index={22}>
                  <ViewTestimonials/>
                </TabPanel>
                <TabPanel value={value1} index={23}>
                  <ViewStartupFAQs/>
                </TabPanel>
                <TabPanel value={value1} index={24}>
                  <ViewGuidline/>
                </TabPanel>
                
                <TabPanel value={value1} index={25}>
                  <ViewIntellectualProperty/>
                </TabPanel>
                <TabPanel value={value1} index={26}>
                  <ViewQualityTesting/>
                </TabPanel>
                <TabPanel value={value1} index={27}>
                  <ViewYatraWinners/>
                </TabPanel>
                <TabPanel value={value1} index={28}>
                  <ViewMaharashtraHackthonFAQ/>
                </TabPanel>
               
                {/* <TabPanel value={value1} index={29}>
                  <ViewEventSchedule/>
                </TabPanel> */}
                </>
                )}
                {
                  isShown2 &&(
                    <>
                <TabPanel value={value2} index={0}>
                  <ViewCountry/>
                </TabPanel>
                <TabPanel value={value2} index={1}>
                  <ViewState/>
                </TabPanel>
                <TabPanel value={value2} index={2}>
                  <ViewDistrict/>
                </TabPanel>
                <TabPanel value={value2} index={3}>
                  <ViewEcosystem/>
                </TabPanel>
                <TabPanel value={value2} index={4}>
                <ViewDivision/>
                </TabPanel>
                <TabPanel value={value2} index={5}>
                <ViewDepartment/>
                </TabPanel>
                <TabPanel value={value2} index={6}>
                <ViewSector/>
                </TabPanel>
                <TabPanel value={value2} index={7}>
                  <Viewstages/>
                </TabPanel>
                <TabPanel value={value2} index={8}>
                  <ViewBusinessModel/>
                </TabPanel>
                <TabPanel value={value2} index={9}>
                  <ViewUserType/>
                </TabPanel>
                <TabPanel value={value2} index={10}>
                  <ViewQualitytestingsector/>
                </TabPanel>
                </>
                )}
                {
                  isShown3 &&(
                    <>
                <TabPanel value={value3} index={0}>
                  <ViewAdminuser/>
                </TabPanel>
                </>
                )}
                 {
                  isShown4 &&(
                    <>
                <TabPanel value={value4} index={0}>
                  <ViewDomestic/>
                </TabPanel>
                <TabPanel value={value4} index={1}>
                  <ViewInternational/>
                </TabPanel>
                <TabPanel value={value4} index={2}>
                  <ViewCopyright/>
                </TabPanel>
                <TabPanel value={value4} index={3}>
                  <ViewTrademark/>
                </TabPanel>
                <TabPanel value={value4} index={4}>
                <ViewDesign/>
                </TabPanel>
                </>
                )}
                {
                  isShown5 &&(
                    <>
                <TabPanel value={value5} index={0}>
                  <ViewFilling/>
                </TabPanel>
                <TabPanel value={value5} index={1}>
                  <ViewGrant/>
                </TabPanel>
                
                </>
                )}
                 {
                  isShown7 &&(
                    <>
                <TabPanel value={value7} index={0}>
                  <ViewAllocateDomestic/>
                </TabPanel>
                <TabPanel value={value7} index={1}>
                  <ViewAllocateInternational/>
                </TabPanel>
                <TabPanel value={value7} index={2}>
                  <ViewAllocateCopyright/>
                </TabPanel>
                <TabPanel value={value7} index={3}>
                  <ViewAllocateTrademark/>
                </TabPanel>
                <TabPanel value={value7} index={4}>
                <ViewAllocateDesign/>
                </TabPanel>
                <TabPanel value={value7} index={5}>
                  <ViewAllocateFilling/>
                </TabPanel>
                <TabPanel value={value7} index={6}>
                <ViewAllocateGrant/>
                </TabPanel>
                </>
                )}
                {
                  isShown8 &&(
                    <>
                <TabPanel value={value8} index={0}>
                  <ViewBPC/>
                </TabPanel>
                <TabPanel value={value8} index={1}>
                  <ViewDistrictAdmin/>
                </TabPanel>
                <TabPanel value={value8} index={2}>
                  <ViewDistrictSectors/>
                </TabPanel>
                <TabPanel value={value8} index={3}>
                  <ViewDefaultSectors/>
                </TabPanel>
                <TabPanel value={value8} index={4}>
                <ViewStudentList/>
                </TabPanel>
                </>
                )}
                {
                  isShown9 &&(
                    <>
                <TabPanel value={value9} index={0}>
                  {/* <ViewAdminuser/> */}Tab 0
                </TabPanel>
                </>
                )}
                {
                  isShown10 &&(
                    <>
                <TabPanel value={value9} index={0}>
                  {/* <ViewAdminuser/> */}Tab 0
                </TabPanel>
                </>
                )}
                {
                  isShown11 &&(
                    <>
                <TabPanel value={value9} index={0}>
                  {/* <ViewAdminuser/> */}Tab 0
                </TabPanel>
                </>
                )}
                {
                  isShown12 &&(
                    <>
                <TabPanel value={value9} index={0}>
                  {/* <ViewAdminuser/> */}Tab 0
                </TabPanel>
                </>
                )}
                {
                  isShown13 &&(
                    <>
                <TabPanel value={value9} index={0}>
                  {/* <ViewAdminuser/> */}Tab 0
                </TabPanel>
                </>
                )}
                {
                  isShown14 &&(
                    <>
                <TabPanel value={value9} index={0}>
                  {/* <ViewAdminuser/> */}Tab 0
                </TabPanel>
                </>
                )}
                {
                  isShown15 &&(
                    <>
                <TabPanel value={value9} index={0}>
                  {/* <ViewAdminuser/> */}Tab 0
                </TabPanel>
                </>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}
