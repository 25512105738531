import React from 'react';
import Header from '../../component/Header';
import Footer from '../../component/footer';
import IdbicapitalBanner from './IdbicapitalBanner'
import MHApplyNow from './MHApplyNow'

import FundInvestment from './FundInvestment'

function IdbicapitalMain() {
  return (
    <>
    <Header/>
    <IdbicapitalBanner />
    <MHApplyNow />
    <FundInvestment />
    <Footer />
  </>
  )
}

export default IdbicapitalMain
