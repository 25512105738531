import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const UserTypeUpdateM = (props) => {
  
// console.log(props)
// const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitCountryUpdateForm(values){
    
    const formData = new FormData();
   
    formData.append("type_name",values.type_name)
    formData.append("type",values.type)
   
    fetch(serverUrl +`/usertype/${props.rowData.userTypeDetails._id}`,
         {
          method: 'PUT',
          body: formData,
            
          })
          .then(res=>res.json())
        //   .then(res=>{setUpdatedImage(null)})
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("Are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitCountryUpdateForm(values)
    }
    validationSchema={Yup.object({
        type_name: Yup.string().required('Enter User Type Name is Required'),
        type: Yup.string().required('Enter Type is Required'),
       })}
      initialValues={{ 
        type_name: props.rowData.userTypeDetails.type_name,
        type: props.rowData.userTypeDetails.type,
       }}

      

      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    User Type Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"                    
                      onChange={handleChange}
                      placeholder="Enter User Type Name" className="textInput" name="type_name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="type_name" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    User Type{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Type" className="textInput" name="type" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="type" /><br></br>
                    </div>                   
                  </div>
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default UserTypeUpdateM;
