import React, { useEffect, useState } from "react";
import { environment } from "../../api";
import '@mui/x-data-grid-generator'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BallotIcon from '@mui/icons-material/Ballot'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import DivisionViewM from './DivisionViewM'
// import DivisionUpdateM from './DivisionUpdateM'
import DivisionAddM from './DivisionAddM'

function ViewDivision() {

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const setModalIsOpenToTrue = () => {
      setModalIsOpen(true)
    }
  
    const setModalIsOpenToFalse = () => {
      setModalIsOpen(false)
    }
    const [modalIsOpen2, setModalIsOpen2] = useState(false)

    const setModalIsOpenToTrue2 = () => {
      setModalIsOpen2(true)
    }
  
    const setModalIsOpenToFalse2 = () => {
      setModalIsOpen2(false)
    }
    
    const [modalIsOpen3, setModalIsOpen3] = useState(false)
  
    const setModalIsOpenToTrue3 = () => {
      setModalIsOpen3(true)
    }
  
    const setModalIsOpenToFalse3 = () => {
      setModalIsOpen3(false)
    }
    const serverUrl = environment.baseUrl;
    const [rows, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [rowId, setrowId] = useState("");                  
    const [rowData, setRowData] = useState({
      division: [],
    });
    useEffect(() => {
      fetch(serverUrl + "/divisions/allDivisions")
        .then((res) => res.json())
        .then(
          (data) => {
            setIsLoaded(true);
            setUsers(data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }, []);
  
    useEffect(() => {
      rows.map((el) => {
        if (el._id === rowId) setRowData({ ...rowData, division: el });
      });
    }, [rowId]);
  
    const columns = [
      { field: '_id', headerName: 'Sr.No.', width: 400 },
      { field: 'name', headerName: 'Name', width: 450 },
      
      {
        field: 'Action',
        headerName: 'Action',
        sortable: false,
        width: 200,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation() // don't select this row after clicking
  
            const api = params.api
            const thisRow = {}
  
            api
              .getAllColumns()
              .filter((c) => c.field !== '__check__' && !!c)
              .forEach(
                (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
              )
  
            return alert(JSON.stringify(thisRow, null, 4))
          }
          return (
            <>
            
              <Button onClick={setModalIsOpenToTrue}>
                <VisibilityIcon />
              </Button>
              {/* <Button onClick={setModalIsOpenToTrue3}>
                <AddIcon />
              </Button> */}
              <Button onClick={setModalIsOpenToTrue2}>
                <BallotIcon />
              </Button>
            </>
          )
        },
      },
    ]
  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
    Division Details
                 <Button className="addbtn" onClick={setModalIsOpenToTrue3}>Add</Button>
          </h2>
          <div style={{ height: '75vh', width: '100%' }}>
           
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
              pageSize={8}
              components={{ Toolbar: GridToolbar }}
              getRowId ={(rows) => rows._id}
              onSelectionModelChange={(newSelection)=>{
                setrowId(newSelection[0])
               }}
            />
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Division Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <DivisionViewM rowData = {rowData}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen3} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Update Division Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse3}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <DivisionAddM rowData = {rowData}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen3} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Add Division</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse3}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <DivisionAddM/>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ViewDivision