import React, { useEffect,useState } from 'react'
import "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
// import MentorViewModel from "./mentorViewMod";
// import MentorAddModel from "./mentorAddMod";
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import {useParams} from "react-router-dom"
// import MentorApprovalModel from './mentorApprovalModel'
import { environment } from '../api';
function ViewMentorDetails({appId}) {
  console.log(appId, "application");

  const serverUrl = environment.baseUrl;

  const authToken =sessionStorage.getItem("token");
  // const { appId } = useParams(); // <-- access id match param here

  console.log(sessionStorage.getItem("_id"),"id")
  const ap= sessionStorage.getItem("mentor_applicationId")
  // const { id } = useParams(); // <-- access id match param here
       const [users, setUsers] = useState([]);
       const [error, setError] = useState(null);
       const [isLoaded, setIsLoaded] = useState(false);
       useEffect(() => {
         fetch(serverUrl +`/mentorDetail/${appId}`,
         
         {headers: {
          // "Content-Type": "application/json",
          // accept: "application/json",
          "auth-token":authToken
          
        }})
           .then(res => res.json())
           .then(
             data => {
               setIsLoaded(true);
               setUsers(data);
             },
             error => {
               setIsLoaded(true);
               setError(error);
             }
           );
       }, []);
  

  return (
    <>
       <div className="bannermain">
    <div className="container">
      <div className="row ">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
             Basic Details
            </h2>
          </div>
          
          <form method="POST" enctype="multipart/form-data">
          {users?
          <>
            <div className="row">
              <div className="col-md-2 pt-4">
                
                <label className="pb-2 label-txt">
                First Name
                  <span className="text-danger">*</span>
                 
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.mentor_name}</span>
                   </div>
                   
                   
              {/* <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Last Name
                  <span className="text-danger">*</span>
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.last_name}</span>
                   </div> */}
                   </div>

                   <div className="row">
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Email-address
                  <span className="text-danger">*</span>
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.email}</span>
                   </div>
                   
                   
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Phone Number
                  <span className="text-danger">*</span>
                 
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.phone_number}</span>
                   </div>
                   </div>
                   <div className="row">
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
               User Type
                  <span className="text-danger">*</span>
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.company_name}</span>
                   </div>
                   
                   <span>Job Title{users.job_title}</span>
              {/* <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Phone Number
                  <span className="text-danger">*</span>
                  <InformationIcon />
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>918899776789</span>
                   </div> */}
                   </div>
                   </>
              : "loading"
            }

              
              
             
              <div>
                {/* <StartupAddmore /> */}
              </div>
              {/* <div className="justify-content-center d-flex py-4">
                  <Stack direction="row" spacing={2}>
                    
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-draft no-shadow" 
                    >
                      Save as Draft
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-next no-shadow" onClick={()=>{props.setValue(props.value+1)}}
                    >
                      Save &amp; Next
                    </Button>
                  </Stack>
                </div> */}
           
            {/*  </form> */}
            {/* <div className=""> */}
            
          </form>
           {/* ))}  */}
        </div>
      </div>
    </div>
  </div>

    </>
  );
}

export default ViewMentorDetails;
