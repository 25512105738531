import React from 'react';
import Resourcesbanner from '../assets/images/Resourcesbanner/MSINS_in_Media.png';
import { Button } from 'react-bootstrap-v5';
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

export default function BannerNewsPress() {
  return (
      
    <div>
    <div className='newsPressBanner'>
    <div className='col-ld-12 col-md-12 col-sm-12 newsPressBanner-display'>
    <div className='offset-lg-1 offset-md-1 col-lg-4 col-md-10 col-sm-12 nodal-top'>
   
      <h2  className="font-28-36 font-20-26  font-weight-600 justify-content-center resourcesBanner-heading" >News and Press</h2>
     <p className='font-style-normal font-weight-400 font-24-29 font-14-17 justify'>
       View all media related MSInS latest news and press features
     </p>

     <div className='pinkbtn pt-3 d-none d-lg-block'>
          <Button className="btn-primary regular_font font-style-normal font-weight-normal font-20-24 ">
          <Link to= "/media">
              <span className='regular_font font-20-24 font-style-normal font-weight-400'>View Media</span>                                   
              <span className='inno-arrow'>
              <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="20px"
                  width="30px"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
              </svg>
              </span>
          </Link>
          </Button>
      </div>
     {/* <div className='pinkbtn d-none d-sm-none'>
          <Button className="regular_font font-style-normal font-weight-normal font-20-24">
          <Link to= "/media">
          View Media 
              <span className='inno-arrow'>
              <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="20px"
                  width="30px"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
              </svg>
              </span>
          </Link>
                                    </Button>
       </div> */}
      
     
 </div>
 <div className='offset-lg-1 offset-md-1 col-md-10 col-sm-7 col-lg-7 d-flex justify-content-center'>
     <img 
     src={Resourcesbanner}
     className='nodalwhatabout-img' alt="Resourcesbanner-img" />
 </div>
 

    </div>

    
   </div>

   <div className='mob-brownbtn align-items-center d-lg-none'>
            <Link to= "/media">
              <div className='d-flex justify-content-center align-items-center py-2'>
              View Media
                <div className='inno-arrow'>
                <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 16 16"
                              height="20px"
                              width="30px"
                              xmlns="http://www.w3.org/2000/svg"
                              >
                              <path
                                  fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                              />
                          </svg>
                </div>
                </div>  
            </Link>
        </div>
</div>
  );
}
