import React from 'react'
import { Link } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/footer';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import carousel1 from '../../';
import carousel1 from '../assets/images/Initiative/843328Fellows Advertisement_001.jpg';
import carousel2 from '../assets/images/Initiative/363755Fellows Advertisement_002.jpg';
import carousel3 from '../assets/images/Initiative/148679Fellows Advertisement_003.jpg';
import carousel4 from '../assets/images/Initiative/381314Fellows Advertisement_004.jpg';
import carousel5 from '../assets/images/Initiative/828222Fellows Advertisement_005.jpg';
function ProfessionalFellows() {
  return (
    <>
    <Header/>
    <div className="mahaGrand-main intellectual-main">
    <div>
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        interval={5000}
      >
        <div>
          
          <img src={carousel1}/>
          {/* <p className="legend">Legend 1</p> */}
        </div>
        <div>
          <img src={carousel2} />
          {/* <p className="legend">Legend 2</p> */}
        </div>
        <div>
          <img src={carousel3}/>
          {/* <p className="legend">Legend 3</p> */}
        </div>
        <div>
          <img src={carousel4}/>
          {/* <p className="legend">Legend 3</p> */}
        </div>
        <div>
          <img src={carousel5}/>
          {/* <p className="legend">Legend 3</p> */}
        </div>
      </Carousel>
      
    </div>
      <div className="container">
        <div className="row d-flex justify-content-center my-3">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row" id="granchallenge">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
              Invitation for Young Professional Fellows
              </h2>
              <h5>INVITATION FOR YOUNG PROFESSIONAL FELLOWS</h5>

              <h5>Background</h5>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
              To create a holistic skilling and entrepreneurship ecosystem in the state, the Skills, Employment, Entrepreneurship, and Innovation Department run various initiatives and policies through its various field offices namely – Secretariat of Skills, Employment, Entrepreneurship and Innovation (https://www.mahaswayam.gov.in), Commissionerate of Skills, Employment, Entrepreneurship and Innovation (https://www.rojgar.mahaswayam.gov.in), Maharashtra State Skill Development Society (MSSDS) (https://kaushalya.mahaswayam.gov.in), Maharashtra State Innovation Society (MSInS) (https://msins.in), Directorate of Vocational Education and Training (DVET) (https://www.dvet.gov.in/mr/), Maharashtra State Board of Vocational Education Examination (MSBVE) (http://www.msbve.gov.in/) and Maharashtra State Skills University (MSSU) (http://mssu.ac.in). 
                
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                The Department provides a unique opportunity for young professionals interested in development sector to gain first-hand experience of working in Government Departments and their field offices and directly contribute towards making change at scale. Therefore, we are hiring Young Professional Fellows with a passion for improving government performance and resolving social problems. 
                
                  
             </p>
             <h5>Roles and Responsibilities:</h5>
            <p>The responsibilities would vary from project to project and would majorly frequently include:</p>  
             <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
             Assist in making policies and programs
Identifying bottlenecks in government schemes and suggest reforms through research on best practices. 
Providing innovative and feasible yet high impact solutions to identified challenges.
Assisting department to brainstorm and identify solutions to produce meaningful change.
Identifying expert agencies and institutions for building knowledge partnerships and technical collaboration. 
Providing information technology, social media marketing, project management, data analytics and legal support for the effective functioning of the Department and its field offices. 
</p>

<h5>Preferred Skills:</h5>
<ol>
                    <li>Improving government performance and solving problems related to skilling, employment, livelihood generation, entrepreneurship, and innovation.</li>
                    <li>Self-motivated with an orientation towards results. </li>
                    <li>Excellent written and verbal communications.</li>
                    <li>Strong project management capabilities.</li>
                    <li>Sound quantitative and analytical skills, with experience generating and using data to drive insights and communicating those insights to others.</li>
                    <li> Ability to navigate complex organizations, develop trust with senior leaders, and build relationships among diverse groups of stakeholders.</li>
                    <li>Awareness and sensitivity to the needs and concerns of individuals from diverse cultures, backgrounds, and orientations, and ability to apply that to their work.</li>
                    <li>Willingness to travel is required.</li>
                    <li>Proficiency in Marathi will be added advantage.</li>
                  </ol>
                  <h5>Preferred Qualification:</h5>
                <ol>
                  <li>A Graduate or Masters (pursuing or completed) in fields like Economics/Public Policy/Management/ Engineering/Mathematics/Computer Science/ Information Technology/ Data Management/ Sales / HR / Media - Journalism / Communication / Literature & allied disciplines along with strong IT and presentation skills. </li>
                  <li> Experience in skilling, livelihoods, entrepreneurship, employment, start-ups, incubators, digital marketing, field work, human resources & admin, media, communications or public relations, and proficiency in documentation would be an added advantage.</li>
                  <li>Excellent verbal, written, interpersonal skills, good time management & organizational skills. </li>
                  <li>The candidates must be proficient in Microsoft office, data analytics, data visualization software, etc.</li>
                  <li>For media & communication fellows, digital marketing expertise, content management systems, social media platforms, graphic designing (Adobe Photoshop, Adobe Illustrator, Adobe InDesign, Illustrator, Corel Draw, Crello, Canva, etc.) is desirable which will help in dissemination of initiatives and through print & digital media.</li>
                  </ol>
                  <h5>Deployment of Young Professional Fellows: </h5>
<p className="font-style-normal font-weight-400 font-18-22 font-14-17">
The shortlisted candidates would be operating from Mumbai under the guidance of Principal Secretary, Department of Skills, Employment, Entrepreneurship and Innovation and Commissioner, Skills, Employment, Entrepreneurship, and Innovation. Office is located at 2nd Floor, 2nd Floor Mantralaya, Madam Cama Road, Hutatma Rajguru Chowk, Mumbai 400032.
However, the candidates may be deployed at the Field offices in Mumbai region as per need.
</p>
<h5> | Sr No | Name of offices | #</h5>
<ol>
    <li>Secretariat of Skills, Employment, Entrepreneurship, and Innovation | 2</li>
    <li>Commissionerate of Skills, Employment, Entrepreneurship, and Innovation | 2</li>
    <li>Maharashtra State Skill Development Society  | 2</li>
    <li>Maharashtra State Innovation Society  | 3</li>
    <li>Directorate of Vocational Education and Training | 2</li>
    <li> Maharashtra State Board of Vocational Education Examination | 2</li>
    <li>Maharashtra State Skills University Academics – 1 Technology – 1 HR – 2 Sales – 6 | 10</li>
    <li>Media & Communications Fellow  | 2</li>
    <li><b>Total | 25</b></li>
</ol>
<h5>Note</h5>
<p className="font-style-normal font-weight-400 font-18-22 font-14-17">
Upper age limit to apply as Young Professional Fellows is 35 years as on 08th September 2022.
Candidates should provide two Letter of recommendations from previous employers and / or alma mater with their contact details.
Duration of the Fellowship shall be for a period of 2 years from the date of joining, subject to performance review, the candidate may get an extension for another year.
</p>
  <h5>Remuneration:</h5> 

 <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
The salary bracket offered YP Fellows shall be in the range of Rs 40,000 to 50,000/- depending on qualification and experience of the candidate.
Travel cost for official work outside Mumbai will be borne by the office. 
Certificates will be given to Young Professional Fellows on satisfactory completion of fellowship period of 2 years.
Interested candidates are invited to submit the duly filled Application Form as given in the Annexure 1, a Statement of Purpose* along with detailed CV and 2 letter of recommendations at Email ID: application.seeid@gmail.com in a zip file.
Last date of application is Friday, 23rd October 2022.
Shortlisted candidates shall be contacted for interview.
All rights with respect to any change in the advertisement content lies with the Skills, Employment, Entrepreneurship Department, Government of Maharashtra.
</p>
<p className="font-style-normal font-weight-400 font-18-22 font-14-17">
Statement of purpose can be written in English or Marathi. No queries or correspondence regarding issue of call letters for interview/selection of candidates for the post will be entertained at any stage and canvassing will lead to the candidate being debarred from consideration of the post. Statement of purpose should include following questions:
</p>
<ol>
    <li>Reason for applying for this program? (100 words)</li>
    <li>Areas in which you would like to contribute? (250 words)</li>
    <li> What are your two core strengths? (150 words) </li>
    </ol> 
</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  )
}

export default ProfessionalFellows