import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from '../startup/informationIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";



const serverUrl = environment.baseUrl;

export default function LegalInformationForm(props) {
    // console.log(JSON.parse(localStorage.getItem('ipDetails')))

    let initialFormObj = {
        do_you_have_IP: ''

    }


    function handleSubmitFinacialForm(values) {
        console.log(values, "hii")
        const formData = new FormData();
        formData.append("do_you_have_IP", values.do_you_have_IP)
        fetch(serverUrl + "/ip/addIpDetail",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                body: JSON.stringify(values, null, 2)


            })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch((err) => { console.log(err) })
        props.setValue(props.value + 1)


    }

    return <div className="container">
        <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12">

                <Formik
                    onSubmit={(values
                    ) =>
                        handleSubmitFinacialForm(values)
                    }
                    validationSchema={Yup.object({
                        do_you_have_IP: Yup.string().required('Please Select Have you appointed CEO for Incubation is required'),
                    })}

                    initialValues={JSON.parse(localStorage.getItem('ipDetails')) || initialFormObj}

                    render={({ values, setFieldValue }) => (
                        <Form >

                            {/*     Legal Information Relating to invention starts here         */}
                            <div className="row pb-5">
                                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                                    Post Grant
                                </h2>
                            </div>
                            <h4 className="text-danger">Note:-</h4>
                            <p className="text-danger">Please verify details on form tab
                                with<InformationIcon /> icon and click Save and Next button on that tab to submit details.
                                Once all the form tab have<CheckCircleIcon className="text-primary" style={{ fontSize: '20px' }} /> icon click submit here to register your
                                application</p>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 p-2">
                                    <label className="pb-2 label-txt">
                                        When did you first conceive the idea and begin
                                        working on your invention{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Field type="date" placeholder="Select From date" className="textInput" name="date_from" />
                                    <p className="mt-2 text-danger">Select Date</p>
                                    <div className="text-danger">
                                        <ErrorMessage className="error" name="date_from" /><br></br>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 p-2">
                                    <label className="pb-2 label-txt">
                                        What is current development stage of your invention?
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Field as="select" className="form-select" name="startup_current_stage">
                                        <option selected></option>
                                        <option value="Ideation">Ideation</option>
                                        <option value="Scaling Up">Scaling Up</option>
                                        <option value="Mature">Mature</option>
                                    </Field>
                                    <div className="text-danger">
                                        <p className="mt-2 text-danger">Please Select Development Stage</p>
                                        <ErrorMessage className="text-danger" name="startup_current_stage" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 p-2">
                                    <label className="pb-2 label-txt">
                                        If ready for commercial launch, please
                                        mention the projected launch date{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Field type="date" placeholder="Select From date" className="textInput" name="date_launch" />
                                    <div className="text-danger">
                                        <ErrorMessage className="error" name="date_launch" /><br></br>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 p-2">
                                    <label className="pb-2 label-txt">
                                        Please provide the details of production if any.
                                    </label><br />
                                    <textarea name="" id="" cols="" rows="3" className="col-lg-12 col-md-12 col-sm-12">

                                    </textarea>
                                </div>
                                <div className="col-lg-12 col-md-12 p-2">
                                    <label className="pb-2 label-txt">
                                        Please provide approximate date of commercial production{" "}
                                    </label>
                                    <Field type="date" placeholder="Select From date" className="textInput" name="date_approx_com" />
                                </div>

                                <div className="col-lg-12 col-md-12 p-2">

                                    <label className="pb-2 label-txt">
                                        Is the invention going to be a joint development
                                        between you and any third party <br />
                                        &#40;Institute, Sponsors, Co-investor,
                                        Co-researchers etc.?&#41;<span className="text-danger">*</span>
                                        {/* <InformationIcon /> */}
                                    </label>
                                    <Field component="div" name="do_you_have_IP">
                                        <div class="radiobuttons d-flex">
                                            <div className="col-lg-5 col-md-5">
                                                <div class="rdio rdio-primary radio-inline">
                                                    <input
                                                        type="radio"
                                                        id="radioYes"
                                                        defaultChecked={values.do_you_have_IP === "yes"}
                                                        name="do_you_have_IP"
                                                        value="yes"
                                                    />
                                                    <label htmlFor="radioYes">Yes</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div class="rdio rdio-primary radio-inline">
                                                    <input
                                                        type="radio"
                                                        id="radioNo"
                                                        defaultChecked={values.do_you_have_IP === "no"}
                                                        name="do_you_have_IP"
                                                        value="no"
                                                    />
                                                    <label htmlFor="radioNo">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Field>
                                    <p className="mt-2 text-danger">Select Option</p>
                                    <div className="text-danger">
                                        <ErrorMessage className="error" name="do_you_have_IP" />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12 p-2">
                                    <label className="pb-2 label-txt">
                                        If YES, please provide details of the third party and the nature of it's
                                        contribution to the invention.
                                    </label><br />
                                    <textarea name="" id="" cols="" rows="3" className="col-lg-12 col-md-12 col-sm-12">

                                    </textarea>
                                    <p className="mt-2 text-danger">Please Enter Text</p>
                                </div>
                            </div>

                            <div className="justify-content-center d-flex pt-4">
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="btn-color-draft no-shadow"
                                        onClick={() => { localStorage.setItem("ipDetails", JSON.stringify(values)) }}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="btn-color-next no-shadow"
                                    >
                                        Save &amp; Next
                                    </Button>
                                </Stack>
                            </div>

                            {/*     Legal Information Relating to invention ends here         */}
                        </Form>
                    )}
                />
            </div>
        </div>
    </div>



}
