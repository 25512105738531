import React, { useState, useEffect } from "react";
import PurchaseOrderAwarded from "../../assets/images/Initiative/InitiativeMaharashtraStartup/trophy.png";
// import { Button } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import { environment } from "../../api";
import Creativity from "../../assets/images/EcoSystem/Idbicapital/creativity.png"
import Jigsaw from "../../assets/images/EcoSystem/Idbicapital/jigsaw.png"
import productManagement from "../../assets/images/EcoSystem/Idbicapital/product-management.png"
import Shield from "../../assets/images/EcoSystem/Idbicapital/shield.png"
import GreenEnergy from "../../assets/images/EcoSystem/Idbicapital/green-energy.png"
import Water from "../../assets/images/EcoSystem/Idbicapital/water.png"
import Bulding from "../../assets/images/EcoSystem/Idbicapital/bulding1.png"
import Book from "../../assets/images/EcoSystem/Idbicapital/book.png"
import Document from "../../assets/images/EcoSystem/Idbicapital/document.png"
// import CloseIcon from '@mui/icons-material/Close'
// import Modal from 'react-modal'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import NewAreaModal from "./NewAreaModal";
import LoginModal from '../../component/loginModal'

function MHApplyNow() {

    const serverUrl = environment.baseUrl;
  const loginUrl = environment.LoginLink
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  
  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true);
  };

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false);
  };

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 768;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    fetch(serverUrl + "/startupWeek/allStartupWeek")
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUsers(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  return (
    <>
    <div className="mahaGrand-main pt-5 ">
      <div className="container">
        <div className="row d-flex justify-content-center pad-banner-all">
          <div
            className={`${
              isMobile
                ? "d-none"
                : "col-lg-3 col-md-3 col-sm-3 d-lg-block d-sm-none d-md-none justify-content-center"
            }`}
          >
          
            <div className="card col-lg-10 col-md-10 col-sm-10 apply-head-mahaStartup  border-2 ">
              <div
              //  onClick={handleShow}
                 className="card-body d-flex justify-content-center  align-items-center cursor-pointer">
                <div className="col-lg-8 col-md-8 col-sm-8 ">
                  
                  <span className="textColourMenu  regular_font font-20-24 font-style-normal font-weight-400">
                    Apply now
                  </span>
                
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">


                <a href='https://docs.google.com/forms/d/e/1FAIpQLSfA50VPWO_YhZzP-PJD-Ci4Jf5zYs-9qHvGu5S1e19yWXSjMw/viewform' target="_blank" className="cursor-pointer">
                  <span className="Arrow-apply align-items-end">
                    <svg
                      stroke="currentColor"
                      fill="#fff"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="20px"
                      width="30px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                  </a>
                
                </div>
              </div>
              <div className="list-group list-group-flush">
                <a
                  href="#mahaStartupWeek"
                  className="list-group-item list-group-item-action"
                >
                  <span className="regular_font font-18-22 font-style-normal font-weight-400">
                  Brief Introduction
                  </span>
                </a>
                <a
                  href="#testimonials"
                  className="list-group-item list-group-item-action"
                >
                  {" "}
                  <span className="regular_font font-18-22 font-style-normal font-weight-400">
                  Fund Investment Criteria
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-7">

           
              <>
                <div className="row" id="mahaStartupWeek">
                  <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pt-2 pb-4">
                  Brief Introduction
                  </h2>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Maharashtra Innovation and Technological Development Fund (“MITDF” or “’Fund”’) has been registered with SEBI as a Category - I Alternative Investment Fund (AIF) under the subcategory Venture Capital Fund with effect from August 25, 2022. MITDF is carrying Registration No. IN/AIF1/22-23/1118. IDBI Trusteeship Services Limited is the Trustee and IDBI Capital Markets & Securities Limited is acting as Sponsor and Investment Manager of the MITDF.
                  </p>
                </div>
                <div className="row">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pt-2 pb-4">
                Investment Objective
                  </h2>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  The primary investment objective of the Fund is to invest in early stage Maharashtra incorporated Startup and MSME companies, which are having innovative and scalable business model and which work towards development or improvement of a product, process or service and/or have scalable business model with high potential for creation of wealth & employment. The Fund shall invest in promising, innovative and high impact startups and MSMEs, who are solving problems with innovative solutions in sectors.The Fund shall invest in promising, innovative and high impact startups and MSMEs, who are solving problems with innovative solutions in sectors.
                  </p>
                </div>
                <div className="row">
                <h4 className="pb-4">Key Factor:-</h4>
                <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div className="d-flex justify-content-center pb-2">
                    <img src={Creativity} width='50px'></img>
                    </div>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 d-flex justify-content-center justify"><b>Innovative Solutions</b></p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div className="d-flex justify-content-center pb-2">
                    <img src={Jigsaw} width='50px'></img>
                    </div>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 d-flex justify-content-center justify"><b>Differentiated products</b></p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div className="d-flex justify-content-center pb-2">
                    <img src={productManagement} width='50px'></img>
                    </div>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 d-flex justify-content-center justify"><b>Socially relevant</b></p>
                </div>
                </div>
                <div className="row pt-4">
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify"><b>Sector Opportunity including but not restricted to following:-</b></p>
                   <div className="row idbicapitalMain">
                    <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div class="testimotionals">
                     <div class="card">
                        <div class="layer">
                         </div>
                    <div class="content">
                    <div class="image pb-3">
                        <img align="center" width="50px" src={Shield} alt="" />
                    </div>
                    <div class="details">
                        <h2><span>Health Care, Cyber Security </span></h2>
                    </div>
                    </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div class="testimotionals">
                     <div class="card">
                        <div class="layer">
                         </div>
                    <div class="content">
                    <div class="image pb-3">
                        <img align="center" width="50px" src={GreenEnergy} alt="" />
                    </div>
                    <div class="details">
                        <h2><span>Agriculture, Clean Energy </span></h2>
                    </div>
                    </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div class="testimotionals">
                     <div class="card">
                        <div class="layer">
                         </div>
                    <div class="content">
                    <div class="image pb-3">
                        <img align="center" width="50px" src={Water} alt="" />
                    </div>
                    <div class="details">
                        <h2><span>Waste & Water Management </span></h2>
                    </div>
                    </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div class="testimotionals">
                     <div class="card">
                        <div class="layer">
                         </div>
                    <div class="content">
                    <div class="image pb-3">
                        <img align="center" width="50px" src={Bulding} alt="" />
                    </div>
                    <div class="details">
                        <h2><span>Smart Infrastructure & Mobility </span></h2>
                    </div>
                    </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div class="testimotionals">
                     <div class="card">
                        <div class="layer">
                         </div>
                    <div class="content">
                    <div class="image pb-3">
                        <img align="center" width="50px" src={Book} alt="" />
                    </div>
                    <div class="details">
                        <h2><span>Education, Skill Development, Fintech </span></h2>
                    </div>
                    </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 p-2">
                    <div class="testimotionals">
                     <div class="card">
                        <div class="layer">
                         </div>
                    <div class="content">
                    <div class="image pb-3">
                        <img align="center" width="50px" src={Document} alt="" />
                    </div>
                    <div class="details">
                        <h2><span>Other Sectors where GoM sees relevance.</span></h2>
                    </div>
                    </div>
                        </div>
                        </div>
                    </div>
                   </div>
                </div>
                <div className="row pt-3">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pt-2 pb-4">
                Investment Strategy
                  </h2>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  The investment identified strategy of the Fund will be to Invest primarily in privately negotiated equity and equity-related instruments of Companies, engaged in the activities permitted under the AIF
                  </p>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Regulations, within the limits and restrictions provided under the AIF Regulations on a private placement basis, which shall exhibit potential to return capital appreciation in the opinion of the Investment Manager. The Fund would be sector agnostic and shall make investments in accordance with the AIF Regulations.
                  </p>
                </div>
              </>
           
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default MHApplyNow
