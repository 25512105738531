import React from "react";
import startupimg from "../../assets/images/Initiative/startupyatrabanner.png";
import { Button } from "react-bootstrap-v5";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom"; 
import banner1 from "../../assets/images/startupyatrabanner/yatra1.jpg";
import banner2 from "../../assets/images/startupyatrabanner/yatra2.jpg" ;
import banner3 from "../../assets/images/startupyatrabanner/yatra3.jpg";
import banner4 from "../../assets/images/startupyatrabanner/yatra4.jpg";

// import EventArguments from './EventArguments';
// import Arrow from '../assets/images/innovation/arrow.png';

function StartupYatraMiddleContent() {
  const navigate = useNavigate();

  // const navigateToContacts = () => {
  //   // 👇️ navigate to /contacts
  //   navigate('/contacts');
  // };

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/StartupYatraWinner');
  };
 
  return (
    <>
      {/* <div className="mastartupyatra">
        <img
          src={startupimg}
          className="maGrandBaneer-img"
          alt="MaharashtraGrandBaneer-img"
        />
      </div> */}

      <div className="mahaGrand-main">
        <div className="container">
          <div className="row d-flex justify-content-end my-4 pad-banner-all">
          <div className="col-lg-9 col-md-9 col-sm-9 pt-4">
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 w-50 pinkbtn d-flex justify-content-end ">
            {/* <button onClick={navigateHome}>Home</button>
        <hr /> */}
        
              <Button className="regular_font font-style-normal font-weight-normal font-20-24 d-sm-block" onClick={navigateHome}>
                <Link to="/StartupWinners">
                  <span className="regular_font font-20-24 font-style-normal font-weight-400" >
                  State Level Startup Winners List
                  </span>
                  <span className="inno-arrow">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="20px"
                      width="30px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </Link>
              </Button>
            </div>
            <div className="row" id="granchallenge">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                MAHARASHTRA STARTUP YATRA 2022
                </h2>
                <h2>About</h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                Maharashtra Startup Yatra 2022 was launched with the objective of scouting grassroot innovators from 358 blocks of Maharashtra across all 36 districts. Maharashtra State Innovation Society will handhold the winners and participants of this yatra to help budding innovators and start-ups to grow in their journey through mentorship, cash prizes, incubation support, cloud support, financial assistance for intellectual property and quality testing etc.
                  <h2>Event Details</h2>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Maharashtra Startup Yatra 2022 was launched at the hands of Hon. Governor of Maharashtra Bhagatsinh Koshyari, Hon. Chief Minister Eknath Shinde, Hon. Dy Chief Minister Devendra Fadanavis and Hon. minister Mangalprabhat Lodha, skills, employment, entrepreneurship and innovation on 15th August 2022. 
Bootcamps for district level participants were held on 13 – 14 October 2022 across Maharashtra and state level winners were facilitated on 16th October 2022 at an event held in Rajbhavan, Mumbai.

                  </p>
                  <h2>Winners</h2>
                  <ol>
                    <li> State Level  </li>
                    <li> District Level  </li>
                  </ol>
                      
                  <h2>Important milestones</h2>
                  <p className="justify">
                  12 dedicated, branded vans with all of the educational and informational content travelled around length and breadth of Maharashtra. Some key facts and figures are mentioned below,
                  </p>
                  <ul>
                    <li>Number of days: 60</li>
                    <li>Number of Talukas covered: 358</li>
                    <li>Number of Institutions visited: ~1050</li>
                    <li>Total number of registrations: 2630</li>
                    <li>Winners at District level: 108</li>
                    <li>Winners at State Level: 20</li>
                    <li>Reservation for women at state level: 33%</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
          <span className="regular_font font-20-24 font-style-normal font-weight-400" >
          Photographs
          </span>
          <div className="row pt-4">
            <div className="col-md-4">
            <img src={banner4} className="img-fluid strtup-img-height" alt="logo" />
            </div>
            <div className="col-md-4"> <img src={banner1} className="img-fluid strtup-img-height" alt="logo" /></div>
            <div className="col-md-4"> <img src={banner2} className="img-fluid strtup-img-height" alt="logo" /></div>
          </div>
          <div className="row pt-2 pb-3">
            <div className="col-md-4"> <img src={banner3} className="img-fluid strtup-img-height" alt="logo" /></div>
            <div className="col-md-4"> </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StartupYatraMiddleContent;
