import React from 'react'
import Header from '../../component/Header';
import IntellectualMiddlecontent from './intellectualPRMiddleContent';
import IntellectualFaq from './IntellectualProPRFaq';
// import IntellectualExternalLink from './IntellectualExternalLink'
import Footer from '../../component/footer';

function IntellectualPRMain() {
  return (
    <>
    <Header/>
    <IntellectualMiddlecontent/>
    <IntellectualFaq/>
    {/* <IntellectualExternalLink /> */}
    <Footer/>
   </>
  )
}

export default IntellectualPRMain