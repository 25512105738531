import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from './informationIcon';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Upload from "../assets/images/navbar/upload.png";

const serverUrl = environment.baseUrl;
const Finacialform = (props) => {
  const authToken =sessionStorage.getItem("token");
  let initialFormObj={ 
    max_turnover_prev_three_financial_years: '',
  what_startup_current_funding_status: '',
  total_funds_received_incorporation: '',

    turnover_details: [{
      financial_year:'',
      audited_financial_number:'',
      proof_doc:''
      }]
   }
  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
     const formData = new FormData();
    formData.append("max_turnover_prev_three_financial_years",values.max_turnover_prev_three_financial_years)
    formData.append("what_startup_current_funding_status",values.what_startup_current_funding_status)
    formData.append("total_funds_received_incorporation",values.total_funds_received_incorporation)
    formData.append("financial_year",values.financial_year)
    formData.append("audited_financial_number",values.audited_financial_number)
    formData.append("proof_doc",values.proof_doc)

    console.log(values, "hii")
    fetch(serverUrl +"/financial/addfinancialDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values
      ) =>
      handleSubmitFinacialForm(values)
    }
    validationSchema={Yup.object({
        max_turnover_prev_three_financial_years: Yup.string().required('Select Maximum Turnover of Any of the Previous Three Financial Years is required'),
        what_startup_current_funding_status: Yup.string().required('Select What is the Startup’s Current Funding Status is required'),
        total_funds_received_incorporation: Yup.string().required('Select Total Funds Received Since Incorporation is required'),
        turnover_details: Yup.array().of(
          Yup.object().shape({
            financial_year: Yup.string().required('Financial Year is required'),
            audited_financial_number: Yup.string().required('Audited Financial Number is required'),
            proof_doc: Yup.string().required('Proof Document is required'),
              
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('financialDetails'))||initialFormObj}

      render={({ values, setFieldValue }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
              Financial
            </h2>
        </div>
        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 py-2">
            <label className="pb-2 label-txt">
              Maximum Turnover of Any of the Previous Three Financial Years
                <span className="text-danger">*</span>
                <InformationIcon />
              </label>
        <Field as="select" className="form-select" name="max_turnover_prev_three_financial_years">
            <option selected>Select </option>
            <option value="Less than 1Cr">Less than 1Cr</option>
            <option value="Between 1Cr and 5Cr">Between 1Cr and 5Cr</option>
            <option value="Between 10Cr and 15Cr">Between 5Cr and 10Cr</option>
            <option value="Between 10Cr and 15Cr">Between 10Cr and 25Cr</option>
          </Field>
          <div className="text-danger">
            <ErrorMessage className="text-danger" name="max_turnover_prev_three_financial_years" />
          </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
            <label className="pb-2 label-txt">
              What is the Startup’s Current Funding Status?<span className="text-danger">*</span>{" "}
                <InformationIcon />
              </label>
          <Field as="select" className="form-select" name="what_startup_current_funding_status">
            <option selected>Select </option>
            <option value="Equity">Equity</option>
            <option value="Debt">Debt</option>
            <option value="Awards">Awards</option>
            <option value="Bootstrapped">Bootstrapped</option>
          </Field>
          <div className="text-danger">
            <ErrorMessage className="text-danger" name="what_startup_current_funding_status" />
          </div>
      </div>
        </div>
        <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
      <label className="pb-2 label-txt">
              Total Funds Received Since Incorporation
                <InformationIcon />
              </label>
        <Field as="select" className="form-select" name="total_funds_received_incorporation">
          <option selected>Select </option>
            <option value="0 To 3 Cr">0 To 3 Cr</option>
            <option value="Debt">3 To 10 Cr</option>
            <option value="Awards">Above 10Cr</option>
        </Field>
        <div className="text-danger">
        <ErrorMessage name="total_funds_received_incorporation" />
        </div>
      </div>
        </div>
          <FieldArray
            name="turnover_details"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>



                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"30%"}}>Financial Year</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"30%"}} align="left">Audited Financial Numbers(in INR)</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"30%"}} align="left">Upload Proof(Audited statement from CA) </TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>

      



                {values.turnover_details && values.turnover_details.length > 0 ? (


                  values.turnover_details.map((friend, index) => (
                    
                     

                    <TableBody  className="data-bg-color d-flex justify-content-between">

              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"30%"}}>
                  <Field type="text" className="txt-color" name={`turnover_details.${index}.financial_year`} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name={`turnover_details.${index}.financial_year`} /><br></br>
                    </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"30%"}}>
                          <Field type="text" className="txt-color" name={`turnover_details.${index}.audited_financial_number`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`turnover_details.${index}.audited_financial_number`} /><br></br>
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"30%"}}>
                         <input
                  id="files"
                  name={`turnover_details.${index}.proof_doc`}
                  type="file"
                  multiple
                  accept="image/*"
                  value={values.files}
                  onChange={(values) => {
                        setFieldValue(`turnover_details.${index}.proof_doc`, values.currentTarget.files[0].name);
                      }}  
                    />
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name={`turnover_details.${index}.proof_doc`} /><br></br>
                        </div>
             </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
          
              </TableBody>  
      
   


                     
                    
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("financialDetails", JSON.stringify(values))}}  
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>

              </div>
            )}
          />
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default Finacialform;
