import React from 'react'
import Header from '../component/Header';
import Footer from '../component/footer';

function securitypolicies() {
  return (
    <>
        <Header />
        <div className="mahaGrand-main intellectual-main">
          <div className="container">
            <div className="row d-flex justify-content-center my-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row" id="granchallenge">
                  <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                    Privacy Policy
                  </h2>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    As a general rule, this website does not collect Personal
                    Information about you when you visit the site. You can
                    generally visit the site without revealing Personal
                    Information, unless you yourself choose to provide such
                    information. This website records your visits and logs the
                    following information for statistical purposes -your servers
                    address; the name of the top-level domain from which you
                    access the Internet (for example, .gov, .com, .in, etc.);
                    the type of browser you use; the date and time you access
                    the site; the pages you have accessed; the documents
                    downloaded and the previous Internet address from which you
                    linked directly to the site. We will not identify users or
                    their browsing activities, except when a law enforcement
                    agency may exercise a warrant to inspect the service
                    providers logs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row" id="granchallenge">
                  <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                    Hyperlinking Policy
                  </h2>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    At many places in this website, one shall find links to
                    other websites/portals. This links have been placed for
                    one’s convenience.It is not responsible for the contents and
                    reliability of the linked websites and does not necessarily
                    endorse the views expressed in them. Mere presence of the
                    link or its listing on this website should not be assumed as
                    endorsement of any kind. It cannot guarantee that these
                    links will work all the time and have no control over
                    availability of linked pages.
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row" id="granchallenge">
                  <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                    Copyright Policy
                  </h2>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    Material featured on this Portal may be reproduced free of
                    charge after taking proper permission by sending a mail to
                    us. However, the material has to be reproduced accurately
                    and not to be used in a derogatory manner or in a misleading
                    context. Wherever the material is being published or issued
                    to others, the source must be prominently acknowledged.
                    However, the permission to reproduce this material shall not
                    extend to any material which is identified as being
                    copyright of a third party. Authorisation to reproduce such
                    material must be obtained from the Departments/copyright
                    holders concerned.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
  )
}

export default securitypolicies