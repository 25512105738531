import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const EventScheduleUpdateM = (props, {setModalIsOpenToFalse3}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [updatedImage,setUpdatedImage]=useState(null)
  
  function handleSubmitEventScheduleForm(values){
   
    function fetcheventshedule(){
      fetch(serverUrl + "/schedule/allSchedule")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    const formData = new FormData();
    formData.append("date_from",values.date_from)
    formData.append("date_to",values.date_to)
    formData.append("description",values.description)
    formData.append("image",values.image)
    fetch(serverUrl +`/schedule/${props.rowData.upEvents._id}`, 
         {
          method: 'PUT',
            headers: {
              "Content-Type": "application/json",
              accept: "application/json"
            },
            body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .then((res) => fetcheventshedule())
          .then((res) => setModalIsOpenToFalse3())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("Are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
            onSubmit={(values
            ) =>
           { console.log(values )
            handleSubmitEventScheduleForm(values)
           
          }
          }
    validationSchema={Yup.object({
      date_from: Yup.string().required('Select From Date is required'),
      date_to: Yup.string().required('Select To Date is required'),
      description: Yup.string().required('Enter Description is required'),
      image: Yup.string().required('image is required'),
       })}
      initialValues={{ 
      date_from:props.rowData.upEvents.date_from,
      date_to: props.rowData.upEvents.date_to,
      description: props.rowData.upEvents.description,
      image: props.rowData.upEvents.image,
       }}

      

      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Date From{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="date"                    
                      onChange={handleChange}
                      placeholder="Select date" className="textInput" name="date_from" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="date_from" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Date To{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="date"
                       onChange={handleChange}
                     placeholder="Select date" className="textInput" name="date_to" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="date_to" /><br></br>
                    </div>                   
                  </div> 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" onChange={handleChange} placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" /><br></br>
                    </div>
                  </div>  

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img id="imgupdate" src={updatedImage||values.image} style={{width:'20%', height:'50%'}} /><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0], "dataaaaaaaaaaaaaaaaa")
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("image", e.target.files[0]);
                      }} required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>       
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default EventScheduleUpdateM;
