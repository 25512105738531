import React from 'react';
import Header from '../component/HeaderAdmin';
import Banneradmin from './banner1';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../component/footer';

function LandingMain() {
  return (
      <>
    <Header/>
    <Banneradmin />
    </>
  );
}

export default LandingMain;
