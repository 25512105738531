import React from 'react'
import Header from '../../component/Header';
import MahaHackBanner from './mahaHackBanner';
import MahaGrandContactus from '../MaharashtraGrandChallenge/mahaGrandContactus';
import MahaHackthonMiddleContent from './mahaHackthonMiddleContent';
import MahaHackthonEvent from './mahaHackthonEvent';
import MahaHackthonFaq from './MHHackthonFaq';
import MahaHackthonPartner from './mahaHackthonPartner';
import Footer from '../../component/footer';

function mahaHackathonMain() {
  return (
    <>
    <Header/>
    <MahaHackBanner/>
    <MahaHackthonMiddleContent/>
    <MahaHackthonEvent/>
    <MahaHackthonFaq/> 
    <MahaHackthonPartner/>
    <MahaGrandContactus/>
    <Footer/>
    </>
  )
}

export default mahaHackathonMain