import React, { useState, useEffect } from 'react';
import Rectangle136 from '../assets/images/feature_event/Rectangle136.jpeg';
import arrowup from '../assets/images/feature_event/arrowup.png';
import Policy_Department from '../assets/document/Policy_Department.pdf';
import { environment } from '../api';
// import SPage from './searchpag/sPage'


function Featuredresources() {
    const serverUrl = environment.baseUrl;
   const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
      fetch(serverUrl +"/featureResource/allfeature")
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setUsers(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
    }, [])
    
    
  return (
      <>
          <div className='featuredresources-main pb-5'>
              <h2 className='font-28-36 font-18-23 font-20-26 font-weight-600 d-flex justify-content-center pb-2 '>Featured Resources</h2>
              <div className='featured-pdf-card justify-content-center'>

              <div className='d-flex justify-content-center justify-content-center '>
     <div className='row col-lg-12 col-md-12 justify-content-center ourLeaderShip-row pt-4'>
 
 <div className='col-lg-4 col-md-4 col-md-4 ourLeaderShip-card '>
              {users.map(user => (
                <div className="d-flex col-lg-5 col-md-10 col-sm-5 p-2 my-4 align-items-center m-2 py-2  featurescard mob-ourfeaturedwidth">
                <div className='d-flex col-lg-3 col-md-3 col-sm-3 col-0 justify-content-center'>
                   <div className=''>
                       <img src={Rectangle136} className='d-none d-sm-block'></img>
                   </div>
                </div>

                <div className='col-lg-7 col-md-7 col-sm-7 col-10 guidPaddLeft'>
                <span className='regular_font font-16-19 font-12-14 font-style-normal font-weight-400'>{user.date}</span>
                   <h2 className='font-20-26 font-14-18 font-style-normal font-weight-600 featurescard-p'>
                   {user.title}
                   </h2>
                </div>

                <div className='col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-center'>
                <div className='d-none d-lg-block'>
                <a href={user.link} target="_blank">
                       <svg 
                           width="30"
                              height="31" 
                              viewBox="0 0 30 31" 
                              fill="none" 
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.57729 29.0557L28.0557 2.5773M28.0557 2.5773L9.01969 2.4587M28.0557 2.5773L28.1743 21.6133" stroke="#174984" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>
                       </a>
                </div>
                <div className='d-lg-none'>
                <a href={user.link} target="_blank">
                     <svg width="18"
                          height="18"
                          viewBox="0 0 18 18" 
                          fill="none" 
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.59374 16.9668L16.8752 1.68534M16.8752 1.68534L5.88897 1.61689M16.8752 1.68534L16.9436 12.6716" stroke="#174984" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                     </svg>
                     </a>
                  </div>
                </div>

                </div>
                ))}        
                </div>
                </div>

                </div>
                
             </div>
            
             
               
          </div>



{/* <SPage /> */}
        
      </>
  );
}

export default Featuredresources;
