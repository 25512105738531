import React, { useEffect,useState } from 'react'
import "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
// import MentorViewModel from "./mentorViewMod";
// import MentorAddModel from "./mentorAddMod";
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import {useParams} from "react-router-dom"
// import MentorApprovalModel from './mentorApprovalModel'
import { environment } from '../api';
function ViewNRIDetails({id}) {
  
  const serverUrl = environment.baseUrl;
  
  const authToken =sessionStorage.getItem("token");

  console.log(sessionStorage.getItem("_id"),"id")
  // const { id } = useParams(); // <-- access id match param here
  
       const [users, setUsers] = useState([]);
       const [error, setError] = useState(null);
       const [isLoaded, setIsLoaded] = useState(false);
       useEffect(() => {
         fetch(serverUrl +`/nriDetail/${id}`,
         {headers: {
            "Content-Type": "application/json",
            // accept: "application/json",
            "x-access-token":authToken
            
          }}
         
         )
           .then(res => res.json())
           .then(
             data => {
               setIsLoaded(true);
               setUsers(data);
             },
             error => {
               setIsLoaded(true);
               setError(error);
             }
           );
       }, []);
  

  return (
    <>
       <div className="bannermain">
    <div className="container">
      <div className="row ">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Mentor Details
            </h2>
          </div>
          
          <form method="POST" enctype="multipart/form-data">
          {users?
          <>
            <div className="row">
              <div className="col-md-2 pt-4">
                
                <label className="pb-2 label-txt">
                Mentor’s Name :
                 
                 
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.mentor_name}</span>
                   </div>
                   </div>
                   <div className="row">
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                E-Mail Address :
                 
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.email}</span>
                   </div>
                   </div>
                   <div className='row'>
                   <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                Phone Number :
                
                 
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   {/* <span>{users.phone_number}</span> */}
                   </div>
                   </div>
                   <div className="row">
              <div className="col-md-2 pt-4">
                <label className="pb-2 label-txt">
                LinkedIn URL :
                
                  
                </label>
                </div>
                <div className="col-md-4 pt-4">
                   <span>{users.company_name}</span>
                   </div>
                   
             
                   </div>
                   </>
              : "loading"
            }

              
              
             
              <div>
                {/* <StartupAddmore /> */}
              </div>
              {/* <div className="justify-content-center d-flex py-4">
                  <Stack direction="row" spacing={2}>
                    
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-draft no-shadow" 
                    >
                      Save as Draft
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      className="btn-color-next no-shadow" onClick={()=>{props.setValue(props.value+1)}}
                    >
                      Save &amp; Next
                    </Button>
                  </Stack>
                </div> */}
           
            {/*  </form> */}
            {/* <div className=""> */}
            
          </form>
           {/* ))}  */}
        </div>
      </div>
    </div>
  </div>

    </>
  );
}

export default ViewNRIDetails;
