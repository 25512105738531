import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const MaharashtraHackthonFAQAddM = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitMaharashtraHackthonFAQAddForm(values){
    // console.log(values, "hii")
    const formData = new FormData();
    formData.append("question",values.question)
    formData.append("answer",values.answer)

    function fetchData(){
      fetch(serverUrl + "/MhHealthFaq/allMhFaq")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    setUsers(data);
  },
)
}

    fetch(serverUrl +"/MhHealthFaq/addMhFaq", 
         {
            method: "POST",
            body: formData,
            // headers: {
            //   'Content-Type': 'application/json',
            //   accept: "application/json",
              
            // },
            // body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          // .then(res=>console.log(res))
          .then((res) => fetchData())
          .then((res) => setModalIsOpenToFalse3())
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
  }
  

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
    onSubmit={(values, {resetForm}
    ) =>
   { console.log(values )
    handleSubmitMaharashtraHackthonFAQAddForm(values)
    resetForm({values:''}) 
  }
  }
    validationSchema={Yup.object({
      question: Yup.string().required('Enter Question is Required'),
      answer: Yup.string().required('Enter Answer is Required'),
       })}
      initialValues={{ 
        question: '',
        answer: '',
       
       }}

      render={({ values, setFieldValue, handleSubmit }) => (
        <Form  
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        >
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Question{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Question" className="textInput" name="question" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="question" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Answer{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Answer" className="textInput" name="answer" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="answer" /><br></br>
                    </div>                   
                  </div>                 
             </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default MaharashtraHackthonFAQAddM;
