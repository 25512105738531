import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const TestimonialsUpdateM = (props, {setModalIsOpenToFalse2}) => {
  const [updatedImage,setUpdatedImage]=useState(null)
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitTestimonialsUpdateForm(values){
    const formData = new FormData();
    formData.append("name",values.name)
    formData.append("position",values.position)
    formData.append("description",values.description)
    formData.append("address",values.address)
    formData.append("image",values.image)
    function fetchData(){
      fetch(serverUrl + "/testimonials/allTestimonials")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    fetch(serverUrl +`/testimonials/${props.rowData.testiMonials._id}`, 
         {
          method: 'PUT',
          body: formData,
            
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .then((res) => fetchData())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
          
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
     
      handleSubmitTestimonialsUpdateForm(values)
    }
    validationSchema={Yup.object({
      name: Yup.string().required('Enter Name is Required'),
      position: Yup.string().required('Enter Position is Required'),
      description: Yup.string().required('Enter Description is Required'),
      address: Yup.string().required('Enter Address is Required'),
      image: Yup.string().required('Image is required'),
       })}
      initialValues={{ 
        name:props.rowData.testiMonials.name,
        position: props.rowData.testiMonials.position,
        description: props.rowData.testiMonials.description,
        address:props.rowData.testiMonials.address,
        image: props.rowData.testiMonials.image,
       }}


       render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"                    
                      onChange={handleChange}
                      
                      placeholder="Enter Name" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Position{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Position" className="textInput" name="position" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="position" /><br></br>
                    </div>                   
                  </div>

                 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Address <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="address" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="address" /><br></br>
                    </div>
                  </div> 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img id="imgupdate" src={updatedImage||values.image} style={{width:'20%', height:'50%'}} /><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("image", e.target.files[0]);
                      }} required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>
                  <div className="justify-content-center d-flex ">
                <Stack direction="row" spacing={1}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>  
                                
        </div>
                   
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default TestimonialsUpdateM;
