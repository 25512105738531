import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from './informationIcon';


const serverUrl = environment.baseUrl;
const BusinessModel = (props) => {
  const authToken =sessionStorage.getItem("token");
  let initialFormObj={ 
    startup_current_stage: '',
    uniqueness_factor: '',
    key_partners_resource: '',
    cost_structure: '',
    revenue_inflow: '',
     
     }

  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("startup_current_stage",values.startup_current_stage)
    formData.append("uniqueness_factor",values.uniqueness_factor)
    formData.append("key_partners_resource",values.key_partners_resource)
    formData.append("cost_structure",values.cost_structure)
    formData.append("revenue_inflow",values.revenue_inflow)
    fetch(serverUrl +"/businessModel/addBizModelDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            // body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values
      ) =>
      handleSubmitFinacialForm(values)
    }
    validationSchema={Yup.object({
      startup_current_stage: Yup.string().required('Select Start-up current stage is required'),
      uniqueness_factor: Yup.string().required('Uniqueness factor is required'),
      key_partners_resource: Yup.string().required('Key Partners/Resources is required'),
      // cost_structure: Yup.string().required('Cost structure is required'),
      revenue_inflow: Yup.string().required('Revenue Inflow is required'),
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('bussinessModalDetails'))||initialFormObj}


      render={({ values }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Business Model
            </h2>
        </div>
        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 py-2">
            <label className="pb-2 label-txt">
            Start-up current stage
                <span className="text-danger">*</span>
                <InformationIcon />
              </label>
        <Field as="select" className="form-select" name="startup_current_stage">
        <option selected>Select </option>
        <option value="Ideation">Ideation</option>
        <option value="Scaling Up">Scaling Up</option>
        <option value="Mature">Mature</option>
          </Field>
          <div className="text-danger">
            <ErrorMessage className="text-danger" name="startup_current_stage" />
          </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
            <label className="pb-2 label-txt">
            Uniqueness factor<span className="text-danger">*</span>{" "}
                <InformationIcon />
              </label>
              <Field type="text" placeholder="Enter Uniqueness factor" className="textInput" name="uniqueness_factor" />
          <div className="text-danger">
            <ErrorMessage className="text-danger" name="uniqueness_factor" />
          </div>
      </div>
        </div>

        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 py-2">
            <label className="pb-2 label-txt">
            Key partners/resources
                <span className="text-danger">*</span>
                <InformationIcon />
              </label>
              <Field type="text" plceholder="Enter Key partners/resources" className="textInput" name="key_partners_resource" />
          <div className="text-danger">
            <ErrorMessage className="text-danger" name="key_partners_resource" />
          </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
            <label className="pb-2 label-txt">
            Cost structure<span className="text-danger">*</span>{" "}
                <InformationIcon />
              </label>
              <input type="text" placeholder="Enter Cost structure" className="textInput" name="cost_structure" />
          <div className="text-danger">
            <ErrorMessage className="text-danger" name="cost_structure" />
          </div>
      </div>
        </div>

        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 py-2">
            <label className="pb-2 label-txt">
            Revenue Inflow
                <span className="text-danger">*</span>
                <InformationIcon />
              </label>
              <Field type="text" plceholder="Enter Revenue Inflow" className="textInput" name="revenue_inflow"/>
          <div className="text-danger">
            <ErrorMessage className="text-danger" name="revenue_inflow" />
          </div>
      </div>
        </div>
       
        <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("bussinessModalDetails", JSON.stringify(values))}}  
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default BusinessModel;
