import React from 'react'
import Header from '../../component/Header';
import Footer from '../../component/footer';
import Businessplan from './businessPlanMiddleContent';

function businessplanMain() {
  return (
    <>
    <Header/>
    <Businessplan/>
    <Footer/>
    </>
  )
}

export default businessplanMain