import React from "react";
import { Link } from "react-router-dom";

function GrassrootInnovationMiddleContent() {
  return (
    <>
      <div className="mahaGrand-main intellectual-main">
        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            {/* <div className="col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center">
            <div className="card col-lg-10 col-md-10 col-sm-10 apply-head  border-2">
              <div className="card-body d-flex justify-content-center  align-items-center">
                <div className="col-lg-8 col-md-8 col-sm-8">
                  <span className="regular_font font-20-24 font-style-normal font-weight-400">
                    Apply now
                  </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <span className="Arrow-apply align-items-end">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="20px"
                      width="30px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="list-group list-group-flush">
                <a
                  href="#granchallenge"
                  className="list-group-item list-group-item-action"
                >
                  <span className="regular_font font-18-23 font-style-normal font-weight-400">
                    Grand Challenge
                  </span>
                </a>
                <a
                  href="#idEligibility"
                  className="list-group-item list-group-item-action"
                >
                  {' '}
                  <span className="regular_font font-18-22 font-style-normal font-weight-400">
                    Eligibility
                  </span>
                </a>
                <a
                  href="#idProblem"
                  className="list-group-item list-group-item-action"
                >
                  {' '}
                  <span className="regular_font font-18-22 font-style-normal font-weight-400">
                    Problem Statement and Prize Money
                  </span>
                </a>
                <a
                  href="#idEventSch"
                  className="list-group-item list-group-item-action"
                >
                  {' '}
                  <span className="regular_font font-18-22 font-style-normal font-weight-400">
                    Event Schedule
                  </span>
                </a>
                <a
                  href="#idFAQ"
                  className="list-group-item list-group-item-action"
                >
                  {' '}
                  <span className="regular_font font-18-22 font-style-normal font-weight-400">
                    FAQs
                  </span>
                </a>
                <a
                  href="#idContact"
                  className="list-group-item list-group-item-action"
                >
                  {' '}
                  <span className="regular_font font-18-22 font-style-normal font-weight-400">
                    Contact
                  </span>
                </a>
              </div>
            </div>
          </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                  Grassroot Innovation
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Any enterprise/business/team/start-up working on innovative
                  solutions which solves a large-scale problem at the rural
                  level shall be considered as a grassroot innovation.
                  Innovation Society shall undertake specific initiatives to
                  support such grassroot businesses. MSInS has executed programs
                  like District Business Plan Competitions and Start-up Yatra to
                  provide a platform to grassroot and rural innovators.
                  Grassroot entrepreneurs are also eligible to benefits of
                  MSInS’s existing and future initiatives:
                  <ol>
                    <li>The Startup Week where 24 startups are eligible for work orders of INR 15 lakhs each</li>
                    <li>Innovation Society-supported incubators being set up across the State</li>
                    <li>Patent Support: Grants of up to INR 2 lakhs for domestic patents and up to INR 10 lakhs for international patents</li>
                    <li>Quality Testing &#38; Certification: Grants of up to INR 2 lakhs for quality testing &#38; certification</li>
                  </ol>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  For any queries, please feel free to reach out to team@msins.inFor any queries, please feel free to reach out to <a  target="_blanc">team@msins.in
                  
                    
                  </a> </p></p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GrassrootInnovationMiddleContent;
