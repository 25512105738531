import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { Container, Col, Row } from 'react-bootstrap-v5';
import { Button } from 'react-bootstrap-v5';
import {Router, Route, Link} from "react-router-dom";
import Policy_Department from '../assets/document/Policy_Department.pdf';
import Rectangle136 from '../assets/images/feature_event/Rectangle136.jpeg';

const Tasks = ({ tasks, searchTasks, loading }) => {
    if (loading) {
        return <h2>Loading...</h2>;
    }
    

    return (
      <>
          {/* <div >
          <List style={{ padding: '16px' }} className="marginleftresources"  > */}
           <div className='featuredresources-main pb-5'>
              <div className='featured-pdf-card justify-content-center'>

              <div className='d-flex justify-content-center justify-content-center '>
     <div className='row col-lg-12 col-md-12 col-sm-12 justify-content-center ourLeaderShip-row pt-4'>
 
 <div className='col-lg-4 col-md-4 col-sm-4 ourLeaderShip-card'></div>
             {tasks?.filter((task,i)=>{
              
        
                 if(searchTasks.length>0){
                 return task.title.toLowerCase().includes(searchTasks.toLowerCase()) || task.date.toLowerCase().includes(searchTasks.toLowerCase())}
                 else{return true}}
                 )
                 .map(task => (
        //         <Box key={task.id} className="rescard">
        //         <div>
        //                 <Row>
        //                     <Col md={9} lg={9} sm={9} className="mob-featured-card-padd">
        //                        <p className='regular_font font-16-19 font-12-14 font-style-normal font-weight-400 search-date'>{task.date}</p>
        //                        <h2 className='font-20-26 font-16-21 font-style-normal font-weight-700 search-heading'>{task.title}</h2>
        //                        {/* <p className='font-20-26 font-14-18 font-style-normal font-weight-400 search-subheading'>{task.description}</p> */}
                            
                            
                            
        //                        {/* <p className='regular_font font-16-19 font-12-14 font-style-normal font-weight-400 search-date'>{task.id}</p>
        //                        <h2 className='font-20-26 font-16-21 font-style-normal font-weight-700 search-heading'>{task.title}</h2>
        //                        <p className='font-20-26 font-14-18 font-style-normal font-weight-400 search-subheading'>{task.completed ? 'Yes' : 'No'}</p> */}
        //                     </Col>
        //                     <Col md={3} lg={3} sm={3} className='d-flex justify-content-end align-self-center d-none d-lg-block'>
        //                         <div className='graybtn feature-card-button'>
        //                             <Button className="btn-primary regular_font font-style-normal font-weight-normal font-20-24">
        //                             <Link to={Policy_Department} without rel="noopener noreferrer" target="_blank">
        //                             <span className='regular_font font-20-24 font-style-normal font-weight-400'> View PDF</span>
        //                                 <span className='inno-arrow'>
        //                                 <svg
        //                                     stroke="currentColor"
        //                                     fill="currentColor"
        //                                     stroke-width="0"
        //                                     viewBox="0 0 16 16"
        //                                     height="20px"
        //                                     width="30px"
        //                                     xmlns="http://www.w3.org/2000/svg"
        //                                     >
        //                                     <path
        //                                         fill-rule="evenodd"
        //                                         d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
        //                                     />
        //                                 </svg>
        //                                 </span>
        //                             </Link>
        //                           </Button>
        //                         </div>

        //                     </Col>
        //                 </Row>

                         
        //             </div>
                    
        //             {/* <ListItem style={{ marginTop: '15px' }} key={task.id}>

        //                 <ListItemText style={{ maxWidth: '25%' }}>{task.id}</ListItemText>
        //                 <ListItemText style={{ maxWidth: '50%' }}>{task.title}</ListItemText>
        //                 <ListItemText style={{ maxWidth: '25%', display: 'flex', justifyContent: 'center' }}>{task.completed ? 'Yes' : 'No'}</ListItemText>
        //             </ListItem> */}
                    
        //             {/* <Divider style={{ height: '2px', background: '#00A0DF' }} /> */}



        // <div className='mob-bluePlanbtn align-items-center d-lg-none'>
        //     <Link to= "/Resources/WomenEntrepreneure">
        //       <div className='d-flex justify-content-center align-items-center py-2'>
        //        <span className='regular_font font-18-22 font-style-normal font-weight-400'> View PDF</span>
        //         <div className='inno-arrow'>
        //         <svg
        //               stroke="currentColor"
        //               fill="currentColor"
        //               stroke-width="0"
        //               viewBox="0 0 16 16"
        //               height="25px"
        //               width="35px"
        //               xmlns="http://www.w3.org/2000/svg"
        //               >
        //               <path
        //                   fill-rule="evenodd"
        //                   d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
        //               />
        //           </svg>
        //         </div>
        //         </div>  
        //     </Link>
        //               </div> 
        //         </Box>

<div key={task.id} className="d-flex col-lg-5 col-md-10 col-sm-5 p-2 my-4 align-items-center m-2 py-2  featurescard mob-ourfeaturedwidth">
<div className='d-flex col-lg-3 col-md-3 col-sm-3 col-0 justify-content-center'>
   <div className=''>
       <img src={Rectangle136} className='d-none d-sm-block'></img>
   </div>
</div>

<div className='col-lg-7 col-md-7 col-sm-7 col-10 guidPaddLeft'>
<span className='regular_font font-16-19 font-12-14 font-style-normal font-weight-400'>{task.date}</span>
   <h2 className='font-20-26 font-14-18 font-style-normal font-weight-600 featurescard-p'>
   {task.title}
   </h2>
</div>

<div className='col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-center'>
<div className='d-none d-lg-block'>
<a href={task.link} without rel="noopener noreferrer" target="_blank">
       <svg 
           width="30"
              height="31" 
              viewBox="0 0 30 31" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg">
              <path d="M1.57729 29.0557L28.0557 2.5773M28.0557 2.5773L9.01969 2.4587M28.0557 2.5773L28.1743 21.6133" stroke="#174984" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
       </a>
</div>
<div className='d-lg-none'>
<a href={task.link} without rel="noopener noreferrer" target="_blank">
     <svg width="18"
          height="18"
          viewBox="0 0 18 18" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1.59374 16.9668L16.8752 1.68534M16.8752 1.68534L5.88897 1.61689M16.8752 1.68534L16.9436 12.6716" stroke="#174984" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
     </a>
  </div>
</div>

</div>

                
            ))}
             </div>
                </div>

                </div>
                
             </div>
        
      </>
    );
};

export default Tasks;