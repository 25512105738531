import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import InformationIcon from '../startup/informationIcon'
import rowData from './viewstartup'
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import AddMember from "../assets/images/Incubator/Add.svg";
import { environment } from '../api';
const serverUrl = environment.baseUrl;
const StartupUpdateModel = (props) => {
console.log(props)
  function handleSubmitStartupForm(values){
    const formData = new FormData();
    formData.append("state",values.state)
    formData.append("district",values.district)
    formData.append("city",values.city)
    formData.append("website",values.website)
    formData.append("sector",values.sector)
    formData.append("general_pitch_deck_file",values.general_pitch_deck_file)

    fetch(serverUrl +`/basicDetailsStartup/${props.rowData.basicDetails._id}`, 
         {
          method: 'PUT',
            headers: {
              accept: "application/json"
            },
            // body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
          
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitStartupForm(values)
    }
    // validationSchema={Yup.object({
      // state: Yup.string().required('State is required'),
      // district: Yup.string().required('District is required'),
      // city: Yup.string().required('City is required'),   
      // website: Yup.string().required('Website is required'),
      // sector: Yup.string().required('Select Startup Sector is required'),
      // general_pitch_deck_file:Yup.string().required('Upload your General Pitch Deck is required'),
    //    })}
      initialValues={{ 
        state:props.rowData.basicDetails.state,
        district: props.rowData.basicDetails.district,
        city: props.rowData.basicDetails.city,
        website:props.rowData.basicDetails.website,
        sector: props.rowData.basicDetails.sector,
        general_pitch_deck_file: props.rowData.basicDetails.general_pitch_deck_file,
       }}


      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      State <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select"  onChange={handleChange} name="state">
                    <option selected>Select State</option>
                      <option value="Maharshtra">Maharshtra</option>
                      <option value="Karnataka">Karnataka</option>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="state" />
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      District<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select"  onChange={handleChange} name="district">
                    <option selected>Select District</option>
                      <option value="Nashik">Nashik</option>
                      <option value="Dhule">Dhule</option>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="district" />
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      City <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select"  onChange={handleChange} name="city">
                    <option selected>Select City</option>
                      <option value="Nashik">Nashik</option>
                      <option value="Pune">Pune</option>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="city" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Website <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Website" onChange={handleChange} className="textInput" name="website" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="website" /><br></br>
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      Startup Sector<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select" onChange={handleChange} name="sector">
                      <option selected>Select Startup Sector</option>
                      <option value="Nashik">Agriculture</option>
                      <option value="Pune">Biotechnology</option>
                      <option value="Pune">Clean Energy</option>
                    </Field>
                    <div className="text-danger">
                    <ErrorMessage className="error" name="sector" /><br></br>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 pt-4">
                    <label className="label-txt">
                      Upload your General Pitch Deck
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <div class="form mt-1 ml-5 pl-5 col-lg-12 col-md-12">
                    <div class="btn float-left imgbackground form-control">
                    <input id="file" name="general_pitch_deck_file" accept="image/png,image/jpeg"
                     type="file" 
                    
                     onChange={(e) => {
                      console.log(e.target.files[0])
                         setFieldValue("general_pitch_deck_file", e.target.files[0]);
                         handleChange();
                      }} 
                      />   
                         <div className="text-danger">
                          <ErrorMessage className="text-danger" name="general_pitch_deck_file" />
                           </div>
                      </div> 
                    </div>
                  </div>
                  </div>
      
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default StartupUpdateModel;
