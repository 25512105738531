import React from 'react'
import InformationIcon from '../startup/informationIcon'
import AddMember from "../assets/images/Incubator/Add.svg";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Upload from "../assets/images/navbar/upload.png";

export default function mentorAddMod() {
  return (
    <div className="bannermain">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12">
             
              <form method="POST">
                <div className="row">
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    First Name
                      <span className="text-danger"> *</span>
                     
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter first Name"
                
                      
                    ></input>
                  </div>
                  
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Last Name  <span className="text-danger"> *</span>
                      
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter last name"
                      
                    ></input>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                   Country
                      <span className="text-danger"> *</span> 
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Select</option>
                      <option value="Nashik">America</option>
                      <option value="Pune">India</option>
                      
                    </select>
                  </div>
                  <div className="col-md-6 pt-4">
                  <label className="pb-2 label-txt">
                    Phone Number
                      <span className="text-danger"> *</span> 
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                    
                      placeholder="Enter Phone number"
                     
                    ></input>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Email<span className="text-danger"> *</span> 
                     
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      
                    ></input>
                  </div>
                 
                </div>
                
               

                <div className="justify-content-center d-flex py-4">
                      <Stack direction="row" spacing={2}>
                        {/* <Button variant="contained">Previous</Button> */}
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow"
                        >
                          Save as Draft
                        </Button>
                      </Stack>
                    </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  )
}
