import React from 'react'
import AIFBanner from "../../assets/images/EcoSystem/Idbicapital/AIFBanner.jpg"
import MITDFLogo from "../../assets/images/EcoSystem/Idbicapital/MITDFLogo.png"
import MahaInvatio from "../../assets/images/EcoSystem/Idbicapital/MahaInvatio.jpg"

import Maha2inve from "../../assets/images/EcoSystem/Idbicapital/Maha2inve.jpg"

function IdbicapitalBanner() {
  return (
    <>
    <div className='mtdfMain'>
         <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
               <div className="carousel-item active">
                      <img 
                        src={AIFBanner} 
                        className="img-fluid"
                        style={{height:'500px'}}
                      />
                  <div className="carousel-caption d-md-block">
                   <div className='container'>
                       <div className='row'>
                       <div className='col-lg-8 col-md-8 col-sm-8'>
                       <img 
                               className="webMHicon mobMHicon"
                                src={MITDFLogo} 
                              />
                               <h4 className='d-flex pt-3 font-24-31 font-18-23 '>Maharashtra Innovation and Technological Development Fund</h4>
                            <h4 className='d-flex pt-2 font-24-31 font-18-23 '>Catalyzing Innovation and Entrepreneurship</h4>
                      
                            <div className='d-lg-none d-sm-block'>
                            <h4 className='font-24-31 font-18-23'>Major Contributor</h4>
                        <div className='row pt-2'>
                           <div className='col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center'>
                           <a href="/" className='cursor-pointer'>
                           <img 
                               style={{width:'100px', height:'50px'}}
                               className="d-flex"
                                src={MahaInvatio} 
                              />
                              </a>
                           </div>
                           <div className='col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center pt-2'>
                            <a href="http://www.mahaswayam.gov.in/" className='cursor-pointer' target="_blank" >
                           <img 
                              style={{width:'100px', height:'50px'}}
                               className="d-flex"
                                src={Maha2inve} 
                              />
                              </a>
                            </div>
                        </div>
                            </div>
                      
                      
                       </div>
                       <div className='d-lg-block d-none col-lg-4 col-md-4 col-sm-4 pt-5 position-absolute bottom-10 end-0'>
                        <h4 className='font-24-31 font-18-23'>Major Contributor</h4>
                        <div className='row pt-3'>
                           <div className='col-lg-6 col-md-6 col-sm-6 d-flex justify-content-end '>
                           <a href="/" className='cursor-pointer'>
                           <img 
                               style={{width:'163px', height:'56px', marginRight:'10px'}}
                               className="d-flex"
                                src={MahaInvatio} 
                              />
                              </a>
                           </div>
                           <div className='col-lg-6 col-md-6 col-sm-6 '>
                            <a href="http://www.mahaswayam.gov.in/" className='cursor-pointer' target="_blank" >
                           <img 
                              style={{width:'163px', height:'56px'}}
                               className="d-flex"
                                src={Maha2inve} 
                              />
                              </a>
                            </div>
                        </div>
                        </div>
                       </div>
                   </div>
                  </div>
               </div>
               
           </div>
         
</div>
    </div>
    </>
  )
}

export default IdbicapitalBanner
