import React from 'react'
// import Navbar from '../../component/Navbar';
import Header from '../../component/Header';
import MediaBanner from './MediaBanner';
import MediaList from './MediaList';
import Footer from '../../component/footer';

 function Media() {
  return (
      <>
      {/* <Navbar /> */}
      <Header/>
      <MediaBanner/>
      <MediaList/>
      <Footer/>
    </>
    
    
  )
}
export default Media;

