import React from 'react'

function ViewPaymentMod() {
  return (
    <div className="container">
                <div className="row ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <label className="pb-2 label-txt fs-4 justify-content-center d-flex">
                            Payment Details{" "}
                        </label>
                        <h4 className="text-danger mt-3 fw-bold">Note:</h4>
                        <p className='fs-5'>Post submitting your application you have to pay to the process partner
                            for further processing. Below are the account details.
                        </p>
                        </div>
                        </div>
                        <div className="row ">
                    <div className="col-lg-3 col-md-3 col-sm-3">
                    <span className="fw-bold fs-5">Amount</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                    <p className='fs-5'>:Data value</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                    <span className="fw-bold fs-5">Account Name</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                    <p className='fs-5'>:Data value</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                    <span className="fw-bold fs-5">Bank Name</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                    <p className='fs-5'>:Data value</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                    <span className="fw-bold fs-5">Current A/C No.</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                    <p className='fs-5'>:Data value</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                    <span className="fw-bold fs-5">Branch Address</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                    <p className='fs-5'>:Data value</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                    <span className="fw-bold fs-5">IFSC Code</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                    <p className='fs-5'>:Data value</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                    <span className="fw-bold fs-5">MICR Code</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                    <p className='fs-5'>:Data value</p>
                    </div>
                        </div>
            </div>
  )
}

export default ViewPaymentMod