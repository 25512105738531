import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";

import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const AnnouncementAddM = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitAnnoucementForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("title",values.title)
    formData.append("name",values.name)
    formData.append("link",values.link)
    formData.append("announcebackground",values.announcebackground)
    formData.append("textColor",values.textColor)
    formData.append("image",values.image)
    console.log(formData)
    function fetchData(){
      fetch(serverUrl + "/annoucements/allAnnounce")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    setUsers(data);
  },
)
}
    fetch(serverUrl +"/annoucements/addAnnounce", 
         {
            method: "POST",
            body: formData,
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then((res) => setModalIsOpenToFalse3())
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
          
  }
  console.log("called")

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
            onSubmit={(values, {resetForm}
            ) =>
           { console.log(values )
            handleSubmitAnnoucementForm(values)
            resetForm({values:''}) 
          }
          }

    
    validationSchema={Yup.object({
    title: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Title Name is Required'),
    name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Name is Required'),
    link: Yup.string().required('Enter Link is Required'),
    announcebackground: Yup.string().required('Enter Annoucement Background color is Required'),
    textColor: Yup.string().required('Enter Annoucement Text color  is Required'),
    image: Yup.string().required('Image  is Required'),
       })}
      initialValues={{ 
      title: '',
      name: '',
      link: '',
      announcebackground:'',
      textColor: '',
      image:'',
       }}

      render={({ values, setFieldValue ,handleSubmit}) => (
        <Form onSubmit={(e)=>{
          
          handleSubmit(e)}}>
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Name" className="textInput" name="name" inputProps={{pattern: "[a-z]"}} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Title Name" className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Website URL <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div>

                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("image", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Text Color <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="color" placeholder="Enter Text Color" className="textInput" name="textColor" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="textColor" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Background Color <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="color" placeholder="Enter Announcement Background" className="textInput" name="announcebackground" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="announcebackground" /><br></br>
                    </div>
                  </div>    
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default AnnouncementAddM;
