import React, { Component, useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Ms_logo from '../assets/images/footer/logo.png';
import Twitter from '../assets/images/footer/twitter.png';
import Facebook from '../assets/images/footer/facebook.png';
import Youtube from '../assets/images/footer/ytube.png';
import Linkedin from '../assets/images/footer/linkedin.png';
import Registration from '../assets/images/footer/MSInS_Startup_Registration.pdf'

import { environment } from '../api';

export default function Footer() {
  const serverUrl = environment.baseUrl;
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  let handleSubmit = async e => {
    e.preventDefault();
    try {
      console.log(email);
      let res = await fetch(serverUrl +"/newsLetter/addnews", {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          "Content-Type": "application/json",
          accept: "application/json"
        }
      });
      const answer = window.confirm("are you sure?");
      if (answer) {
        // Save it!
        console.log("Thing was saved to the database.");
        alert("Email Send Successfully");
        
      } else {
        // Do nothing!
        alert("Please fill out all fields");
        console.log("Thing was not saved to the database.");
      }

      // if (res.status === 200) {
      //   setEmail("");
      //   setMessage("User created successfully");
      // }
      //  else {
      // setMessage("Some error occured");
      // }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <footer className="footer-section pt-1">
        <div className="container">
          <div className="footer-cta pt-3 pl-5 ">
            <div className="row pl-3">
              <div className="footer-logo col-md-5">
                <a href="/">
                  <img src={Ms_logo} className="img-fluid" alt="logo" />
                </a>
              </div>
              <div className="footer-logo col-md-7"></div>
            </div>
          </div>
          <div className="footer-content pt-1 pb-1 pl-5">
            <div className="row">
              <div className="col-xl-5 col-lg-4 mb-50">
                <div className="footer-widget mob-hide">
                  <div className="footer-widget-heading-1 mb-1">
                    <h3 className="footerTitle font-34-44 font-20-26">
                      Navigation
                    </h3>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-8 pl-0">
                    <ul className="mobolinkList mb-0">
                      <li className="bulletlist">
                        <a href="/about" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">About</span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Initiative" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                            Initiative
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/EcoSystem" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                            Ecosystem
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Resources" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                            Resources
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Contact" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                            Write to us
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-8  li-1 ">
                    <ul className="mobolinkList ">
                      <li className="bulletlist">
                        <a href="/Mentor" className="arrow-on-hover-1">
                          <span className="font-20-26 font-14-18">Mentor</span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Incubator" className="arrow-on-hover-1">
                          <span className="font-20-26 font-14-18">
                            Incubators
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/media" className="arrow-on-hover-1">
                          <span className="font-20-26 font-14-18">Media</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-widget web-hide ">
                <div className="footer-widget-heading-1 mb-1">
                    <h3 className="footerTitle font-34-44 font-20-26">
                      Navigation
                    </h3>
                  </div>
                  <div className="pl-0 mb-3">
                    <ul className="mobolinkList mb-0">
                      <li className="bulletlist">
                        <a href="/about" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">About</span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Initiative" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                            Initiative
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Mentor" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">Mentor</span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/EcoSystem" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                            Ecosystem
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Resources" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                            Resources
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/Contact" className="arrow-on-hover">
                          <span className="font-20-26 font-14-18">
                          Incubators
                          </span>
                        </a>
                      </li>
                      <li className="bulletlist">
                        <a href="/media" className="arrow-on-hover-1">
                          <span className="font-20-26 font-14-18">Media</span>
                        </a>
                      </li>
                      <li style={{width:'104%'}} className="bulletlist">
                        <a href="/Incubator" className="arrow-on-hover-1">
                          <span className="font-20-26 font-14-18">
                            Write to us
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget-1">
                  <div className="footer-widget-heading-1 mb-1">
                    <h3 className="footerTitle font-34-44 font-20-26">Links</h3>
                  </div>
                  <ul className="mobolinkList1">
                  <li className="bulletlist">
                      <Link to="/Resources" className="arrow-on-hover" target="_blanc">
                        <span className="und font-20-26 font-14-18" >
                          Guidelines
                        </span>
                      </Link>
                    </li>
                    <li className="bulletlist">
                      <Link to={Registration} className="arrow-on-hover" target="_blanc">
                        <span className="und font-20-26 font-14-18" >
                          Registration guide
                        </span>
                      </Link>
                    </li>
                    <li className="bulletlist">
                      <Link to="/securitypolicies" className="arrow-on-hover">
                        <span className="und font-20-26 font-14-18">
                          Security Policies
                        </span>
                      </Link>
                    </li>
                    <li className="bulletlist">
                      <Link to="/Terms" className="arrow-on-hover">
                        <span className="und font-20-26 font-14-18">Terms</span>
                      </Link>
                    </li>
                    {/* <li className="bulletlist">
                      <Link to="/accessibility_help" className="arrow-on-hover">
                        <span className="und font-20-26 font-14-18">
                          Accesibility help
                        </span>
                      </Link>
                    </li> */}
                    <li className="bulletlist">
                      <Link to="/Disclaimer" className="arrow-on-hover">
                        <span className="und font-20-26 font-14-18">
                          Disclaimer
                        </span>
                        
                      </Link>
                    </li>
                    <li className="bulletlist">
                      <Link to="/Careers" className="arrow-on-hover">
                        <span className="und font-20-26 font-14-18">
                          Careers
                        </span>
                        
                      </Link>
                    </li>
                    <li className="bulletlist">
                      <Link to="/TenderNotification" className="arrow-on-hover">
                        <span className="und font-20-26 font-14-18">
                          Tender Notifications
                        </span>
                        
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget-1">
                  <div className="footer-widget-heading-1 mb-1">
                    <h3 className="footerTitle font-34-44 font-20-26">
                      Social
                    </h3>
                  </div>
                  <ul className="moboListIcon bulletlist">
                    <li className="bulletlist">
                      <a
                        href="https://twitter.com/MSInSociety"
                        className="arrow-on-hover-2 "
                        target="_blank"
                      >
                        <img
                          className="social-icon-1"
                          src={Twitter}
                          alt="Twitter"
                        />
                        <span className="und font-20-26 font-14-18">
                          Twitter
                        </span>
                      </a>
                    </li>
                    <li className="bulletlist">
                      <a
                        href="https://www.facebook.com/MSInSociety"
                        className="arrow-on-hover-2 "
                        target="_blank"
                      >
                        <img
                          className="social-icon-1"
                          src={Facebook}
                          alt="Facebook"
                        />
                        <span className="und font-20-26  font-14-18">
                          Facebook
                        </span>
                      </a>
                    </li>
                    <li className="bulletlist">
                      <a
                        href="https://www.linkedin.com/company/msins/?originalSubdomain=in"
                        className="arrow-on-hover-2 "
                        target="_blank"
                      >
                        <img
                          className="social-icon-1"
                          src={Linkedin}
                          alt="Linkedin"
                        />
                        <span className="und font-20-26 font-14-18">
                          Linkedin
                        </span>
                      </a>
                    </li>
                    <li className="bulletlist">
                      <a
                        href="https://www.youtube.com/channel/UCocqBhWb0xKDrm8FRfhaF6w"
                        className="arrow-on-hover-2 "
                        target="_blank"
                      >
                        <img
                          className="social-icon-1"
                          src={Youtube}
                          alt="Youtube"
                        />
                        <span className="und font-20-26 font-14-18">
                          Youtube
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="footer-social-icon pl-5">
                    {/* <br />
                    <br /> */}
                    <h5 className="footer_contacttxt">Contact us at</h5>
                    <p className="footer_contacttxt mob-hide">
                      Email:
                      <a style={{ color: '#71B2FF' }} href="/">
                        {' '}
                        team@msins.in
                      </a>
                      &nbsp;&nbsp; Phone:
                      <a style={{ color: '#71B2FF' }} href='tel:+912235543099'>
                        +91 22 3554 3099
                      </a>
                    </p>
                    <p className="footer_contacttxt web-hide">
                    Email:
                      <a style={{ color: '#71B2FF' }} href="/">
                        {' '}
                        team@msins.in
                      </a>
                      <br/> Phone:
                      <a style={{ color: '#71B2FF' }} href='tel:+912235543099'>
                        +91 22 3554 3099
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-2 pt-3">
                <a href="https://www.hitwebcounter.com" target="_blank">
<img src="https://hitwebcounter.com/counter/counter.php?page=8059322&style=0030&nbdigits=5&type=page&initCount=32364" title="Free Counter" Alt="web counter"   border="0" /></a>                                      
       
                  </div>
                <div className="col-md-5 d-flex align-items-center">
                  {/* <div className="footer-widget-heading-1 mb-3">
                    <h3 className="footerTitle title_plex_font font-34-44 font-20-26">
                      Subscribe
                    </h3>
                  </div> */}

                  <form
                    className="form-subscribe"
                    onSubmit={handleSubmit}
                    method="POST"
                  >
                    <div className="input-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control input-lg"
                        placeholder="Your Email Address"
                        // value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-btn">
                        <button
                          className="subbgcolor"
                          type="submit"
                        >
                          Subscribe
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
           
          </div>
          <div className="row ">
            <div className="col-md-12 d-flex justify-content-center font-20-26  font-14-18 footer-text-line">This is beta launch of website and any suggestions are welcome.</div>
           
          </div>
        </div>
      </footer>
    </div>
  )
}
