import React from "react";
import GovernmentResolution from '../../assets/images/Initiative/businessPlanCompetition/241928District Business Plan Competition.pdf'

function businessPlanMiddleContent() {
  
  return (
    <div className="mahaGrand-main intellectual-main">
      <div className="container">
        <div className="row d-flex justify-content-center my-3">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row" id="granchallenge">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                Business Plan Competition
              </h2>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                Maharashtra State Innovative Start-up Policy, 2018 ensures
                organizing various events and competitions throughout the year
                to provide global exposure to the Start-up ecosystem and to
                further deepen the engagement between the Government of
                Maharashtra and the Start-up ecosystem.
              </p>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                The Business Plan Competition in Districts of Maharashtra shall
                be organized annually by the District Innovation Councils (DICs)
                of respective Maharashtra districts. Applications shall be
                invited online through MSInS web portal in various sectors. The
                competition will be divided into 3 categories wherein applicants
                will suggest an innovative solution to an existing problem or
                come up with an innovative product/process or service for the
                growth and betterment of the district.
              </p>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                It also aims to provide a platform to schools, colleges and
                other institutions or individuals to propose innovative business
                ideas to address issues faced by local communities and society
                at large.
              </p>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                <b>
                  Following are the list of Akola District Business Plan
                  Competition Winners
                </b>
              </p>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="justify-content-center d-flex">Sr No.</th>
                    <th scope="col">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="justify-content-center d-flex">
                      1
                    </th>
                    <td>
                      <b>Mr. Akshay Kawale</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="justify-content-center d-flex">
                      2
                    </th>
                    <td>
                      <b>Mr. Rahul Pawar</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="justify-content-center d-flex">
                      3
                    </th>
                    <td>
                      <b>Mr. Devidas Bangad</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="justify-content-center d-flex">
                      4
                    </th>
                    <td>
                      <b>Mr. Utkarsh Jain</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="justify-content-center d-flex">
                      5
                    </th>
                    <td>
                      <b>Mr. Praful Phade</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="justify-content-center d-flex">
                      6
                    </th>
                    <td>
                      <b>Mr. Gajanan Nilkhan</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17 "><a href={GovernmentResolution} target="_blanc" className="underline-remove">Government Resolution</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default businessPlanMiddleContent;
