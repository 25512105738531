import React, { useState, useEffect } from "react";
import axios from "axios";
import Tasks from "./guidTask";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { spacing } from "@material-ui/system";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
// import SearchRes from './search.json';
import FilterIcon from '../assets/images/Resourcesbanner/filterIcon.png';
import { environment } from '../api';
// const useStyles = makeStyles((theme: Theme) =>
// console.log(SearchRes);

const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      background: "#F7F7F7 !important;",
      padding: "10px"
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "center"
    }
  })
);

function Guid() {
  const serverUrl = environment.baseUrl;
 const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  // const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([tasks]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(10);
  const [searchTasks,setSearchTasks]=useState("");
  const [searchGuidline,setSearchGuidline]=useState("")


  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get(serverUrl +
        "/guideline/allGuideline"
      );
      
      // setAllData(res.data);
      setTasks(res.data);
      setFilteredData(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, []);



  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get(serverUrl +
        "/guideline/allGuideline"
      );
      
      // setAllData(res.data);
      // setTasks(res.data);
      setSearchGuidline(res.data)
      // setFilteredData(res.data);
      // setLoading(false);
    };

    fetchPosts();
  }, []);

  // Change page

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


 
  // const handleSearch = (event) => {
  //   let value = event.target.value.toLowerCase();
  //   let result = [];
  //   console.log(value);
  //   result = tasks.filter((data) => {
  //     return data.title.search(value) != -1;
  //   });
  //   setTasks(result)
  //   console.log({ result });
  //   setFilteredData(result);
  // };

  // Get current tasks
  function getCurrentTasks(tasks) {
    const indexOfLastTask = currentPage * tasksPerPage;
    const indexOfFirstTask = indexOfLastTask - tasksPerPage;
    return tasks.slice(indexOfFirstTask, indexOfLastTask);
  }

  const data =Math.ceil(filteredData.length / tasksPerPage);
  console.log(tasks,"tasks");
  return (
    <React.Fragment  >
    
      <div  className="searchResources-main">
     {/*  <Container maxWidth="lg">
        <Box  >
          
          <Grid container spacing={3} className="d-flex justify-content-center"> */}


         
<h2 className='font-28-36 font-18-23 font-20-26 font-weight-600 d-flex justify-content-center pb-2 '>Government Resolutions/Policy Documents </h2>

            <Grid item xs={12} sm={12} style={{display:"flex", alignItems:"center", justifyContent:"center" }}>
            <div style={{ width:"70%", marginTop: "1%", padding:"10px 15px 10px 15px" }}>           
            <input type="text" className="searchplaceholder search-border-remove form-control" placeholder=" &#61442; Search any Government Resolutions/Policy Documents" value={searchTasks} onChange={(event) => setSearchTasks(event.target.value)} />
            
            </div>
           
              {/* <Box className={classes.boxContainer} component="div">
             <p className="filter-center justify-content-between px-1">
                 <span className="d-none d-sm-block">Showing {tasksPerPage} out of {data} results</span>
                 <span className=" d-flex justify-content-end"> <img src={FilterIcon}></img> Filter by: Name</span>
             </p>
              </Box> */}
            </Grid>
            <Grid item xs={12} sm={12}>
                {/* <List>
                  <ListItem>
                    <ListItemText style={{ maxWidth: "25%" }}>
                      <Typography
                        style={{ fontWeight: 600 }}
                        variant="subtitle2"
                        color="primary"
                      >
                        USERID
                      </Typography>
                    </ListItemText>
                    <ListItemText style={{ maxWidth: "50%" }}>
                      <Typography
                        style={{ fontWeight: 600 }}
                        variant="subtitle2"
                        color="primary"
                      >
                        TITLE
                      </Typography>
                    </ListItemText>
                    <ListItemText
                      style={{
                        maxWidth: "25%",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 600 }}
                        variant="subtitle2"
                        color="primary"
                      >
                        COMPLETED
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List> */}
                {/* <Divider style={{ marginLeft: "12px", marginRight: "12px" }} /> */}
                {/* <Tasks tasks={getCurrentTasks(tasks)} loading={loading} /> */}
                <Tasks
                  tasks={getCurrentTasks(filteredData)}
                  loading={loading}
                  searchTasks={searchTasks}
                  searchGuidline={searchGuidline}
                />
                <div className="row justify-content-center">
                <div className="pag-flex mob-pagination-colour pb-2">
                    <div className="col-sm-2 col-lg-2 d-flex justify-content-center">
                      <div className="d-lg-block d-none">
                        <b>
                          Page ({currentPage})({data})
                        </b>
                      </div>
                       
                       <div className="d-lg-none">
                       <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg>
                       </div>

                      
                    </div>
                    <div className="col-sm-8 d-none d-sm-block ">
                      <Pagination
                        className={classes.paginationContainer}
                        onChange={handlePageChange}
                        count={Math.ceil(filteredData.length / tasksPerPage)}
                        page={currentPage}
                        color="primary"



                      />
                    </div>

                    <div className="col-sm-8 d-lg-none d-md-none ">
                      <Pagination
                        //className={classes.paginationContainer}
                        onChange={handlePageChange}
                        count={Math.ceil(filteredData.length / tasksPerPage)}
                        page={currentPage}
                        color="primary"
                      />
                    </div>
                    <div className="col-sm-2 d-flex justify-content-center pag-float-right">
                      <div className="d-lg-block d-none">
                        <b>
                          Next Page ({currentPage + 1})
                        </b>
                      </div>
                      <div  className="d-lg-none">
                         <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path></svg>
                      </div>
                    </div>
                  </div>
                  </div>
            </Grid>
          {/* </Grid>
        </Box>
      </Container> */}
    </div> 
    </React.Fragment>
  );
}

export default Guid;
