import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function IntellectualExternalLink() {
  return (
    <>
      <div
        className=""
        id="idQualityTesting"
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="container con" id="idResourcesIPR">
                <div>
                <h2 className="font-22-26 font-18-23 font-20-26 font-weight-600 pb-2 externallinkcolour pt-2">
                  Apply here with MSInS
                  </h2>
                  <ul>
                    <li  class="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-2"> <a href="https://forms.gle/n2S7w8ib9iYMXPRf6" target="_blank">Domestic Patent</a></li>
                    <li  class="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-2"> <a href="https://forms.gle/mqWFBw2sREKTLnfk6" target="_blank">International Patent</a></li>
                    <li  class="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-2"><a href="https://forms.gle/zqocmHfXGnNUNrSx6" target="_blank">Post Grant Applications</a></li>
                    <li  class="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-2"><a href="https://forms.gle/3kfs145SnUjhnxkk7" target="_blank">Trademark</a> </li>
                    <li  class="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-2"><a href="https://forms.gle/Kdb7JqpKxcEwaDs1A" target="_blank">Design</a> </li>
                    <li  class="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-2"> <a href="https://forms.gle/ve1jooFsBd6hmGHYA" target="_blank">Copyright</a></li>
                  </ul>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntellectualExternalLink;
