import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom"
import axios from 'axios';
import { environment } from '../api';
// import Eventimg from "../assets/images/Initiative/EventDetails/617281MainhomepageStartupweek.png";
import { Button } from "react-bootstrap-v5";
import {FaCalendarDay} from 'react-icons/fa';
import {FaMapMarker} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaTwitterSquare} from 'react-icons/fa';
import { Link } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/footer';
import moment from "moment";

function InitiativeListDetails() {

  const serverUrl = environment.baseUrl;
  const { id } = useParams(); // <-- access id match param here
console.log(id, "@@@@@@@@@@@@@@@")
// const [data, setData] = useState(null);
// useEffect(() => {
//   fetchProduct();
// }, []);
// const fetchProduct = () => {
//   axios
//     .get(serverUrl +`/initiative/allInitiative/${id}`
//     )
//     .then((res) => {
//       setData(res.data);
//       console.log("data",res.data);
//     })
//     .catch((err) => console.log(err));
// };









const [previous, setPrevious] = useState([]);
const [future, setFuturedata] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
      fetch(serverUrl +`/initiative/allInitiative/${id}`)
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  // setprevious(data);
                  // console.log(data[1].prevdata[0].title, "previous dataaaaaaaaa");
                  setPrevious(data[1].prevdata);
                  setFuturedata(data[0].futuredata);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
    }, [])
  return (
    <> 
     <Header/>
     {future.length!=0 ?
     <div className="mahaGrand-main pagepaddingTop">
      {future.length!=0 ?
        <div className="container">
           {future.map(futuredate => (
          <>
          <div className="row d-flex justify-content-center" id="granchallenge">
            <div className="col-md-6 d-flex justify-content-center pt-3">
              <h2>{futuredate.title}</h2>
            </div>
            <div className="col-md-6">
              <div className="graybtn feature-card-button d-flex justify-content-end pt-3">

                <Button className="regular_font font-style-normal font-weight-normal  font-20-24 d-none d-sm-block ">
                  <Link to="/Initiative" className="underline-remove">
                    <span className=" regular_font font-20-24 font-style-normal font-weight-400">
                      Back to Listing 
                    </span>
                    <span className="inno-arrow visit-btn-title">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          <div
            className="row d-flex justify-content-center my-3"
            id="granchallenge"
          >
            <div className="col-lg-7 col-md-7 col-sm-7">
              <img
                src={futuredate.image}
                className="img-fluid img-border"
                alt="Events"
              ></img>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 px-4 pb-4 pt-1">
            <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Date</p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start"><FaCalendarDay/>&nbsp;&nbsp;
              {/* {futuredate.current_date} */}
{moment(futuredate.current_date).utc().format('YYYY-MM-DD')}
              </p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Address</p><FaMapMarker/>
              <p></p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Share</p>
              <p className="font-22-26"> <a href={futuredate.facebookLink} style={{cursor:'pointer'}} target="_blank"><FaFacebookSquare/></a>&nbsp;&nbsp;<a href={futuredate.facebookLink} style={{cursor:'pointer'}} target="_blank"><FaTwitterSquare/></a></p>
              <hr />
              <p className="font-style-normal font-weight-400 font-20-24">{futuredate.title}</p>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 pt-3">
            <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
            {futuredate.description}
              </p>
            </div>
         
            <div className="col-md-5">
              <div className="graybtn feature-card-button d-flex justify-content-end ">
                <Button className="regular_font font-style-normal font-weight-normal  font-20-24 d-none d-sm-block ">
                  {/* <Link
                    to={futuredate.link}
                    className="underline-remove"
                    target="_blank"
                  > */}
                  <a href={futuredate.link} target="_blank" className=''>
                    <span className=" regular_font font-20-24 font-style-normal font-weight-400">
                      Register Now
                    </span>
                    <span className="inno-arrow visit-btn-title">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  {/* </Link> */}
                  </a>
                </Button>
              </div>
            </div>  </div>     
          </>
          ))}
        </div>
        : "loading"
}
      </div>
:
      <div className="mahaGrand-main pagepaddingTop">
      {previous.length!=0 ?
        <div className="container">
           {previous.map(prevdate => (
          <>
          <div className="row d-flex justify-content-center" id="granchallenge">
            <div className="col-md-6 d-flex justify-content-center pt-3">
              <h2>{prevdate.title}</h2>
            </div>
            <div className="col-md-6">
              <div className="graybtn feature-card-button d-flex justify-content-end pt-3">

                <Button className="regular_font font-style-normal font-weight-normal  font-20-24 d-none d-sm-block ">
                  <Link to="/Initiative" className="underline-remove">
                    <span className=" regular_font font-20-24 font-style-normal font-weight-400">
                      Back to Listing 
                    </span>
                    <span className="inno-arrow visit-btn-title">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          <div
            className="row d-flex justify-content-center my-3"
            id="granchallenge"
          >
            <div className="col-lg-7 col-md-7 col-sm-7">
              <img
                src={prevdate.image}
                className="img-fluid img-border"
                alt="Events"
              ></img>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 px-4 pb-4 pt-1">
            <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Date</p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start"><FaCalendarDay/>&nbsp;&nbsp;
              {/* {prevdate.current_date} */}
              {moment(prevdate.current_date).utc().format('YYYY-MM-DD')}
              </p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Address</p><FaMapMarker/>
              <p></p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Share</p>
              <p className="font-22-26"> <a href={prevdate.facebookLink} style={{cursor:'pointer'}} target="_blank"><FaFacebookSquare/></a>&nbsp;&nbsp;<a href={prevdate.facebookLink} style={{cursor:'pointer'}} target="_blank"><FaTwitterSquare/></a></p>
              <hr />
              <p className="font-style-normal font-weight-400 font-20-24">{prevdate.title}</p>
              
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-md-7 pt-3">
            <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
            {prevdate.description}
              </p>
            </div>
            {/* <div className="col-md-5">
              <div className="graybtn feature-card-button d-flex justify-content-end ">
                <Button className="regular_font font-style-normal font-weight-normal  font-20-24 d-none d-sm-block ">
                  <Link
                    to={prevdate.link}
                    className="underline-remove"
                    target="_blank"
                  >
                    <span className=" regular_font font-20-24 font-style-normal font-weight-400">
                      Register Now
                    </span>
                    <span className="inno-arrow visit-btn-title">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                </Button>
              </div>
            </div> */}
          </div>         
          </>
          ))}
        </div>
        : "loading"
}
      </div>
}
      <Footer />
    </>
  );
}

export default InitiativeListDetails;




