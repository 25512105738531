import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  

export default function KeyInitiativeViewM(props) {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Title :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <div className="">
              <span>{props.rowData.keyInitiative.title}</span>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Description :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.keyInitiative.description}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Link :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.keyInitiative.link}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Color :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.keyInitiative.textColor}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b>Background Color :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.keyInitiative.cardBackground}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
        <div className="col-md-2">
          <div className="">
            <label>
              {' '}
              <b> Logo :</b>
            </label>
          </div>
        </div>
        <div className="col-md-10">
            <img src={props.rowData.keyInitiative.image} style={{width:'20%', height:'100%'}} />
          </div>
      </div>
      
        
      </TabPanel>
    </Box>
  )
}
