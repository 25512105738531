import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const serverUrl = environment.baseUrl;
const TeamDetails = (props) => {
  const authToken =sessionStorage.getItem("token");
  let initialFormObj={ 
    team_details: [{
      name_of_team_member:'',
      designation_team_member:'',
      relevant_work_exp:'',
      qualification:''
      }],

      founder_details: [{
        name_of_founder:'',
        designation_founder:'',
        contact_no:'',
        email_ID:'',
        linkdin_url:''

      }]
   }

  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name_of_team_member",values.name_of_team_member)
    formData.append("designation_team_member",values.designation_team_member)
    formData.append("relevant_work_exp",values.relevant_work_exp)
    formData.append("relevant_work_exp",values.relevant_work_exp)
    formData.append("qualification",values.qualification)
    formData.append("name_of_founder",values.name_of_founder)
    formData.append("designation_founder",values.designation_founder)
    formData.append("contact_no",values.contact_no)
    formData.append("email_ID",values.email_ID)
    formData.append("linkdin_url",values.linkdin_url)
    fetch(serverUrl +"/team/addteamDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            // body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    <Formik
      onSubmit={(values) =>
      handleSubmitFinacialForm(values)
    }
    validationSchema={Yup.object({
       
        team_details: Yup.array().of(
          Yup.object().shape({
            name_of_team_member: Yup.string().required('Name of Team Member is required'),
            designation_team_member: Yup.string().required('Designation Team Member is required'),
            relevant_work_exp: Yup.string().required('Elevant Work Experience is required'),
            qualification: Yup.string().required('Qualification is required')
              
          })
        ),

        founder_details: Yup.array().of(
          Yup.object().shape({
            name_of_founder: Yup.string().required('Name of Team Founder is required'),
            designation_founder: Yup.string().required('Designation Founder Team Member is required'),
            contact_no: Yup.string().required('Contact Number is required'),
            email_ID: Yup.string().required('Email ID is required'),
            linkdin_url: Yup.string().required('Linkdin URL is required')
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('teamDetails'))||initialFormObj} 

      render={({ values }) => (
        <Form >
          <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
              Team Details
            </h2>
        </div>
          <FieldArray
            name="team_details"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>
            <TableContainer component={Paper} className="no-shadow">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="head-bg-color">
                  <TableRow className="d-flex justify-content-between ">
                    <TableCell className="table-txt align-items-center"  style={{width:"20%"}}>Name</TableCell>
                    <TableCell className="table-txt align-items-center"  style={{width:"20%"}} align="left">Designation</TableCell>
                    <TableCell className="table-txt align-items-center"  style={{width:"30%"}} align="left">Relevant Work Experience</TableCell>
                    <TableCell className="table-txt align-items-center "  style={{width:"20%"}} align="left">Qualification</TableCell>   
                    <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action</TableCell>            
                  </TableRow>
                </TableHead>
                {values.team_details && values.team_details.length > 0 ? (
                  values.team_details.map((friend, index) => (
              <TableBody  className="data-bg-color d-flex justify-content-between">
                 <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
                    <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"20%"}}>
                        <Field type="text" className="txt-color" name={`team_details.${index}.name_of_team_member`} />
                          <div className="text-danger">
                          <ErrorMessage className="error" name={`team_details.${index}.name_of_team_member`} /><br></br>
                          </div>
                    </TableCell>
                    <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                                <Field type="text" className="txt-color" name={`team_details.${index}.designation_team_member`} />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name={`team_details.${index}.designation_team_member`} /><br></br>
                              </div>
                    </TableCell>

                    <TableCell align="center" className="d-flex align-items-center" style={{width:"30%"}}>
                           <Field type="text" className="txt-color" name={`team_details.${index}.relevant_work_exp`} />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name={`team_details.${index}.relevant_work_exp`} /><br></br>
                              </div>
                  </TableCell>
                  <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                           <Field type="text" className="txt-color" name={`team_details.${index}.qualification`} />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name={`team_details.${index}.qualification`} /><br></br>
                              </div>
                  </TableCell>
                    <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                      <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                      >
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                        <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </span>
                    </TableCell>  
              </TableRow>
              </TableBody> 
               
                  ))
                ) : (
                 <></>
                )}
                 
                </Table>
      </TableContainer>
               
              </div>
            )}
          />

          {/* --------------------------founder---------------------------- */}

           <FieldArray
            name="founder_details"
            render={(arrayHelpers) => (
              <div className="mt-5">
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>
            <TableContainer component={Paper} className="no-shadow">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="head-bg-color">
                  <TableRow className="d-flex justify-content-between ">
                    <TableCell className="table-txt align-items-center"  style={{width:"10%"}}>Name</TableCell>
                    <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Designation</TableCell>
                    <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Contact No</TableCell>
                    <TableCell className="table-txt align-items-center "  style={{width:"20%"}} align="left">Email ID</TableCell>  
                    <TableCell className="table-txt align-items-center "  style={{width:"30%"}} align="left">Linkedin Profile Link</TableCell>    
                    <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action</TableCell>            
                  </TableRow>
                </TableHead>
                {values.founder_details && values.founder_details.length > 0 ? (
                  values.founder_details.map((friend, index) => (
              <TableBody  className="data-bg-color d-flex justify-content-between">
                 <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
                    <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"10%"}}>
                        <Field type="text" className="txt-color" name={`founder_details.${index}.name_of_founder`} />
                          <div className="text-danger">
                          <ErrorMessage className="error" name={`founder_details.${index}.name_of_founder`} /><br></br>
                          </div>
                    </TableCell>
                    <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                                <Field type="text" className="txt-color" name={`founder_details.${index}.designation_founder`} />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name={`founder_details.${index}.designation_founder`} /><br></br>
                              </div>
                    </TableCell>

                    <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                           <Field type="text" className="txt-color" name={`founder_details.${index}.contact_no`} />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name={`founder_details.${index}.contact_no`} /><br></br>
                              </div>
                  </TableCell>
                  <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                           <Field type="text" className="txt-color" name={`founder_details.${index}.email_ID`} />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name={`founder_details.${index}.email_ID`} /><br></br>
                              </div>
                  </TableCell>
                  <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                           <Field type="text" className="txt-color" name={`founder_details.${index}.linkdin_url`} />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name={`founder_details.${index}.linkdin_url`} /><br></br>
                              </div>
                  </TableCell>
                    <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                      <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                      >
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                        <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </span>
                    </TableCell>  
              </TableRow>
              </TableBody>  
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("teamDetails", JSON.stringify(values))}}  
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>
               
              </div>
            )}
          /> 
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default TeamDetails;
