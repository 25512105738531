import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const FeaturedVideosUpdateM = (props, {setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
// console.log(props)

  function handleSubmitFeaturedVideosUpdateForm(values){
    
    const formData = new FormData();
    formData.append("title",values.title)
    formData.append("video",values.video)
    function fetchData(){
      fetch(serverUrl + "/video/allvideo")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    fetch(serverUrl +`/video/${props.rowData.featureVideo._id}`,
         {
          method: 'PUT',
            headers: {
              "Content-Type": "application/json",
              accept: "application/json"
            },
            body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitFeaturedVideosUpdateForm(values)
    }
    validationSchema={Yup.object({
      title: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Title Name is Required'),
      video: Yup.string().required('Video  is Required'),
       })}
      initialValues={{ 
      title:props.rowData.featureVideo.title,
      video: props.rowData.featureVideo.video,
       }}

      

      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Title Name" className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>                  
                  <div className="col-lg-6 col-md-6 p-2">
                  {/* <label className="pb-2 label-txt">
                  video <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img src={values.video} style={{width:'60%', height:'50%'}} /><br></br>

                        <input id="file" name="video"  accept=".mov,.mp4"
                     type="file" 
                    
                     onChange={(e) => {
                      console.log(e.target.files[0])
                         setFieldValue("video", e.target.files[0]);
                         handleChange();
                      }} 

                      />      */}
                 <label className="pb-2 label-txt">
                  video <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                      <Field type="text"   onChange={handleChange} placeholder="Enter Video" accept=".mov,.mp4" className="textInput" name="video" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="video" /><br></br>
                    </div> 

                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default FeaturedVideosUpdateM;
