import { useEffect, useState } from "react";
import axios from "axios";
import { useRef} from "react";
import { BsFiles } from "react-icons/bs";
import WinnersTable from './winnersList'

// import AllwinnerList from './startupWeekWinner'
// import Table from "./Table/index";
// import Sort from "../../Resources/searchpag/Sort";
import Genre from "./Genre/index";
import Pagination from "../../searchpagination/Pagination/index";
// import Search from "../../Resources/searchpag/Search/winnerSearch";
// import DropdownSearch from "../../Resources/searchpag/Search/mentorSearch"
// import "./sPage.scss";
import { environment } from '../../api';
import Grid from "@material-ui/core/Grid";

function StartupWinnerListProfile() {
    const serverUrl = environment.baseUrl;
    
    const [obj, setObj] = useState({});
	// const [sort, setSort] = useState({ sort: "rating", order: "desc" });
	const [filterGenre, setFilterGenre] = useState([]);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
    const [city, setCity] = useState("");
    const [category, setCategory] = useState("");
    const [yearget, setYear] = useState("");

	useEffect(() => {
        
		const getAllMovies = async () => {
			try {
				const url = `${serverUrl +"/startupWinner/allStartupWinnerList"}?page=${page},&title=${search}&city=${city}&category=${category}&year=${yearget}`;
				const { data } = await axios.get(url);
                console.log(data, "dtrsddsdfshtgxcvdgfx");
				setObj(data);
			} catch (err) {
				console.log(err);
			}
		};

		getAllMovies();
	}, [ page, search, category, city, yearget]);
    
  return (
    <>
    <div className="wrapper" style={{backgroundColor:'#fff'}}>
        <h2 className="d-flex justify-content-center title_plex_font font-28-36 font-20-26 topPadding pb-4">
          <b>Winner Announcement</b>
        </h2>
    <div className="container">
        <div className="row d-flex">
        <div className="col-lg-12 col-md-12 col-sm-12">
        <Genre
                    filterGenre={filterGenre}
                    categorys={obj.categorys ? obj.categorys : []}
                    setFilterGenre={(category) => setFilterGenre(category)}
                    setSearch={(search) => setSearch(search)}  
                    setCity={(city) => setCity(city)}
                    setCategory={(category) => setCategory(category)}
                    setYear={(yearget) => setYear(yearget)}
                />
            </div>
         
        </div>
        <div className="body">
            
            {/* <div className="filter_container">
               
                <Sort sort={sort} setSort={(sort) => setSort(sort)} />
            </div> */}
            <div className="table_container">
                <WinnersTable winnerList={obj.winnerList ? obj.winnerList : []} />
                <Pagination
                    page={page}
                    limit={obj.limit ? obj.limit : 0}
                    total={obj.total ? obj.total : 0}
                    setPage={(page) => setPage(page)}
                />
            </div>
        </div>
    </div>
</div>

</>

  )
}

export default StartupWinnerListProfile
