import React from 'react';
import Admin from './dashboard';
// import Innovation from './Innovation';
// import FeatureEvent from './feature_event';
// import Ecosystem from './Ecosystem';
// import OurPartner from './Our_partner';
// import Announcements from './announcements'
import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from '../component/HeaderAdmin';
import AdminLogin from '../superadmin/superadminlogin';
// import React, { useState } from "react";
function Superadmin() {
  return (
    <>
    {/* <Header/> */}
    <AdminLogin/>
    {/* <Admin /> */}
    {/* <Adminspt /> */}
    </>
  );
}

export default Superadmin;