import React, { useEffect,useState } from 'react'
import "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
// import MentorViewModel from "../mentorViewMod";
// import MentorAddModel from "../mentorAddMod";
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
// import MentorApprovalModel from '../mentorApprovalModel'
import { environment } from '../api';
function Viewdesign() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true);
  };

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false);
  };

  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true);
  };

  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false);
  };

  // const serverUrl = environment.baseUrl;
  // const [rows, setUsers] = useState([]);
  // const [error, setError] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  // useEffect(() => {
  //     fetch(serverUrl +"/mentor/allmentorDetail")
  //         .then(res => res.json())
  //         .then(
  //             (data) => {
  //                 setIsLoaded(true);
  //                 setUsers(data);
  //             },
  //             (error) => {
  //                 setIsLoaded(true);
  //                 setError(error);
  //             }
  //         )
  //   }, [])

    const [showData,setShowData] = useState('');
    const serverUrl = environment.baseUrl;
    const [rows, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [rowId, setrowId] = useState('');
    const [rowData, setRowData] = useState({
      
      basicDetails: []
     
    })
    useEffect(() => {
        fetch(
          // serverUrl +"/mentorDetail/allmentorDetail"
          )
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setUsers(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
            
      }, [])
  
      useEffect(()=>{
        rows.map((el)=>{
          if(el._id === rowId)
            setRowData({...rowData, basicDetails: el})
  
        })
        
      },[rowId])
  
      
  const columns = [
    { field: "_id", headerName: "Form ID", width: 260 },
    { field: "Name_of_Startup", headerName: "Name of Startup", width: 260 },
    { field: "Name_Of_Applicant", headerName: "Name Of Applicant", width: 260 },
    { field: "email", headerName: "Email ID", width: 300 },
    { field: "phone_number", headerName: "Mobile No.", width: 150 },
    // { field: "company_name", headerName: "Company", width: 150 },
    // { field: "job_title", headerName: "Job Title", width: 150 },
    // { field: "status", headerName: "Status	", width: 110 },
    // {
    //   field: "Action",
    //   headerName: "Action",
    //   sortable: false,
    //   width: 200,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking

    //       const api = params.api;
    //       const thisRow = {};

    //       api
    //         .getAllColumns()
    //         .filter((c) => c.field !== "__check__" && !!c)
    //         .forEach(
    //           (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
    //         );

    //       return alert(JSON.stringify(thisRow, null, 4));
    //     };
    //     return (
    //       <>
    //         <Button onClick={setModalIsOpenToTrue}>
    //           <VisibilityIcon />
    //         </Button>
    //         {/* <Button onClick={onClick}>
    //   <BallotIcon />
    // </Button> */}
    //         <Button onClick={setModalIsOpenToTrue2}>
    //   <ThumbUpIcon />
    // </Button>
    //         {/* <Button onClick={onClick}>
    //   <ThumbDownIcon />
    // </Button> */}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Design 
          </h2>
          <div style={{ height: "75vh", width: "100%" }}>
            
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
               pageSize={8}
               onSelectionModelChange={(newSelection)=>{
                setrowId(newSelection[0])
               }}
               components={{ Toolbar: GridToolbar
                 }}
              getRowId ={(rows) => rows._id} 
                          />
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
          <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Mentor</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            {/* <MentorViewModel rowData = {rowData}/> */}
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen1} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Add Mentor</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse1}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            {/* <MentorAddModel /> */}
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen2} className="ModOverlay">
          <div className="viewModMentor">
          <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Form Approvals</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse2}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            {/* <MentorApprovalModel /> */}
          </div>
        </Modal>
        
      </div>
    </>
  );
}

export default Viewdesign;