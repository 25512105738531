import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from "../api";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InformationIcon from "../startup/informationIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddMember from "../assets/images/Incubator/Add.svg";
const serverUrl = environment.baseUrl;

const TrademarkTab2 =(props)=> {
  let initialFormObj = {
    name: "",
    Upload_image: "",
    description:"",
    Date:"",
    Document_reason:"",
    description_query:"",
    Query_Date:"",
    Upload_doc:"",
  };
  const [flag, setFlag] = useState("no")
  const btnClick = (e) => {
   setFlag(e.target.value);
  };
  function handleSubmitForm(values) {
    console.log(values, "hii");
    const formData = new FormData();
    formData.append("do_you_have_IP", values.do_you_have_IP);
    
    fetch(serverUrl + "/ip/addIpDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values, null, 2),
    })
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
      });
    props.setValue(props.value + 1);
  }
  return (
    <div className="container">
    <div className="row ">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Formik
          onSubmit={(values) => handleSubmitForm(values)}
          validationSchema={Yup.object({
            name: Yup.string().required("Company name is required"),
            // do_you_have_IP: Yup.string().required(
            //   "Please Select Is your company registered in Maharashtra?"
            // ),
            // Company_Website:Yup.string().required('Company Website URL is required'),
            Upload_image: Yup.string().required(
              "Please Upload Proof of being incorporated in Maharashtra."
            ),
            description: Yup.string().required(
              "Please provide short brief about the company"
            ),
            Date: Yup.string().required(
              "Please provide short brief about the company"
            ),
            Document_reason: Yup.string().required(
              "Please provide short brief about the company"
            ),
            description_query: Yup.string().required(
              "Please provide short brief about the company"
            ),
            Query_Date: Yup.string().required(
              "Please provide short brief about the company"
            ),
            Upload_doc: Yup.string().required(
              "Please provide short brief about the company"
            ),
          })}
          initialValues={
            JSON.parse(localStorage.getItem("teamDetails")) ||
            initialFormObj
          }
          render={({ values, setFieldValue }) => (
            <Form>
              <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        Relevant images as trademark is a logo mark
                        </h2>
                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                       Name <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter Name"
                          className="textInput"
                          name="name"
                        />
                        <div className="text-danger">
                          <ErrorMessage className="error" name="name" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Upload Image
                            <span className="text-danger">*</span>{" "}
                            <InformationIcon />
                          </label>
                          <Field
                            type="file"
                            placeholder="Upload image"
                            className="textInput"
                            name="Upload_image"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Upload_image"
                            />
                          </div>
                    </div>
                    </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        Empanelled Action 
                        </h2>
                  </div>
                  <div className="row pb-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                        Description <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter Description"
                          className="textInput"
                          name="description"
                        />
                        <div className="text-danger">
                          <ErrorMessage className="error" name="description" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Date
                            <span className="text-danger">*</span>
                            <InformationIcon />
                          </label>
                          <Field
                            type="date"
                            placeholder="Select Date"
                            className="textInput"
                            name="Date"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Date"
                            />
                          </div>
                    </div>
                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Upload Document for Reasons
                            <span className="text-danger">*</span>{" "}
                            <InformationIcon />
                          </label>
                          <Field
                            type="file"
                            placeholder="Upload File"
                            className="textInput"
                            name="Document_reason"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Document_reason"
                            />
                          </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        User Reply to Need more information
                        </h2>
                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                      <label className="pb-2 label-txt">
                      Description <span className="text-danger">*</span>{" "}
                        <InformationIcon />
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Description"
                        className="textInput"
                        name="description_query"
                      />
                      <div className="text-danger">
                        <ErrorMessage className="error" name="description_query" />
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                        Date
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          type="date"
                          placeholder="Enter Registered Address"
                          className="textInput"
                          name="Query_Date"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="Query_Date"
                          />
                        </div>
                  </div>

                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Upload Document
                            <span className="text-danger">*</span>{" "}
                            <InformationIcon />
                          </label>
                          <Field
                            type="file"
                            placeholder="Upload File"
                            className="textInput"
                            name="Upload_doc"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Upload_doc"
                            />
                          </div>
                    </div>
                  </div>
              <div className="justify-content-center d-flex pt-4">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-draft no-shadow"
                    onClick={() => {
                      localStorage.setItem(
                        "ipDetails",
                        JSON.stringify(values)
                      );
                    }}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Save &amp; Next
                  </Button>
                </Stack>
              </div>
          
            </Form>
          )}
        />
      </div>
    </div>
  </div> 
  )
}

export default TrademarkTab2