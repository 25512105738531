import React, { useState, useEffect }  from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { environment } from '../../api';

function IntellectualPRFaq() {

  const serverUrl = environment.baseUrl;
  const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(serverUrl +"/Intel/allIntellectualProperty")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setUsers(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  
  return (
    <>
     
    <div className='FAQ-main bg-color-white faq-intellectual' id="idResourcesIPR">
   <div className="container">
           <div className="row d-flex justify-content-center my-3">
               <div className="offset-lg-3 col-lg-7 col-md-12 col-sm-7">
                   <div className="container con" id="idResourcesIPR">
                   <div>
                   <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">Resources about IPR</h2>
     <p  className='font-style-normal font-weight-400 font-18-22 font-14-17 notes justify'><b>Note :</b>
     1. Before proceeding with international application for multiple country filing, necessary due diligence will be done on the total cost of filing and startup fund reserves. This is done to ensure that the startup has sufficient balance funds, beyond the benefits provided through this scheme to support the IPR application. Post satisfactory due-diligence, funds will be released under the IPR scheme.
2. Startups can apply for multiple applications till they exhaust their limit of 2 lakhs for domestic application and 10 lakhs for international application.
3. GST component will be excluded while processing reimbursement applications.
     </p>

     {users.map(user => (
 
  <Accordion expanded={expanded === user} onChange={handleChange(user)}> 
   <AccordionSummary className="w-100" expandIcon={<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 2L13 13L24 2" stroke="#595959" stroke-width="3"/>
</svg>}
     aria-controls="panel1bh-content"
     id="panel1bh-header"
   >
     <Typography className='font-style-normal font-weight-400 font-18-22 font-14-17  d-flex'>
     <span className='font-style-normal font-weight-400 font-18-22 font-14-17 '>
     {user.question}
       </span>
     </Typography>
   </AccordionSummary>
   <AccordionDetails>
     <Typography >
     <p className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>
      {user.answer}
     {/* Intellectual Property (IP) is everywhere. Every product or service used by us, starting from an alarm clock that wakes us up till the late night TV show we fall asleep to, consists of IP in different forms. */}

     
     </p>
    
     <a href={user.link} target="_blank">
     <button type="button" class="btn btn-primary btn-md">Read More</button></a>
           {/* <ul className='font-style-normal font-weight-400 font-18-22 font-14-17 justify'>
             <li>Be incorporated as a private limited company (as defined in the Companies Act, 2013) OR registered as a partnership firm (under Section 59 of the Partnership Act, 1932) OR as a limited liability partnership (under the Limited Liability Partnership Act, 2008) in India</li>
             <li>Have been incorporated/registered within the last seven years (in the case of startups in the biotechnology and/or social sector the period shall be up to ten years)</li>
             <li>Not have exceeded a turnover of Rs.25 Cr in any of the financial years since its incorporation/registration</li>
             <li>Be working towards innovation, development or improvement of products or processes or services, or if it is a scalable business model with a high potential of employment generation or wealth creation.Furthermore, startups must have a “minimum viable product” and be ready to demonstrate “proof of concept”. Idea-stage ventures shall not be considered at this point.</li>
     </ul> */}
     </Typography>
   </AccordionDetails>
 </Accordion>
 
     ))}
 
 
 
 

</div>
   </div>
 </div>
</div>
</div>
</div>
</>
  )
}

export default IntellectualPRFaq