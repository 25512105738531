import React, { useEffect, useState } from 'react'
import { environment } from '../../api'
import '@mui/x-data-grid-generator'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BallotIcon from '@mui/icons-material/Ballot'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import FeaturedVideosViewM from './FeaturedVideosViewM' 
import FeaturedVideosUpdateM from './FeaturedVideosUpdateM'
import FeaturedVideosAddM from './FeaturedVideosAddM'
import DeleteIcon from "@mui/icons-material/Delete";
import axios from 'axios'

function ViewFeaturedVideos() {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const setModalIsOpenToTrue = () => {
      setModalIsOpen(true)
    }
  
    const setModalIsOpenToFalse = () => {
      setModalIsOpen(false)
    }
    const [modalIsOpen2, setModalIsOpen2] = useState(false)
  
    const setModalIsOpenToTrue2 = () => {
      setModalIsOpen2(true)
    }
  
    const setModalIsOpenToFalse2 = () => {
      setModalIsOpen2(false)
    }
    const [modalIsOpen3, setModalIsOpen3] = useState(false)
  
    const setModalIsOpenToTrue3 = () => {
      setModalIsOpen3(true)
    }
  
    const setModalIsOpenToFalse3 = () => {
      setModalIsOpen3(false)
    }
  
    const serverUrl = environment.baseUrl
    const [rowdelete, setRowDelete] = useState("");
  const [rows, setUsers] = useState([])
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [rowId, setrowId] = useState('')
  const [rowData, setRowData] = useState({
   featureVideo: [],
  })
  function fetchData(){
    fetch(serverUrl + '/video/allvideo')
    .then((res) => res.json())
    .then(
      (data) => {
        setIsLoaded(true)
        setUsers(data)
      },
      (error) => {
        setIsLoaded(true)
        setError(error)
      },
    )
  }
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    rows.map((el) => {
      if (el._id === rowId) setRowData({ ...rowData, featureVideo: el })
    })
  }, [rowId])
  
    const columns = [
      { field: '_id', headerName: 'Sr.No.', width: 120 },
      { field: 'title', headerName: 'Title', width: 300 },
      { field: 'video', headerName: 'Video', width: 300 },
      {
        field: 'Action',
        headerName: 'Action',
        sortable: false,
        width: 300,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation() // don't select this row after clicking
  
            const api = params.api
            const thisRow = {}
  
            api
              .getAllColumns()
              .filter((c) => c.field !== '__check__' && !!c)
              .forEach(
                (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
              )
              axios.delete(
                serverUrl + `/video/delete/${thisRow._id}`,
                // { isDeleted: "false" },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => fetchData())
              .then((info) => {
                setRowDelete(info);
              });
            // alert("Deleted successfully..!");
            // window.confirm("Are you sure you want to delete ?");
            
  
            return alert(JSON.stringify("Deleted successfully..!", null, 4))
          }
          return (
            <>
              <Button onClick={setModalIsOpenToTrue}>
                <VisibilityIcon />
              </Button>
              <Button onClick={setModalIsOpenToTrue2}>
                <BallotIcon />
              </Button>
              <Button>
              <DeleteIcon onClick={onClick}/>
            </Button>
            </>
          )
        },
      },
    ]
  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
          Feature Videos Details<Button className="addbtn" onClick={setModalIsOpenToTrue3}>Add</Button>
          </h2>
          <div style={{ height: '75vh', width: '100%' }}>
            
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
              pageSize={8}
              components={{ Toolbar: GridToolbar }}
              getRowId={(rows) => rows._id}
              onSelectionModelChange={(newSelection) => {
                setrowId(newSelection[0])
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Feature Videos Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <FeaturedVideosViewM rowData={rowData}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen2} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Update Feature Videos Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse2}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <FeaturedVideosUpdateM rowData={rowData} setUsers={setUsers} setModalIsOpenToFalse2={setModalIsOpenToFalse2}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen3} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Add Feature Videos Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse3}>
                  <CloseIcon />
                </Button>
              </div>
            </div>

            <FeaturedVideosAddM rowData={rowData} setUsers={setUsers} setModalIsOpenToFalse3={setModalIsOpenToFalse3}/>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ViewFeaturedVideos