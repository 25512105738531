import React from 'react'
import Header from '../component/HeaderAdmin';
import Ipradmin from './IPRadmin';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../component/footer';


function IprMain() {
  return (
    <>
    <Header/>
   
    <Ipradmin />
    
    </>
  )
}

export default IprMain;