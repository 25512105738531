import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function StartupViewMod(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab className="txt-main-style" label="Personal Details" {...a11yProps(0)} />
          <Tab className="txt-main-style" label="Basic details" {...a11yProps(1)} />
          <Tab className="txt-main-style" label="Location" {...a11yProps(2)} />
          <Tab className="txt-main-style" label="Team details" {...a11yProps(3)} />
          {/* <Tab className="txt-main-style" label="Patent Details" {...a11yProps(4)} />*/}
          <Tab className="txt-main-style" label="Business Model Details" {...a11yProps(4)} />
          {/*<Tab className="txt-main-style" label="Funding" {...a11yProps(6)} />
          <Tab className="txt-main-style" label="Current statistics" {...a11yProps(7)} /> */}
          <Tab className="txt-main-style" label="uploadsDetails" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> Firstname :</b>
              </label>
              <span> Vaibhav</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Lastname :</b> 
              </label>
              <span> VPsolutions</span>
              </div>
            <hr></hr>
            </div>
          </div>
       

        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Email : </b>
                <span> vaibhav@VPsolutions.com</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Contact No :</b> <span> 9876543210</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>User Type :</b> <span> Mayuri</span>
              </label>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Eligibility Criteria :</b> <span> Mayuri</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name of Startup :</b> 
              </label>
              <span> {props.rowData.basicDetails.name_of_startup}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Product Name :</b>
            </label>
            <span className="text-justify">
              {props.rowData.basicDetails.product_name}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Website :</b> 
              </label>
              <span> {props.rowData.basicDetails.website}</span>
            </div>
            <hr></hr>
          </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Registration Date :</b> 
            </label>
            <span> {props.rowData.basicDetails.firm_register_date}</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Address</b> 
              </label>
              <span> {props.rowData.basicDetails.company_address}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>State :</b> 
            </label>
            <span> {props.rowData.basicDetails.state}</span>
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>District :</b> 
              </label>
              <span> {props.rowData.basicDetails.district}</span>
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>City :</b> <span> {props.rowData.basicDetails.city}</span>
            </label>
          </div>
          <hr></hr>
          </div>
      
      </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Business model type :</b>{" "}
               
              </label>
              <span> {props.rowData.basicDetails.biz_model_type}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Team Size :</b> <span> {props.rowData.basicDetails.team_size}</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
      
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Startup Sector :</b> 
              </label>
              <span> {props.rowData.basicDetails.sector}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Technology used :</b> 
            </label>
            <span> {props.rowData.basicDetails.technology_used}</span>
            </div>
            <hr></hr>

          </div>
        </div>
        
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Pitch Deck :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pitch uploadsDetails :</b>
            </label>
            <span> 06/09/2022</span>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Other Description :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Explainer Video :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Roadblocks :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Awards/Achievements :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Source of information :</b>{" "}
               
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Please specify Source of information :</b>{" "}
             
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={3}>

      {props.rowData.teamDetails.founder_details.map((founder_details)=>{
        return(
            <div>
              <h4>Founder Details</h4>
              <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              <span> {founder_details.name_of_founder}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation :</b> 
            </label>
            <span> {founder_details.designation_founder}</span>
          </div>
          <hr></hr>
        </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Contact :</b>{" "}
              </label>
              <span> {founder_details.contact_no}</span>
              </div>
            <hr></hr>
            </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Email-Id :</b> 
            </label>
            <span> {founder_details.email_ID}</span>
          </div>
          <hr></hr>
        </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Url :</b> 
              </label>
              <span> {founder_details.linkdin_url}</span>
            </div>
            <hr></hr>
          </div>
          
        </div>
            </div>
      )
})}
         
         {props.rowData.teamDetails.team_details.map((team_details)=>{  
         return (
            <div>
              <h3>Team Details</h3>
              <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              <span> {team_details.name_of_team_member}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation :</b> 
            </label>
            <span> {team_details.designation_team_member}</span>
          </div>
          <hr></hr>
        </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Relevant Work Experience :</b>{" "}
              </label>
              <span> {team_details.relevant_work_exp}</span>
              </div>
            <hr></hr>
            </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Qualification :</b> 
            </label>
            <span>{team_details.qualification}</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </div>
           ) 
         })}
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Logo :</b> 
              </label>
              <span> {props.rowData.uploadsDetails.company_logo}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Company PAN :</b> 
            </label>
            <span> {props.rowData.uploadsDetails.company_PAN_card}</span>
          </div>
          <hr></hr>
          </div>
        </div>
        
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company PAN Number :</b>{" "}
                
              </label>
              <span> {props.rowData.uploadsDetails.company_PAN_card}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Udyog Aadhar :</b> 
            </label>
            <span> {props.rowData.uploadsDetails.udyog_aadhar}</span>
          </div>
          <hr></hr>
        </div>
       </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Udyog Aadhar Number :</b>{" "}
                
              </label>
              <span> {props.rowData.uploadsDetails.udyog_aadhar}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Company GSTIN :</b> 
            </label>
            <span>{props.rowData.uploadsDetails.company_GSTIN} </span>
          </div>
          <hr></hr>
        </div>
       </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company GSTIN Number :</b>{" "}
                
              </label>
              <span> {props.rowData.uploadsDetails.company_GSTIN} </span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Company Registration ID :</b> <span> {props.rowData.uploadsDetails.company_registration_ID}</span>
            </label>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Registration ID Number :</b>{" "}
               
              </label>
              <span> {props.rowData.uploadsDetails.company_registration_ID}</span>
            </div>
            <hr></hr>
          </div>
          {/* <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>DPIIT Number :</b> 
            </label>
            <span> {props.rowData.uploadsDetails.}</span>
          </div>
          <hr></hr>
        </div> */}
        </div>
        <div className="row">
          {/* <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>DPIIT Certificate :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div> */}
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <span> {props.rowData.uploadsDetails.relevant_doc}</span>
            </label>
            {/* <span> 06/09/2022</span> */}
          </div>
          <hr></hr>
        </div>
        
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Have you patented any product? :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Product Name :</b> 
              <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patenting description :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Patent No :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patented In :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Patenting Authority :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
        </div>
        
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patent Validity :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload Patent Certificate :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
      </TabPanel> */}
      <TabPanel value={value} index={4}>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Targeted customer segment :</b>{" "}
                
              </label>
              <span> {props.rowData.modelDetails.company_registration_ID}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Market Size :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Value Proposition :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Competitors :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div> */}
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Start-up current stage :</b>{" "}
                
              </label>
              <span> {props.rowData.modelDetails.startup_current_stage}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Uniqueness factor :</b> 
            </label>
            <span> {props.rowData.modelDetails.uniqueness_factor}</span>
          </div>
          <hr></hr>
        </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Key partners/resources :</b>{" "}
               
              </label>
              <span> {props.rowData.modelDetails.key_partners_resource}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Cost structure :</b> 
            </label>
            <span> {props.rowData.modelDetails.cost_structure}</span>
          </div>
          <hr></hr>
        </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Revenue Inflow :</b> 
              </label>
              <span> {props.rowData.modelDetails.revenue_inflow}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pricing Model :</b> 
            </label>
            <span> {props.rowData.modelDetails.startup_current_stage}</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={6}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Revenue Inflow :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pricing Model :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Annual gross revenue :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Annual Gross expenditure :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Margin (%):</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Customer acquisition :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Total contract value :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Total addressable market :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel> */}
     
    </Box>
  );
}

export default StartupViewMod;
