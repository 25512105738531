import React, { useState, useEffect } from 'react'


function MahaGrandMiddleContent() {
  

  const [myStyle, setMyStyle]= useState({
    paddingTop:'20px'
  
  })
  
  const [btntext, setBtnText]= useState("Enable Dark Mode");
  
  const toggleStyle = ()=>{
    if(myStyle.color == 'white' ){
    setMyStyle({
      paddingTop:'0px'
    })
  setBtnText("enable light mode")
  
  }
  else{
    setMyStyle({
      paddingTop:'20px'
    })
    setBtnText('enable Dark mode')
  }
  }
    
  return (
    <>
      <div className="mahaGrand-main pt-4">
        <div className="container">
          <div className="row d-flex justify-content-center my-3 pad-banner-all">
            <div className="col-lg-3 col-md-3 col-sm-3 d-md-none d-lg-block d-sm-none justify-content-center">
              <div className="card col-lg-10 col-md-10 col-sm-10 apply-head  border-2">
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSfixu9r0oVly6-utreOf_d-RjBqbrcG_-2TRDsDw1ATJ-XkVQ/viewform' target="_blanc">
                <div className="card-body d-flex justify-content-center  align-items-center">
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <span className="regular_font font-20-24 font-style-normal font-weight-400">
                      Apply now
                    </span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <span className="Arrow-apply align-items-end">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                </a>
                <div className="list-group list-group-flush">
                  <a
                    href="#granchallenge"
                    className="list-group-item list-group-item-action"
                  >
                    <span className="regular_font font-18-23 font-style-normal font-weight-400">
                      Grand Challenge
                    </span>
                  </a>
                  <a
                    href="#idEligibility"
                    className="list-group-item list-group-item-action"
                  >
                    {' '}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Eligibility
                    </span>
                  </a>
                  <a
                    href="#idProblem"
                    className="list-group-item list-group-item-action"
                  >
                    {' '}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400" onClick={toggleStyle}>
                      Problem Statement and Prize Money
                    </span>
                  </a>
                  <a
                    href="#idEventSch"
                    className="list-group-item list-group-item-action"
                  >
                    {' '}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Event Schedule
                    </span>
                  </a>
                  <a
                    href="#idFAQ"
                    className="list-group-item list-group-item-action"
                  >
                    {' '}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      FAQs
                    </span>
                  </a>
                  <a
                    href="#idContact"
                    className="list-group-item list-group-item-action"
                  >
                    {' '}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Contact
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-7">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Grand Challenge
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                  Grand Challenges aim to foster innovation and research to
                  develop affordable and sustainable solutions. A platform for
                  end-to-end development of solution for defined problem
                  statements.
                </p>
              </div>
              <div className="row">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Maharashtra Grand Challenge
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  <b className="grand-text-bold">Maharashtra Grand Challenge</b>{' '}
                  is an initiative by Maharashtra State Innovation Society to
                  bring Innovation in Governance. Through this initiative,
                  innovation society intends to address key challenges faced by
                  the various department of Maharashtra by supporting and
                  accelerating the innovative ideas and solutions. In the
                  current edition Maharashtra State Innovation Society in
                  association with Fisheries Department calling for innovative
                  ideas and solutions to solve problems faced by Fisheries
                  department.
                </p>
              </div>
              <div className="row" id="idEligibility">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Eligibility
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Applications are open for both domestic and foreign
                  individuals, teams, organizations, for-profit companies,
                  non-profit organisations, academic institutions and government
                  agencies.
                </p>
              </div>
              <div className="row">
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Foreign national, team, institution, companies and
                  organisation shall abide by the rules and regulations of
                  Government of India and Government of Maharashtra. They also
                  must fulfil all the tax norms prescribed by the Government of
                  India and Government of Maharashtra.
                </p>
              </div>
              <div className="row" id="idProblem" style={myStyle}>
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Problem Statements and Prize Money
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Applications are open for both domestic and foreign
                  individuals, teams, organizations, for-profit companies,
                  non-profit organisations, academic institutions and government
                  agencies.
                </p>
              </div>
              <div className="row">
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Foreign national, team, institution, companies and
                  organisation shall abide by the rules and regulations of
                  Government of India and Government of Maharashtra. They also
                  must fulfil all the tax norms prescribed by the Government of
                  India and Government of Maharashtra.
                </p>
              </div>
              <div className="row probSatbottomBorder">
                <p className="font-style-normal font-weight-700 font-18-22 font-14-17 ">
                  <u>Problem Statement 1</u>
                </p>
                <p className="font-style-normal font-weight-700 font-22-26 font-14-17 ">
                  Automation of Large-scale fishpond
                </p>
                <span className="regular_font font-style-normal font-weight-400 font-22-26 font-14-17 ">
                  Prize amount - upto 5 lakhs
                </span>
                <span className="regular_font font-style-normal font-weight-400 font-22-26 font-14-17">
                  Pilot amount - upto 15 lakhs
                </span>
                <br />
              </div>
              <div className="row probSatbottomBorder pt-2">
                <p className="font-style-normal font-weight-700 font-18-22 font-14-17 ">
                  <u>Problem Statement 2</u>
                </p>
                <p className="font-style-normal font-weight-700 font-22-26 font-14-17 ">
                  Automation of Large-scale fishpond
                </p>
                <span className="regular_font font-style-normal font-weight-400 font-22-26 font-14-17 ">
                  Prize amount - upto 5 lakhs
                </span>
                <span className="regular_font font-style-normal font-weight-400 font-22-26 font-14-17 ">
                  Pilot amount - upto 15 lakhs
                </span>
                <br />
              </div>
              <div className="row pt-2">
                <p className="font-style-normal font-weight-700 font-18-22 font-14-17 ">
                  <u>Problem Statement 3</u>
                </p>
                <p className="font-style-normal font-weight-700 font-22-26 font-14-17 ">
                  Automation of Large-scale fishpond
                </p>
                <span className="regular_font font-style-normal font-weight-400 font-22-26 font-14-17">
                  Prize amount - upto 5 lakhs
                </span>
                <span className="regular_font font-style-normal font-weight-400 font-22-26 font-14-17 ">
                  Pilot amount - upto 15 lakhs
                </span>
                <br />
              </div>
              <div className="tablerounededCorner mt-3 d-none b-sm-block">
                <table className="table table-bordered table-striped roundedTable">
                  <tr>
                    <th className="font-style-normal font-weight-700 font-18-22 font-14-17 text-center">
                      Category 1
                    </th>
                    <th className="font-style-normal font-weight-700 font-18-22 font-14-17  text-center">
                      Category 2
                    </th>
                    <th className="font-style-normal font-weight-700 font-18-22 font-14-17  text-center">
                      Category 3
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <p className="font-style-normal font-weight-700 font-17-20 font-14-17 ">
                        Prize amount - upto 25 lakhs Pilot amount - upto 1 crore
                      </p>
                      <p className="font-style-normal font-weight-400 font-18-22 font-14-17 ">
                        Onshore energy generation on jetties, harbours fishing
                        villages as well as ice/cold storage/processing units in
                        coastal areas.
                      </p>
                    </td>
                    <td>
                      <p className="font-style-normal font-weight-700 font-17-20 font-14-17 ">
                        Prize amount - upto 25 lakhs Pilot amount - upto 1 crore
                      </p>
                      <p className="font-style-normal font-weight-400 font-18-22 font-14-17 ">
                        Onshore energy generation on jetties, harbours fishing
                        villages as well as ice/cold storage/processing units in
                        coastal areas.
                      </p>
                    </td>
                    <td>
                      <p className="font-style-normal font-weight-700 font-17-20 font-14-17 ">
                        Prize amount - upto 25 lakhs Pilot amount - upto 1 crore
                      </p>
                      <p className="font-style-normal font-weight-400 font-18-22 font-14-17 ">
                        Onshore energy generation on jetties, harbours fishing
                        villages as well as ice/cold storage/processing units in
                        coastal areas.
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="row d-lg-none">
                <div class="card grandtable">
                  <div class="card-body">
                    <span className="font-style-normal font-weight-700 font-18-22 font-14-17 text-center">
                      Category 1
                    </span>
                    <p className="font-style-normal font-weight-700 font-17-20 font-14-17">
                      Prize amount - upto 25 lakhs <br></br> Pilot amount - upto
                      1 crore
                    </p>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 ">
                      Onshore energy generation on jetties, harbours fishing
                      villages as well as ice/cold storage/processing units in
                      coastal areas.
                    </p>
                  </div>
                </div>
                <div class="card grandtable">
                  <div class="card-body">
                    <span className="font-style-normal font-weight-700 font-18-22 font-14-17  text-center">
                      Category 1
                    </span>
                    <p className="font-style-normal font-weight-700 font-17-20 font-14-17 ">
                      Prize amount - upto 25 lakhs <br></br> Pilot amount - upto
                      1 crore
                    </p>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 ">
                      Onshore energy generation on jetties, harbours fishing
                      villages as well as ice/cold storage/processing units in
                      coastal areas.
                    </p>
                  </div>
                </div>
                <div class="card grandtable">
                  <div class="card-body">
                    <span className="font-style-normal font-weight-700 font-18-22 font-14-17  text-center">
                      Category 1
                    </span>
                    <p className="font-style-normal font-weight-700 font-17-20 font-14-17 ">
                      Prize amount - upto 25 lakhs <br></br> Pilot amount - upto
                      1 crore
                    </p>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 ">
                      Onshore energy generation on jetties, harbours fishing
                      villages as well as ice/cold storage/processing units in
                      coastal areas.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MahaGrandMiddleContent
