import React from 'react';
import ReactDOM from "react-dom";
import { useRef, useState } from "react";
import MssgIcon from '../../assets/images/EcoSystem/Mentors/wnattogettouch/mssgIcon.png';
import MssgIcon1 from '../../assets/images/EcoSystem/Mentors/wnattogettouch/mssgIcon-1.png';
import MoboMsgImg from '../../assets/images/EcoSystem/Mentors/wnattogettouch/mobomsgimg.png';
// import { Container, Col, Row } from 'react-bootstrap-v5';
import { BsFiles } from "react-icons/bs";
function Wanttogettouch() {
  const textEl = useRef(null);

  const copyToClipboard = async e => {
    const wdsl =window.getSelection().removeAllRanges();
    console.log(wdsl);
    var range = document.createRange();
    range.selectNode(textEl.current);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };
  return (
  <>
  <div>
  <div className='whantgettouch_banner'>
    {/* <Container> */}
       
        {/* <Container> */}
            <div className=' wanttotouchbannercontent'>
        <div className='col-9 d-flex whantgettouch-main'>
             <div className='col-9 p-4'>
               <h3 className='whant-heading font-28-36  font-18-23'>Want to get in touch?</h3>
               <p className='whant-p font-22-26 font-14-17'>You can connect to mentors through email or LinkedIn profile given in mentor 
               details section. To connect to any other mentors or details please contact us at &nbsp;
                <u className="linkbold" onClick={copyToClipboard} ref={textEl}>team@msins.in <BsFiles/></u> </p>
             </div>
             <div className='col-3 pr-2 d-none d-lg-block justify-content-end'>
                 <span className='mssg-img'><img src={MssgIcon}></img></span>
                 <span className='mssg-img1'> <img src={MssgIcon1}></img></span>
             </div>
             <div className='col-3 d-flex d-lg-none d-block align-items-center'>
                 <span className='img-fluid'><img src={MoboMsgImg}></img></span>
             </div>
        
        </div>
        </div>
        {/* </Container> */}
    {/* </Container> */}
   </div>
  </div>
  
        
  </>
  );
}

export default Wanttogettouch;
