import React from 'react'
import Header from "../component/Header";
import Footer from "../component/footer";
import ChiefExecutiveOfficer from '../assets/document/Chief_Executive_Officer.pdf'

function Careers() {
  return (
    <>
    <Header />
    <div className="mahaGrand-main intellectual-main">
        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
               <a href={ChiefExecutiveOfficer} target="_blank"> <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                Recruitment of Additional Chief Executive Officer - 2024
                </h2></a>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 mt-5 d-flex justify-content-center">
                 {/* <h4>There are no current openings at the moment.</h4>  */}
                  
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
  
  <Footer />
  </>
  )
}

export default Careers