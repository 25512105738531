import React from 'react'
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import InformationIcon from "../../startup/informationIcon";
import { environment } from '../api';
const serverUrl = environment.baseUrl;

export default function DistrictFormAddM() {

    function handleSubmitBusinessModelAddForm(values) {
        console.log(values, "hii")
        const formData = new FormData();
        formData.append("name", values.title)
        fetch(serverUrl + "/businessM/allbusinessModel",
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              accept: "application/json",
    
            },
            body: JSON.stringify(values, null, 2)
    
          })
          .then(res => res.json())
          .then(res => console.log(res))
          .catch((err) => { console.log(err) })
        const answer = window.confirm("are you sure?");
        if (answer) {
          // Save it!
          console.log("Thing was saved to the database.");
          alert("Form Submitted Successfully");
    
        } else {
          // Do nothing!
          alert("Please fill out all fields");
          console.log("Thing was not saved to the database.");
        }
      }
    
    
      return <div className="container">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {/* {message} */}
            <Formik
              onSubmit={(values
              ) =>
                handleSubmitBusinessModelAddForm(values)
              }
              validationSchema={Yup.object({
                name: Yup.string().required('Enter Name is Required'),
    
              })}
              initialValues={{
                name: '',
    
              }}
    
              render={({ values, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 pl-2">
                      {/* <h6>STATE</h6> */}
                      <label className="pb-2 label-txt">
                        Select District{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select name="state" className="form-control size"
                      // value={state}
                      // onChange={(e) => {
                      //   console.log(e.target.value, "8777")
                      //   // setstate("state", e.target.value)
                      //   handleCategory(e)
                      // }}
                      >
                        <option>--Select--</option>
                        <option>Admin</option>
                        <option>MSInS User</option>
                        <option>Cordiantor</option>
                        <option>Finance</option>
                        <option>JCEO</option>
                        <option>CEO</option>
                        <option>Query Handler</option>
                        <option>Process Partner</option>
                        <option>Enpaneled Partner</option>
                        <option>Data Entry Operator</option>
                        {/* {
                          users.map((user) => {
                            return (
                              <option key={user._id} value={user._id}> {user.name} </option>
                            )
                          })
                        } */}
                      </select>
                    </div>
                  </div>
      
    
                </Form>
              )}
            />
          </div>
        </div>
      </div>

}
