import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";

import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const ExecutiveTeamAddM = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitExecutiveTeamAddForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name",values.name)
    formData.append("position",values.position)
    formData.append("profile",values.profile)
    formData.append("linkdin",values.linkdin)
    console.log(formData)
    function fetchData(){
      fetch(serverUrl + "/about/allExecutive")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    setUsers(data);
  },
)
}
    fetch(serverUrl +"/about/addExecutive", 
         {
            method: "POST",
            body: formData,
           
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then((res) => setModalIsOpenToFalse3())
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
          
  }
  console.log("called")

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
            onSubmit={(values, {resetForm}
            ) =>
           { console.log(values )
            handleSubmitExecutiveTeamAddForm(values)
            resetForm({values:''}) 
          }
          }
    validationSchema={Yup.object({
    name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Name is Required'),
    position: Yup.string().required('Enter Position is Required'),
    profile: Yup.string().required('Enter Profile is Required'),
    linkdin: Yup.string().required('Enter Linkdin  is Required'),
       })}
      initialValues={{ 
      title: '',
      name: '',
      position: '',
      profile:'',
      linkdin: '',
       }}

      render={({ values, setFieldValue ,handleSubmit}) => (
        <Form onSubmit={(e)=>{
          
          handleSubmit(e)}}>
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Name" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    position{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Position Name" className="textInput" name="position" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="position" /><br></br>
                    </div>                   
                  </div>
                  {/* <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Website URL <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div> */}

                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Profile <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="profile" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("profile", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="profile" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Linkdin <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Linkdin" className="textInput" name="linkdin" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="linkdin" /><br></br>
                    </div>
                  </div>
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default ExecutiveTeamAddM;
