import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import success from "../../assets/images/bannerIcon/success.png";

import { Fragment } from "react/cjs/react.production.min";
import { environment } from '../../api'


const EmailVerify = () => {
	const serverUrl = environment.baseUrl


	const [validUrl, setValidUrl] = useState(false);
	const param = useParams();

	useEffect(() => {
		const verifyEmailUrl = async () => {
			try {
				const url = serverUrl + `/users/${param.id}/verify/${param.token}`;
				console.log(url, "ttttttttttttttttttt");
				const { data } = await axios.get(url);
				console.log(data);
				setValidUrl(true);
			} catch (error) {
				console.log(error);
				setValidUrl(false);
			}
		};
		verifyEmailUrl();
	}, [param]);

	return (
		<Fragment>
			{validUrl ? (
				<div 
				// className={styles.container}
				>
					<img src={success} alt="success_img" 
					// className={styles.success_img} 
					/>
					<h1>Email verified successfully</h1>
					<Link to="/login">
						<button 
						// className={styles.green_btn}
						>Login</button>
					</Link>
				</div>
			) : (
				<h1>404 Not Found</h1>
			)}
		</Fragment>
	);
};

export default EmailVerify;
