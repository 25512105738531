import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GestioneAree from './modalLink'
// import "./Header.css";

// import Navigation from "../Navigation/Navigation";
import Navigation from "./Navigation";


export default function Header() {
  const [formData, setFormData] = useState("");
  const path = useLocation().pathname;
  const location = path.split("/")[1];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
  <>
    
    <div className={"header " + location}>
      <Navigation />
    
     
      {/* <div className="header-content">
        {location === "home" ? (
          <>
            <h2>This h2 in home page only</h2>
            <input
              type="text"
              value={formData}
              placeholder="Input in home..."
              onChange={e => setFormData(e.target.value)}
            />
          </>
        ) : (
          <h4>{location}</h4>
        )}
      </div> */}
      
    </div>
    
  </>
  );
}
