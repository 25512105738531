import React, { useEffect,useState } from 'react'
import "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility'
import BallotIcon from '@mui/icons-material/Ballot'
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import OurPartnerHackthonViewM from './OurPartnerHackthonViewM'
import OurPartnerHackthonUpdateM from './OurPartnerHackthonUpdateM'
import OurPartnerHackthonAddM from './OurPartnerHackthonAddM'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
// import MentorApprovalModel from './mentorApprovalModel'
import { environment } from '../../api';
function ViewOurPartnerHackthon() {
  const authToken =sessionStorage.getItem("token");
console.log(authToken, "sessionTkennnnnn");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true);
  };

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false);
  };

  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true);
  };

  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false);
  };


    const [showData,setShowData] = useState('');
    const serverUrl = environment.baseUrl;
    const [rows, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [rowId, setrowId] = useState('');
    const [rowData, setRowData] = useState({
      
      ourPartner: []
     
    })
    useEffect(() => {
        fetch(serverUrl +"/ourPartnerHackthon/allOurHackthon",
        // {headers: {
        //   "auth-token":authToken
          
        // }}
        )
            .then(res => res.json())
            .then(
                (data) => {
                  console.log("mentor data",data.data);
                    setIsLoaded(true);
                    setUsers(data.data);
                    // window.location.reload();
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
            
      }, [])
  
      useEffect(()=>{
        rows.map((el)=>{
          if(el._id === rowId)
            setRowData({...rowData, ourPartner: el})
  
        })
        
      },[rowId])
  
      
  const columns = [
    { field: "_id", headerName: "Sr.No.", width: 300 },
    { field: "partnerType", headerName: " Partner Type", width: 250 },
    { field: "link", headerName: "link", width: 250 },
    { field: 'image', headerName: 'Image', width: 200 },
   
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          return alert(JSON.stringify(thisRow, null, 4));
        };
        return (
          <>
            <Button onClick={setModalIsOpenToTrue2}>
            <VisibilityIcon />
        </Button>
          </>
        );
      },
    },

   
  ];

  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
          Our Partner Hackthon Details
            <Button className="addbtn" onClick={setModalIsOpenToTrue}>Add</Button>
          </h2>
          <div style={{ height: "75vh", width: "100%" }}>
            
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
               pageSize={8}
               onSelectionModelChange={(newSelection)=>{
                setrowId(newSelection[0])
               }}
               components={{ Toolbar: GridToolbar
                 }}
              getRowId ={(rows) => rows._id} 
                          />
          </div>
        </div>
      </div>
     <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
          <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Add Our Partner Hackthon</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <OurPartnerHackthonAddM rowData = {rowData}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen1} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Update Our Partner Hackthon</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse1}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <OurPartnerHackthonUpdateM rowData = {rowData}/>
          </div>
        </Modal>
      </div>

      <div>
        <Modal isOpen={modalIsOpen2} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Our Partner Hackthon</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse2}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <OurPartnerHackthonViewM rowData = {rowData}/>
          </div>
        </Modal>
      </div>
    
    </>
  );
}

export default ViewOurPartnerHackthon;
