import { createSlice } from '@reduxjs/toolkit'

const initialStateValue = {
 isMentorDetails: 'not_set',
}

export const mentorDetailsSlice = createSlice({
 name: 'counteryou',
 initialState: initialStateValue,
 reducers: {
  updateisMentorDetails: (state) => {
   state.isMentorDetails ='set'
  },
  // updateisMentorDetails: (state,action) => {
  //    state.isMentorDetails =action.payload;
  //   },
}
})

// Action creators are generated for each case reducer function
export const { updateisMentorDetails } = mentorDetailsSlice.actions

export default mentorDetailsSlice.reducer