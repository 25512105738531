import React, { useEffect, useState } from "react";
import "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BallotIcon from "@mui/icons-material/Ballot";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import { environment } from "../../api";
import LandingBannerUpdateM from "./landingBannerUpdateM";
import LandingBannerViewM from "./landingBannerViewM";
import LandingBannerAddM from "./landingBannerAddM";
import DeleteIcon from "@mui/icons-material/Delete";
import RearrangeBannerId from "./RearrangeBannerId";
import axios from "axios";

export default function Viewlandingbanner() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  function refreshPage() {
    window.location.reload(false);
  }

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true);
  };

  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false);
  };
  const [modalIsOpen3, setModalIsOpen3] = useState(false);

  const setModalIsOpenToTrue3 = () => {
    setModalIsOpen3(true);
  };

  const setModalIsOpenToFalse3 = () => {
    setModalIsOpen3(false);
  };

  const [modalIsOpen4, setModalIsOpen4] = useState(false);

  const setModalIsOpenToTrue4 = () => {
    setModalIsOpen4(true);
  };

  const setModalIsOpenToFalse4 = () => {
    setModalIsOpen4(false);
  };

  const serverUrl = environment.baseUrl;
  const [rowdelete, setRowDelete] = useState("");

  const [rows, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rowId, setrowId] = useState("");
  const [rowData, setRowData] = useState({
    landingBanner: [],
  });
  function fetchData() {
    fetch(serverUrl + "/banners/allbanner")
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUsers(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    rows.map((el) => {
      if (el._id === rowId) setRowData({ ...rowData, landingBanner: el });
    });
  }, [rowId]);

  const columns = [
    { field: "_id", headerName: "Sr.No.", width: 200 },
    { field: "rearrange_number", headerName: "Rearrange Number", width: 150 },
    // { field: "name", headerName: "Name", width: 200 },
    // { field: "title", headerName: "Title", width: 230 },
    // { field: "url", headerName: "Link", width: 200 },
    { field: "image", headerName: "Image", width: 250 },
    { field: "link", headerName: "Link", width: 250 },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 270,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          axios
            .delete(
              serverUrl + `/banners/delete/${thisRow._id}`,
              // { isDeleted: "false" },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => fetchData())
            .then((info) => {
              setRowDelete(info);
            });
          // alert("Deleted successfully..!");
          // window.confirm("Are you sure you want to delete ?");

          return alert(JSON.stringify("Deleted successfully..!", null, 4));
        };
        return (
          <>
            <Button onClick={setModalIsOpenToTrue}>
              <VisibilityIcon />
            </Button>
            <Button onClick={setModalIsOpenToTrue2}>
              <BallotIcon />
            </Button>
            <Button>
              <DeleteIcon onClick={onClick} />
            </Button>
            {/* <Button onClick={setModalIsOpenToTrue4}>
              <BallotIcon />
            </Button> */}
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Landing Banner Details{" "}
            <Button className="addbtn" onClick={setModalIsOpenToTrue3}>
              Add
            </Button>
            <Button
              className="addbtn"
              rows={rows}
              onClick={setModalIsOpenToTrue4}
            >
              Rearrange Id
            </Button>
          </h2>

          <div style={{ height: "75vh", width: "100%" }}>
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
              pageSize={8}
              components={{ Toolbar: GridToolbar }}
              getRowId={(rows) => rows._id}
              onSelectionModelChange={(newSelection) => {
                setrowId(newSelection[0]);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Banner Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <LandingBannerViewM
              rowData={rowData}
              setUsers={setUsers}
              setModalIsOpenToFalse={setModalIsOpenToFalse}
            />
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen2} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Update Banner Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse2}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <LandingBannerUpdateM
              rowData={rowData}
              setUsers={setUsers}
              setModalIsOpenToFalse2={setModalIsOpenToFalse2}
            />
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen3} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Add Landing Banner</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse3}>
                  <CloseIcon />
                </Button>
              </div>
            </div>

            <LandingBannerAddM
              setUsers={setUsers}
              setModalIsOpenToFalse3={setModalIsOpenToFalse3}
            />
          </div>
        </Modal>
      </div>

      <div>
        <Modal isOpen={modalIsOpen4} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Update Rearrange Id</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button
                  onClick={() => {
                    setModalIsOpenToFalse4();
                    refreshPage();
                  }}
                >
                  <CloseIcon />
                </Button>
                <Button
                style={{backgroundColor:'#174984'}}
                className="btn btn-sm"
                variant="contained"
                type="submit"
                  onClick={() => {
                    setModalIsOpenToFalse4();
                    refreshPage();
                  }}
                >
                  Back
                </Button>
               
              </div>
            </div>

            {/* <RearrangeBannerId  setUsers={setUsers} setModalIsOpenToFalse4={setModalIsOpenToFalse4}/> */}
            <RearrangeBannerId
              rowData={rowData}
              setUsers={setUsers}
              setModalIsOpenToFalse4={setModalIsOpenToFalse4}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
