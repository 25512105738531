import React, { useState, useEffect }  from "react";
import { environment } from '../../api';
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap-v5";

function StartupYatraContent() {

  const serverUrl = environment.baseUrl;
  const num=0;
  const navigate = useNavigate();
  const handleClick = () => {
    // 👇️ replace set to true
    navigate('/Startupyatra', {replace: true});
  };
  const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(serverUrl +"/yatraStartupWinners/allYatraWinners")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setUsers(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])
    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
    
    <div className="mahaGrand-main intellectual-main">
    
      <div className="container">
      
        <div className="row d-none justify-content-center my-3 d-lg-block d-md-none d-sm-none">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            {/* <div className="mob-scroll-horizontal"> */}
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                Startup Yatra 2022 - State Level Winners
                </h2>

                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th className="justify-content-center d-flex">Sr No.</th>
                      <th scope="col">Category</th>
                      <th scope="col">District</th>
                      <th scope="col">Application Id</th>
                      <th scope="col">Name of Participant</th>
                    </tr>
                  </thead>
                  {users.map((user,num) => (
                  <tbody>
                    <tr>
                      <th scope="row" className="justify-content-center d-flex">
                      {num+1}
                      </th>
                      <td> {user.category}</td>
                       <td> {user.rank} </td>
                       <td>{user.prize} </td> 
                       <td> {user.name} </td>
                    </tr>
                    
                  </tbody>
                  ))}
                </table>
                {/* <p className="font-style-normal font-weight-400 font-18-22 font-14-17 "><a href={GovernmentResolution} target="_blanc" className="underline-remove">Government Resolution</a></p> */}
              </div>
            {/* </div> */}
          </div>
        </div>
        <div className="row d-flex justify-content-center my-3 d-lg-none d-md-block d-sm-block">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="mob-scroll-horizontal">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                Startup Yatra 2022 - State Level Winners
                </h2>

                <table class="table table-bordered">
                  <thead>
                    <tr>
                      {/* <th className="justify-content-center d-flex">Sr No.</th>
                      <th scope="col">Name of the Team Leader</th>
                      <th scope="col">Rank</th>
                      <th scope="col">Category</th>
                      <th scope="col">Prize Money Won</th> */}
                      <th className="justify-content-center d-flex">Sr No.</th>
                      <th scope="col">Category</th>
                      <th scope="col">District</th>
                      <th scope="col">Application Id</th>
                      <th scope="col">Name of Participant</th>

                    </tr>
                  </thead>
                  {users.map((user,num) => (
                  <tbody>
                    <tr>
                      <th scope="row" className="justify-content-center d-flex">
                      {num+1}
                      </th>
                      {/* <td> {user.name} </td>
                      <td> {user.category}</td>
                      <td> {user.rank} </td>
                      <td>{user.prize} </td> */}
                       <td> {user.category}</td>
                       <td> {user.rank} </td>
                       <td>{user.prize} </td> 
                       <td> {user.name} </td>
                    </tr>
                    
                  </tbody>
                  ))}
                </table>
                {/* <p className="font-style-normal font-weight-400 font-18-22 font-14-17 "><a href={GovernmentResolution} target="_blanc" className="underline-remove">Government Resolution</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default StartupYatraContent;
