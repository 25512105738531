import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const FundingSourcesUpdateM = (props, {setModalIsOpenToFalse2}) => {
  const [isLoaded, setIsLoaded] = useState(false);
// console.log(props)
const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitFundingSourcesUpdateForm(values){
    
    const formData = new FormData();
    formData.append("description",values.description)
    formData.append("link",values.link)
    formData.append("logo",values.logo)
    function fetchData(){
      fetch(serverUrl + "/ecosystemFunding/allFunding")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    fetch(serverUrl +`/ecosystemFunding/${props.rowData.fundSource._id}`,
         {
          method: 'PUT',
          body: formData,
            
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .then((res) => fetchData())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
            onSubmit={(values, {resetForm}
            ) =>
           { console.log(values )
            handleSubmitFundingSourcesUpdateForm(values)
            resetForm({values:''}) 
          }
          }
   
    validationSchema={Yup.object({
    description: Yup.string().required('Enter Description is Required'),
    link: Yup.string().required('Enter Link is Required'),
    logo: Yup.string().required('Logo  is Required'),
       })}
      initialValues={{ 
      link: props.rowData.fundSource.link,
      description:props.rowData.fundSource.description,
      logo: props.rowData.fundSource.logo,
       }}

      

      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Link <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                       <Field as="textarea" placeholder="Enter Description" 
                        onChange={handleChange}
                        className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" />
                    </div>
                  </div>   
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Logo  <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img id="imgupdate" src={updatedImage||values.logo} style={{width:'20%', height:'50%'}} /><br></br>
                    <input id="file" name="logo" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("logo", e.target.files[0]);
                      }} required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="logo" /><br></br>
                    </div>
                  </div>
                
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default FundingSourcesUpdateM;
