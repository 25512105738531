import React from 'react';
import Header from '../../component/Header';
import MaharashtraStartupBaneer from './MaharashtraStartupBaneer';
import MahaStartUpMiddleContent from './MahaStartUpMiddleContent';
import MaharashtraStartupTestimonials from './MaharashtraStartupTestimonials';
// import MaharashtraStartupOurPartners from './MaharashtraStartupOurPartners';
import MahaGrandContactus from '../MaharashtraGrandChallenge/mahaGrandContactus';
import MahaStartupContactus from '../MaharashtraStartupWeek/mahaStartupContactus';
import Footer from '../../component/footer';
import MahaStartupFaq from './MHStartupFaq';
import MhOurPart from './mhOurPrtner'
// import MhOurPart from './mhOurPrtner';



function MaharashtraStartupMain() {
  return (
    
    <>
   <Header/>
   <MaharashtraStartupBaneer/>
   <MahaStartUpMiddleContent/>
   <MaharashtraStartupTestimonials/>
   <MahaStartupFaq/>
   {/* <MaharashtraStartupOurPartners/> */}
   <MhOurPart />
 
   <MahaStartupContactus/>

   {/* <MhOurPart /> */}
   <Footer/>

  </>
  );
}

export default MaharashtraStartupMain;