import React from 'react'
// import Complianceimg from '../../assets/images/EcoSystem/MAITRI/corporate-compliance.d675c153.jpg'
import { Button } from "react-bootstrap-v5";
import { Link } from "react-router-dom";

function MaitriMiddleContent() {
  return (
    <>
      <div className="mahaGrand-main intellectual-main">
        <div className="container">
            <div className="row d-flex justify-content-center my-3" id="granchallenge">
              <div className="col-lg-6 col-md-6 col-sm-6">
                {/* <img src={Complianceimg} className="img-fluid img-border" alt="Compliance"></img> */}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 p-4">
                <p className="font-style-normal font-weight-400 font-24-29">
                Maharashtra Investment and Trade Agency is a body which aims to:
                  <ol className="font-style-normal font-weight-400 font-20-26">
                    <li>Ensure timely approvals and services to organisations.</li>
                    <li>Handholding support to organisations setting up new entity.</li>
                    <li>Management of web-based portal.</li>
                    <li>Handling compliance related grievance.</li>
                    <li>Coordination with Industrial Associations for Ease of Doing Business initiatives.</li>
                    <li>Function as a clearing house for all business compliance related information.</li>
                  </ol>
                  </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 pt-3'>
                <p className='font-style-normal font-weight-600 font-28-36 d-flex justify-content-center'>
                MAITRI currently offers 45 online services spread across 12 departments of the state.
                </p>
                <p className='font-style-normal font-weight-600 font-20-24 font-36-47 d-flex justify-content-center'>Visit Business Compliance Information – MAITRI</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 mt-4'>
                <p className='font-style-normal font-weight-600 font-28-36 font-34-38 d-flex justify-content-center'>
                Know more about business compliance plan
                </p>
                <div className='graybtn feature-card-button d-flex justify-content-center pt-3' >
                  <Button className="regular_font font-style-normal font-weight-normal  font-20-24 d-sm-block " onClick={()=> window.open("https://maitri.mahaonline.gov.in/UserDashboard/Dashboard", "_blank")}>
                  <Link to="" className='underline-remove'>
                    <span className=" regular_font font-20-24 font-style-normal font-weight-400" >
                    Visit
                    </span>
                    <span className="inno-arrow visit-btn-title">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                  </Button>
              </div>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default MaitriMiddleContent