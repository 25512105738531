import React from "react";
import Eventimg from "../../assets/images/Initiative/EventDetails/617281MainhomepageStartupweek.png";
import { Button } from "react-bootstrap-v5";
import {FaCalendarDay} from 'react-icons/fa';
import {FaMapMarker} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaTwitterSquare} from 'react-icons/fa';
import { Link } from "react-router-dom";

function eventMiddleContent() {
  return (
    <> 
      <div className="mahaGrand-main intellectual-main">
        <div className="container">
          <div className="row d-flex justify-content-center" id="granchallenge">
            <div className="col-md-6 d-flex justify-content-center pt-3">
              <h2>Maharashtra Startup Week 2022</h2>
            </div>
            <div className="col-md-6">
              <div className="graybtn feature-card-button d-flex justify-content-end pt-3">
                <Button className="regular_font font-style-normal font-weight-normal  font-20-24 d-none d-sm-block ">
                  <Link to="/Initiative" className="underline-remove">
                    <span className=" regular_font font-20-24 font-style-normal font-weight-400">
                      Back to Listing
                    </span>
                    <span className="inno-arrow visit-btn-title">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          <div
            className="row d-flex justify-content-center my-3"
            id="granchallenge"
          >
            <div className="col-lg-7 col-md-7 col-sm-7">
              <img
                src={Eventimg}
                className="img-fluid img-border"
                alt="Events"
              ></img>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 px-4 pb-4 pt-1">
            <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Date</p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start"><FaCalendarDay/>&nbsp;&nbsp;2022-06-15</p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Address</p><FaMapMarker/>
              <p></p>
              <p className="font-style-normal font-weight-600 font-20-23 d-flex justify-content-start bg-color-form">Share</p>
              <p className="font-22-26"><FaFacebookSquare/>&nbsp;&nbsp;<FaTwitterSquare/></p>
              <hr />
              <p className="font-style-normal font-weight-400 font-20-24">Maharashtra Startup Week 2022</p>
              
            </div>
          </div>
          <div className="row">
          {/* <div className="col-md-4 pt-3">
              <p className="font-style-normal font-weight-600 font-28-36 d-flex justify-content-center"></p>
              <p className="font-style-normal font-weight-600 font-20-24 font-36-47 d-flex justify-content-center"></p>
            </div> */}
            <div className="col-md-7 pt-3">
              
            <p className="font-style-normal font-weight-400 font-24-29">
                Applications for Maharashtra Startup Week 2021 are now open.
                Startup Week is an annual flagship program of Maharashtra State
                Innovation Society where 24 startups across 8 sectors get work
                order of Rs. 15 lakhs each &#38; a chance to pilot with
                Government of Maharashtra.
              </p>
            </div>
            <div className="col-md-5 pt-3">
              <div className="graybtn feature-card-button d-flex justify-content-center pt-3">
                <Button className="regular_font font-style-normal font-weight-normal  font-20-24 d-none d-sm-block ">
                  <Link
                    to="/MaharashtraStartupMain"
                    className="underline-remove"
                    target="_blanc"
                  >
                    <span className=" regular_font font-20-24 font-style-normal font-weight-400">
                      Register Now
                    </span>
                    <span className="inno-arrow visit-btn-title">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                </Button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-4">
              {/* <p className="font-style-normal font-weight-600 font-28-36 font-34-38 d-flex justify-content-center">
                Know more about business compliance plan
              </p> */}
              

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default eventMiddleContent;
