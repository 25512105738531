import React, { useEffect, useState } from "react";
import '@mui/x-data-grid-generator'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BallotIcon from '@mui/icons-material/Ballot'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import AnnouncementUpdateM from './AnnouncementUpdateM'
import AnnouncementViewM from './AnnouncementViewM'
import AnnouncementAddM from './AnnouncementAddM'
import { environment } from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from 'axios'

function Viewannounce() {
  const [modalIsOpen1, setModalIsOpen1] = useState(false)

  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true)
  }

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false)
  }
  const [modalIsOpen2, setModalIsOpen2] = useState(false)

  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true)
  }

  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false)
  }
  const [modalIsOpen3, setModalIsOpen3] = useState(false)

  const setModalIsOpenToTrue3 = () => {
    setModalIsOpen3(true)
  }

  const setModalIsOpenToFalse3 = () => {
    setModalIsOpen3(false)
  }
  // const srNo = () => {
    const i=0;
    // return i+1;
  


  

  const serverUrl = environment.baseUrl;
  const [rowdelete, setRowDelete] = useState("");
  const [rows, setUsers] = useState([]);
  const [updaterow, setUpdaterow] = useState([]);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rowId, setrowId] = useState("");

  const [rowData, setRowData] = useState({
    Announcement: [],
    
  });
  function fetchData(){
  fetch(serverUrl + "/annoucements/allAnnounce")
  .then((res) => res.json())
  .then(
    (data) => {
      setIsLoaded(true);
      setUsers(data);
    },
    (error) => {
      setIsLoaded(true);
      setError(error);
    }
  );
  }

  useEffect(() => {
    fetchData()
  }, 
   []);

  useEffect(() => {
    rows.map((el) => {
      if (el._id === rowId) setRowData({ ...rowData, Announcement: el });
    });
   
  },
  [rowId]);


  const columns = [
    // { field: 'i', headerName: 'Sr.No.', width: 150 ,
    // renderCell: (params) => {
      
    //      return console.log(i+1);
    //     }
    //   }
    //   ,
      { field: '_id', headerName: 'Sr. No.', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'image', headerName: 'Image', width: 150 },
    { field: 'link', headerName: 'Link', width: 200 },
    {
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            )
            axios.delete(
              serverUrl + `/annoucements/delete/${thisRow._id}`,
              // { isDeleted: "false" },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => fetchData())
            .then((info) => {
              setRowDelete(info);
            });
          // alert("Deleted successfully..!");
          // window.confirm("Are you sure you want to delete ?");
       

          return alert(JSON.stringify("Deleted successfully..!", null, 4))
        }
        return (
          <>
            <Button onClick={setModalIsOpenToTrue1}>
              <VisibilityIcon />
            </Button>
            <Button onClick={setModalIsOpenToTrue2}>
              <BallotIcon />
            </Button>
            <Button>
              <DeleteIcon onClick={onClick}/>
            </Button>
          </>
        )
      },
    },
  ]
  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Announcement Details<Button className="addbtn" onClick={setModalIsOpenToTrue3}>Add</Button>
          </h2>
          <div style={{ height: '75vh', width: '100%' }}>
            
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
              pageSize={8}
              components={{ Toolbar: GridToolbar }}
              getRowId ={(rows) => rows._id}
              onSelectionModelChange={(newSelection)=>{
                setrowId(newSelection[0])
               }}
            />
          </div>
        </div>
      </div>

      <div>
        <Modal isOpen={modalIsOpen1} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Announcement Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse1}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <AnnouncementViewM rowData = {rowData}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen2} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Update Announcement Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse2}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <AnnouncementUpdateM  rowData = {rowData} setUsers={setUsers} setModalIsOpenToFalse2={setModalIsOpenToFalse2}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen3} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Add Announcement</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button
                 onClick={setModalIsOpenToFalse3}>
                  <CloseIcon />
                </Button>
              </div>
            </div>

            <AnnouncementAddM setUsers={setUsers} setModalIsOpenToFalse3={setModalIsOpenToFalse3}/>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Viewannounce
