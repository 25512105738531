import React from 'react';
import Header from '../../component/Header';
// import Grassroot from './GrassrootInnovation';
import GrassrootMiddleContent from './GrassrootMiddleContent';
import Footer from '../../component/footer';

function GrassrootInnovation() {
  return (
    <>
    <Header/>
    {/* <Grassroot/> */}
    <GrassrootMiddleContent/>
    <Footer/>
    </>
  )
}

export default GrassrootInnovation