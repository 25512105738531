import React from 'react'
import Folderfile from "../../assets/images/EcoSystem/Idbicapital/folderfile.png"
import Message from "../../assets/images/EcoSystem/Idbicapital/message.png"


function FundInvestment() {
  return (
    <>
    <div className="mahaGrand-main pt-4" id="ourPartner">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="offset-lg-3 col-lg-7 col-md-12 col-sm-7">
              <div className='row'>
<div className='col-lg-12 col-md-12 col-sm-12'>
<h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pt-2 pb-4">
      Fund Investment Criteria
          </h2>
          <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
          The following is an illustrative list of criteria to be met by every applicant. The competent authorities reserve the right to add and edit other fund/company specific criteria. The final decision of investment / divestment shall be subject to approval from the competent authorities of the funds.
          </p>
          <ul className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
            <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">The Fund invests only in companies incorporated in Maharashtra under The Companies Act, 2013 (or registered under the erstwhile Act).</li>
            <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">The Company and its operations shall be domiciled in the state of Maharashtra. MITDF funds shall be wholly invested in the state of Maharashtra so as to provide economic benefit to the people of Maharashtra.</li>
            <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">The Company shall meet the criteria of Start-up as per start up India initiative by Government of India. The primary focus will be on impact startups and MSMEs, who are solving problems with innovative solutions in sectors including but not restricted to agriculture, healthcare, waste management, water management,fintech, cyber security, governance, smart infrastructure, education, skilling, clean energy and mobility and other sectors where the government of Maharashtra sees relevance.</li>
            <li>Investment in any company in any single investment round shall not be more ₹10 Crore or 10% of the committed corpus, whichever is lower
The fund will look for excellent entrepreneurial qualities; strong capability in innovation (preferably IP) based products/ services or highly scalable businesses with proof, track record and a clarity and focus of business models</li>
            <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">The company must have sound governance standards and ethical practices and a capability to put in place systems and processes which will support the growth plans of the company. It should also have a strong management team with relevant and complementary experiences.</li>
            <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">An auxiliary focus of the Fund shall also be on supporting the cause of women entrepreneurship.</li>
          </ul>
</div>
<div className='col-lg-12 col-md-12 col-sm-12'>
</div>
              </div>
                 <div className='bg-light my-5'>
                    <div className='row p-5' >
                <div className='col-lg-6 col-md-6 col-sm-6'>
                <div class="card m-1" >
                    <div className='d-flex justify-content-center pt-3'>
                    <img src={Folderfile} class="card-img-top" style={{width:'50px', height:'50px'}} alt="..." />
                    </div>
                        <div class="card-body">
                            <p class="card-text d-flex justify-content-center"><b>Application</b></p>
                            <div className='d-flex justify-content-center'>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfA50VPWO_YhZzP-PJD-Ci4Jf5zYs-9qHvGu5S1e19yWXSjMw/viewform" class="btn btn-sm cursor-pointer" style={{backgroundColor:'#AC8258', width:'100px', color:'#fff'}} target="_blank">Fill Form</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                <div class="card p-1 m-1">
                    <div className='d-flex justify-content-center pt-3'>
                    <img src={Message} class="card-img-top" style={{width:'50px', height:'50px'}} alt="..." />
                    </div>
                        <div class="card-body">
                            <p class="card-text d-flex justify-content-center"><b>Email</b></p>
                            <div className='d-flex justify-content-center'>
                                <span className='cursor-pointer'><a href="mailto:mitdf@idbicapital.com" style={{color:'#000'}}>mitdf@idbicapital.com</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
          </div>
        </div>
        
      </div>
 </>
  )
}

export default FundInvestment
