import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from '../startup/informationIcon';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

const serverUrl = environment.baseUrl;

const Basicdetails = (props) => {
 console.log(JSON.parse(sessionStorage.getItem('user_id')), "hhhhhhhhhhhhhhhhhhhhhh")

// const authToken = localStorage.getItem("token");
// console.log(authToken);


const authToken =sessionStorage.getItem("token");
console.log(authToken, "sessionTkennnnnn");

let initialFormObj={ 
  // nri_name: '',
  // email: '',
  // phone_number: '',
  linkedIn_url: '',
  company_name: '',
  job_title: '',
  profile:'',
  sector: '',
  address: '',
  // state: '',
  // district: '',
  // city: '',
  working_domain_experience:'',
  upload_logo: '',
    relevant_doc: [{
      relevantDoc:''
      }]
   }

    const [users, setUsers] = useState([]);
    const [sector, setSector] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
      fetch(serverUrl + "/sectors/allSectors")
        .then(res => res.json())
        .then(
          (data) => {
            setIsLoaded(true);
            setUsers(data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, []) 
  
    const handleSector = (values) => {
      const getSector = values.target.value;
      setSector( getSector);
  }

  const ref = useRef(null);

const handleClick = event => {
  console.log(event.currentTarget.id);

  console.log(ref.current.id);
};


const userId = sessionStorage.getItem("_id");
     const [usersmentor, setUsersMentor] = useState([]);
     useEffect(() => {
       fetch(serverUrl +`/auth/userdetail/${userId}`,
       {headers: {
        "auth-token":authToken
        
      }}
       )
         .then(res => res.json())
         .then(
           data => {
             setIsLoaded(true);
             console.log(data, "testuuuuuuuuuuuuuuuuuuuuuuuuuuu");
             setUsersMentor(data);
           },
           error => {
             setIsLoaded(true);
             setError(error);
           }
         );
     }, []);

  function handleSubmitNRIApplicationDetailsForm(values){
    console.log(values, "hiiiiiiiiii");
    const formData = new FormData();
    // formData.append("nri_name",values.nri_name)
    // formData.append("email",values.email)
    // formData.append("phone_number",values.phone_number)
    formData.append("linkedIn_url",values.linkedIn_url)
    formData.append("company_name",values.company_name)
    formData.append("job_title",values.job_title)
    // formData.append("profile",values.profile)
    formData.append("sector",values.sector)
    formData.append("address",values.address)
    formData.append("working_domain_experience",values.working_domain_experience)
    formData.append("upload_logo",values.upload_logo)
    formData.append("relevantDoc",values.relevantDoc)
fetch(serverUrl +"/nri/addnriDetail", 
    // sessionStorage.setItem("user_id",null),
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            // body:JSON.stringify(values, null, 2)            
          })
          .then((response) => {
            sessionStorage.type = ''
            console.log("RESPONSE", response)
            alert("form submitted")
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("applicationId", response.data.applicationId);
             console.log("roleee",  response.data.applicationId);   
                    
          })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      })
      //
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Submitted Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
          // setMessage(`thank you  for your message`);
          // props.setValue(props.value-1)  
  }
  
  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>handleSubmitNRIApplicationDetailsForm(values)
    
      }
    
    validationSchema={Yup.object({
      // nri_name: Yup.string().required('NRI Name is required'),
      // email: Yup.string().required('Email is required').email('Email is invalid'),
      // phone_number: Yup.number().required('Phone Number is required'),
      linkedIn_url: Yup.string().required('LinkedIn URL is required'),
      company_name: Yup.string().required('Company Name is required'),
      job_title: Yup.string().required('Job Title is required'),
      // profile: Yup.string().required('Profile is required'),
      sector: Yup.string().required('Sector is required'),
      address: Yup.string().required('Address is required'),
      // state: Yup.string().required('State is required'),
      // district: Yup.string().required('District is required'),
      // city: Yup.string().required('City is required'),
      working_domain_experience: Yup.string().required('Working Domain Experience is required'),
      upload_logo: Yup.string().required('Upload Logo is required'),
      acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
         relevant_doc: Yup.array().of(
            Yup.object().shape({
              relevantDoc: Yup.string().required('Relevant Documnet is required'),
                
            })
          )
             
         })}

      initialValues={JSON.parse(sessionStorage.getItem('user_id'))||initialFormObj}  
      // initialValues={
       
      // }
      // initialValues={{ 
      //   nri_name: '',
      //   email: '',
      //   phone_number: '',
      //   linkedIn_url: '',
      //   company_name: '',
      //   job_title: '',
      //   profile:'',
      //   sector: '',
      //   address: '',
      //   // state: '',
      //   // district: '',
      //   // city: '',
      //   working_domain_experience:'',
      //   upload_logo: '',
      //     relevant_doc: [{
      //       relevantDoc:''
      //       }]
      //    }}

      render={({ values,  setFieldValue, handleSubmit, handleChange}) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
           NRI Details
            </h2>
        </div>

        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Nri Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" onChange={handleChange} placeholder="Enter NRI Name" disabled readOnly={true} value={usersmentor.first_name} className="textInputDisable" name="nri_name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="nri_name" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                    E-Mail Address  <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="email" placeholder="Enter Email" className="textInputDisable" name="email" disabled readOnly={true} value={usersmentor.email} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="email" /><br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Phone Number <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="tel" placeholder="Enter Phone number" className="textInputDisable" name="phone_number" pattern="[789]{1}[0-9]{9}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" minlength="10" disabled readOnly={true} value={usersmentor.mobile_no} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="phone_number" /><br></br>
                    </div>
                  </div>


                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  LinkedIn URL <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter LinkedIn URL" className="textInput" name="linkedIn_url" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="linkedIn_url" /><br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Company <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter company name" className="textInput" name="company_name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="company_name" /><br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Job Title <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter job title" className="textInput" name="job_title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="job_title" /><br></br>
                    </div>
                  </div>
                  
                  {/* <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Profile Photo <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="profile" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("profile", e.target.files[0].name);
                      }} />      
                    <div className="text-danger">
                    <ErrorMessage className="error" name="profile" /><br></br>
                    </div>
                  </div> */}


                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Sector<span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                  <select type="select" name="sector" className="form-control form-select size"
                    value={values.sector}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("sector", e.target.value)
                      handleSector(e)
                    }}
                  >
                    <option value={""}>Select Interested Sector</option>
                      {
                        users.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>



                    <div className="text-danger">
                    <ErrorMessage className="error" name="sector" /><br></br>
                    </div>
                  </div>
        </div>
        <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd pt-5">
                  Location of Registeration
                </h2>

                <div className="row">
                  <div className="col-md-12 pt-4">
                    <label className="pb-2 label-txt">
                      Address<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="address" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="address" /><br></br>
                    </div>
                  </div>
                </div>
                  <div className="row">
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Working Domain Experience
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Working Domain Experience"  className="textInput" name="working_domain_experience" />
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="working_domain_experience" />
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 pt-4">
                  <label className="label-txt">
                    Upload Logo
                    
                      <InformationIcon />
                    </label>
                    <div class="form mt-1 ml-5 pl-5 col-lg-12 col-md-12">
                      <div class="btn float-left imgbackground form-control">
                      <input id="file" name="upload_logo" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("upload_logo", e.target.files[0]);
                      }} /> 
                         <div className="text-danger">
                          <ErrorMessage className="text-danger" name="upload_logo" />
                           </div>
                       
                      </div>
                    </div>
                  </div>
                  </div>
       
       
          <FieldArray
            name="relevant_doc"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>



                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"90%"}}>Upload relevant documents</TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>

      



                {values.relevant_doc && values.relevant_doc.length > 0 ? (


                  values.relevant_doc.map((friend, index) => (
                    
                     

                    <TableBody  className="data-bg-color d-flex justify-content-between">

              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell align="center" className="d-flex align-items-center" style={{width:"90%"}}>
              <input id="file" name={`relevant_doc.${index}.relevantDoc`} accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue(`relevant_doc.${index}.relevantDoc`, e.target.files[0]);
                      }} /> 
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name={`relevant_doc.${index}.relevantDoc`} /><br></br>
                        </div>
             </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
              </TableBody>  
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("user_id", JSON.stringify(values))}} 
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                          ref={ref}  onClick={handleClick}
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>

              </div>
            )}
          />
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default Basicdetails;