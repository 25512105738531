import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Navbar from '../../component/Navbar';
import Header from '../../component/Header';
import IncubationProgram from './IncubationProgram';
import Footer from '../../component/footer';

function IncubationMain() {
  return (
    <>
    <Header/>
    <IncubationProgram/>
    <Footer/>
  </>
  )
}

export default IncubationMain