import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Navbar from '../../component/Navbar';
import Header from '../../component/Header';
import Wanttogettouch from './wanttogettouch';
// import MeetMentors from './MeetMentors';
// import MentorProfile from './MentorProfile';
import Footer from '../../component/footer';
import MentorProfile from './mentorProfile';


function Mentor() {
  return (
    <>
   {/* <Navbar/> */}
   <Header/>
   {/* <Wanttogettouch/> */}
   {/* <MeetMentors/> */}
  <MentorProfile />

   {/* <MentorProfile /> */}
   <Footer/>

  </>
  );
}

export default Mentor;
