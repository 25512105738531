import React, {useEffect, useState } from 'react'
import '@mui/x-data-grid-generator'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { environment } from '../api';
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import NriViewModel from './nriViewMod'

function Viewnri() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
    const authToken =sessionStorage.getItem("token");
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true)
  }

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }
  const serverUrl = environment.baseUrl;
  const [rows, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rowId, setrowId] = useState('');
  const [rowData, setRowData] = useState({
    basicDetails:[]
  })
  useEffect(() => {
    fetch(serverUrl +"/nri/allnriDetail",
    {headers: {
      // accept: "application/json",
      "auth-token":authToken
      
    }})
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setUsers(data.data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
      }, [])

   useEffect(()=>{
      rows.map((el)=>{
        if(el._id === rowId)
          setRowData({...rowData, basicDetails: el})

      })
    },[rowId])

const columns = [
  { field: '_id', headerName: 'Sr.No.', width: 60 },
  { field: 'nri_name', headerName: 'Name', width: 120 },
  { field: 'email', headerName: 'Email', width: 120 },
  { field: 'phone_number', headerName: 'Phone No.', width: 150 },
  { field: 'linkedIn_url', headerName: 'URL', width: 120 },
  { field: 'company_name', headerName: 'Company', width: 110 },
  { field: 'job_title', headerName: 'Job Title	', width: 110 },
  { field: 'status', headerName: 'Status	', width: 110 },
  { field: 'Action', headerName: 'Action', sortable: false, width: 200,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation() // don't select this row after clicking

        const api = params.api
        const thisRow = {}

        api
          .getAllColumns()
          .filter((c) => c.field !== '__check__' && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
          )

        return alert(JSON.stringify(thisRow, null, 4))
      }
      return (
        <>
        <Button onClick={setModalIsOpenToTrue}>
          <VisibilityIcon />
        </Button>
        
      </>
    )
    },
    },
    ]
  return (
    <>
    <div className="bannermain">
    <div className="container">
      <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
        Nri Details
      </h2>
      <div style={{ height: '75vh', width: '100%' }}>
        {/* <div className="d-flex justify-content-end">
          <Button type="submit" Name="submit">
            Add
          </Button>
        </div> */}
        <DataGrid
          className="pb-3"
          rows={rows}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: GridToolbar }}
          onSelectionModelChange={(newSelection)=>{
            setrowId(newSelection[0])
           }}
          getRowId ={(rows) => rows._id} 
        />
      </div>
    </div>
    </div>
    <div>
    <Modal isOpen={modalIsOpen} className="ModOverlay">
      <div className="viewMod">
        <div className="d-flex justify-content-end">
          <Button onClick={setModalIsOpenToFalse}>
            <CloseIcon />
          </Button>
        </div>
        <NriViewModel rowData = {rowData}/>
      </div>
    </Modal>
    
    </div>
    
    </>
  )
}

export default Viewnri