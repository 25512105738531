import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Investor from './investor'; 
import Header from '../component/HeaderAdmin';

function investorMain() {
    return (
        <>
      <Header/>
      <Investor/>
      </>
    );
  }
  
export default investorMain;