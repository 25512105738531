import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap-v5';
import Arrow from '../assets/images/about/arrow.png';
// import Grassroot_Innovation from '../assets/images/ecosystemdetails/Grassroot_Innovation.png';
// import _Entrepreneurship_Cell from '../assets/images/ecosystemdetails/Women_Entrepreneurship_Cell1.png';
// import Fintech from '../assets/images/ecosystemdetails/Fintech1.png';
// import Covid_Resources from '../assets/images/ecosystemdetails/Covid_Resources1.png';
// import MAITRI from '../assets/images/ecosystemdetails/MAITRI1.png';
// import Entrepreneurship_Development from '../assets/images/ecosystemdetails/Entrepreneurship_Development1.png';
import {Link} from "react-router-dom"; 
import { environment } from '../api';



function EcosystemDetails() {
  const serverUrl = environment.baseUrl;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(serverUrl +"/ecosystemCard/allEcosystem")
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setUsers(data);
                console.log(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
  }, [])



  return (
    <>
       <div className=''>
       <div className='header'>
 {/* <h1>dsfdsfsd</h1> */}
       </div>
       {users.map((user,index) =>
       
        index % 2 ? (
              <>
              
             <div className='ecosystem-main'>
             <div className='col-lg-6 col-md-12 col-sm-12 left-border ecosystem-card flex-item'>
                 <div className=' ecosystem-card-padding'>
                    <button className='btn-ecosystem font-style-normal font-weight-500 mt-3 font-10-12'>Initiative</button>
                      <h2 className='font-style-normal font-weight-600 font-28-36 font-18-23 echosystemcard-heading'>{user.title}</h2>
                      <p className='font-style-normal font-weight-normal font-20-26 font-12-16 echosystemcard-text '>
                      {user.description}                      </p>
                      <div className='bluebtn pt-2 d-none d-lg-block'>
                        <Button className="btn-primary" onClick={()=> window.open(user.link, "_blank")}>
                              Learn More
                            <span className='inno-arrow'>
                              <img src={Arrow} alt="Arrow" />
                            </span>
                        </Button>
                     </div>
                 </div>
                 <div className='mob-bluebtn align-items-center mobbtn-main d-sm-block  d-lg-none'>
                    <a href= {user.link} target="_blank">
                    
                      <div className='d-flex justify-content-center align-items-center py-2'>
                        Learn More
                        <div className='inno-arrow'>
                        <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 16 16"
                                      height="20px"
                                      width="30px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      >
                                      <path
                                          fill-rule="evenodd"
                                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                      />
                                  </svg>
                        </div>
                        </div>  
                  
                    </a>
                </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                <img src={user.image}  className="ecosystem-img flex-item" />
                </div>
              </div>
              
              </>
             ) :
             (
              <>
              <div className='ecosystem-main'>
              <div className='col-lg-6 col-md-12 col-sm-12 flex-itemRevers'>
                <img src={user.image}  className="ecosystem-img" />
                </div>
                
                <div className='col-lg-6 col-md-12 col-sm-12 right-border ecosystem-card flex-itemRevers'>
                   <div className='col-lg-12 col-md-12 col-sm-12'>
                   <div className='ecosystem-card-padding'>
                   <button className='btn-ecosystem font-style-normal font-weight-500 mt-3 font-10-12'>Initiative</button>
                      <h2 className='font-style-normal font-weight-600 font-28-36 font-18-23 echosystemcard-heading'>{user.title}</h2>
                      <p className='font-style-normal font-weight-normal font-20-26 font-12-16 echosystemcard-text'>
                      {user.description}                      </p>
                      <div className='bluebtn pt-2 d-none d-lg-block'>
                        <Button className="btn-primary" onClick={()=> window.open(user.link, "_blank")}>
                        {/* <a href= {user.link}> */}
                        Learn More
                            <span className='inno-arrow'>
                              <img src={Arrow} alt="Arrow" />
                            </span>
                        {/* </a> */}
                        </Button>
                     </div>
                 </div>
                 <div className='mob-bluebtn align-items-center mobbtn-main d-sm-block d-lg-none'>
                    <a href= {user.link}  target="_blank">
                  
                      <div className='d-flex justify-content-center align-items-center py-2  '>
                        Learn More
                        <div className='inno-arrow'>
                        <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 16 16"
                                      height="20px"
                                      width="30px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      >
                                      <path
                                          fill-rule="evenodd"
                                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                      />
                                  </svg>
                        </div>
                        </div>  
                   
                    </a>
                </div>
                </div>
                </div>
             </div>
              </>
             ))}
        
          
          </div>        
    </>
  );
}

export default EcosystemDetails;


