import React from 'react'
import { Link } from "react-router-dom";

function MahaGrandContactus() {
  return (
    <>
       <div className='mahaContactus-main' id='idContact'>
       <h3 className='mahaContactus-heading'>Contact Us</h3>
         <p className='mahaContactus-text'>For any other questions about the application process, please email <u>team@msins.in</u></p>
       </div> 

       <div className="mobobluebtnpx loginbtnMobo">
                      <div className="mob-primarybtnyellow d-lg-none flex-item poschange"> 
                      <a href='https://docs.google.com/forms/d/e/1FAIpQLSfixu9r0oVly6-utreOf_d-RjBqbrcG_-2TRDsDw1ATJ-XkVQ/viewform' >

                          <div className="d-flex justify-content-center align-items-center py-2">
                            <span className="regular_font font-18-22 font-style-normal font-weight-400">
                              {" "}
                              Apply Now
                            </span>
                            <div className="inno-arrow">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="25px"
                                width="35px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                              </svg>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
    </>
  )
}

export default MahaGrandContactus