import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function ContactQueryViewM(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Name :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <div className="">
              <span>{props.rowData.conQuery.full_name}</span>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Email :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.conQuery.email}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Type of Grievance :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.conQuery.typeOfQuery}</span>
          </div>
        </div>
        <hr></hr>
        {/* <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Country Code :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.conQuery.contry_code}</span>
          </div>
        </div>
        <hr></hr> */}
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Phone No :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.conQuery.mobileNo}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> subject :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.conQuery.subject}</span>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-2">
            <div className="">
              <label>
                {" "}
                <b> Message :</b>
              </label>
            </div>
          </div>
          <div className="col-md-10">
            <span>{props.rowData.conQuery.message}</span>
          </div>
        </div>
        <hr></hr>
      </TabPanel>
    </Box>
  )
}
