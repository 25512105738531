import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const CountryUpdateM = (props) => {
  
// console.log(props)
const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitCountryUpdateForm(values){
    
    const formData = new FormData();
   
    formData.append("name",values.name)
    formData.append("code",values.code)
    formData.append("country_code",values.country_code)
   
    fetch(serverUrl +`/countryList/${props.rowData.country._id}`,
         {
          method: 'PUT',
          body: formData,
            
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("Are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitCountryUpdateForm(values)
    }
    validationSchema={Yup.object({
        name: Yup.string().required('Enter Name is Required'),
        code: Yup.string().required('Enter Code is Required'),
        country_code: Yup.string().required('Enter Country Code is Required'),
       })}
      initialValues={{ 
      name: props.rowData.country.name,
      code: props.rowData.country.code,
      country_code:props.rowData.country.country_code,
       }}

      

      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"                    
                      onChange={handleChange}
                      placeholder="Enter Name" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Code{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Code Name" className="textInput" name="code" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="code" /><br></br>
                    </div>                   
                  </div>

                 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Country Code <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter Country Code" className="textInput" name="country_code" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="country_code" /><br></br>
                    </div>
                  </div>

                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default CountryUpdateM;
