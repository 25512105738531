import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import InformationIcon from "../startup/informationIcon";
import PDFGuideline from "../assets/document/termsandcondition/Incubation.pdf";
import PDFTandC from "../assets/document/termsandcondition/TermsCondition.pdf";


export default function termsandconditions(props) {
    
  return (
    <div className="bannermain">
      <div className="container">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                Terms &amp; conditions
              </h2>
            </div>
            
              <p className="label-txt">
                Please go through the terms and conditions mentioned below under
                “GR for guidelines of incubation center”
              </p>
              <div className="row"></div>

              <div className="">
                <form>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="pdf-hgt">
                        <embed
                          src={PDFGuideline}
                          allowFullScreen
                          className="pdf-align"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 pt-4">
                    <div className="pdf-hgt">
                      <iframe
                        src={PDFTandC}
                        className="pdf-align"
//                         allowFullScreen
                      ></iframe>
                    </div>
                    </div>
                    <div class="radiobuttons d-flex">
                      <div class="rdio rdio-primary radio-inline">
                        <input
                          name="radio"
                          value="1"
                          id="radio1"
                          type="radio"
                          checked
                        />
                        <label for="radio1">
                          I have read the GR and underdtood the process
                          thoroughly
                        </label>
                      </div>
                    </div>
                    <div className="justify-content-center d-flex py-3">
                      <Stack direction="row" spacing={2}>
                        {/* <Button variant="contained">Previous</Button> */}
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow"
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow" onClick={()=>{props.setValue(props.value+1)}}
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                  </div>
                </form>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
