import React from 'react';
import StartupWinnerBanner from './startupWinnerBanner';
import Header from '../../component/Header';
import Footer from '../../component/footer';
import SWinner from './swinnerProfile'
// import SearchWinners from './searchWinners';
// import WinnerList from './winnersList'
// import StartupWinnerListProfile from './startupWinnerListProfile'


export default function StartupWeekWinner() {
  return (
    <>
      <Header/>
      <SWinner />
      {/* <StartupWinnerBanner /> */}
      {/* <SearchWinners />
      <WinnerList /> */}
      {/* <StartupWinnerListProfile /> */}
      <Footer />
    </>
  )
}
