import React from 'react'
import Header from '../../component/Header';
import Footer from '../../component/footer';
import Maitri from './MaitriBanner';
import MaitriContent from './MaitriMiddleContent';


function MaitriMain() {
  return (
    <>
    <Header/>
    {/* <Maitri/>
    <MaitriContent/> */}
    <Footer/>
    </>
  )
}

export default MaitriMain