// import React,{Suspense} from 'react';
import React, { useState, useEffect, useRef,Suspense } from 'react'
import {
  BrowserRouter as Router,
  Route,
Routes,
withRouter,
  Switch,
  Navigate
} from "react-router-dom";

import Footer from './component/footer';
// import About from './About/about';
import EcoSystem from './EcoSystem/EcoSystem';
import MaharashtraVirtualIncubationCenter from './EcoSystem/Idbicapital/IdbicapitalMain';
import Mentor from './EcoSystem/Mentor/Mentor';
import PressRelese from './Resources/media/pressRelese';
// import LandingMain from './landing_page/LandingMain';
import Resources from './Resources/Resources';
// import Contact from './contactUs/Contact';
import Login from './component/Login';
import Register from './component/Register';
import GrassrootInn from './EcoSystem/GrassrootInnovation/GrassrootInnovation';
import WomenEntrepreneure from './Resources/WEC/WomenEntrepreneure';
import Media from './Resources/media/Media';
import PressNote from './Resources/media/pressNote';
import Terms from './component/terms';
import TenderNotification from './component/TenderNotification';
import Careers from './component/Careers';
import Disclaimer from './component/disclaimer';
import AccessibilityHelp from './component/accessibility_help';
import SecurityPolicy from './component/securitypolicies';
import Initiatives from './Initiative/Initiatives';
import Incubator from './EcoSystem/Incubators/Incubator';
import StartupWeekWinner from './Initiative/MHStartupWinner/startupWeekWinner';
import MahaGrandMain from './Initiative/MaharashtraGrandChallenge/mahaGrandMain';
import MahaHackathonMain from './Initiative/MahaHackathon/mahaHackathonMain'
import MaharashtraStartupMain from './Initiative/MaharashtraStartupWeek/MaharashtraStartupMain';
import Startup from './startup/LandingMain';
import IPR from './IPR/IprMain';
import InnovationDepartment from './Initiative/InnovationDepartment';
import ProfessionalFellows from './Initiative/ProfessionalFellows';
import EventDetails from './Initiative/EventDetails/eventDetailsMain';
import BusinessPlan from './Initiative/BusinessPlanCompetition/businessPlanMain';
import Intellectual from './Initiative/IntellectualPropertyRights/IntellectualPRMainFile';
import QualityTesting from './Initiative/QualityTesting/qualityTestingMain';
import StartupYatraWinner from './Initiative/StartupYatraWinners/startupYatraWinnersMain';
import StartupMaitri from './EcoSystem/MAITRI/MaitriMain';
import IncubatorAdmin from './incubator/IncubatorMain';
import MentorAdmin from './mentor/mentorMain';
import InvestorAdmin from './investor/investorMain';
import NRIAdmin from './nri/nriMain';
import SuperAdmin from './superadmin/superadmin';
import Dashboard from './superadmin/dashboard';

import Viewlandingbanner from './cms/landingBanner/viewlandingbanner';
// import ForgetPassword from './component/ForgetPassword';
import ResetPW from './component/resetPW';
import InitiattiveListDetails from './Initiative/InitiativeListDetails'
// import Personalinformation from './mentor/personalinformation';
import Personalinformation from './startup/personalinformation'
import Startupyatra from './Initiative/StartupYatra/startupyatraMain';
import DistrictLogin from './DistrictLogin/DistrictLoginMain';
import ViewMentorDetails from './mentor/viewMentorDetails';
import ViewIncubation from './EcoSystem/Incubation/IncubationMain';


import Test from './component/test'

import EmailVerify from "./component/EmailVerify/index";
import ForgotPassword from "./component/ForgetPassword";
import PasswordReset from "./component/resetPW";
import ProtectedRoute from './component/ProtectedRoute';
// import GoogleLogin  from './component/GoogleLogin';
// import 
const Home = React.lazy(() => import("./landing_page/LandingMain"));
const About = React.lazy(() => import("./About/about"));
const Contact = React.lazy(() => import("./contactUs/Contact"));

const Loader=(<div>
 Loading.........
</div>)

function Main() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };
  const user = sessionStorage.getItem("token");
  //Pages
// const Home = () => {
//   return (
//     <div>
//       <h1>Home</h1>
//     </div>
//   );
// };



  return (
    <>
 <Suspense fallback={Loader}>

 
      <Router>
      {/* <Header/> */}
     
        <Routes>
        {/* <Header/> */}
        {/* <Route path="/EcoSystem" element={<Header />}></Route> */}
         {user && <Route path="/" element={<Home />}></Route>} 
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/EcoSystem" element={<EcoSystem />}></Route>
          <Route path="/mitdf" element={<MaharashtraVirtualIncubationCenter />}></Route>
          <Route path="/test/:id" element={<Test />}></Route>
          <Route path="/Mentor" element={<Mentor />}></Route>
          <Route path="/pressNote" element={<PressNote/>}></Route>
          <Route path="/Incubator" element={<Incubator />}></Route>
          <Route path="/TenderNotification" element={<TenderNotification />}></Route>
          <Route path="/Careers" element={<Careers />}></Route>
          <Route path="/Resources/" element={<Resources />}></Route>
          <Route path="/WomenEntrepreneure" element={<WomenEntrepreneure />}></Route>
          <Route path="/mahaGrandMain" element={<MahaGrandMain />}></Route>
          <Route path="/mahaHackathonMain" element={<MahaHackathonMain />}></Route>
          <Route path="/MaharashtraStartupMain" element={<MaharashtraStartupMain />}></Route>
          <Route path="/Initiative" element={<Initiatives />}></Route>
          <Route path="/InnovationDepartment" element={<InnovationDepartment />}></Route>
          <Route path="/ProfessionalFellows" element={<ProfessionalFellows />}></Route>
          <Route path="/EventDetails" element={<EventDetails />}></Route>
          <Route path="/StartupWeekWinner" element={<StartupWeekWinner />}></Route>
          <Route path="/GrassrootInn" element={<GrassrootInn/>}></Route>
          <Route path="/Startupyatra" element={<Startupyatra/>}></Route>
          <Route path="/BusinessPlan" element={<BusinessPlan/>}></Route>
          <Route path="/StartupYatraWinner" element={<StartupYatraWinner/>}></Route>
          <Route path="/StartupMaitri" element={<StartupMaitri/>}></Route>
          <Route path="/Media" element={<Media/>}></Route>
          <Route path="/pressRelese" element={<PressRelese/>}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/Intellectual" element={<Intellectual/>}></Route>
          <Route path="/QualityTesting" element={<QualityTesting/>}></Route>
          <Route path="/IprMain" element={<IPR/>}></Route>
          {/* <Route path="/Startup" element={<Startup/>}></Route>
          <Route path="/Incubator_admin" element={<IncubatorAdmin/>}></Route> */}
          {/* <Route path="/Mentor_admin" element={<MentorAdmin/>}></Route> */}
          {/* <Route path="/Investor_admin" element={<InvestorAdmin/>}></Route>
          <Route path="/NRI_admin" element={<NRIAdmin/>}></Route> */}

          <Route path="/Mentor_admin" element={
          <ProtectedRoute allowedTypes={["Mentor"]} adminRoute>
          <MentorAdmin/>
          </ProtectedRoute>
          }></Route>
          
          <Route path="/Investor_admin" element={
          <ProtectedRoute allowedTypes={["Investor"]} adminRoute>
          <InvestorAdmin/>
          </ProtectedRoute>
          }></Route>
          <Route path="/NRI_admin" element={
          <ProtectedRoute allowedTypes={["NRI"]} adminRoute>
          <NRIAdmin/>
          </ProtectedRoute>
          }></Route>
             <Route path="/Incubator_admin" element={
          <ProtectedRoute allowedTypes={["Incubator"]}  adminRoute>
          <IncubatorAdmin/>
          </ProtectedRoute>
          }></Route>  
           <Route path="/Startup" element={
          <ProtectedRoute allowedTypes={["Startup"]} adminRoute>
          <Startup/>
          </ProtectedRoute>
          }></Route>   
          <Route path="Register" element={<Register />}></Route>
          <Route  path="/Login"
            element={<Login/>}></Route>
          {/* <Route path="/Superadmin" element={
          <ProtectedRoute adminRoute={["ADMIN"]} >
          <SuperAdmin/>
          </ProtectedRoute>
          }></Route> */}
          <Route path="/Superadmin" element={<SuperAdmin/>}></Route> 

           <Route path="/Dashboard" element={
          <ProtectedRoute adminRoute={["ADMIN"]} >
          <Dashboard/>
          </ProtectedRoute>
          }></Route> 
           <Route path="/banner" element={<Viewlandingbanner/>}></Route> 


          {/* <Route path="/Superadmin" element={<SuperAdmin/>}></Route> */}
          {/* <Route path="/Dashboard" element={<Dashboard/>}></Route> */}
          {/* <Route path="/ForgetPassword" element={<ForgetPassword/>}></Route> */}
          <Route path="/DistrictLogin" element={<DistrictLogin/>}></Route>
          <Route path="/resetPW" element={<ResetPW/>}></Route>
          <Route path="/InitiattiveListDetails/:id" element={<InitiattiveListDetails/>}></Route>

          <Route path="/ViewMentorDetails/:id" element={<ViewMentorDetails/>}></Route>

          <Route path="/personalinf/:id" element={<Personalinformation/>}></Route>

          <Route path="/terms" element={<Terms/>}></Route>
          <Route path="/disclaimer" element={<Disclaimer/>}></Route>
          <Route path="/accessibility_help" element={<AccessibilityHelp/>}></Route>
          <Route path="/securitypolicies" element={<SecurityPolicy/>}></Route>
          {/* <Route path="/googlelogin" element={<GoogleLogin/>}></Route> */}

			<Route path="/users/:id/verify/:token" element={<EmailVerify />} />
      <Route path="/IncubationMain" element={<ViewIncubation />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/password-reset/:id/:token" element={<PasswordReset />} />
        </Routes>

        {/* <Footer /> */}
      </Router>
      </Suspense>
    </>
  );
}

export default Main;
