import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";

import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const FundingSourcesAddM = ({setUsers, setModalIsOpenToFalse3}) => {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  
  function handleSubmitFundingSourcesAddForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("description",values.description)
    formData.append("link",values.link)
    formData.append("logo",values.logo)
   
    console.log(formData)
    fetch(serverUrl +"/ecosystemFunding/addFunding", 
         {
            method: "POST",
            body: formData,
           
            
          })

          
          .then(
            // res=>res.json()
          
            fetch(serverUrl + '/ecosystemFunding/allFunding')
            .then((res) => res.json())
            .then(
              (data) => {
                setIsLoaded(true)
                setUsers(data)
              },
              (error) => {
                setIsLoaded(true)
                setError(error)
              },
            )
   
          
          
          
          )
          .then((res) => setModalIsOpenToFalse3())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
          //   // window.location.reload();
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }




          
  }
  console.log("called")

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values, {resetForm}
      ) =>
     { console.log(values )
      handleSubmitFundingSourcesAddForm(values)
      resetForm({values:''}) 
    }
    }
    
    validationSchema={Yup.object({
    description: Yup.string().required('Enter Description is Required'),
    link: Yup.string().required('Enter Link is Required'),
    logo: Yup.string().required('Logo  is Required'),
       })}
      initialValues={{ 
      description: '',
      link: '',
      logo:'',
    
       }}

      render={({ values, setFieldValue ,handleSubmit}) => (
        <Form onSubmit={(e)=>{
          
          handleSubmit(e)}}>
        <div className="row">
       
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Link <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div>

                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Logo <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="logo" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("logo", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="logo" /><br></br>
                    </div>
                  </div>  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                       <Field as="textarea" placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" />
                    </div>
                  </div>                              
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default FundingSourcesAddM;
