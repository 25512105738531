import React from 'react';
// import InitiativesBanner from './InitiativesBanner';
// import InitiativesList from './InitiativesList';
// import Navbar from '../component/Navbar';
import Header from '../component/Header';
import Footer from '../component/footer';
import Init from './Initiative'
// import SearchInitiative from './searchInitiative'


export default function Initiatives() {

  return (
    <>
      {/* <Navbar /> */}
      <Header/>
    {/* <InitiativesBanner/> */}
    {/* <InitiativesList/> */}
    <Init />
    {/* <SearchInitiative /> */}
    <Footer/>
    {/* <Asd /> */}
    </>
  )
}
