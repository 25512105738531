import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };



export default function InvestorViewMod(props) {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
       
        <Tab className="txt-main-style" label="Basic details" {...a11yProps(0)} />
        <Tab className="txt-main-style" label="Other Details" {...a11yProps(1)} />
       
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b> Investor’s Name :</b>
            </label>
            <span> {props.rowData.basicDetails.investor_name}</span>
          </div>
          <hr></hr>
        </div>
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Firm Name :</b> 
            </label>
            <span>{props.rowData.basicDetails.firm_name}</span>
            </div>
          <hr></hr>
          </div>
        </div>
     

      <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Email :</b> <span>{props.rowData.basicDetails.email}</span>
            </label>
            </div>
          <hr></hr>
          </div>
       
        <div className="col-md-6">
        <div className="">
          <label>
            {" "}
            <b>Phone No.:</b> <span>{props.rowData.basicDetails.phone_number}</span>
          </label>
          </div>
          <hr></hr>
        </div>
      </div>
    
     <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>State :</b> <span> {props.rowData.basicDetails.state}</span>
            </label>
            </div>
          <hr></hr>
          </div>
       
        <div className="col-md-6">
        <div className="">
          <label>
            {" "}
            <b>District:</b> <span> {props.rowData.basicDetails.district}</span>
          </label>
          </div>
          <hr></hr>
        </div>
      </div> 
      <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>City :</b> <span> {props.rowData.basicDetails.city}</span>
            </label>
            </div>
          <hr></hr>
          </div>
          </div>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Address :</b> 
            </label>
            <span> {props.rowData.basicDetails.reg_address}</span>
          </div>
          <hr></hr>
        </div>
        <div className="col-md-6">
        <div className="">
          <label>
            {" "}
            <b>Website URL :</b>
          </label>
          <span className="text-justify">
          {props.rowData.basicDetails.website_url}
          </span>
        </div>
        <hr></hr>
        </div>
        </div>
        <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>LinkedIn URL :</b> 
            </label>
            <span>{props.rowData.basicDetails.linkedIn_url} </span>
          </div>
          <hr></hr>
        </div>
        <div className="col-md-6">
        <div className="">
          <label>
            {" "}
            <b>Interested Sector :</b>
          </label>
          <span className="text-justify">
          {props.rowData.basicDetails.sector}
          </span>
        </div>
        <hr></hr>
        </div>
        </div>
        <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Choice of startup stage for Investing :</b> 
            </label>
            <span>  {props.rowData.basicDetails.choice_startup_stage_invest}</span>
          </div>
          <hr></hr>
        </div>
        <div className="col-md-6">
        <div className="">
          <label>
            {" "}
            <b>Portfolio Description :</b>
          </label>
          <span className="text-justify">
          {props.rowData.basicDetails.portfolio_desc}
          </span>
        </div>
        <hr></hr>
        </div>
        </div>
        <div className="row">
        <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Success Stories :</b> 
            </label>
            <span>  {props.rowData.basicDetails.success_stories}</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
        <div className="col-md-6">
        <div className="">
          <label>
            {" "}
            <b>Logo :</b>
          </label>
          <span> <img src={props.rowData.basicDetails.upload_logo} style={{width:'20%', height:'100%'}} /></span>
        </div>
        <hr></hr>
        </div>
        </div>
        {/* <div className="row">
         <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload Relevant Documents :</b> 
            </label>
            <span> {props.rowData.basicDetails.relevant_document}</span>
          </div>
          <hr></hr>
        </div> 
        
        </div> */}
     
    </TabPanel>
  </Box>
  )
}
