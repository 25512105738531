import React from 'react'
import { Redirect } from 'react-router'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({children, allowedTypes,adminRoute}) => {
  
    const token = sessionStorage.getItem("token")
    const role = sessionStorage.getItem("role")
    const user = JSON.parse(sessionStorage.getItem("user"))
    const admin = JSON.parse(sessionStorage.getItem("admin"))
    // console.log(user.role, "ffffffffffffffffffffffffff")

    const isAllowed = adminRoute || allowedTypes.includes(user.type); 

    console.log(adminRoute, "adminlog");
    // const isAllowed = (admin.role === "ADMIN" && adminRoute) ||(user.role === "USER" &&  allowedTypes.includes(user.type)); 
    // const isAllowed = (admin.role === "ADMIN" && adminRoute) || (allowedTypes.includes(user.type) && user.role === "USER"); 
    // const isAllowed =  adminRoute.includes(admin.role) || allowedTypes.includes(user.type);  
    if(token && isAllowed ){
        return <>{children}</>
    }else{
        return(
            <div>
            {adminRoute ? <Navigate to="/login" />:<Navigate to="/Superadmin" /> }
            </div>
          
            
        ) 
       
    }

}

export default ProtectedRoute
