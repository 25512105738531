import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Startupcopyrightmod(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab className="txt-main-style" label="Basic Details" {...a11yProps(0)} />
          <Tab className="txt-main-style" label="Incorporation Details" {...a11yProps(1)} />
          <Tab className="txt-main-style" label="Founder Details" {...a11yProps(2)} />
          <Tab className="txt-main-style" label="Point of Contact for IPR filings" {...a11yProps(3)} />
          {/* <Tab className="txt-main-style" label="Patent Details" {...a11yProps(4)} />*/}
          <Tab className="txt-main-style" label="Revenue Details" {...a11yProps(4)} />
          {/*<Tab className="txt-main-style" label="Funding" {...a11yProps(6)} />
          <Tab className="txt-main-style" label="Current statistics" {...a11yProps(7)} /> */}
          <Tab className="txt-main-style" label="Revenue Details(Last 3 financial year from the date of appliaction)" {...a11yProps(5)} />
          <Tab className="txt-main-style" label="DPIIT Registration Details" {...a11yProps(6)} />
          <Tab className="txt-main-style" label="Author Details" {...a11yProps(7)} />
          
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> Name of Company :</b>
              </label>
              <span> Vaibhav</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Is your Company registered in Maharashtra:</b> 
              </label>
              <span> No</span>
              </div>
            <hr></hr>
            </div>
          </div>
       

        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Website URL :</b>
                <span> vaibhav@VPsolutions.com</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload Proof of being incorporated in Maharashtra:</b> <span> 9876543210</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Registered Address as specified  on incorporation Certificate:</b> <span> Mayuri</span>
              </label>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Communication Address:</b> <span> Mayuri</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Short brief about the Company:</b> <span> Mayuri</span>
              </label>
            </div>
            <hr></hr>
          </div>
          
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Firm Registration Date:</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.name_of_startup}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Type of entity:</b>
            </label>
            <span className="text-justify">
              {/* {props.rowData.basicDetails.product_name} */}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          
        
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Contact :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Email :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Linkedin Link :</b>{" "}
               
              </label>
              <span> {props.rowData.basicDetails.biz_model_type}</span>
            </div>
            <hr></hr>
          </div>
          
        </div>
      
        
        
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Pitch Deck :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pitch uploadsDetails :</b>
            </label>
            <span> 06/09/2022</span>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Other Description :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Explainer Video :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Roadblocks :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Awards/Achievements :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Source of information :</b>{" "}
               
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Please specify Source of information :</b>{" "}
             
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>First Name :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Last Name :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>E-mail Address :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Phone Number :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Address for Communication:</b>{" "}
               
              </label>
              {/* <span> {props.rowData.basicDetails.biz_model_type}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Parmanant Address:</b>{" "}
               
              </label>
              {/* <span> {props.rowData.basicDetails.biz_model_type}</span> */}
            </div>
            <hr></hr>
          </div>
          
        </div>
      
         
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Financial year :</b> 
              </label>
              {/* <span> {props.rowData.uploadsDetails.company_logo}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Audited Financial Number :</b> 
            </label>
            {/* <span> {props.rowData.uploadsDetails.company_PAN_card}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Proof(Audited Statment from CA) :</b>{" "}
                
              </label>
              {/* <span> {props.rowData.uploadsDetails.company_PAN_card}</span> */}
            </div>
            <hr></hr>
          </div>
          
       </div>
        
        
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Registration ID Number :</b>{" "}
               
              </label> */}
              {/* <span> {props.rowData.uploadsDetails.company_registration_ID}</span> */}
            {/* </div>
            <hr></hr>
          </div> */}
          {/* <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>DPIIT Number :</b> 
            </label>
            <span> {props.rowData.uploadsDetails.}</span>
          </div>
          <hr></hr>
        </div> */}
        {/* </div> */}
        {/* <div className="row"> */}
          {/* <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>DPIIT Certificate :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div> */}
          {/* <div className="col-md-6">
          <div className="">
            <label>
              {" "} */}
              {/* <span> {props.rowData.uploadsDetails.relevant_doc}</span> */}
            {/* </label> */}
            {/* <span> 06/09/2022</span> */}
          {/* </div>
          <hr></hr>
        </div>
        
        </div> */}
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Have you patented any product? :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Product Name :</b> 
              <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patenting description :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Patent No :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patented In :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Patenting Authority :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
        </div>
        
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patent Validity :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload Patent Certificate :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
      </TabPanel> */}
      <TabPanel value={value} index={4}>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Targeted customer segment :</b>{" "}
                
              </label>
              <span> {props.rowData.modelDetails.company_registration_ID}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Market Size :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Value Proposition :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Competitors :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div> */}
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> maximum turnover of any of the previous Three finantial years :</b>{" "}
                
              </label>
              {/* <span> {props.rowData.modelDetails.startup_current_stage}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Current finantial status of the company :</b> 
            </label>
            {/* <span> {props.rowData.modelDetails.uniqueness_factor}</span> */}
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={6}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Revenue Inflow :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pricing Model :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Annual gross revenue :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Annual Gross expenditure :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Margin (%):</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Customer acquisition :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Total contract value :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Total addressable market :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel> */}
      <TabPanel value={value} index={6}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>DPIIT No :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Uploaded DIIPT certificate :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Have you taken benefit of this schema before?  :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>if YES,Please provide details for the same:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>How did you come to know about this schema?  :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>Title of the Software/app:</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>is your software/app Published?</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>Jurisdiction of filing of patent</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>The first date of publication/launcg of this software/app</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>Source code and object code in a file</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>Name of authorized signatory</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>A Scanned image of signature of an authorized signatory</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="">
              <label>
                {" "}
                <b>List of all the programming languages used in making the software/app
</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
     </TabPanel>
     <TabPanel value={value} index={7}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Author Name :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Nationality:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Address :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      
      
     
        
      
         
      </TabPanel>
     
    </Box>
  );
}

export default Startupcopyrightmod;