import React, { useState, useEffect } from 'react'
import GrandChallenge from '../../assets/images/Initiative/gradchallenge/Maharashtra_Grand_Challenge.png'
import Hackathon from '../../assets/images/Initiative/gradchallenge/mh.png'
import MH2 from '../../assets/images/Initiative/gradchallenge/mh2logo.png'
import Arrow from '../../assets/images/innovation/arrow.png'
import { Button } from 'react-bootstrap-v5'


function MahaHackBanner() {
  const [dropdownState, setDropdownState] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('')

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState)
  }
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value)
    setDropdownState(!dropdownState)
  }
  return (
    <>
      <div className="mahagranddropdown d-lg-none d-xl-none d-sm-block d-md-block" id='mahadropdown'>
        <div className="container">
          <div className={`dropdown pt-5`}>
            <button onClick={handleDropdownClick} className="dropdown-btn">
              {dropdownValue === '' ? 'Menu' : dropdownValue}
            </button>
            <div
              className={`dropdown-items ${dropdownState ? 'isVisible' : 'isHidden'
                }`}
            >
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Winners')}
                >
                  <a href="#winners">1.  Winners</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Latest Updates')}
                >
                  <a href="#updates">2. Latest Updates</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() =>
                    handleSetDropdownValue('Hackathon Tracks')
                  }
                >
                  <a href="#tracks">3. Hackathon Tracks</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Event Schedule')}
                >
                  <a href="#idEventSch">4. Event Schedule</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Speaker')}
                >
                  <a href="#speaker">5. Speaker</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('FAQs')}
                >
                  <a href="#idFAQ">6. FAQs</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Partner')}
                >
                  <a href="#idPartner">7. Partner</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Contact')}
                >
                  <a href="#idContact">8. Contact</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="maGrandBaneer p-0">
        <img
          src={Hackathon}
          className="maGrandBaneer-img"
          alt="MaharashtraGrandBaneer-img"
        />
        <div className='container'>
          <div className='row'>
              <div className='d-flex justify-content-start align-items-start'>
                <img
                  src={MH2}
                  className="maGrandBaneer-img centered2"
                  alt="MaharashtraGrandBaneer-img"
                  style={{width:'50%'}}
                />
              </div>
              <div className='centered1'>
                <h2 className="pb-4 mahahack-heading font-64-49 font-18-23 font-style-bold font-weight-600">
                Maharashtra Health Hackathon
                </h2> 
              </div>
          </div>
          <h2 className="centered pb-4 nodal-heading font-38-49 font-18-23 font-style-bold font-weight-600">With aim of becoming facilitator from regulator</h2>
        </div>
      </div> */}
    </>
  )
}

export default MahaHackBanner