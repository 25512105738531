import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonalInformation from "./personalinformation";
import BasicDetails from "./basicDetails";
import BusinessModel from "./businessModel";
import Finacialform from './finacialform';
import Ipform from './ipform';
import Uploads from './uploadForm';
import TeamDetails from './teamForm';
import userPic from '../assets/images/bannerslider/MobHomepage321.png';
// import Countrystatecity from "./countryStateCity";
import { Navigate } from "react-router-dom";


// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Banner(props) {
  const [value, setValue] = React.useState(0);
  console.log(sessionStorage.getItem("_id"),"id")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
const u_id=sessionStorage.getItem("_id")
  return (
    <>
    <div className='basicFormMain'> 
    <Box
      // sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
    >
      
      <div className='row'>
      <div className='col-sm-3 col-lg-3'>
        <div className='leftsideacc'>
        <div className='user_info p-3'>
            <div className='row'>
              <div className='col-sm-4 col-lg-4 d-flex align-items-center'>
                <div className="user_img">
                  <img src={userPic} className="img-fluid" alt="user Picture" />
                </div>
              </div>
              <div className='col-sm-8 col-lg-8'>
                <div className="user_text">
                  <div className="user_titile">Jayanth Rao
                  <p className="user_service">Startup Profile</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
          <Tabs
            className='w-100 px-4 pt-4'
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            // sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab  className='textColor side-menu'  label="Personal information" {...a11yProps(0)}/>
            <Tab className='textColor side-menu' label="Basic details" {...a11yProps(1)} />
            <Tab className='textColor side-menu' label="Team details" {...a11yProps(2)} />
            <Tab className='textColor side-menu' label="IP" {...a11yProps(3)} />
            <Tab className='textColor side-menu' label="Business model" {...a11yProps(4)} />
            <Tab className='textColor side-menu' label="Financials" {...a11yProps(5)} />
            <Tab className='textColor side-menu' label="Uploads" {...a11yProps(6)} />
            {/* <Tab className='textColor side-menu' label="CountryStateCity" {...a11yProps(7)} /> */}

          </Tabs>
        </div>
      </div>
      <div className='col-lg-9 col-sm-9'>
        <div className="scrollTab">
          <TabPanel value={value} index={0}> 
          <PersonalInformation value={value} setValue={setValue} id={u_id}/>
         </TabPanel> 
          <TabPanel value={value} index={1}>
            <BasicDetails value={value} setValue={setValue}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TeamDetails value={value} setValue={setValue}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Ipform value={value} setValue={setValue}/>
          </TabPanel>
          <TabPanel value={value} index={4}>
          <BusinessModel value={value} setValue={setValue}/>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Finacialform value={value} setValue={setValue}/>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Uploads value={value} setValue={setValue}/>
          </TabPanel>
          {/* <TabPanel value={value} index={7}>
            <Countrystatecity value={value} setValue={setValue}/>
          </TabPanel> */}
           
        </div>
      </div>
      
      </div>
      
    </Box>
    </div>
    </>
  );
}
