import React, { useEffect,useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MentorApplicationDetails from '../mentor/mentorApplicationDetails';
// import ViewMentorDetails from '../mentor/viewMentorDetails';
import userPic from '../assets/images/bannerslider/MobHomepage321.png';
import Personalinformation from '../startup/personalinformation';
import { environment } from '../api';


function TabPanel(props) {

 
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Banner() {
  const serverUrl = environment.baseUrl;
  const [value, setValue] = React.useState(0);


//   const [users, setUsers] = useState([]);
//   const [sector, setSector] = useState();
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     fetch(serverUrl + "/mentorDetail/allmentorDetail")
//       .then(res => res.json())
//       .then(
//         (data) => {
//           setIsLoaded(true);
//           setUsers(data);
//         },
//         (error) => {
//           setIsLoaded(true);
//           setError(error);
//         }
//       )
//   }, []) 

//   const handleSector = (values) => {
//     const getSector = values.target.value;
//     setSector( getSector);
// }









  console.log(sessionStorage.getItem("_id"),"id")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
const u_id=sessionStorage.getItem("_id")

const appId=sessionStorage.getItem("applicationId")
console.log(appId,"asdfghjk");

  return (
    <>
    <div className='basicFormMain'> 
    <Box
      // sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
    >
      
      <div className='row'>
      <div className='col-sm-3 col-lg-3'>
        <div className='leftsideacc'>
        <div className='user_info p-3'>
            <div className='row'>
              <div className='col-sm-4 col-lg-4 d-flex align-items-center'>
                <div className="user_img">
                  <img src={userPic} className="img-fluid" alt="user Picture" />
                </div>
              </div>
              <div className='col-sm-8 col-lg-8'>
                <div className="user_text">
                  <div className="user_titile">Jayanth Rao
                  <p className="user_service">Mentor Profile</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
          <Tabs
            className='w-100 px-4 pt-4'
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            // sx={{ borderRight: 1, borderColor: 'divider' }}
          >
          <Tab className='textColor side-menu' label="Personal Information" {...a11yProps(0)}/>
            <Tab className='textColor side-menu' label="Basic details" {...a11yProps(1)}/>
            {/* <Tab className='textColor side-menu' label="View Basic Details" {...a11yProps(2)}/> */}
          </Tabs>
        </div>
      </div>
      <div className='col-lg-9 col-sm-9'>
        <div className="scrollTab">
        <TabPanel value={value} index={0} >
          <Personalinformation  value={value} setValue={setValue} id={u_id}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <MentorApplicationDetails  value={value} setValue={setValue} id={u_id}/>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
          <ViewMentorDetails  value={value} setValue={setValue} id={appId}/>
          </TabPanel> */}
        </div>
      </div>
      </div>
    </Box>
    </div>
    </>
  );
}
