import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../startup/informationIcon";
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Upload from "../assets/images/navbar/upload.png";

const serverUrl = environment.baseUrl;
const LeadershipTeam = (props) => {
  const authToken =sessionStorage.getItem("token");

 let initialFormObj={ 
  have_you_appointed_CEO_for_incubation: '',
  CEO_name: '',
  CEO_designation: '',
  CEO_professional_experience: '',
  CEO_email_address: '',
  CEO_upload_profile: '',
  have_you_appointed_Manager_for_incubation: '',
  manager_name: '',
  manager_designation: '',
  manager_professional_experience: '',
  manager_email_address: '',
  manager_upload_profile: '',

  board_of_director_details: [{
    name:'',
    designation:'',
    relevant_work_experience:'',
    qualification:'',
    upload_profile:''
    }]
 }
  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("have_you_appointed_CEO_for_incubation",values.have_you_appointed_CEO_for_incubation)
    formData.append("CEO_name",values.CEO_name)
    formData.append("CEO_designation",values.CEO_designation)
    formData.append("CEO_professional_experience",values.CEO_professional_experience)
    formData.append("CEO_email_address",values.CEO_email_address)
    formData.append("CEO_upload_profile",values.CEO_upload_profile)
    formData.append("have_you_appointed_Manager_for_incubation",values.have_you_appointed_Manager_for_incubation)
    formData.append("manager_name",values.manager_name)
    formData.append("manager_designation",values.manager_designation)
    formData.append("manager_professional_experience",values.manager_professional_experience)
    formData.append("manager_email_address",values.manager_email_address)
    formData.append("manager_upload_profile",values.manager_upload_profile)
    formData.append("name",values.name)
    formData.append("designation",values.designation)
    formData.append("manager_upload_profile",values.manager_upload_profile)
    formData.append(" relevant_work_experience",values. relevant_work_experience)
    formData.append("qualification",values.qualification)
    formData.append("upload_profile",values.upload_profile)
    fetch(serverUrl +"/teamLeaderDetail/addleaderDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken,
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values) =>
      handleSubmitFinacialForm(values)
      
    }
    validationSchema={Yup.object({
      have_you_appointed_CEO_for_incubation: Yup.string().required('Have you appointed CEO for Incubation is required'),
      CEO_name: Yup.string().required('CEO Name is required'),
      CEO_designation: Yup.string().required('CEO Designation is required'),
      CEO_professional_experience: Yup.string().required('CEO Professional Experience is required'),
      CEO_email_address: Yup.string().required('CEO Email Address is required'),
      CEO_upload_profile: Yup.string().required('CEO Upload Profile is required'),
      have_you_appointed_Manager_for_incubation: Yup.string().required('Have you appointed Manager for Incubation is required'),
      manager_name: Yup.string().required('Manager Name is required'),
      manager_designation: Yup.string().required('Manager Designation is required'),
      manager_professional_experience: Yup.string().required('Manager Professional Experience is required'),
      manager_email_address: Yup.string().required('Manager Email Address is required'),
      manager_upload_profile: Yup.string().required('Manager Upload Profile is required'),
        
        board_of_director_details: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('Name is required'),
            designation: Yup.string().required('Designation is required'),
            relevant_work_experience: Yup.string().required('Relevant Work Experience is required'),
            qualification: Yup.string().required('Qualification is required'),
            upload_profile: Yup.string().required('Upload Profile / Relevant Documents is required'),
              
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('leadershipDetails'))||initialFormObj}

      render={({ values, setFieldValue }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            LeadershipTeam
            </h2>
        </div>
        <div className="row">
        <div className="col-lg-12 col-md-12 pb-4">
                      <label className="pb-2 label-txt">
                      Have you appointed CEO for Incubation?<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field component="div" name="have_you_appointed_CEO_for_incubation">
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-1 col-md-1">
                          <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes"
                            defaultChecked={values.have_you_appointed_CEO_for_incubation === "yes"}
                            name="have_you_appointed_CEO_for_incubation"
                            value="yes"
                          />
                          <label htmlFor="radioYes">Yes</label>
                          </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                                            <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo"
                            defaultChecked={values.have_you_appointed_CEO_for_incubation === "no"}
                            name="have_you_appointed_CEO_for_incubation"
                            value="no"
                          />
                          <label htmlFor="radioNo">No</label>
                          </div>
                          </div>
                          </div>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`have_you_appointed_CEO_for_incubation`} />
                        </div>
                    </div>
        </div>
        <TableContainer component={Paper} className="no-shadow">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="head-bg-color">
                  <TableRow>
                    <TableCell className="table-txt">Name</TableCell>
                    <TableCell className="table-txt" align="left">Designation</TableCell>
                    <TableCell className="table-txt" align="left">Years of Professional Experience</TableCell>
                    <TableCell className="table-txt" align="left">E-Mail Address</TableCell>
                    <TableCell className="table-txt" align="left">Upload Profile / Relevant Documents</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className="data-bg-color">
                 
                    <TableRow>
                      <TableCell component="th" scope="row">
                         <Field type="text" className="txt-color" name={`CEO_name`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`CEO_name`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                      <Field type="text" className="txt-color" name={`CEO_designation`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`CEO_designation`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                      <Field as="select" className="form-select" name="CEO_professional_experience">
                            <option selected>Select </option>
                            <option value="0-1 Year">0-1 Year</option>
                            <option value="1-5 Year">1-5 Year </option>
                            <option value="5-10 Year">5-10 Year</option>
                            <option value="10-15 Year">10-15 Year</option>
                            <option value="15-20 Year">15-20 Year</option>
                     </Field>
                     <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`CEO_professional_experience`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                          <Field type="email" className="txt-color" name={`CEO_email_address`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`CEO_email_address`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                              <div className="">
                              <div class="btn float-left  form-control py-4">
                              <input id="file" name="CEO_upload_profile" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("CEO_upload_profile", e.target.files[0]);
                      }} /> 
                                <div className="text-danger">
                            <ErrorMessage className="error" name="CEO_upload_profile" />
                            </div>  
                          </div>
                      
                        </div>
                          </TableCell>
                        </TableRow>
                     
                    </TableBody>
                  </Table>
                </TableContainer>
                <br></br>
                <div className="col-lg-12 col-md-12 pb-4">
                  <label className="pb-2 label-txt">
                    Have you appointed Manager for Incubation <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                   <Field component="div" name="have_you_appointed_Manager_for_incubation">
                   <div class="radiobuttons d-flex">
                        <div className="col-lg-1 col-md-1">
                          <div class="rdio rdio-primary radio-inline">
                    <input
                      type="radio"
                      id="radioYes1"
                      defaultChecked={values.have_you_appointed_Manager_for_incubation === "yes"}
                      name="have_you_appointed_Manager_for_incubation"
                      value="yes"
                    />
                    <label htmlFor="radioYes1">Yes</label>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                          <div class="rdio rdio-primary radio-inline">

                    <input
                      type="radio"
                      id="radioNo1"
                      defaultChecked={values.have_you_appointed_Manager_for_incubation === "no"}
                      name="have_you_appointed_Manager_for_incubation"
                      value="no"
                    />
                    <label htmlFor="radioNo1">No</label>
                    </div>
                    </div>
                    </div>
                  </Field>
                  <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`have_you_appointed_Manager_for_incubation`} />
                        </div>
                </div>
                <TableContainer component={Paper} className="no-shadow">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="head-bg-color">
                      <TableRow>
                        <TableCell className="table-txt">Name</TableCell>
                        <TableCell className="table-txt" align="left">Designation</TableCell>
                        <TableCell className="table-txt" align="left">Years of Professional Experience</TableCell>
                        <TableCell className="table-txt" align="left">E-Mail Address</TableCell>
                        <TableCell className="table-txt" align="left">Upload Profile / Relevant Documents</TableCell>
                      </TableRow>
                    </TableHead>

                <TableBody className="data-bg-color">
                  
                    <TableRow>
                      <TableCell component="th" scope="row">
                         <Field type="text" className="txt-color" name={`manager_name`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`manager_name`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                         <Field type="text" className="txt-color" name={`manager_designation`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`manager_designation`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                      <Field as="select" className="form-select" name="manager_professional_experience">
                            <option selected>Select </option>
                            <option value="0-1 Year">0-1 Year</option>
                            <option value="1-5 Year">1-5 Year </option>
                            <option value="5-10 Year">5-10 Year</option>
                            <option value="10-15 Year">10-15 Year</option>
                            <option value="15-20 Year">15-20 Year</option>
                     </Field>
                     <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`manager_professional_experience`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                         <Field type="email" className="txt-color" name={`manager_email_address`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`manager_email_address`} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                              <div className="">
                              <div class="btn float-left  form-control py-4">
                               <input id="file" name="manager_upload_profile" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("manager_upload_profile", e.target.files[0]);
                      }} />    
                          </div>
                        <div className="text-danger">
                            <ErrorMessage className="error" name="manager_upload_profile" />
                            </div>
                        </div>
                          </TableCell>
                        </TableRow>
                     
                    </TableBody>
                  </Table>
                </TableContainer>
                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd pt-5">
                  Board of Director Details
                </h2>
          <FieldArray
            name="board_of_director_details"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>



                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"15%"}}>Name</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Designation</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"25%"}} align="left">Relevant Work Experience</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Qualification</TableCell>
              <TableCell className="table-txt align-items-center"  style={{width:"20%"}} align="left">Upload Profile / Relevant Documents</TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>
                {values.board_of_director_details && values.board_of_director_details.length > 0 ? (
                  values.board_of_director_details.map((friend, index) => (
                    <TableBody  className="data-bg-color d-flex justify-content-between">
              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"15%"}}>
                  <Field type="text" className="txt-color" name={`board_of_director_details.${index}.name`} />
                    <div className="text-danger">
                    <ErrorMessage className="error" name={`board_of_director_details.${index}.name`} />
                    </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                          <Field type="text" className="txt-color" name={`board_of_director_details.${index}.designation`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`board_of_director_details.${index}.designation`} />
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"25%"}}>
                          <Field type="text" className="txt-color" name={`board_of_director_details.${index}.relevant_work_experience`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`board_of_director_details.${index}.relevant_work_experience`} />
                        </div>
              </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                          <Field type="text" className="txt-color" name={`board_of_director_details.${index}.qualification`} />
                          <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`board_of_director_details.${index}.qualification`} />
                        </div>
              </TableCell>
             
              <TableCell align="center" className="d-flex align-items-center" style={{width:"20%"}}>
                  <input id="file" name={`board_of_director_details.${index}.upload_profile`} accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue(`board_of_director_details.${index}.upload_profile`, e.target.files[0]);
                      }} />   
                    
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name={`board_of_director_details.${index}.upload_profile`} />
                        </div>
             </TableCell>
             
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
              </TableBody>  
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("leadershipDetails", JSON.stringify(values))}}   
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                          
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>

              </div>
            )}
          />
        </Form>
      )}
    />
  </div>
  </div>
  </div>
  
};

export default LeadershipTeam;
