import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";

import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const OurPartnerAdd = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUser] = useState([]);
  const [partnertypemh, setPartnerTypemh] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(serverUrl + "/partType/allPartnerTypeMh")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUser(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []) 

  const handleCategory = (values) => {
    const getPartnerTypemh = values.target.value;
    setPartnerTypemh( getPartnerTypemh);
}

function fetchData(){
  fetch(serverUrl + "/our/allOur")
.then((res) => res.json())
.then(
(data) => {
console.log(data, "dataaaaaaaaaaaa")
setIsLoaded(true);
setUsers(data);
},
)
}
  

  function handleSubmitOurPartnerAddForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("link",values.link)
    formData.append("image",values.image)
    formData.append("partnertypemh",values.partnertypemh)
    console.log(formData)
    fetch(serverUrl +"/our/addOur", 
         {
            method: "POST",
            body: formData,
           
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then((res) => setModalIsOpenToFalse3())
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
          
  }
  console.log("called")

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
    onSubmit={(values, {resetForm}
    ) =>
   { console.log(values )
    handleSubmitOurPartnerAddForm(values)
    resetForm({values:''}) 
  }
  }
validateOnChange={true}
    
    validationSchema={Yup.object({
    link: Yup.string().required('Enter Link is Required'),
    image: Yup.string().required('Image  is Required'),
     partnertypemh: Yup.string().required('Enter partnertypemh is Required').min(1,'Enter partnertypemh is Required'),
       })}
      initialValues={{ 
      link: '',
      image:'',
      partnertypemh:"",
       }}

      render={({ values, setFieldValue ,handleSubmit,handleChange}) => (
        <Form onSubmit={(e)=>{
          
          handleSubmit(e)}}>

        <div className="row">
        <h6>Partner Type :  </h6> 
        <select type="select" name="partnertypemh" className="form-control size"
          value={values.partnertypemh}
          onChange={(e) => {
            handleChange(e);
            console.log(e.target.value, "87777777777777777777777777777777777")
            // setPartnerTypemh("partnertypemh", e.target.value)
            handleCategory(e)
          }}
         >
          <option value={""}>--Select Partner Type--</option>
            {
              users.map((user) => {
                    return (
                        <option key={user._id} value={user._id}> {user.name} </option>
                    )
                })
            }
        </select>
        <div className="text-danger">
          <ErrorMessage className="error" name="partnertypemh" /><br></br>
        </div>




                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                 Link <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div>

                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("image", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default OurPartnerAdd;
