import React from 'react'
import Header from '../../component/Header';
import StartupYatraContent from './startupYatraContent';
import Footer from '../../component/footer';


function startupYatraMain() {
  return (
    <>
    <Header/>
    <StartupYatraContent/>
    <Footer/>
    </>
  )
}

export default startupYatraMain