import React from 'react'
// import maitriimg from '../../assets/images/EcoSystem/MAITRI/flat-bg.454bd557.jpg';

function MaitriBanner() {
  return (
    <>
    <div className="mastartupyatra">
      
    {/* <img className="maGrandBaneer-img" src={maitriimg}/> */}
    <div className='container'>
      <div className='row'>
        {/* <div className='col-md-2'></div> */}
        <div className='d-flex justify-content-center align-items-center'>
        <h2 className="maitri-header-title centered-title pb-4 nodal-heading font-36-47 font-style-bold font-weight-600">With aim of becoming facilitator from regulator</h2>
        </div>
        {/* <div className='col-md-2'></div> */}
      </div>
      <div className='row'>
        {/* <div className='col-md-2'></div> */}
        <div className='d-flex justify-content-center align-items-center'>
        <h2 className="maitri-header-title centered-sub-title nodal-heading font-34-44 font-weight-400">Business Compliance Information - MAITRI</h2>
        </div>
        {/* <div className='col-md-2'></div> */}
      </div>
    
   
    </div>
    </div>
    </>
  )
}

export default MaitriBanner