import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

function mentorApprovalModel() {
  return (
    <>
    <div className='text-center'>
        <h6>
           DO YOU WANT TO APPROVE <span><b>MAHENDRA SINGH DHONI</b></span>?
        </h6>
    </div>
    <div className="justify-content-center d-flex py-4">
          <Stack direction="row" spacing={2}>
            {/* <Button variant="contained">Previous</Button> */}
            <Button
              variant="contained"
              type="submit"
              className="btn-color-next no-shadow"
            >
              Approve
            </Button>
          </Stack>
        </div>
        </>
  )
}

export default mentorApprovalModel