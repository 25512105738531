import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from './informationIcon';

const serverUrl = environment.baseUrl;
const Ipform = (props) => {
  const authToken =sessionStorage.getItem("token");

  let initialFormObj={ 
    do_you_have_IP: '',
    productName:'',
    ipDescription:'',
    ipValidity:'',
    uploadIpDoc:''
   }
  

  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("do_you_have_IP",values.do_you_have_IP)
    formData.append("productName",values.productName)
    formData.append("ipDescription",values.ipDescription)
    formData.append("ipValidity",values.ipValidity)
    formData.append("uploadIpDoc",values.uploadIpDoc)
    fetch(serverUrl +"/ip/addIpDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitFinacialForm(values)
    }
    validationSchema={Yup.object({
      do_you_have_IP: Yup.string().required('Please Select Have you appointed CEO for Incubation is required'),
      productName: Yup.string().required('Product Name is required'),
      ipDescription: Yup.string().required('IP Description is required'),
      ipValidity: Yup.string().required('IP Validity is required'),
      uploadIpDoc: Yup.string().required('upload IP Document is required'),

      radioG3: Yup.string().when("radioG2", {
        is: "other", // alternatively: (val) => val == true
        then: Yup.string().required("can't be blank")
      }),
       })}

       initialValues={JSON.parse(sessionStorage.getItem('ipDetails'))||initialFormObj}

      render={({ values,setFieldValue }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            IP
            </h2>
        </div>
      
        <div className="row">
                      <label className="pb-2 label-txt">
                      Have you appointed CEO for Incubation?<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field component="div" name="do_you_have_IP">
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-1 col-md-1">
                          <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes"
                            defaultChecked={values.do_you_have_IP === "yes"}
                            name="do_you_have_IP"
                            value="yes"
                          />
                          <label htmlFor="radioYes">Yes</label>
                          </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                                            <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo"
                            defaultChecked={values.do_you_have_IP === "no"}
                            name="do_you_have_IP"
                            value="no"
                          />
                          <label htmlFor="radioNo">No</label>
                          </div>
                          </div>
                          </div>
                        </Field>
                        <div className="text-danger">
                    <ErrorMessage className="error" name="do_you_have_IP" />
                    </div>
                    </div>
                    <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
        <label className="pb-2 label-txt">
                    Product Name<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field type="text" placeholder="Enter Organization Name" className="textInput" name="productName" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="productName" /><br></br>
                    </div>                  
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                    IP description<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field type="textarea" placeholder="Enter Organization Name" className="textInput" name="ipDescription" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="ipDescription" /><br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                    IP Validity<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field type="date" placeholder="Enter Organization Name" className="textInput" name="ipValidity" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="ipValidity" /><br></br>
                    </div>
                  </div>


                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                    Upload IP Certificate<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <input id="file" name="uploadIpDoc" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("uploadIpDoc", e.target.files[0]);
                      }} /> 
                    <div className="text-danger">
                    <ErrorMessage className="error" name="uploadIpDoc" /><br></br>
                    </div>
                  </div>
                  </div>    
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("ipDetails", JSON.stringify(values))}}  
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default Ipform;