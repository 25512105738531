import React from "react";
import { Link } from "react-router-dom";
import Header from '../../component/Header';
import Footer from '../../component/footer';

function pressRelese() {
  return (
    <>
    <Header/>
    <div className="mahaGrand-main intellectual-main">
      <div className="container">
        <div className="row d-flex justify-content-center my-3">
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row" id="granchallenge">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
              Startup India Maharashtra Yatra
              </h2>
              <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
              The Department of Industrial Policy & Promotion, Startup India and Government of Maharashtra, Skill Development and Entrepreneurship Department’s, Maharashtra State Innovation Society (MSInS) together are organising the Maharashtra leg of Startup India Yatra from October 3rd to November 3rd, 2018. Earlier today, the Yatra was flagged off by Shri. Ch. Vidyasagar Rao, Hon’ble Governor of Maharashtra, Shri Devendra Fadnavis, Hon’ble Chief Minister, Shri Suresh Prabhu, Hon’ble Union Minister, Shri Vinod Tawde, Hon’ble Minister. The Yatra was flagged off from Raj Bhavan, Mumbai and will travel across the state reaching out to students, entrepreneurs and innovators.
                
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                At the Startup India Maharashtra Flag Off, Shri CH. Vidyasagar Rao, Hon’ble Governor said that “The Government realizes that startups are not just confined to big cities. Smaller towns and villages are also emerging as vibrant startup centres. The Maharashtra Startup Yatra is a journey to discover and nurture talent.” Shri Devendra Fadnavis, Hon’ble Chief Minister said that “Maharashtra is the startup capital & attracts highest investment. We are confident that this will lead to creation of employment opportunities too. We also need to stress more on effective use of available resources and how startups can help in this. With strong determination, new ideas and ability to adopt newer technologies, our young minds can create wonders in innovation. We expect more startups in agriculture and water conservation to help our farmers and society at large.” Shri Suresh Prabhu, Hon’ble Union Minister of Commerce & Industry and Civil Aviation said that “While existing companies are slipping out of the Fortune 500 list, many new startup companies are making it to the list. The future belongs to the entrepreneurs. Maharashtra is the ninth state where the Startup Yatra is happening”. Welcoming the initiative of Startup India Maharashtra Yatra, Shri Suresh Prabhu expressed the need to turn startup into a movement.
                
                  
             </p>
             <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
             The Yatra comprises of Van Stops and 1-day bootcamps at various locations in Maharashtra where participants can pitch their ideas which can be of any sector and any stage of development. Each Boot Camp will have mentorship and ideation workshops where innovators will be exposed to various facets of starting a business. An idea pitching session would also be conducted and the winners from each bootcamp would be invited to the Grand Finale in Nagpur.
</p>
<p className="font-style-normal font-weight-400 font-18-22 font-14-17">
At the Grand Finale, budding entrepreneurs will also have an opportunity to get incubated. Final winners will receive pre-incubation offers and other prizes to further nurture their ideas and create successful ventures in the state. With respect to the Startup India Maharashtra Yatra, anyone with an idea/startup can apply for participation at bootcamp locations, at the van stops, or online on Startup India (www.startupindia.gov.in) and MSInS (www.msins.in) websites.
</p>
<p className="font-style-normal font-weight-400 font-18-22 font-14-17">
Along with the Yatra Flag Off, 16 incubator centres were also announced. Setting up a state-wide network of incubators is a strategy of Government of Maharashtra to promote innovation and entrepreneurial spirit in the state. In Phase 1, the applications were invited from across Maharashtra and were shortlisted depending on the quality of proposals received. Applications were judged on the basis of business plan, leadership team, sector expertise, market linkages and infrastructure. The evaluation was done by a panel of experts from the startup ecosystem. Giving a special emphasis to universities and after considering the evaluation done by the panel, the final shortlisted applicants in Phase 1 who are eligible for grants of INR 5 crore each to set up an incubation centre include (in alphabetical order):
</p>
       
  <ol>
                  <li>Amravati University Development of Innovation, Incubation and Entrepreneurship (AUDiiE)</li>
                  <li>Center for Agro-Bio-Innovation and Incubation (CABII), SRTM University, Nanded </li>
                  <li>CSIR-National Environmental Engineering Research Institute, Nagpur</li>
                  <li>Dr. Babasaheb Ambedkar Marathwada University, Aurangabad</li>
                  <li>Dr. Babasaheb Ambedkar Technological University, Lonere</li>
                  <li>Dr. Panjabrao Deshmukh Krishi Vidyapeeth, Akola </li>
                  <li>Marathwada Accelerator for Growth and Incubation Council, Aurangabad </li>
                  <li>Nashik Engineering Cluster (NEC) </li>
                  <li>Kavayitri Bahinabai Chaudhari North Maharashtra University, Jalgaon </li>
                  <li>Rajaram Bapu Institute of Technology, Sangli </li>
                  <li>Rashtrasant Tukadoji Maharaj Nagpur University Business Incubation Center, Napgur</li>
                  <li>Research Innovation Incubation Design Laboratory Foundation, Mumbai  </li>
                  <li>Savitribai Phule Pune University </li>
                  <li>Shivaji University, Kolhapur </li>
                  <li>Uddyam-Solapur University Incubation Centre, Solapur </li>
                  <li>University of Mumbai</li>

                </ol>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
The 2nd Phase to apply for Incubation centres is open now. Industries, organisations and universities are invited to submit their proposals on www.msins.in
</p> 
</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  )
}

export default pressRelese