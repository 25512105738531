import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const YatraWinnersAdd = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitYatraWinnersAddForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name",values.name)
    formData.append("rank",values.rank)
    formData.append("category",values.category)
    formData.append("prize",values.prize)
    function fetchData(){
      fetch(serverUrl + "/yatraStartupWinners/allYatraWinners")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    setUsers(data);
  },
)
}
    fetch(serverUrl +"/yatraStartupWinners/addYatraWinners", 
         {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              accept: "application/json",
              
            },
            body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then((res) => setModalIsOpenToFalse3())
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
  }
  

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
    onSubmit={(values, {resetForm}
    ) =>
   { console.log(values )
    handleSubmitYatraWinnersAddForm(values)
    resetForm({values:''}) 
  }
  }
    validationSchema={Yup.object({
      name: Yup.string().required('Enter Name is Required'),
      rank: Yup.string().required('Enter Rank is Required'),
      category: Yup.string().required('Enter Category is Required'),
      prize: Yup.string().required('Enter Prize is Required'),
       })}
      initialValues={{ 
        name: '',
        rank: '',
        category: '',
        prize:'',
       }}

      render={({ values}) => (
        <Form >
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Name" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Rank{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Rank Name" className="textInput" name="rank" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="rank" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Category <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Category" className="textInput" name="category" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="category" /><br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Prize <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Prize" className="textInput" name="prize" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="prize" /><br></br>
                    </div>
                  </div>                          
             </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default YatraWinnersAdd;
