import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal';
import "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import GrantEligibility from './QualityTestingEligibility';
import GrantTab2 from './QualityTestingTab2';

import Stack from '@mui/material/Stack';
import { environment } from '../api';

function QualityTesting() {
    const [modalIsOpen, setModalIsOpen] = useState(false);

const setModalIsOpenToTrue = () => {
  setModalIsOpen(true);
};

const setModalIsOpenToFalse = () => {
  setModalIsOpen(false);
};

const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
  };
const [showData,setShowData] = useState('');
// const serverUrl = environment.baseUrl;
const [rows, setUsers] = useState([]);
const [error, setError] = useState(null);
const [isLoaded, setIsLoaded] = useState(false);
// const [rowId, setrowId] = useState('');
// const [rowData, setRowData] = useState({
  
//   basicDetails: []
 
// })
// useEffect(() => {
//     fetch(serverUrl +"/mentorDetail/allmentorDetail")
//         .then(res => res.json())
//         .then(
//             (data) => {
//                 setIsLoaded(true);
//                 setUsers(data);
//             },
//             (error) => {
//                 setIsLoaded(true);
//                 setError(error);
//             }
//         )
        
//   }, [])

//   useEffect(()=>{
//     rows.map((el)=>{
//       if(el._id === rowId)
//         setRowData({...rowData, basicDetails: el})

//     })
    
//   },[rowId])

  
const columns = [
{ field: "_id", headerName: "Sr.No.", width: 280 },
{ field: "mentor_name", headerName: "Appliaction No", width: 150 },
{ field: "email", headerName: "Application Date", width: 150 },
{ field: "phone_number", headerName: "proposed Title of Invention", width: 200 },
{ field: "company_name", headerName: "status", width: 150 },

{
    field: "Action",
    headerName: "Action",
    sortable: false,
    width: 200,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        const api = params.api;
        const thisRow = {};

        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );

        return alert(JSON.stringify(thisRow, null, 4));
      };
      return (
        <>
          {/* <Button onClick={setModalIsOpenToTrue}>
            <VisibilityIcon />
          </Button> */}
          {/* <Button onClick={onClick}>
    <BallotIcon />
  </Button> */}
          {/* <Button onClick={setModalIsOpenToTrue2}>
    <ThumbUpIcon />
  </Button> */}
          {/* <Button onClick={onClick}>
    <ThumbDownIcon />
  </Button> */}
        </>
      );
    },
  },
];
  return (
    <div>
    <div className="bannermain">
    { !isShown && (
      <>
        <div className="container">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Quality Testing <Button className="addbtn" onClick={setModalIsOpenToTrue}>Apply for Quality Testing</Button>
            </h2>
            <div style={{ height: "75vh", width: "100%" }}>
    
    <DataGrid
      className="pb-3"
      rows={rows}
      columns={columns}
       pageSize={8}
    //    onSelectionModelChange={(newSelection)=>{
    //     setrowId(newSelection[0])
    //    }}
       components={{ Toolbar: GridToolbar
         }}
    //   getRowId ={(rows) => rows._id} 
                  />
  </div>
        </div>
         <div>
         <Modal isOpen={modalIsOpen} className="ModOverlay">
         <div className="viewMod">
           <div className="d-flex justify-content-end">
             <Button onClick={setModalIsOpenToFalse}>
               <CloseIcon />
             </Button>
           </div>
           
    <div className="container">
      <h2 className="d-flex justify-content-center font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
        Disclaimer
      </h2>
      <div>
        <ol className="font-style-normal font-weight-400 font-18-22 font-14-17">
          <li>
            I hereby certify that the startup is registered with Department for
            Promotion of Industry and Internal Trade (DPIIT).
          </li>
          <li>
            I hereby certify that the startup is incorporated under the
            applicable provisions of laws of India and registered in the State
            of Maharashtra.
          </li>
          <li>
            I hereby certify that the startup annual revenue has not crossed INR
            1 crore in any given financial year, nor has raised funds exceeding
            INR 3 crore for domestic IPR application.
          </li>
          <li>
            I hereby certify that the startup annual revenue has not crossed INR
            5 crore in any given financial year for international IPR
            application.
          </li>
          <li>
            I hereby certify that the startup has not availed any benefit under
            any other Central/State Government scheme with respect to this
            particular application·
          </li>
          <li>
            I hereby certify that the information provided is true and complete
            to the best of my knowledge and belief and nothing has been
            concealed therein. I am aware that this self-declaration statement
            is subject to review and verification and if such information has
            been falsified, I will have to face punishment as per applicable
            provision of Law for the time being in force as well as the benefit
            availed of by me or the benefit accrued to me shall be summarily
            cancelled.
          </li>
          <li>
            In case MSInS found any deceive practices conducted by the Startup,
            then MSInS can take appropriate legal action and holds the right to
            recover supported monetary funding.
          </li>
          <li>
            The startup shall be solely responsible for all decisions taken with
            respect to the services offered through the MSInS platform. MSInS
            shall not be liable for any damages whatsoever, which may result
            directly / indirectly by obtaining services through this platform.
          </li>
        </ol>
       <span className="justify-content-center d-flex font-style-normal font-weight-400 font-18-22 font-14-17"><input type="checkbox" name="checkbox"/><span className="mx-2">I understood and agree to above mentioned terms.</span></span>
      </div>
    </div>
   
    <div className="justify-content-center d-flex pt-4">
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        type="submit"
        className="btn-color-next no-shadow"
        onClick={handleClick} 
      >
        Save &amp; Next
      </Button>
    </Stack>
  </div>
  
 
         </div>
       </Modal>
       </div>
       </>
    )}
    {isShown &&(
      <>
      <div>
        <Tabs  defaultActiveKey="eligibility"  id="fill-tab-example" className="mb-3" fill>
          <Tab eventKey="eligibility" title="Eligibility Details">
            <GrantEligibility/>
          </Tab>
          <Tab eventKey="Quality Testing" title="Quality Testing">
            <GrantTab2/>
          </Tab>
        </Tabs>
      </div>
      </>
    )}
    </div>
</div>
  )
}

export default QualityTesting