import React, { useState, useEffect }  from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { environment } from '../api';
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Container, Col, Row } from 'react-bootstrap-v5';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

function Init() {

  const serverUrl = environment.baseUrl;
  const [previous, setPrevious] = useState([]);
  const [future, setFuturedata] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(serverUrl +"/initiative/allInitiative")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    // setprevious(data);
                    console.log(data[1].prevdata, "lpg");
                    setPrevious(data[1].prevdata);
                    setFuturedata(data[0].futuredata);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])

     

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 768;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
}, [isMobile]);
  


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

console.log(future, "future dataaa");
  return (
    <>
<div className='text-center intiative-Banner-title pt-5'>
            {/* <h2 className='font-28-36 font-20-26 font-weight-600 d-flex justify-content-center'><b>Initiatives</b></h2> */}
        </div>
    {/* ============================== */}
     <div className='mahaGrand-main scroll-card-event scroll-add-vertical' id='ourPartner'>
     
        <div className="container">
           <div className="row d-flex justify-content-center">
           <div className='our-partner-card py-2'>
          <div className="row">
          <div className="col-sm-12 col-lg-12 pb-2  initiative-card-title">
            <h3 className="font-28-36  font-20-26">
            <h2 className='font-28-36 font-20-26 font-weight-600 d-flex justify-content-center'><b>Initiatives</b></h2>
             
            </h3>
          </div>
        </div>
        <h3 className="title_plex_font font-28-36 font-18-23 font-weight-600 d-flex">
              Upcoming Events
            </h3>
      
         {future.length!=0 ? (
<div className='row '>
        {future.map(user => (
        <div >
          <div className="pb-3" key={user._id}>
                    <div className="row border d-flex align-items-center initiativeCard " style={{
                                        backgroundColor: user.bgCard,
                                        color: user.txtColor,
                                        
                                      }}>
                      <div className="col-sm-6 col-md-6 col-12 col-lg-6">
                        <img className="img-fluid card-img-fix" src={user.image} width={'100%'} />
                      </div>
                      <div className="col-sm-6 col-md-6 col-12 col-lg-6  MH-startupTextCard card-details"  >
                        <div className="cardTextDetailsMH">
                          <h4 className="font-28-36 font-18-23 font-style-bold font-weight-600">
                            {user.title}
                          </h4>
                          
                          <p className="font-18-22 font-14-17 justify">
                            {user.description}
                          </p>
                          <div className="learnMoreArrow">
                          <Link to={`/InitiattiveListDetails/${user._id}`} className="underline-remove">
                            <button className={`${isMobile ? "d-none" : "btn btnInitiativeList d-flex justify-content-around "}`}
                            style={{
                                        backgroundColor: user.btnbg,
                                        color: user.txtColor,                              
                                      }}>
                              <span className='align-vertical-center'>Learn More</span>
                              <span className="inno-arrow">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"    
                                  viewBox="0 0 16 16"
                                  height="2em"
                                  width="2em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                  />
                                </svg>
                              </span>
                            </button>
                          </Link>
                          

                          </div>
                        </div>
                        <div className="arrowinitiativelearnmore ">
                        <Link to={`/InitiattiveListDetails/${user._id}`} className="underline-remove arrow-color">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            height="2em"
                            width="2em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                            />
                          </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
          </div>
      </div>
       ))}
          
          </div>) : ( <div className="">
          <div className='w-100 d-inline-block bg-light d-flex justify-content-center align-items-center iniUpcomingHeight'>
        <h5 className='font-28-36 font-18-23 '>Currently no upcoming events are there</h5>
        </div>

      </div>
       )
    
}
        </div>
            {/* ================================= */}
                <div className='our-partner-card py-2'>
          <div className="row">
          <div className="col-sm-12 col-lg-12 pb-2  initiative-card-title">
            <h3 className="title_plex_font font-28-36 font-18-23 font-weight-600 d-flex">
              <b>Previous Events</b>
            </h3>
          </div>
        </div>
        <div className='row '>
        {previous.map(user => (
        <div className="initiativeMain">
          <div className="pb-3" key={user._id}>
                    <div className="row border d-flex align-items-center initiativeCard " style={{
                                        backgroundColor: user.bgCard,
                                        color: user.txtColor,
                                        
                                      }}>
                      <div className="col-sm-6 col-md-6 col-12 col-lg-6">
                        <img className="img-fluid card-img-fix" src={user.image} width={'100%'} />
                      </div>
                      <div className="col-sm-6 col-md-6 col-12 col-lg-6  MH-startupTextCard card-details"  >
                        <div className="cardTextDetailsMH">
                          <h4 className="font-28-36 font-18-23 font-weight-600">
                          {/* <h1>Current date is {date}</h1> */}
                            {user.title}
                          </h4>
                          
                          <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                               <span className="justify"> {user.description}</span>
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <h2 style={{color:'black'}} className="initativeContentWrap font-18-22 font-14-17 font-style-normal font-weight-400 p-y-1 ellipsis justify">
                            {user.description}{' '}
                          </h2>
                        </HtmlTooltip>


                          {/* <p className="font-18-22 font-14-17 card-text-wrap justify ellipsis">
                            {user.description}
                          </p> */}
                          <div className="learnMoreArrow pt-3">
                          <Link to={`/InitiattiveListDetails/${user._id}`} className="underline-remove">
                            <button className={`${isMobile ? "d-none" : "btn btnInitiativeList d-flex justify-content-around "}`}
                            style={{
                                        backgroundColor: user.btnbg,
                                        color: user.txtColor,                              
                                      }}>
                              <span className='align-vertical-center'>Learn More</span>
                              <span className="inno-arrow">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"    
                                  viewBox="0 0 16 16"
                                  height="2em"
                                  width="2em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                  />
                                </svg>
                              </span>
                            </button>
                          </Link>
                          

                          </div>
                        </div>
                        <div className="arrowinitiativelearnmore ">
                        <Link to={`/InitiattiveListDetails/${user._id}`} className="underline-remove arrow-color">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            height="2em"
                            width="2em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                            />
                          </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
          </div>
      </div>
       ))}
          </div>

        </div>
        </div>
    </div>
    </div>
</>
  )
}

export default Init