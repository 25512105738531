import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const ExecutiveTeamUpdateM = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
// console.log(props)
const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitExecutiveTeamUpdateForm(values){
    
    const formData = new FormData();
    formData.append("name",values.name)
    formData.append("position",values.position)
    formData.append("profile",values.profile)
    formData.append("linkedin",values.linkdin)
    function fetchData(){
      fetch(serverUrl + "/about/allExecutive")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    fetch(serverUrl +`/about/${props.rowData.excutiveTeam._id}`,
         {
          method: 'PUT',
          body: formData,
            
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .then((res) => fetchData())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitExecutiveTeamUpdateForm(values)
    }
    validationSchema={Yup.object({
    name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Name is Required'),
    position: Yup.string().required('Enter Position is Required'),
    profile: Yup.string().required('Enter Profile is Required'),
    linkdin: Yup.string().required('Enter Linkedin  is Required'),
       })}
      initialValues={{ 
      name: props.rowData.excutiveTeam.name,
      position: props.rowData.excutiveTeam.position,
      profile:props.rowData.excutiveTeam.profile,
      linkdin: props.rowData.excutiveTeam.linkdin,
       }}

      

      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"                    
                      onChange={handleChange}
                      placeholder="Enter Name" className="textInput" name="name" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                    */}
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    position{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Position Name" className="textInput" name="position" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="position" /><br></br>
                    </div>                   
                  </div>

                 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Linkedin <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter Linkedin" className="textInput" name="linkdin" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="linkdin" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Profile <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img id="imgupdate" src={updatedImage||values.profile} style={{width:'20%', height:'50%'}} /><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("profile", e.target.files[0]);
                      }} required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="profile" /><br></br>
                    </div>
                  </div>


                  {/* <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Profile <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img src={values.profile} style={{width:'30%', height:'30%'}} /><br></br>

                        <input id="file" name="profile" accept="image/png,image/jpeg"
                     type="file" 
                    
                     onChange={(e) => {
                      console.log(e.target.files[0])
                         setFieldValue("profile", e.target.files[0]);
                         handleChange();
                      }} 

                      />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="profile" /><br></br>
                    </div>
                  </div>                   */}
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default ExecutiveTeamUpdateM;
