export const environment = {
  production: false,
  // baseUrl:'http://192.168.1.42:8080/api'
  // baseUrl:'http://localhost:8080/api',
  // admin url
  baseUrl:'https://www.msins.in/api',
//  baseUrl:'http://3.109.215.92/api',
REACT_APP_SECRET_KEY:"6LcpfFgjAAAAAD-AVjbFaATBz8dqRl5fFl23STbR",
REACT_APP_SITE_KEY:"6LcpfFgjAAAAADx_jeWYdtPZM3d3-l-RXsZEjEU6",
RegistrationLink:"",
LoginLink:"",
// RegistrationLink:"https://portal.msins.in/?reg=reg",
// LoginLink:"https://portal.msins.in/?login=login",

} 