import React from 'react';
import { Container, Col, Row } from 'react-bootstrap-v5';
import MapImage from '../assets/images/EcoSystem/Group 668.png';
import LogoImage from '../assets/images/EcoSystem/Group 559.png';
//import Arrow from '../assets/images/innovation/arrow.png';
import { Button } from 'react-bootstrap-v5';
import {Link} from "react-router-dom";

export default function Ecobanner() {
  return (
    <>
        <div>
        <div className='eco_banner'>
            <h2 className='font-28-36 font-20-26 font-weight-600 d-flex justify-content-center'>Explore our Ecosystem</h2>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-end'>
                    <div className='eco-bannbox'>
                                            <div className='p-2'>
                                                <img className="img-fluid" src={MapImage} alt='MapImage'/>
                                            </div>
                                            <footer className='eco-bannfooter title_plex_font'>
                                                <div className='w-50'>
                                                <h2 className='font-style-normal font-weight-600 font-28-36 font-20-26'>Mentor</h2>
                                                </div>
                                                

                                                <div className='w-50 pinkbtn d-flex justify-content-end  align-items-center'>
                                                    <Button className="btn-primary regular_font font-style-normal font-weight-normal font-20-24  d-none d-sm-block">
                                                    <Link to= "../Mentor">
                                                        <span className='regular_font font-20-24 font-style-normal font-weight-400'>Learn More</span>
                                                        <span className='inno-arrow'>
                                                        <svg
                                                            stroke="currentColor"
                                                            fill="currentColor"
                                                            stroke-width="0"
                                                            viewBox="0 0 16 16 "
                                                            height="20px"
                                                            width="30px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                            <path
                                                                fill-rule="evenodd"
                                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                                            />
                                                        </svg>
                                                        </span>
                                                    </Link>
                                                    </Button>
                                                    <Link to= "../Mentor" style={{color : "#fff"}} className="">
                                                    <span className='d-flex justify-content-end  align-items-center d-lg-none d-md-none d-sm-block'>
                                                        <svg
                                                            stroke="currentColor"
                                                            fill="currentColor"
                                                            stroke-width="0"
                                                            viewBox="0 0 16 16"
                                                            height="20px"
                                                            width="30px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                            <path
                                                                fill-rule="evenodd"
                                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                                            />
                                                        </svg>
                                                    </span>
                                                   </Link>
                                                </div>
                                                


                                            </footer>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-start'>
                <div className='eco-bannbox '>
                                        <div className='py-4 px-2'>
                                            <img className="img-fluid pb-2 ecobanner-image" src={LogoImage} alt='LogoImage'/>
                                        </div>
                                        <footer className='eco-bannfooter title_plex_font'>
                                        <div className='w-50'>
                                            <h2 className='font-style-normal font-weight-600 font-28-36 font-20-26'>Incubators</h2>
                                        </div>
                                            <div className='w-50 pinkbtn d-flex justify-content-end  align-items-center'>
                                                <Button className="btn-primary regular_font font-style-normal font-weight-normal font-20-24  d-none d-sm-block">
                                                <Link to= "../Incubator">
                                                <span className='regular_font font-20-24 font-style-normal font-weight-400'>Learn More</span>
                                                    <span className='inno-arrow'>
                                                    <svg
                                                        stroke="currentColor"
                                                        fill="currentColor"
                                                        stroke-width="0"
                                                        viewBox="0 0 16 16"
                                                        height="20px"
                                                        width="30px"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                                        />
                                                    </svg>
                                                    </span>
                                                </Link>
                                                </Button>
                                                <Link to= "../Incubator" style={{color : "#fff"}}>
                                                <span className='d-flex justify-content-end  align-items-center d-lg-none d-md-none d-sm-block'>
                                                        <svg
                                                            stroke="currentColor"
                                                            fill="currentColor"
                                                            stroke-width="0"
                                                            viewBox="0 0 16 16"
                                                            height="20px"
                                                            width="30px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                            <path
                                                                fill-rule="evenodd"
                                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                                            />
                                                        </svg>
                                                    </span>
                                                    </Link>
                                            </div>
                                        </footer>
                                    </div>
                </div>
            </div>   
        </div>
    </div>
    </>
  );
}
