import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../startup/informationIcon";
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const serverUrl = environment.baseUrl;
const Businessplan = (props) => {
  const authToken =sessionStorage.getItem("token");
  console.log(authToken, "sessionTkennnnnn");


  // const apllicationId =sessionStorage.getItem("apllicationId");
  // console.log(apllicationId, "biszApplication");

  let initialFormObj={ 
    proposed_Project_cost: '',
    budgetplan: '',
    capital_investment: '',
    operational_model:'',

      relevant_doc: [{
        relevantDoc:'',
        }]
     }
  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    let imagesCount = values.relevant_doc;
    let imagesCountFinal = imagesCount.length;
    let i=0;
    formData.append("relevantDocCount",imagesCountFinal)
    for (i = 0; i < imagesCountFinal; i++) {
      console.log('imagesCount[i]');
      console.log(imagesCount[i]);
      formData.append(`relevantDoc_${i}`,imagesCount[i].relevantDoc)
      
    }
    formData.append("proposed_Project_cost",values.proposed_Project_cost)
    formData.append("budgetplan",values.budgetplan)
    formData.append("capital_investment",values.capital_investment)
    formData.append("operational_model",values.operational_model)
    // formData.append("relevantDoc",values.relevantDoc)
    fetch(serverUrl +"/businessPlan/addBizPlan", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken,
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values
      ) =>
      handleSubmitFinacialForm(values)
    }
    validationSchema={Yup.object({
      proposed_Project_cost: Yup.string().required('Proposed Project Cost is required'),
    budgetplan: Yup.string().required('Select What is the Startup’s Current Funding Status is required'),
    capital_investment: Yup.string().required('Select Total Funds Received Since Incorporation is required'),
    operational_model: Yup.string().required('Select Total Funds Received Since Incorporation is required'),
        
        relevant_doc: Yup.array().of(
          Yup.object().shape({
            relevantDoc: Yup.string().required('Firm Register Date is required'),
           
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('businessplanDetails'))||initialFormObj}

      render={({ values, setFieldValue }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Business Plan
            </h2>
        </div>
        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 py-2">
        <label className="pb-2 label-txt">
               Proposed Project Cost
                <InformationIcon />
              </label>
        <Field type="text" placeholder="Enter Proposed Project Cost" className="textInput" name="proposed_Project_cost" />
        <div className="text-danger">
        <ErrorMessage className="error" name="proposed_Project_cost" /><br></br>
        </div>
      </div>
        </div>
        <label className="pb-2 label-txt pt-4">List of documents</label>
        <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Budget Plan
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="budgetplan" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("budgetplan", e.target.files[0]);
                      }} /> 
                </div>
                <div className="text-danger">
                    <ErrorMessage className="error" name="budgetplan" />
                    </div>
                </div>

                <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Capital Investment
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="capital_investment" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("capital_investment", e.target.files[0]);
                      }} />                   
                </div>
                <div className="text-danger">
                    <ErrorMessage className="error" name="capital_investment" />
                    </div>
                </div>

                <div className="col-md-12 pt-4">
                  <label className="pb-2 label-txt">
                  Operational Model
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <div class="btn float-left imgUploadBakColor form-control py-4">
                  <input id="file" name="operational_model" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("operational_model", e.target.files[0]);
                      }} />   
                </div>
                <div className="text-danger">
                    <ErrorMessage className="error" name="operational_model" />
                    </div>
                </div>
                
                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd pt-5">
                  Location of Registered Office
                </h2>
                <FieldArray
            name="relevant_doc"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>
         <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"90%"}}>Upload relevant documents</TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>      
                {values.relevant_doc && values.relevant_doc.length > 0 ? (
                  values.relevant_doc.map((friend, index) => (
              <TableBody  className="data-bg-color d-flex justify-content-between">
              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell align="center" className="d-flex align-items-center" style={{width:"90%"}}>
                       <input id="file" name={`relevant_doc.${index}.relevantDoc`} accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue(`relevant_doc.${index}.relevantDoc`, e.target.files[0]);
                      }} />   
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name={`relevant_doc.${index}.relevantDoc`} /><br></br>
                        </div>
             </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
              </TableBody>  
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        {/* <Button variant="contained">Previous</Button> */}
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("businessplanDetails", JSON.stringify(values))}}   
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                          //  onClick={()=>{props.setValue(props.value+1)}}
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>

              </div>
            )}
          />
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default Businessplan;
