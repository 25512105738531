import React from 'react';
import Incubator from './incubator';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../component/HeaderAdmin';

function IncubatorMain() {
  return (
      <>
    <Header/>
    <Incubator/>
    </>
  );
}

export default IncubatorMain;
