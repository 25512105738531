import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Startupinternationalymod(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab className="txt-main-style" label="Startup Eligibility Check" {...a11yProps(0)} />
          <Tab className="txt-main-style" label="Incorporation Details" {...a11yProps(1)} />
          <Tab className="txt-main-style" label="Founder Details" {...a11yProps(2)} />
          <Tab className="txt-main-style" label="Point of Contact for IPR filings" {...a11yProps(3)} />
          {/* <Tab className="txt-main-style" label="Patent Details" {...a11yProps(4)} />*/}
          <Tab className="txt-main-style" label="Revenue Details" {...a11yProps(4)} />
          {/*<Tab className="txt-main-style" label="Funding" {...a11yProps(6)} />
          <Tab className="txt-main-style" label="Current statistics" {...a11yProps(7)} /> */}
          <Tab className="txt-main-style" label="Revenue Details(Last 3 financial year from the date of appliaction)" {...a11yProps(5)} />
          <Tab className="txt-main-style" label="DPIIT Registration Details" {...a11yProps(6)} />
          <Tab className="txt-main-style" label="International patent" {...a11yProps(7)} />
          <Tab className="txt-main-style" label="Payment Proof" {...a11yProps(8)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> Name of Company :</b>
              </label>
              <span> {props.rowData.basicDetails.firm_name}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Is your Company registered in Maharashtra:</b> 
              </label>
              <span> {props.rowData.basicDetails.is_reg_mah}</span>
              </div>
            <hr></hr>
            </div>
          </div>
       

        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Website URL :</b>
                <span> {props.rowData.basicDetails.website}</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload Proof of being incorporated in Maharashtra:</b> 
              <span>image
                {/* {props.rowData.basicDetails.is_reg_mah} */}
                </span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Registered Address as specified  on incorporation Certificate:</b> 
                <span> {props.rowData.basicDetails.incorporation_address}</span>
              </label>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Communication Address:</b> 
              <span> {props.rowData.basicDetails.comm_address}</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Short brief about the Company:</b>
                <span> {props.rowData.basicDetails.pitch_deck}</span>
              </label>
            </div>
            <hr></hr>
          </div>
          
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Firm Registration Date:</b> 
              </label>
            <span> {props.rowData.basicDetails.foundation_date}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Type of entity:</b>
            </label>
            <span className="text-justify"> {props.rowData.basicDetails.entity_id}</span>
          </div>
          <hr></hr>
          </div>
          </div>
          
        
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              {/* <span> {props.rowData.founderDetails.testName}</span> */}
              
            <span> {props.rowData.founderDetails.name}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Contact :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Email :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Linkedin Link :</b>{" "}
               
              </label>
              <span> {props.rowData.basicDetails.biz_model_type}</span>
            </div>
            <hr></hr>
          </div>
          
        </div>
      
        
        
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Pitch Deck :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pitch uploadsDetails :</b>
            </label>
            <span> 06/09/2022</span>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Other Description :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Explainer Video :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Roadblocks :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Awards/Achievements :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Source of information :</b>{" "}
               
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Please specify Source of information :</b>{" "}
             
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>First Name :</b> 
              </label>
              <span> {props.rowData.basicDetails.poc_ipr_first_name}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Last Name :</b> 
            </label>
            <span> {props.rowData.basicDetails.poc_ipr_last_name}</span>
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>E-mail Address :</b> 
              </label>
              <span> {props.rowData.basicDetails.poc_ipr_email_id}</span>
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Phone Number :</b> 
            </label>
            <span> {props.rowData.basicDetails.poc_ipr_contact_no}</span>
          </div>
          <hr></hr>
          </div>
      
      </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Address for Communication:</b>{" "}
               
              </label>
              <span> {props.rowData.basicDetails.poc_ipr_comm_address}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Parmanant Address:</b>{" "}
               
              </label>
              <span> {props.rowData.basicDetails.poc_ipr_perm_address}</span>
            </div>
            <hr></hr>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
         <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> maximum turnover of any of the previous Three finantial years :</b>{" "}
              </label>
              <span> {props.rowData.basicDetails.max_turnover_id}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Current finantial status of the company :</b> 
            </label>
            <span> {props.rowData.basicDetails.current_funding_status_id}</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Financial year :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.current_funding_status_id}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Audited Financial Number :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.max_turnover_id}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Proof(Audited Statment from CA) :</b>{" "}
                
              </label>
              {/* <span> {props.rowData.uploadsDetails.company_PAN_card}</span> */}
            </div>
            <hr></hr>
          </div>
          
       </div>
        
        
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Registration ID Number :</b>{" "}
               
              </label> */}
              {/* <span> {props.rowData.uploadsDetails.company_registration_ID}</span> */}
            {/* </div>
            <hr></hr>
          </div> */}
          {/* <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>DPIIT Number :</b> 
            </label>
            <span> {props.rowData.uploadsDetails.}</span>
          </div>
          <hr></hr>
        </div> */}
        {/* </div> */}
        {/* <div className="row"> */}
          {/* <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>DPIIT Certificate :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div> */}
          {/* <div className="col-md-6">
          <div className="">
            <label>
              {" "} */}
              {/* <span> {props.rowData.uploadsDetails.relevant_doc}</span> */}
            {/* </label> */}
            {/* <span> 06/09/2022</span> */}
          {/* </div>
          <hr></hr>
        </div>
        
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={6}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>DPIIT No :</b> 
              </label>
              <span> {props.rowData.basicDetails.dpiit_no}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Uploaded DIIPT certificate :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.current_funding_status_id}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Have you taken benefit of this schema before?  :</b> 
              </label>
              <span> {props.rowData.basicDetails.is_scheme_benefit_taken}</span>
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>How did you come to know about this scheme?:</b> 
            </label>
            <span> {props.rowData.basicDetails.scheme_source}</span>
          </div>
          <hr></hr>
          </div>
      
      </div>
     </TabPanel>
     <TabPanel value={value} index={7}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Title of the invention :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Date of filing:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Parent Application number or Ref.no. of patent filed in india :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>upload filing receipt:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Type of application :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Please upload as filed patent application document as submitted to the patent office:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>is the Indian application published :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Which route of application do you wish to choose?:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Please explain the reason for selecting the above country/Countries.How does it help your startup?How is it a part of your Business model?</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
        
      
         
      </TabPanel>
     
    </Box>
  );
}

export default Startupinternationalymod;
