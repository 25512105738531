import React, { useState,useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { environment } from '../api'
import axios from "axios";
import loginMoboImg from '../assets/images/login/Login.png'
import loginlogo from '../assets/images/login/Login.png'
import { Button } from 'react-bootstrap-v5'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from '../../src/assets/scss/component/register.scss'

window.recaptchaOptions = {
    useRecaptchaNet: true,
  }
  
export default function ForgetPassword() {

    // const [email, setEmail] = useState('');
    // const forgotPassword = (e) => {
    //     e.preventDefault();
    // }

    const serverUrl = environment.baseUrl
    
  const recaptchaRef = React.useRef()
  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync()
  }

	const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
  const captchaRef = useRef(null)
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = serverUrl +`/password-reset`;
			const { data } = await axios.post(url, { email });
			setMsg(data.message);
			setError("");
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};

    
  return (
    <>
    {/* <div className="row g-0 auth-wrapper">
            <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                <div className="auth-background-holder"></div>
                <div className="auth-background-mask"></div>
            </div>

            <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                <div className="d-flex flex-column align-content-end">
                    <div className="auth-body mx-auto">
                        <p>Forgot Password</p>
                        <div className="auth-form-container text-start">
                            <form className="auth-form" method="POST" onSubmit={handleSubmit} autoComplete={'off'}>
                                <div className="email mb-3">
                                    <input type="email"
                                        className={`form-control 'is-invalid ' : ''}`}
                                        id="email"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
					value={email}
					required
                                        placeholder="Email"
                                        // onChange={(e) => setEmail(e.target.value)}
                                    />

                                    <div className={`invalid-feedback text-start 'd-block' : 'd-none'}`} >
                                        
                                    </div>
                                </div>
                                
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary w-100 theme-btn mx-auto">Forgot Password</button>
                                </div>
                            </form>
                            <hr />
                            <div className="auth-option text-center pt-2"><Link className="text-link" to="/login" >Back to Login</Link></div>
                        </div>
                    </div>
                </div>
            </div>

        </div> */}
        {/* ============================ */}
       
            <div className="login">
        <span className="back-head gobacklogBtn d-lg-none ">
          <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            &nbsp;&nbsp;Go Back
          </Link>
        </span>
       






          <div className="row">
            <div className="col-sm-6 loginFormCol">
            <div className='loginData1'>
                  
                  <span className="back-head gobackRegBtn font-18-22 d-lg-block d-none">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>
                    <Link to={'/'} style={{ textDecoration: 'none', color: '#000' }}>
                      <span className="gobackMoboSpace"> Go Back</span>
                    </Link>
                  </span>

                  <div className="loginMoboImg d-lg-none">
                    <img className="loginImg" src={loginMoboImg} />
                    </div>
                  <h5 className="py-3 font-28-36 font-20-26 loginTitle">
                    Forget Paasword
                </h5>   

                
                  <div className="row">
                  <form className="auth-form" method="POST" onSubmit={handleSubmit} autoComplete={'off'}>
                      <div className="containMoboRegister">
                        <div className="py-1">
                          <label
                            for="exampleInputEmail1"
                            className=" font-18-22 log-label"
                          >
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control input-width  formInputMobile input-click my-1"
                            id="email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            placeholder="Email"
                          />
                          {/* {errors.email && (
                            <p className="help is-danger invalid-feedback ">{errors.email}</p>


                          )} */}
                        </div>
                        <div className="py-2 col-sm-6">
                        <ReCAPTCHA
                            ref={captchaRef}
                              // ref={recaptchaRef}
                              sitekey="6LcpfFgjAAAAADx_jeWYdtPZM3d3-l-RXsZEjEU6"
                              // sitekey={process.env.REACT_APP_SITE_KEY}
                            />
                        </div>
                        <div className="col-sm-6"></div>
                        <div className="bluebtn py-2 d-none d-sm-block">
                        {error && <div className={styles.error_msg}>{error}</div>}
					               	{msg && <div className={styles.success_msg}>{msg}</div>}
                          <Button className="btn-primary" type="submit">
                            <Link to="">
                              <span className="regular_font font-20-24 font-style-normal font-weight-400">
                                {' '}
                                Submit
                              </span>
                              <span className="inno-arrow">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 16 16"
                                  height="25px"
                                  width="35px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                  />
                                </svg>
                              </span>
                            </Link>
                          </Button>
                        </div>

                        <div className="py-3 row">
                          <div className="col-sm-5 webLoginforgotLink">
                            <span className="">
                              {' '}

                            </span>



                            <div className="col-sm-8 webLoginregisterLink">
                              <span className="reg font-18-22 font-14-17">
                                Don't have an account?&nbsp;
                                <Link to="/Register"><a className="login-link link-hover arrow-on-hover">
                                  Register
                                </a></Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mobobluebtnpx loginbtnMobo">
                        <div className="mob-primarybtn  d-lg-none flex-item  ">
                          <Link to="">
                            <div className="d-flex justify-content-center align-items-center py-2">
                              <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                {' '}
                                Submit
                              </span>
                              <div className="inno-arrow">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 16 16"
                                  height="25px"
                                  width="35px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
            <div className="col-sm-6 ">
            <div className="d-none d-sm-flex ">
              <img className="img-fluid loginImg" src={loginlogo} />
            </div>
          </div>
            </div>
            <div className="log-footer d-none d-sm-flex ">
          <div className="curve-mask">
            <div className="curve-up ">
              <br />
            </div>
          </div>
        </div>
            </div>
            
</>
  )
}
