import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { environment } from '../../../api';
const Genre = ({ categorys, filterGenre, setFilterGenre, setSearch, setCity, setCategory, setYear }) => {
	const onChange = ({ currentTarget: input }) => {
		if (input.checked) {
			const state = [...filterGenre, input.value];
			setFilterGenre(state);
		} else {
			const state = filterGenre.filter((val) => val !== input.value);
			setFilterGenre(state);
		}
	};





	const serverUrl = environment.baseUrl;
  // const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [winnerCity, setWinnerCity] = useState([]);
  const [winnerSector, setWinnerSector] = useState([]);
  const [mentorYear, setWinnerYear] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(6);
  const [searchTasks, setSearchTasks] = useState("");

  useEffect(() => {
    fetch(serverUrl + "/startupWinner/allStartupWinner")
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          let unique = [];
          let mymap = new Map();
          unique = data.filter(el => {
            const val = mymap.get(el.city);
            if(val) {
                if(el.city < val) {
                    mymap.delete(el.city);
                    mymap.set(el.city, el.city);
                    return true;
                }
                else {
                    return false;
                }
            }
            mymap.set(el.city, el.city);
            return true;
        });

        let unique1 = [];
        let mymap1 = new Map();
        unique1 = data.filter(el => {
          console.log("el all ");
          console.log(el.category[0]);
          const val1 = mymap1.get(el.category[0]);
          if(val1) {
              if(el.category[0] < val1) {
                  mymap1.delete(el.category[0]);
                  mymap1.set(el.category[0], el.category[0]);
                  return true;
              }
              else {
                  return false;
              }
          }
          mymap1.set(el.category[0], el.category[0]);
          return true;
      });
  
      let unique2 = [];
      let mymap2 = new Map();
      unique2 = data.filter(el => {
        const val2 = mymap2.get(el.year);
        if(val2) {
            if(el.year < val2) {
                mymap2.delete(el.year);
                mymap2.set(el.year, el.year);
                return true;
            }
            else {
                return false;
            }
        }
        mymap2.set(el.year, el.year);
        return true;
    });

        setWinnerCity(unique);
        setWinnerSector(unique1);
        setWinnerYear(unique2)
        console.log(unique1, "sectorrrrrrrrrrrr");
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);
 

	return (
		<>
		<div className="col-lg-12 col-md-12 col-sm-12 searchDisplay">
		<div className="col-lg-3 col-md-3 col-sm-3">
          <div className="p-1">
            <input
              type="text"
              className="searchplaceholder search-border-remove form-control search-bar-color"
              // className={styles.search}
              placeholder=" &#61442; Search Winner by Name"
              onChange={({ currentTarget: input }) => setSearch(input.value)}
            />
          </div>		 		 
        </div>

        <div className="col-lg-3 col-md-3 col-sm-3">
          <div className="p-1">
            <select
              class="form-select"
              onChange={({ currentTarget: input }) => setCity(input.value)}
            >
              <option>Select City</option>
              {winnerCity.map((task) => (
                <option
                  key={task.value}
                  value={({ currentTarget: input }) => setCity(input.value)}
                >
                  {task.city}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3">
          <div className="p-1">
            <select
              class="form-select"
              onChange={({ currentTarget: input }) => setCategory(input.value)}
            >
              <option>Select Sector</option>
              {winnerSector.map((task) => (
                <option
                  key={task.value}
                  value={({ currentTarget: input }) => setCategory(input.value)}
                >
                  {task.category}
                </option>
              ))}
            </select>
          </div>
        </div>



        <div className="col-lg-3 col-md-3 col-sm-3">
          <div className="p-1">
            <select
              class="form-select"
              onChange={({ currentTarget: input }) => setYear(input.value)}
            >
              <option>Select Year</option>
              {mentorYear.map((task) => (
                <option
                  key={task.value}
                  value={({ currentTarget: input }) => setYear(input.value)}
                >
                  {task.year}
                </option>
              ))}
            </select>
          </div>
        </div>
       
{/* 		
		<div className="col-lg-5 col-md-5 col-sm-5 sectroCheckbox px-2">
		<div className={styles.container}>
			<div className={styles.sectroCheckbox}>
			<h1 className={styles.heading}><b>Filter By Sector</b></h1>
			<div className={styles.genre_container}>
				{categorys.map((category) => (
					<div className={styles.category} key={category}>
						<div className="d-flex">
						<input
							className={styles.genre_input}
							type="checkbox"
							value={category}
							onChange={onChange}
						/>
						<p className={styles.genre_label}>{category}</p>
						</div>
					</div>
				))}
			</div>
		</div>
		</div>
		</div> */}
		</div>




		
		</>
	);
};

export default Genre;
