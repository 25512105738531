import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import skilllogo from '../assets/images/login/skill-logo.png'
import loginlogo from '../assets/images/login/Login.png'
import loginMoboImg from '../assets/images/login/Login.png'
import { useParams } from "react-router-dom";
// import ForgetPW from './ForgetPW'
import apple from '../assets/images/login/Apple.png'
import facebook from '../assets/images/login/facebook.png'
import Windows from '../assets/images/login/Windows.png'
import Slack from '../assets/images/login/Slack.png'
import Gmail from '../assets/images/login/gmail.png'
import Recaptcha from 'react-recaptcha'
import { Button } from 'react-bootstrap-v5'
import ReCAPTCHA from 'react-google-recaptcha'
// import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
// import { Redirect } from 'react-router-dom'
import { environment } from '../api'
// import StartUp from '../startup/LandingMain'
import axios from "axios";
import validate from './LoginFormValidationRules';
window.recaptchaOptions = {
  useRecaptchaNet: true,
}


function ResetPW(callback, props) {
  const serverUrl = environment.baseUrl
  const [validUrl, setValidUrl] = useState(false);
	const [password, setPassword] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
	const param = useParams();
	const url = serverUrl + `/password-reset/${param.id}/${param.token}`;
 

	useEffect(() => {
		const verifyUrl = async () => {
			try {
				await axios.get(url);
				setValidUrl(true);
			} catch (error) {
				setValidUrl(false);
			}
		};
		verifyUrl();
	}, [param, url]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await axios.post(url, { password });
			setMsg(data.message);
			setError("");
			window.location = "/login";
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};






  // ============================
 
  return (
    <>
    <div className="login">
      <span className="back-head gobacklogBtn d-lg-none ">
        <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 16 16"
            height="25px"
            width="25px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          &nbsp;&nbsp;Go Back
        </Link>
      </span>

      <div className="loginMoboImg d-lg-none">
        <img className="loginImg" src={loginMoboImg} />
      </div>

      <div className="row">
        <div className="col-sm-6">
            <div className="loginData1">
              <span className="back-head gobackRegBtn font-18-22 d-lg-block d-none">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="20px"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
                <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
                  <span className="gobackMoboSpace"> Go Back</span>
                </Link>
              </span>

              <h5 className="py-3 font-28-36 font-20-26 loginTitle">
                Reset Password
              </h5>
              <div className="container">
                
                <div className="row pt-4">
                  <form onSubmit={handleSubmit} method="POST">
                    <div className="containMoboRegister">
                      <div className="py-1">
                        <label
                          for="exampleInputEmail1"
                          className=" font-18-22 log-label"
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          className="form-control input-width  formInputMobile  input-click my-1"
                          id="inputemail"
                          placeholder="Enter Password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
							value={password}
                        />
                        {/* {<errors className="password"></errors> && (
                          <p className="help is-danger invalid-feedback ">{errors.password}</p>


                        )} */}
                      </div>

                      {/* <div className="py-1">
                        <label
                          for="exampleInputEmail1"
                          className="font-18-22 log-label"
                        >
                          Confirm New Password
                        </label>
                        <input
                          type="password"
                          className="form-control input-width  formInputMobile  input-click my-1"
                          id="inputemail"
                          placeholder="Enter Password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                        /> */}
                        {/* {<errors className="password"></errors> && (
                          <p className="help is-danger invalid-feedback ">
                             {errors.password} 
                            </p>


                        )} */}
                      {/* </div> */}
                      <div className="col-sm-6"></div>
                      <div className="bluebtn py-2 d-none d-sm-block">
                        <Button className="btn-primary" type="submit">
                          {/* <Link to=""> */}
                          <span className="regular_font font-20-24 font-style-normal font-weight-400">
                            {' '}
                            Submit
                          </span>
                          <span className="inno-arrow">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 16 16"
                              height="25px"
                              width="35px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                              />
                            </svg>
                          </span>
                          {/* </Link> */}
                        </Button>
                      </div>

                      <div className="py-3 row">
                        <div className="col-sm-5 webLoginforgotLink">
                          <span className="">
                            {' '}
                            {/* <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            height="20px"
                            width="20px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                            ></path>
                          </svg> */}
                          </span>
                          {/* <a onClick={handleClick} className="pl-2 login-link link-hover  font-18-22 font-14-17 ">
                            Forgot Password
                          </a> */}


                          <div className="col-sm-8 webLoginregisterLink">
                            <span className="reg font-18-22 font-14-17">
                              Don't have an account?&nbsp;
                              <Link to="/Register"><a className="login-link link-hover arrow-on-hover">
                                Register
                              </a></Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mobobluebtnpx loginbtnMobo">
                      <div className="mob-primarybtn  d-lg-none flex-item  ">
                        <Link to="">
                          <div className="d-flex justify-content-center align-items-center py-2">
                            <span className="regular_font font-18-22 font-style-normal font-weight-400">
                              {' '}
                              Submit
                            </span>
                            <div className="inno-arrow">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="25px"
                                width="35px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                              </svg>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
        <div className="col-sm-6 ">
          <div className="d-none d-sm-flex ">
            <img className="img-fluid loginImg" src={loginlogo} />
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="log-footer d-none d-sm-flex ">
        <div className="curve-mask">
          <div className="curve-up ">
            <br />
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default ResetPW