import React from 'react'
// import Navbar from '../../component/Navbar';
import Wec from './Wec';
// import WecBanner from './WecBanner';
import Footer from '../../component/footer';
import Header from '../../component/Header';
 function WomenEntrepreneure() {
  return (
      <>
      <Header/>
      {/* <Navbar /> */}
      {/* <WecBanner/> */}
    <Wec/>
    <Footer/>
    </>
    
    
  )
}
export default WomenEntrepreneure;

