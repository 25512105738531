import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from "../api";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InformationIcon from "../startup/informationIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddMember from "../assets/images/Incubator/Add.svg";
const serverUrl = environment.baseUrl;
const InternationalPatentTab2= (props) => {
  let initialFormObj = {
    invention_name: "",
    Filling_Date: "",
    Patent_Number: "",
    Filing_Receipt: "",
    application_type: "",
    do_you_have_IP: "",
    route_application: "",
    Payment_Proof: "",
    description:"",
    Date:"",
    Document_reason:"",
    description_query:"",
    Query_Date:"",
    Upload_doc:"",


   
  };
  const [flag, setFlag] = useState("no")
  const btnClick = (e) => {
   setFlag(e.target.value);
  };
  function handleSubmitForm(values) {
    console.log(values, "hii");
    const formData = new FormData();
    formData.append("do_you_have_IP", values.do_you_have_IP);
    
    fetch(serverUrl + "/ip/addIpDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values, null, 2),
    })
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
      });
    props.setValue(props.value + 1);
  }
  return (
   <>
    <div className="container">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Formik
              onSubmit={(values) => handleSubmitForm(values)}
              validationSchema={Yup.object({
                invention_name: Yup.string().required("Company name is required"),
                // do_you_have_IP: Yup.string().required(
                //   "Please Select Is your company registered in Maharashtra?"
                // ),
                // Company_Website:Yup.string().required('Company Website URL is required'),
                Filling_Date: Yup.string().required(
                  "Please Upload Proof of being incorporated in Maharashtra."
                ),
                Patent_Number: Yup.string().required(
                  "Please enter Registered Address as on incorporation certificate"
                ),
                Filing_Receipt: Yup.string().required(
                  "Please Select Funding Received"
                ),
                application_type: Yup.string().required(
                  "Please Enter Address for Communication"
                ),
                do_you_have_IP: Yup.string().required(
                  "Please provide short brief about the company"
                ),
                route_application: Yup.string().required("Select Date"),
                route: Yup.string().required(
                  "Please details for the same"
                ),
                Payment_Proof: Yup.string().required(
                  "Please provide short brief about the company"
                ),
                description: Yup.string().required(
                  "Please provide short brief about the company"
                ),
                Date: Yup.string().required(
                  "Please provide short brief about the company"
                ),
                Document_reason: Yup.string().required(
                  "Please provide short brief about the company"
                ),
                description_query: Yup.string().required(
                  "Please provide short brief about the company"
                ),
                Query_Date: Yup.string().required(
                  "Please provide short brief about the company"
                ),
                Upload_doc: Yup.string().required(
                  "Please provide short brief about the company"
                ),

              })}
              initialValues={
                JSON.parse(localStorage.getItem("teamDetails")) ||
                initialFormObj
              }
              render={({ values, setFieldValue }) => (
                <Form>
                  <div className="row pb-3">
                 
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                    <label className="pb-2 label-txt">
                    Title of the invention <span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter Name of Invention"
                      className="textInput"
                      name="invention_name"
                    />
                    <div className="text-danger">
                      <ErrorMessage className="error" name="invention_name" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                        Date of filing
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          type="date"
                          placeholder="Enter Registered Address"
                          className="textInput"
                          name="Filling_Date"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="Filling_Date"
                          />
                        </div>
                      </div>
                  </div>

                  <div className="row pb-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                      <label className="pb-2 label-txt">
                      Patent Application number or Ref. no. of patent filed in India
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="number"
                        placeholder="Enter Application Number"
                        className="textInput my-3"
                        name="Patent_Number"
                      />
                      <div className="text-danger">
                        <ErrorMessage
                          className="text-danger"
                          name="Patent_Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                        Upload Filing Receipt
                          <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="file"
                          placeholder="Upload File"
                          className="textInput my-3"
                          name="Filing_Receipt"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="Filing_Receipt"
                          />
                        </div>
                      </div>
                  </div>

                  <div className="row pb-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                      <label className="pb-2 label-txt">
                      Type of application <span className="text-danger">*</span>{" "}
                        <InformationIcon />
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Type of Application"
                        className="textInput"
                        name="application_type"
                      />
                      <div className="text-danger">
                        <ErrorMessage className="error" name="application_type" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Please upload as filed patent application document as submitted to the Patent Office
                            <span className="text-danger">*</span>{" "}
                            <InformationIcon />
                          </label>
                          <Field
                            type="file"
                            placeholder="Upload File"
                            className="textInput my-3"
                            name="Filing_Receipt"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Filing_Receipt"
                            />
                          </div>
                    </div>
                  </div>

                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 pb-4">
                    <label className="pb-2 label-txt">
                   Is the Indian application published?
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field
                      component="div"
                      name="do_you_have_IP"
                      onChange={btnClick}
                    >
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-2 col-md-1">
                          <div class="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              id="radioYes"
                              // defaultChecked={values.do_you_have_IP === "yes"}
                              name="do_you_have_IP"
                              value="yes"
                            />
                            <label htmlFor="radioYes">Yes</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div class="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              id="radioNo"
                              defaultChecked
                              name="do_you_have_IP"
                              value="no"
                            />
                            <label htmlFor="radioNo">No</label>
                          </div>
                        </div>
                      </div>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="error" name="do_you_have_IP" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                      <label className="pb-2 label-txt">
                      Which route of application do you wish to choose? <span className="text-danger">*</span>{" "}
                        <InformationIcon />
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter route of Application"
                        className="textInput"
                        name="route_application"
                      />
                      <div className="text-danger">
                        <ErrorMessage className="error" name="route_application" />
                      </div>
                  </div>
                  </div>

                  <div className="row pb-3">
                  <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                      <label className="pb-2 label-txt">
                      Please explain the reason for selecting the above country/ countries. How does it help your startup? How is it a part of your business model? <span className="text-danger">*</span>{" "}
                        <InformationIcon />
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter route of Application"
                        className="textInput"
                        name="route"
                      />
                      <div className="text-danger">
                        <ErrorMessage className="error" name="route" />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                          Payment Proof
                        </h2>
                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Upload Document of Payment proof
                            <span className="text-danger">*</span>{" "}
                            <InformationIcon />
                          </label>
                          <Field
                            type="file"
                            placeholder="Upload File"
                            className="textInput my-3"
                            name="Payment_Proof"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Payment_Proof"
                            />
                          </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                          Action Taken Details
                        </h2>
                  </div>
                  <div className="row pb-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                        Description <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter Description"
                          className="textInput"
                          name="description"
                        />
                        <div className="text-danger">
                          <ErrorMessage className="error" name="description" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Date
                            <span className="text-danger">*</span>
                            <InformationIcon />
                          </label>
                          <Field
                            type="date"
                            placeholder="Select Date"
                            className="textInput"
                            name="Date"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Date"
                            />
                          </div>
                    </div>
                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Upload Document for Reasons
                            <span className="text-danger">*</span>{" "}
                            <InformationIcon />
                          </label>
                          <Field
                            type="file"
                            placeholder="Upload File"
                            className="textInputs"
                            name="Document_reason"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Document_reason"
                            />
                          </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        Partner Query Reply
                        </h2>
                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                      <label className="pb-2 label-txt">
                      Description <span className="text-danger">*</span>{" "}
                        <InformationIcon />
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Description"
                        className="textInput"
                        name="description_query"
                      />
                      <div className="text-danger">
                        <ErrorMessage className="error" name="description_query" />
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                        Date
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          type="date"
                          placeholder="Enter Registered Address"
                          className="textInput"
                          name="Query_Date"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="Query_Date"
                          />
                        </div>
                  </div>

                  </div>
                  <div className="row pb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                          <label className="pb-2 label-txt">
                          Upload Document
                            <span className="text-danger">*</span>{" "}
                            <InformationIcon />
                          </label>
                          <Field
                            type="file"
                            placeholder="Upload File"
                            className="textInput"
                            name="Upload_doc"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="Upload_doc"
                            />
                          </div>
                    </div>
                  </div>
                  <div className="justify-content-center d-flex pt-4">
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        type="submit"
                        className="btn-color-draft no-shadow"
                        onClick={() => {
                          localStorage.setItem(
                            "ipDetails",
                            JSON.stringify(values)
                          );
                        }}
                      >
                        Save as Draft
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        className="btn-color-next no-shadow"
                      >
                        Save &amp; Next
                      </Button>
                    </Stack>
                  </div>
                  
                </Form>
              )}
            />
          </div>
        </div>
      </div> 
   </>
  )
}

export default InternationalPatentTab2