import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function StageViewM(props) {
  const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: "100%" }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      {/* <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
    >
     
      <Tab className="txt-main-style" label="Basic details" {...a11yProps(0)} />
      <Tab className="txt-main-style" label="Other Details" {...a11yProps(1)} />
      
    </Tabs> */}
    </Box>
    <TabPanel value={value} index={0}>
      <div className="row">
        <div className="col-md-2">
          <div className="">
            <label>
              {" "}
              <b> Name :</b>
            </label>
          </div>
        </div>
        <div className="col-md-10">
          <div className="">
            <span>{props.rowData.Stage.name}</span>
          </div>
        </div>
      </div>
      <hr></hr>

      

      
      
    </TabPanel>
  </Box>
  );
}
