import React, { useState, useEffect } from "react";
import Arrow from "../../assets/images/FundResourc/arrow-visit.png";
// import article1 from "../../assets/images/Resourcesbanner/Media/article1.png";
// import article2 from "../../assets/images/Resourcesbanner/Media/article2.png";
// import article3 from "../../assets/images/Resourcesbanner/Media/article3.png";
// import { Button } from "react-bootstrap-v5";
import { environment } from '../../api';
import {Link} from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText
} from "react-bootstrap-v5";
export default function MediaList() {
  const serverUrl = environment.baseUrl;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(serverUrl +"/media/allmedia")
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setUsers(data);
                console.log(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
  }, [])
  return (
    <div>
       <h5 className="font-20-26 d-lg-none d-flex cardMediaMobotiltle justify-content-center"><b>News And Press</b></h5>
      <div className="container d-none d-sm-flex d-sm-none d-md-block d-lg-block">
        <div className="container pb-4">
        {users.map(user => 
               (
          <div className="pb-4">
          <div className=" mediaCard  ">
          <div className="row cardrow">
            <div className="col-sm-10 col-md-8 d-flex align-items-center  py-1">
              <h6 className="p-3 mb-0">
              {user.date}
              {/* August 18, 2020 */}
              </h6>
            </div>
            <div className="col-sm-2 col-md-4 d-flex align-items-center justify-content-end p-2 py-1">
              <a href={user.link} target="_blank">
              <Button >
                View Articles
                <span className="media-arrow">
                  <img src={Arrow} />
                </span>
              </Button>
              </a>
            </div>
          </div>
          <div className="row  mediaDetailscard">
            <div className="col-sm-4 d-flex align-items-center justify-content-center p-4">
              {" "}<img className="img-fluid " src={user.image} />
            </div>
            <div className="col-sm-8  ">
              <h3 className="mediaCardTitle font-24-29 font-18-23">
                <b>
                {user.title}
                 
                </b>
              </h3>
              <p className="mediacardsutext font-22-26 font-14-17 ellipsis">
              {user.description}
               
              </p>
            </div>
          </div>
          </div>
          </div>
          ))}
       
        </div>
      </div>
      {/* -----------------Mobile Responsive card--------------- */}

      <div className="cardMediaResponsiveList mobmediacard d-sm-block d-md-none d-lg-none">
      {users.map(user => 
               (
        <div className="pb-4">
        <Card >
              <Card.Header as="h5">
                
                <div className="px-3 py-4">
                  {" "}<img className="img-fluid riidlImgMobo" src={user.image} />
                  <span className="font-12-14">{user.date}</span>
                </div>
                <div>
                <h3 className="mediaCardTitle font-28-36 font-18-23 ">
                <b>
                {user.title}
                </b>
              </h3>
              <p className="mediacardsutext font-22-26 font-14-17 ">
              {user.description}
              </p>
                </div>
                
              </Card.Header>
              
              <Card.Footer>
               
              <div className='mob-graybtn py-3 d-lg-none'>
            <a href={user.link} target="_blank">
              <div className='d-flex justify-content-center align-items-center'>
                View Articles
               
                </div> 
                
                <span className='inno-arrow float-end'>
                <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 16 16"
                              height="20px"
                              width="30px"
                              xmlns="http://www.w3.org/2000/svg"
                              >
                              <path
                                  fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                              />
                          </svg>
                </span>
            </a>
        </div>
              </Card.Footer>
            </Card>
        </div>
        ))}
        
      </div>
    </div>
  );
}
