import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";

import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const FeaturedVideosAddM = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitFeaturedVideosAddForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("title",values.title)
    formData.append("video",values.video)
    console.log(formData)
    function fetchData(){
      fetch(serverUrl + "/video/allvideo")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    setUsers(data);
  },
)
}
    fetch(serverUrl +"/video/addvideo", 
         {
            method: "POST",
            body: formData,
           
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then((res) => setModalIsOpenToFalse3())
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
          
  }
  console.log("called")

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
            onSubmit={(values, {resetForm}
            ) =>
           { console.log(values )
            handleSubmitFeaturedVideosAddForm(values)
            resetForm({values:''}) 
          }
          }
    validationSchema={Yup.object({
    title: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Title Name is Required'),
    video: Yup.string().required('Video  is Required'),
       })}
      initialValues={{ 
      title: '',
      video:'',
       }}

      render={({ values, setFieldValue ,handleSubmit}) => (
        <Form onSubmit={(e)=>{
          
          handleSubmit(e)}}>
        <div className="row">

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Title Name" className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Video <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>  
                    <Field type="text" placeholder="Enter Video" accept=".mov,.mp4" className="textInput" name="video" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="video" /><br></br>
                    </div>                       
                    {/* <input id="file" name="video"  accept=".mov,.mp4"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("video", e.target.files[0]);
                      }} />      */}

{/* <input
    type='file'
    className='form-control form-control-sm'
    id='media-URL'
    accept='image/*,audio/*,gif/*,video/mp4,video/x-m4v,video/*'
    onChange={(e) => {
      console.log(e.target.files[0])
        setFieldValue("video", e.target.files[0]);
      }}
    /> */}
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="video" /><br></br>
                    </div> */}
                  </div>



                  
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default FeaturedVideosAddM;
