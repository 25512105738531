import React, { useEffect,useState } from 'react'
import '@mui/x-data-grid-generator'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import IncubatorViewModel from './incubatorViewMod'
import IncubatorApproveModel from './incubatorApproveModel'
import { environment } from '../api';

function Viewincubator() {
  const authToken =sessionStorage.getItem("token");
  console.log(authToken, "sessionTkennnnnn");
  const [modalIsOpen, setModalIsOpen] = useState(false)
  


  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true)
  }

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }
  const [modalIsOpen1, setModalIsOpen1] = useState(false)
  


  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true)
  }

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false)
  }

  const [showData,setShowData] = useState('');
  const serverUrl = environment.baseUrl;
  const [rows, setUsers] = useState([]);
  const [businessPlan, setBusinessPlan] = useState([]);

  const [leadershipTeam, setLeadershipTeam] = useState([]);
  const [sectorExpertise, setSectorExpertise] = useState([]);
  const [marketLinkages, setMarketLinkages] = useState([]);
  const [infrastructure, setInfrastructure] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rowId, setrowId] = useState('');
  const [rowData, setRowData] = useState({
    IncubatorDetail: [],
    BusinessPlan: [],
    LeadershipTeam:[],
    SectorExpertise: [],
    MarketLinkages: [],
    Infrastructure: [],
  })
  useEffect(() => {
      fetch(serverUrl +"/incubatorDetail/allIncubatorDetail",
      {headers: {
        "auth-token":authToken
        
      }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setUsers(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/businessPlan/allBizPlan",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setBusinessPlan(data);
              },
              
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/teamLeaderDetail/allleaderDetail",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setLeadershipTeam(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/sectorExperties/allsectorExpert",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setSectorExpertise(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/marketLinkage/allmarketLinkage",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setMarketLinkages(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/infrastructure/allInfraDetail",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setInfrastructure(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
    }, [])

    useEffect(()=>{
      let tempData = rowData;
      rows.map((el)=>{
        if(el.applicationId === rowId)
          tempData.IncubatorDetail = el;

      })
      businessPlan.map((el)=>{
        if(el.applicationId === rowId)
          tempData.BusinessPlan = el;
          
      })

      leadershipTeam.map((el)=>{
        if(el.applicationId === rowId)
          tempData.LeadershipTeam = el;
          
      })
      sectorExpertise.map((el)=>{
        if(el.applicationId === rowId)
          tempData.SectorExpertise = el;
      })
      marketLinkages.map((el)=>{
        if(el.applicationId === rowId)
          tempData.MarketLinkages = el;
          
      })
      infrastructure.map((el)=>{
        if(el.applicationId === rowId)
          tempData.Infrastructure = el;
          
      })
      setRowData(tempData)

    },[rowId])

  const columns = [
    { field: 'applicationId', headerName: 'Sr.No.', width: 60 },
    { field: 'name_of_individual_firm', headerName: 'Name of individual /firm', width: 120 },
    { field: 'focus_industries', headerName: 'Focus industries', width: 120 },
    { field: 'state', headerName: 'Incubation Status', width: 150 },
    { field: 'date_of_incorporation', headerName: 'Date of Incorporation', width: 120 },
    { field: 'type_of_incubator', headerName: 'Type of Incubator', width: 110 },
    { field: 'Submit_Date', headerName: 'Submitted Date', width: 110 },
    { field: 'Status1', headerName: 'Status	', width: 110 },
    { field: 'Action', headerName: 'Action', sortable: false, width: 200,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            )

          return alert(JSON.stringify(thisRow, null, 4))
        }
  return (
    <>
            <Button onClick={setModalIsOpenToTrue}>
              <VisibilityIcon />
            </Button>
            {/* <Button onClick={onClick}>
              <BallotIcon />
            </Button> */}
            <Button onClick={setModalIsOpenToTrue1}>
              <ThumbUpIcon />
            </Button>
            {/* <Button onClick={onClick}>
              <ThumbDownIcon />
            </Button> */}
          </>
        )
      },
    },
  ]

  

  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Incubator Details
          </h2>
          <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
               pageSize={8}
               onSelectionModelChange={(newSelection)=>{
                setrowId(newSelection[0])
               }}
               components={{ Toolbar: GridToolbar
                 }}
              getRowId ={(rows) => rows.applicationId}  
                           
            />
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
          <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Incubator Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <IncubatorViewModel rowData = {rowData}/>
          </div>
        </Modal>
        
      </div>
      <div>
        <Modal isOpen={modalIsOpen1} className="ModOverlay">
          <div className="viewMod">
          <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Form Approvals</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse1}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <IncubatorApproveModel />
          </div>
        </Modal>
        
      </div>
    </>
  )
}

export default Viewincubator