import React from "react";
import Header from "../component/Header";
import Footer from "../component/footer";

function disclaimer() {
  return (
    <>
      <Header />
      <div className="mahaGrand-main intellectual-main">
        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                Disclaimer
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Although every care has been taken to ensure the accuracy of
                  information furnished in this website, it does not accept any
                  responsibility or liability for any damage or loss to any
                  person or organisation for the result of any action taken on
                  the basis of this information. However, it wil be obliged if
                  errors/omission are brought to its notice for carrying out
                  corrections in the next update.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default disclaimer;
