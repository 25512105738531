import React, { useState, useEffect } from 'react'
import QualityTesting from "../../assets/images/Initiative/qualityTesting/qt_1.3cea0ad0.png";
import QualityTestingprocess from "../../assets/images/Initiative/qualityTesting/qt_2.34506fa7.png";
import QualityTestingDoc from "../../assets/images/Initiative/qualityTesting/qt_3.4f498442.png";
import Pdf from "../../assets/images/Initiative/financialAssistance/230548MSInS- IPR.pdf";
import QualityTestingGuideline from "../../assets/images/Initiative/qualityTesting/QT_Detailed_Application_Guide.pdf";
import StartupGuideline from "../../assets/images/Initiative/financialAssistance/MSInS_Startup_Registration.pdf";
import { environment } from '../../api'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function QualityTestingMiddleContent() {
  const loginUrl = environment.LoginLink
  const [dropdownState, setDropdownState] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('')

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState)
  }
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value)
    setDropdownState(!dropdownState)
  }

  
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  
  return (
    <>
      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be Redirected to existing Portal to access your account.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="primary"  target="_blank" href={loginUrl} >
           Ok
          </Button>
        </Modal.Footer>
      </Modal> */}

    <div className="mahagranddropdown d-lg-none d-xl-none d-md-block d-sm-block" id='mahadropdown'>
        <div className="container">
          <div className={`dropdown pt-2`} >
            <button onClick={handleDropdownClick} className="dropdown-btn">
              {dropdownValue === '' ? 'Menu' : dropdownValue}
            </button>
            <div
              className={`dropdown-items ${dropdownState ? 'isVisible' : 'isHidden'
                }`}
            >
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Scheme Description')}
                >
                  <a href="#idScheme">1. Scheme Description</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Reimbursement criteria')}
                >
                  <a href="#idReimbursement">2. Reimbursement criteria</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() =>
                    handleSetDropdownValue('Eligibility Criteria')
                  }
                >
                  <a href="#idEligibility">3. Eligibility Criteria</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Application Process')}
                >
                  <a href="#idAppProcess">4. Application Process</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Reimbursement Process')}
                >
                  <a href="#idReimbursementProcess">5. Reimbursement Process</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('FAQ')}
                >
                  <a href="#idFaq">6. FAQ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mahaGrand-main intellectual-main1">
        <div className="row">
          <div className="col-md-12 title-align pb-3">
            <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 p-2">
              Quality Testing &#38; Certification Scheme
            </h2>
          </div>
        </div>

        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-3 col-md-3 col-sm-3 d-md-none d-lg-block d-sm-none justify-content-center">
              <div className="card col-lg-10 col-md-10 col-sm-10 apply-head border-warning border-2">
                <div 
                // onClick={handleShow1} 
                className="card-body d-flex justify-content-center  align-items-center cursor-pointer">
                  <div   className="col-lg-8 col-md-8 col-sm-8  ">
                    <span  className="regular_font font-20-24 font-style-normal font-weight-400">
                    <a href='https://forms.gle/7SQfLW1HedjCDCtM7'target="_blank" >Apply Now</a>
                    </span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <span className="Arrow-apply align-items-end">
                    <a href='https://forms.gle/7SQfLW1HedjCDCtM7'target="_blank" >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                      </a>
                    </span>
                  </div>
                </div>
                <div className="list-group list-group-flush">
                  <a
                    href="#idScheme"
                    className="list-group-item list-group-item-action"
                  >
                    <span className="regular_font font-18-23 font-style-normal font-weight-400">
                      Scheme Description
                    </span>
                  </a>
                  <a
                    href="#idReimbursement"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Reimbursement criteria
                    </span>
                  </a>
                
                  <a
                    href="#idAppProcess"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Application Process
                    </span>
                  </a>

                  <a
                    href="#idReimbursementProcess"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                    Submission Reimbursement Process
                    </span>
                  </a>

                  <a
                    href="#idFaq"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      FAQ's
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-7">                 
              <div className="row" id="idScheme">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Scheme Description
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                Testing and certification of products for quality and assurance are an important part of business for startups. However, these costs are high for startups in their formative years. Through this Scheme, the Government of Maharashtra will be reimbursing a part of quality testing costs incurred by startups at NABL or BIS recognised labs, with the objective of increasing the competence of Maharashtra-incorporated startups in product development as well as ensuring their competitiveness in the domestic and export markets.
                </p>
              </div>
              <div className="row b-2" id="idReimbursement">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Reimbursement criteria
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    Through this Scheme, MSInS shall reimburse 80% of product testing/certification costs and system certification costs incurred by startups at NABL accredited or BIS recognized labs. Reimbursement should be claimed within 1 year of the testing report. 
                    </p>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    Through this Scheme, maximum reimbursement shall be INR 2 lakh per startup, until the startup makes an annual revenue of INR 1 crore (in any financial year since incorporation) or raises total funds of INR 3 crores or more, whichever is earlier.
                    </p>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    Reimbursement will not be granted for routine quality control tests or consultant fees.
Eligibility

                    </p>
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    In order to be eligible for benefits under the IPR Scheme, a startup should meet following norms:
                    </p>
                 <p>
                 <ul>
                  <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'><b>Maharashtra incorporated :</b> Startup should be incorporated in Maharashtra</li>
                  <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'><b>DPIIT Recognised :</b> Startup should be recognised by the Department for Promotion of Industry and Internal Trade (DPIIT), Government of India</li>
                 <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'><b>Revenue & Funding Cap :</b> Startup’s annual revenue should not have crossed INR 1 crore in any financial year (since incorporation), and the startup should not have raised funds more than INR 3 crores.</li>
                <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>The test invoice shouldn’t be before 12 months from the date of filing reimbursement. </li>
                 </ul>
                 </p>
               
              </div>
              <div className="row" id="idAppProcess">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Application Process
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">                  
                  In order to complete the application, you may need the following documents:
                  <ol className="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-2">
                    <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Company Incorporation Certificate  </li>
                    <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Audited Financial statements (since inception): To be audited by a CA since inception till application date with MSInS</li>
                    <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>For the reimbursement being claimed submit a self-declaration confirming that you have not received any funding for that particular test. </li>
                    <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>DPIIT Recognition Certificate</li>
                    <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Lab BIS/NABL Approval/Recognition Certificate: To be provided by the testing lab as a proof of the lab being recognised by BIS/NABL</li>
                    <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Test Certificate: Issued by the testing lab (after testing your product)</li>
                   <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Test Invoice/Receipt: Clearly highlighting the amount spent on testing.</li>
                   <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Test report</li>
                  </ol>
                </p>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                <b>Note :</b> All documents should be submitted in PDF/JPG format (max size per document: 10 MB). 
                </p>
              </div>
              <h2 className="font-22-26 font-18-23 font-20-26 font-weight-600 pb-2 externallinkcolour">
                  Apply here with MSInS
                  </h2>
                  <ul>
                    <li  class="font-style-normal font-weight-400 font-18-22 font-14-17 justify pt-1 pb-2 "> <a href="https://forms.gle/7SQfLW1HedjCDCtM7" target="_blank">Quality Testing</a></li>
                  </ul>
              <div className="row" id="idReimbursementProcess">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 justify">
                Post Application Submission Reimbursement Process
                </h2>
               <p> <ol>
                  <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Post submission, each application will be screened by MSInS to ensure compliance with eligibility criteria and reimbursement criteria. MSInS team may contact Startup Point of Contact to clarify information which may not be clear through the application form.</li>
                <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>Post all approvals, reimbursements shall be processed batchwise on a weekly basis and payment shall be processed through the National Electronic Fund Transfer (NEFT) / Real Time Gross Settlement (RTGS) system.</li>
               <li className='font-style-normal font-weight-400 font-18-22 font-14-17 justify py-1'>The reimbursements shall be processed on a first come first serve basis, subject to availability of budgets and orderly submission, within 30 days of application.</li>
               </ol></p>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QualityTestingMiddleContent;
