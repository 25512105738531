import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../../startup/informationIcon";
import { environment } from '../../../api';
const serverUrl = environment.baseUrl;
const PreviousEventsUpdateM = (props, {setModalIsOpenToFalse2}) => {
  const [isLoaded, setIsLoaded] = useState(false);
// console.log(props)
const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitUpcomingEventsUpdateForm(values){
    
    const formData = new FormData();
    formData.append("title",values.title)
    formData.append("current_date",values.current_date)
    formData.append("description",values.description)
    formData.append("image",values.image)
    formData.append("txtColor",values.txtColor)
    formData.append("btnbg",values.btnbg)
    formData.append("bgCard",values.bgCard)
    formData.append("link",values.link)

    function fetchlandingBanner(){
      fetch(serverUrl + "/initiative/allInitiative")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data[1].prevdata);
  },
)
}
    fetch(serverUrl +`/initiative/${props.rowData.upEvents._id}`,
         {
          method: 'PUT',
          body: formData,
                      
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .then((res) => fetchlandingBanner())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("Are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
          
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitUpcomingEventsUpdateForm(values)
    }
    validationSchema={Yup.object({
    title: Yup.string().required('Enter Title Name is Required'),
    current_date: Yup.string().required('Selecte Date is Required'),
    description: Yup.string().required('Enter Description is Required'),
    image: Yup.string().required('Image  is Required'),
    // btnbg: Yup.string().required('Select button color'),
    // bgCard: Yup.string().required('Background Card is Required'),
    link: Yup.string().required('Link is Required'),
       })}
      initialValues={{ 
      title:props.rowData.upEvents.title,
      current_date: props.rowData.upEvents.current_date,
      description: props.rowData.upEvents.description,
      image: props.rowData.upEvents.image,
      txtColor: props.rowData.upEvents.txtColor,
      btnbg: props.rowData.upEvents.btnbg,
      bgCard: props.rowData.upEvents.bgCard,
      link: props.rowData.upEvents.link,
       }}
      render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Title Name" className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                   Date{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="date"  onChange={handleChange} placeholder="Select From date" className="textInput" name="current_date" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="current_date" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Text Color{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="color"                    
                      onChange={handleChange}
                      placeholder="Enter Card Text Color" className="textInput" name="txtColor" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="txtColor" /><br></br>
                    </div>                   
                  </div> 
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Background Card{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="color"                    
                      onChange={handleChange}
                      placeholder="Enter Card Type" className="textInput" name="btnbg" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="btnbg" /><br></br>
                    </div>                   
                  </div>  
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Button Background{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="color"                    
                      onChange={handleChange}
                      placeholder="Enter Card Type" className="textInput" name="bgCard" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="bgCard" /><br></br>
                    </div>                   
                  </div>                                 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea"
                      onChange={handleChange}
                     placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" /><br></br>
                    </div>
                  </div>  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                 Link <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"   onChange={handleChange}  placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div>                 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img id="imgupdate" src={updatedImage||values.image} style={{width:'20%', height:'50%'}} /><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("image", e.target.files[0]);
                      }}
                      
                      required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>
              
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default PreviousEventsUpdateM;
