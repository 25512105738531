import React from 'react';
import Header from '../../component/Header';
import IncubatorProfile from "./IncubatorProfiles"
import Footer from '../../component/footer';


function Incubator() {
  return (
    <>
    <Header/>
    <IncubatorProfile />
    <Footer/>
  </>
  );
}

export default Incubator;
