import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink,useParams, withRouter  } from 'react-router-dom'
import NavLogo from '../assets/images/navbar/navbar_logo.png'
import Icoaccessibility from '../assets/images/ico-accessibility1.svg.png'
import NavLogoWhite from '../assets/images/navbar/navbar_lg_black.png'
import CloseIcon from '@mui/icons-material/Close'
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NewAreaModal from "./NewAreaModal";
import LoginModal from './loginModal'
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { useParams, withRouter } from "react-router";
import axios from "axios";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { environment } from '../api'

import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import Tooltip from "@material-ui/core/Tooltip";

import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap'



export default function Navigation(props) {
  const serverUrl = environment.baseUrl

  const script = document.createElement("script");
  script.src = "../html-magnifier.js";
  script.async = true;
  script.onload = () => this.scriptLoaded();

  document.body.appendChild(script);

  const [fontSize, setFontSize] = useState(16);
  const [zoomLevel, setZoomLevel] = useState(1);

  const FontSizeContext = React.createContext(16);
  // const [zoom, setZoom] = useState(1);

  const regUrl = environment.RegistrationLink
  const loginUrl = environment.LoginLink
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
        includedLanguages : 'en,mr'
      },
      'google_translate_element',
    )
  }
  useEffect(() => {
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
    )
    
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [])

  
 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);


  const {id} = useParams();
  const [users, setUsers] = useState([])
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    fetch(serverUrl + `/menubar/allEchoMenubar`)
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true)
          setUsers(data)
          // console.log(data, "jjjjjjjjjjjjjjjjjjjjjj");
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        },
      )
  }, [])




  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
 


  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset >= 168) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.addEventListener("scroll", handleScroll);
  });



  const [click, setClick] = React.useState(false)

  const handleClick = () => setClick(!click)
  const Close = () => setClick(false)

  const [isNavCollapsed, setIsNavCollapsed] = useState(true)

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)
  

  useEffect(() => {
    document.body.style.fontSize = `${fontSize}px`;
  }, [fontSize]);

  const increaseFontSize = () => {
    if(fontSize>'17'){
      fontSize=17;
     
    }
    setFontSize(parseInt(fontSize) + 1);
    //  alert(fontSize);
  }

  const decreaseFontSize = () => {
    if(fontSize<'15'){
      fontSize=15;
     
    }
    setFontSize(parseInt(fontSize) - 1);
    // alert(fontSize);
  }

  const  normalFontSize = () => {
    // fontSize=16;
    setFontSize('16')
    fontSize=16;
    //  alert(fontSize);
  }

  const handleReset = () => {
    setFontSize("");
  };
 
  const handleZoom = () => {
    setZoom(zoom + 0.1);
  };

  const [zoom, setZoom] = useState(1);
  const textRef = useRef(null);

  const handleZoomIn = () => {
    setZoom(zoom => zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom => zoom - 0.1);
  };

  useEffect(() => {
    document.body.style.fontSize = `${zoom}em`;
  }, [zoom]);


  const [showbutton, setShowButton] = useState(false);
  const handleChange11 = () => {
    setShowButton(showbutton ? false : true);
    // alert(showbutton)
   if(showbutton== true){
            const magnifier =  window.HTMLMagnifier({ width: 400 });
            magnifier.show()
            // alert("in show")

           } else {
            // document.getElementByClass("magnifier").style.display='none'
            // alert("in hide")

            const element = document.getElementById("magnifier");
            element.remove();

            const list = document.getElementById("magnifier");
            list.removeChild(list.lastElementChild);
           }

          //   document.getElementById("magnify-button").addEventListener("click", function() {
          //   //  magnifier.show()
          //    //showbutton ? magnifier.show() : document.getElementByClass("magnifier").style.display='none'
          //   //  magnifier.style={display: "none"};
          // })
       };


  return (
    <>
    
     <div className="w-100">
            {/* <AcessibilityNavbar/> */}
        <nav className="navbar navbar-fixed-top navbar-expand-lg" scrolling={scrolling}>
          <div className="container-fluid">
            <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-start pl-2'>
              <NavLink exact to="/" className="nav-logo d-flex justify-content-start">
                <img src={NavLogo} alt="navlogo" className="whitelogohomepage" />
                <img src={NavLogo} alt="navlogo" className="whiteogo " />
                <img src={NavLogoWhite} alt="navlogo" className="blackLogo" />
              </NavLink>

              <span
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample09"
                aria-controls="navbarsExample09"
                aria-expanded={!isNavCollapsed ? true : false}
                aria-label="Toggle navigation"
                onClick={handleNavCollapse}
              >
                <span className="">
                  <div className="nav-icon" onClick={handleClick}>
                    <i className={click ? 'fa fa-times' : 'fa fa-bars'}></i>
                  </div>
                </span>
              </span>
              
              
            </div>
            <div className='col-lg-10 col-md-10 col-sm-10 d-flex justify-content-center'>
              <div  className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}  id="navbarsExample09">
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                  {/* <li className="nav-item navigationPaddingMob d-lg-none">
                    <NavLink
                      exact
                      to="/Login"
                      activeClassName="active"
                      className="nav-links"
                      onClick={click ? handleClick : null}
                    >
                      <span onClick={handleShow3} className="regular_font font-16-19  font-style-normal font-weight-500 d-none ">
                        Login
                      </span>
                      <span className='d-flex w-100 align-items-center'>
                      <sapn className="w-75 mobpadLeftreg textColourMenu d-lg-none d-md-block d-sm-block">
                        <span onClick={handleShow3} className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                          Login
                        </span>
                      </sapn>
                      <span onClick={handleShow3} className="w-25 mobIconRight d-lg-none d-md-block d-sm-block">
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                            className="iconWeb"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>

                      </span>
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item navigationPaddingMob d-lg-none">
                    <NavLink
                      exact
                      to="/Register"
                      activeClassName="active"
                      className="nav-links "
                      onClick={click ? handleClick : null}
                    >
                      <span onClick={handleShow2}   className="textColourMenu  regular_font font-16-19  font-style-normal font-weight-500 d-none">
                        Register
                      </span>
                      <div className=" d-flex align-items-center">
                
                </div>

                      
                      <span className='d-flex w-100 align-items-center'>
                      <sapn className="w-75 mobpadLeftreg d-lg-none d-md-block d-sm-block">
                        <span  onClick={handleShow2}  className="textColourMenu title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none d-md-block d-sm-block">
                          Register
                        </span>
                      </sapn>
                      <span onClick={handleShow2} className="w-25 mobIconRight d-lg-none d-md-block d-sm-block">
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                            className="iconWeb"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      </span>
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/about"
                      activeClassName="active"
                      className="nav-links"
                      onClick={click ? handleClick : null}>
                      <span className="regular_font font-16-19 navigationPaddingWeb font-style-normal font-weight-600 d-lg-block d-none" style={{fontSize: fontSize}}>
                        About
                      </span>
                      
                      <span className="w-75 navigationPaddingMob  d-lg-none d-md-block d-sm-block">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600">
                          About
                        </span>
                      </span>
                      <span className="w-25 navigationPaddingMob mobIconRight d-lg-none d-md-block d-sm-block">
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                            className="iconWeb"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>

                      <div></div>
                    </NavLink>
                  </li>
                  <li style={{ width: '100%' }}>
                    <NavDropdown
                      title={<span style={{fontSize: fontSize}} >Initiative</span>}
                      className="mainInitative initiative-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                      id="basic-nav-dropdown">
                      <div  id="style-4">
                        <NavDropdown.Item>
                          <Link to={'/MaharashtraStartupMain'} className="button">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Startup Week</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link to={'/mahaGrandMain'} className="button">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-6">
                                  <h6>Maharashtra Grand Challenge</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          <Link
                            to='/mahaHackathonMain'
                            // onClick={() =>
                            //   window.open(
                            //     'https://msins.in/public/maharashtra-health-hackathon',
                            //     '_blank',
                            //   )
                            // }
                            className="button"
                          >
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Maharashtra Health Hackathon</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown
                      title="Financial Assistance"
                      className="subinitative initiativeSub-dropdown regular_font font-16-19 font-style-normal font-weight-500 d-none d-lg-block"
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown
                      title="Intellectual Property Rights"
                      className="subSubinitative initiativeSubSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                      id="basic-nav-dropdown"
                    >
                          {/* <div onClick={handleShow1} className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12 mobpadLeftreg textColourMenu cursor-pointer">
                                  <h6>Apply Now</h6>
                              </div>
                            </div>
                          </div> */}
                                             
                        {/* ================================== */}
                        <NavDropdown.Item href="/Intellectual">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>IPR Details</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://forms.gle/n2S7w8ib9iYMXPRf6" target="_blank">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>Domestic Patent</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://forms.gle/mqWFBw2sREKTLnfk6" target="_blank">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>International Patent</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>

                        <NavDropdown.Item href="https://forms.gle/zqocmHfXGnNUNrSx6" target="_blank">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>Post Grant Applications</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://forms.gle/3kfs145SnUjhnxkk7" target="_blank">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>Trademark</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://forms.gle/Kdb7JqpKxcEwaDs1A" target="_blank">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>Design</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://forms.gle/ve1jooFsBd6hmGHYA" target="_blank">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>Copyright</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                        {/* ============================= */}
                    </NavDropdown>
                    <NavDropdown
                      title="Quality Testing and Certification"
                      className="subSubinitative initiativeSubSub-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block"
                      id="basic-nav-dropdown"
                    >
                        {/* <NavDropdown.Item href="/Login" > */}
                        {/* <div   className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div onClick={handleShow1}  className="col-md-12 mobpadLeftreg textColourMenu cursor-pointer">
                                  <h6>Apply Now</h6>
                              </div>
                            </div>
                          </div> */}
                        {/* </NavDropdown.Item> */}
                        <NavDropdown.Item href="https://forms.gle/7SQfLW1HedjCDCtM7" target="_blank">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>Apply Now</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/QualityTesting">
                          <div className="row px-2">
                            <div className="d-flex  align-items-center">
                              <div className="col-md-12">
                                  <h6>Details</h6>
                              </div>
                            </div>
                          </div>
                        </NavDropdown.Item>
                    </NavDropdown>
                        </NavDropdown>
                        <NavDropdown.Item href="#">
                          <Link
                            to={'/Startupyatra'}
                            
                            className="button"
                          >
                            <div className="row px-2 pt-1">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12 pt-1">
                                  <h6>Startup Yatra</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.6">
                          <Link
                            to={'/businessplan'}
                            
                            className="button"
                          >
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Business Plan Competition</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="">
                          <Link
                            to={'/IncubationMain'}
                            onClick={click ? handleClick : null}
                            className="button"
                          >
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Incubation Program</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="">
                          <Link to={'/WomenEntrepreneure'} onClick={click ? handleClick : null} className="button">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Women Entrepreneurship Cell</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="">
                          <Link to={'/mitdf'} onClick={click ? handleClick : null} className="button">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Maharashtra Innovation and Technological Development Fund</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item  href="https://www.mvic.in/" target="_blank">
                        {/* <Link > */}
                          {/* <Link to="" onClick={click ? handleClick : null} className="button"> */}
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Maharashtra Virtual Incubation Center</h6>
                                </div>
                              </div>
                            </div>
                          {/* </Link> */}
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                  <li>
                    <div class="mobNavigationDropdown d-lg-none d-md-block d-sm-block">
                      <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                              Initiative
                              </span>
                              </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                        <NavLink
                                          exact
                                          to="/MaharashtraStartupMain"
                                          activeClassName="active"
                                          className="nav-links py-1 pl-4"
                                          onClick={click ? handleClick : null}
                                        >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Startup Week</h6>
                                            </span>
                                          </span>
                                        </NavLink>     
                                        <NavLink
                                          exact
                                          to="/mahaGrandMain"
                                          activeClassName="active"
                                          className="nav-links py-1"
                                          onClick={click ? handleClick : null}
                                        >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Maharashtra Grand Challenge</h6>
                                            </span>
                                          </span>
                                        </NavLink> 
                                        <NavLink
                                          exact
                                          to='/mahaHackathonMain'
                                          //       onClick={() =>
                                          //         window.open(
                                          //           'https://msins.in/public/maharashtra-health-hackathon',
                                          //           '_blank',
                                          //         )
                                          //       }
                                          // activeClassName="active"
                                          className="nav-links py-1"

                                        >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Maharashtra Health Hackathon</h6>
                                            </span>
                                          </span>
                                        </NavLink> 
                                  <div class="accordion" id="sub-accordionExample">
                                      <div class="accordion-item">
                                          <h2 class="accordion-header finacialPadd" id="sub-headingOne">
                                              <button class="accordion-button accordion-button1" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                              <h6>Financial Assistance</h6>
                                              </span>
                                              </button>
                                          </h2>
                                          <div id="sub-collapseOne" class="accordion-collapse collapse intellectualPadd" aria-labelledby="sub-headingOne" data-bs-parent="#collapseOne">
                                              <div class="accordion-body">
                                                  {/* <strong>This is the first item's accordion body.</strong> */}


                                                  <div class="accordion-item">
                                          <h2 class="accordion-header finacialPadd" id="sub-headingTwo">
                                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo" aria-expanded="false" aria-controls="sub-collapseTwo">
                                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                              <h6>Intellectual Property Rights</h6>
                                              </span>
                                              </button>
                                          </h2>
                                          <div id="sub-collapseTwo" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo" data-bs-parent="sub-collapseOne">
                                              <div class="accordion-body">
                                          {/* <span onClick={handleShow3} className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="textColourMenu mobpadLeftreg cursor-pointer title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Apply Now</h6>
                                            </span>
                                          </span> */}
                                         

                                              <NavLink exact to={'https://forms.gle/n2S7w8ib9iYMXPRf6'}  target="_blank" className="nav-links pt-2 pb-1" >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Domestic Patent</h6>
                                            </span>
                                          </span>
                                              </NavLink>
                                              <NavLink exact to={'https://forms.gle/mqWFBw2sREKTLnfk6'}  target="_blank" className="nav-links py-1" >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>International Patent</h6>
                                            </span>
                                          </span>
                                              </NavLink>
                                              <NavLink exact to={'https://forms.gle/zqocmHfXGnNUNrSx6'}  target="_blank" className="nav-links py-1" >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Post Grant Applications</h6>
                                            </span>
                                          </span>
                                              </NavLink>

                                              <NavLink exact to={'https://forms.gle/3kfs145SnUjhnxkk7'}  target="_blank" className="nav-links py-1" >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Trademark</h6>
                                            </span>
                                          </span>
                                              </NavLink>

                                              <NavLink exact to={'https://forms.gle/Kdb7JqpKxcEwaDs1A'}  target="_blank" className="nav-links py-1" >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Design</h6>
                                            </span>
                                          </span>
                                              </NavLink>
                                              <NavLink exact to={'https://forms.gle/ve1jooFsBd6hmGHYA'}  target="_blank" className="nav-links py-1" >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Copyright</h6>
                                            </span>
                                          </span>
                                              </NavLink>
                                              <NavLink exact to={'/Intellectual'}   className="nav-links py-1" >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Details</h6>
                                            </span>
                                          </span>
                                              </NavLink>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="accordion-item">
                                          <h2 class="accordion-header finacialPadd" id="sub-headingThree">
                                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseThree" aria-expanded="false" aria-controls="sub-collapseTwo">
                                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                              <h6>Quality Testing and Certification</h6>
                                              </span>
                                              </button>
                                          </h2>
                                          <div id="sub-collapseThree" class="accordion-collapse collapse" aria-labelledby="sub-headingThree" data-bs-parent="sub-collapseOne">
                                              <div class="accordion-body">

                                          {/* <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span onClick={handleShow3} className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor pt-3'>Apply Now</h6>
                                            </span>
                                          </span> */}
                                            
                                          <NavLink
                                          exact
                                          to={'https://forms.gle/7SQfLW1HedjCDCtM7'}
                                          target="_blank"
                                          className="nav-links py-1">
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Quality Testing</h6>
                                            </span>
                                          </span>
                                              </NavLink>

                                              <NavLink
                                          exact
                                          to={'/QualityTesting'}
                                                
                                          className="nav-links py-1">
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Details</h6>
                                            </span>
                                          </span>
                                              </NavLink>
                                              </div>
                                          </div>
                                      </div>  
                                              </div>
                                          </div>
                                      </div>
                                    
                                  </div>
                                  <NavLink
                                          exact
                                          to={'/Startupyatra'}
                                                
                                          activeClassName="active"
                                          className="nav-links py-1"

                                        >
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Startup Yatra</h6>
                                            </span>
                                          </span>
                                        </NavLink> 
                                        <NavLink
                                          exact
                                          to={'/businessplan'}
                                                
                                          activeClassName="active"
                                          className="nav-links py-1"

                                        >
                                          <sapn className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Business Plan Competition</h6>
                                            </span>
                                          </sapn>
                                        </NavLink> 
                                        <NavLink
                                          exact
                                          to="/Incubator"
                                          activeClassName="active"
                                          className="nav-links py-1 pl-4"
                                          onClick={click ? handleClick : null}
                                        >
                                          <sapn className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Incubators Supported</h6>
                                            </span>
                                          </sapn>
                                        </NavLink>  
                                        <NavLink  exact  to="/WomenEntrepreneure"
                                          activeClassName="active"
                                          className="nav-links py-1 pl-4"
                                          onClick={click ? handleClick : null}
>
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Women Entrepreneurship Cell</h6>
                                            </span>
                                          </span>
                                        </NavLink> 
                                        <NavLink  exact  to="/mitdf"
                                          activeClassName="active"
                                          className="nav-links py-1 pl-4"
                                          onClick={click ? handleClick : null}
>
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Maharashtra Innovation and Technological Development Fund</h6>
                                            </span>
                                          </span>
                                        </NavLink> 
                                        <NavDropdown.Item href="#action/3.8">
                                          <a href="https://www.mvic.in/"
                                            className="button" target="_blank"
                                          >
                                            <div className="row px-1">
                                              <div className="d-flex  align-items-center">
                                                <div className="col-md-12">
                                                  <h6 className="title_plex_font font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block" style={{color:'#000'}}>Maharashtra Virtual Incubation Center</h6>
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </NavDropdown.Item>
                                        <div class="accordion" id="sub-accordionExample1">
                                      {/* <div class="accordion-item">
                                          <h2 class="accordion-header finacialPadd" id="sub-headingTen">
                                              <button class="accordion-button accordion-button1" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                              <h6>Other Events</h6>
                                              </span>
                                              </button>
                                          </h2>
                                          <div id="sub-collapseTen" class="accordion-collapse collapse intellectualPadd" aria-labelledby="sub-headingTen" data-bs-parent="#collapseTen">
                                              <div class="accordion-body">
                                                  


                                                  <div class="accordion-item">
                                          <h2 class="accordion-header finacialPadd" id="sub-headingEleven">
                                          <NavLink
                                          exact
                                          to={'/InnovationDepartment'}
                                                
                                          activeClassName="active"
                                          className="nav-links py-1"

                                        >
                                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseEleven" aria-expanded="false" aria-controls="sub-collapseEleven">
                                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                              <h6>Application for Internship with Skills, Employment, Entrepreneurship and Innovation Department</h6>
                                              </span>
                                              </button>
                                              </NavLink>
                                          </h2>
                                          <div id="sub-collapseEleven" class="accordion-collapse collapse" aria-labelledby="sub-headingEleven" data-bs-parent="sub-collapseEleven">
                                              <div class="accordion-body">
                                            
                                                                      </div>
                                          </div>
                                      </div>
                                      <div class="accordion-item">
                                          <h2 class="accordion-header finacialPadd" id="sub-headingTweleve">
                                          <NavLink
                                          exact
                                          to={'/ProfessionalFellows'}
                                                
                                          activeClassName="active"
                                          className="nav-links py-1"

                                        >
                                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTweleve" aria-expanded="false" aria-controls="sub-collapseTweleve">
                                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                              <h6>Invitation for Young Professional Fellows</h6>
                                              </span>
                                              </button>
                                              </NavLink>
                                          </h2>
                                          <div id="sub-collapseTweleve" class="accordion-collapse collapse" aria-labelledby="sub-headingTweleve" data-bs-parent="sub-collapseTweleve">
                                              <div class="accordion-body">
                                              
                                              </div>
                                          </div>
                                      </div>

                                              </div>
                                          </div>
                                      </div> */}
                                    
                                  </div>
                                        
                              </div>
                          </div>

                      </div>



                      </div>
                    </div> 
                  </li> 
                  </li>
                  <li className="nav-item">
                    <NavDropdown title={<span style={{fontSize: fontSize}} >Ecosystem</span>} className="mainEcosystem initiative-dropdown regular_font font-16-19  font-style-normal font-weight-500 d-none d-lg-block" id="basic-nav-dropdown">
                      <div id="style-4">
                        <NavDropdown.Item>
                          <Link to="../Incubator" className="button">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Incubators</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link to="../Mentor" className="button">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-6">
                                  <h6>Mentors</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          <a href='https://maitri.mahaonline.gov.in/' className="button" target="_blank">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-6">
                                  <h6>MAITRI - Business Compliance Information</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          <a href='https://fintech.maharashtra.gov.in/'
                            
                            target="_blank"
                            className="button"
                          >
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Mumbai FinTech Hub</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.5">
                          <a href="https://www.startupindia.gov.in/content/sih/en/covid-19_resource_section.html"
                            
                            className="button" target="_blank"
                          >
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>COVID-19 Resource Section</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.6">
                          <Link
                            to={'/Ecosystem'}
                            
                            className="button"
                          >
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Funding Programs</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.6">
                          <Link
                            to={'/Initiative'}
                            
                            className="button"
                          >
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6>Upcoming & Past Events</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </NavDropdown.Item>
                        {/* <NavLink  exact  to="/WomenEntrepreneure"
                                          activeClassName="active"
                                          className="nav-links py-1 pl-4"
                                          onClick={click ? handleClick : null}
>
                                          <span className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Women Entrepreneurship Cell</h6>
                                            </span>
                                          </span>
                        </NavLink>  */}
                      </div>
                    </NavDropdown>
                  </li>
                  <li>
                    <div class="mobNavigationDropdown d-lg-none d-md-block d-sm-block">
                      <div class="accordion" id="accordionExample1">
                      <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                              <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                              Ecosystem
                              </span>
                              </button>
                          </h2>
                          <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                              <div class="accordion-body">
                                <NavLink
                                  exact
                                  to="../Incubator"
                                  activeClassName="active"
                                  className="nav-links py-1 pl-4"
                                  onClick={click ? handleClick : null}
                                >
                                  <span className="w-100  d-lg-none d-md-block d-sm-block">
                                    <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                    <h6 className='mobTextColor'>Incubators</h6>
                                    </span>
                                  </span>
                                </NavLink>     
                                <NavLink
                                  exact
                                  to="../Mentor"
                                  activeClassName="active"
                                  className="nav-links py-1"
                                  onClick={click ? handleClick : null}
                                >
                                  <span className="w-100  d-lg-none d-md-block d-sm-block">
                                    <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                    <h6 className='mobTextColor'>Mentors</h6>
                                    </span>
                                  </span>
                                </NavLink> 
                                <NavLink
                                  exact
                                  to={''}
                                  //       onClick={() =>
                                  //         window.open(
                                  //           'https://msins.in/public/maharashtra-health-hackathon',
                                  //           '_blank',
                                  //         )
                                  //       }
                                  // activeClassName="active"
                                  className="nav-links py-1" target="_blank">
                                    <a href="https://maitri.mahaonline.gov.in/" target="_blank">
                                  <span className="w-100  d-lg-none d-md-block d-sm-block">
                                    <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                    <h6 className='mobTextColor'>MAITRI - Business Compliance Information</h6>
                                    </span>
                                  </span>
                                  </a>
                                </NavLink> 
                                <NavLink
                                  exact
                                  to={''}
                                        
                                  activeClassName="active"
                                  className="nav-links py-1"
                                  target="_blank"
                                >
                                  <a href="https://fintech.maharashtra.gov.in/" target="_blank">
                                  <span className="w-100  d-lg-none d-md-block d-sm-block">
                                    <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                    <h6 className='mobTextColor'>Mumbai FinTech Hub</h6>
                                    </span>
                                  </span>
                                  </a>
                                </NavLink> 
                                <NavLink
                                  exact
                                  to={''}
                                  activeClassName="active"
                                  className="nav-links py-1" 
                                  target="_blank"
                                >
                                   <a href="https://www.startupindia.gov.in/content/sih/en/covid-19_resource_section.html" target="_blank">
                                  <span className="w-100  d-lg-none d-md-block d-sm-block">
                                    <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                    <h6 className='mobTextColor'>COVID-19 Resource Section</h6>
                                    </span>
                                  </span>
                                  </a>
                                </NavLink> 
                                <NavLink
                                  exact
                                  to="/Ecosystem"
                                  activeClassName="active"
                                  className="nav-links py-1 pl-4"
                                  onClick={click ? handleClick : null} >
                                  <sapn className="w-100  d-lg-none d-md-block d-sm-block">
                                    <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                    <h6 className='mobTextColor'>Funding Programs</h6>
                                    </span>
                                  </sapn>
                                </NavLink> 
                                <NavLink
                                  exact
                                  to="/Initiative"
                                  activeClassName="active"
                                  className="nav-links py-1 pl-4"
                                  onClick={click ? handleClick : null} >
                                  <span className="w-100  d-lg-none d-md-block d-sm-block">
                                    <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                    <h6 className='mobTextColor'>Upcoming & Past Events</h6>
                                    </span>
                                  </span>
                                </NavLink>
                                        {/* <NavDropdown.Item href="">
                                              <Link
                                                to={'/Initiative'}
                                                onClick={click ? handleClick : null}
                                                className="button"
                                              >
                                                <div className="row px-2">
                                                  <div className="d-flex  align-items-center">
                                                    <div className="col-md-12">
                                                      <h6>Upcoming & Past Events</h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Link>
                                        </NavDropdown.Item> */}
                                      
                                        {/* <NavLink
                                          exact
                                          to="/GrassrootInn"
                                          activeClassName="active"
                                          className="nav-links py-1 pl-4"
                                          onClick={click ? handleClick : null}
                                        >
                                          <sapn className="w-100  d-lg-none d-md-block d-sm-block">
                                            <span className="title_plex_font font-20-26  font-style-normal font-weight-600  d-lg-none d-md-block d-sm-block">
                                            <h6 className='mobTextColor'>Grassroot Innovation</h6>
                                            </span>
                                          </sapn>
                                        </NavLink>    */}
                              </div>
                          </div>
                      </div>
                      </div>
                    </div> 
                  </li> 
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/Resources"
                      activeClassName="active"
                      className="nav-links"
                      onClick={click ? handleClick : null}
                    >
                      <span className="regular_font font-16-19 navigationPaddingWeb  font-style-normal font-weight-600 d-lg-block d-none" style={{fontSize: fontSize}}>
                        {' '}
                        Resources
                      </span>
                      <span className="w-75 navigationPaddingMob  d-lg-none d-md-block d-sm-block">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none d-md-block d-sm-block">
                          Resources
                        </span>
                      </span>

                      <span className="w-25 navigationPaddingMob mobIconRight d-lg-none d-md-block d-sm-block">
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                            className="iconWeb"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink
                      exact
                      to="/TenderNotification"
                      activeClassName="active"
                      className="nav-links"
                      onClick={click ? handleClick : null}
                    >
                      <span className="regular_font font-16-19 navigationPaddingWeb  font-style-normal font-weight-600 d-lg-block d-none" style={{fontSize: fontSize}}>
                        {' '}
                        Tenders
                      </span>

                      <span className="w-75 navigationPaddingMob d-lg-none d-md-block d-sm-block">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none d-md-block d-sm-block">
                        Tenders
                        </span>
                      </span>
                      <span className="w-25 navigationPaddingMob mobIconRight d-lg-none d-md-block d-sm-block">
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                            className="iconWeb"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink
                      exact
                      to="/Careers"
                      activeClassName="active"
                      className="nav-links"
                      onClick={click ? handleClick : null}
                    >
                      <span className="regular_font font-16-19 navigationPaddingWeb  font-style-normal font-weight-600 d-lg-block d-none" style={{fontSize: fontSize}}>
                        {' '}
                        Careers
                      </span>

                      <span className="w-75 navigationPaddingMob  d-lg-none d-md-block d-sm-block">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none d-md-block d-sm-block">
                        Careers
                        </span>
                      </span>
                      <span className="w-25 navigationPaddingMob mobIconRight d-lg-none d-md-block d-sm-block">
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                            className="iconWeb"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item navContactLeftPadd" >
                    <NavLink
                      exact
                      to="/Contact"
                      activeClassName="active"
                      className="nav-links"
                      onClick={click ? handleClick : null}
                    >
                      <span className="regular_font font-16-19 navigationPaddingWeb  font-style-normal font-weight-600 d-lg-block d-none" style={{fontSize: fontSize}}>
                        {' '}
                        Contact 
                      </span>

                      <span className="w-75 navigationPaddingMob  d-lg-none d-md-block d-sm-block">
                        <span className="title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none d-md-block d-sm-block">
                          Contact 
                        </span>
                      </span>
                      <span className="w-25 navigationPaddingMob mobIconRight d-lg-none d-md-block d-sm-block">
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                            className="iconWeb"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </NavLink>
                  </li>




                  {/* <li className="nav-item d-lg-block d-md-none d-sm-none align-items-center">
                  <NavLink
                      exact
                      to=""
                      onClick={click ? handleClick : null}
                    >
                    <span onClick={handleShow1}  className="cursor-pointer px-3 textColourMenu regular_font font-16-19  font-style-normal font-weight-600 d-lg-block d-none" style={{fontSize: fontSize}}>
                      Login
                    </span>

                    
                    <sapn className="w-75  d-lg-none d-md-none">
                      <span className="cursor-pointer textColourMenu title_plex_font font-20-26  font-style-normal font-weight-600 d-none">
                        Login
                      </span>
                    </sapn>
                    <span className="w-25 mobIconRight d-none">
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      
                    </span>
                  </NavLink>
                </li>

                <li className="nav-item mob-hide align-items-center acc_display align-items-center">
                <NavLink
                      exact
                      to=""
                      onClick={click ? handleClick : null}
                    >
                    <span onClick={handleShow}  className=" textColourMenu cursor-pointer px-3 regular_font font-16-19  font-style-normal font-weight-500 d-lg-block d-md-none d-sm-none" style={{fontSize: fontSize}}>
                      Register  
                    </span>

                    <span className="w-75  d-lg-none d-md-none">
                      <span onClick={handleShow}    className="textColourMenu title_plex_font font-20-26  font-style-normal font-weight-600 d-lg-none">
                        Register
                      </span>
                    </span>
                    <span onClick={handleShow}   className="w-25 mobIconRight d-lg-none d-md-none d-sm-block">
                      <svg
                      
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.81683H22.6112M22.6112 8.81683L14.8912 1M22.6112 8.81683L14.8912 16.6337"
                          className="iconWeb"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    </NavLink>
                </li> */}



                <li  className='nav-item mob-hide align-items-center acc_display align-items-center padding-top-lang'>
                  <div id="google_translate_element" style={{fontSize: fontSize}}  className=' textColourMenu cursor-pointer px-2 regular_font font-16-19  font-style-normal font-weight-500 d-lg-block d-md-none d-sm-none'></div>
                </li>
             
                <li className="nav-item d-lg-block d-none navigationPaddingWeb">
                <Tooltip title="Magnifier">
                <div onClick={handleChange11} className="textColourMenu " style={{cursor: 'pointer'}}>
                <svg width="20" height="20" viewBox="0 0 22 22"  stroke="currentColor"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 21L16.2779 16.2695L21 21ZM18.8947 9.94737C18.8947 12.3204 17.9521 14.5962 16.2741 16.2741C14.5962 17.9521 12.3204 18.8947 9.94737 18.8947C7.57438 18.8947 5.29858 17.9521 3.62062 16.2741C1.94267 14.5962 1 12.3204 1 9.94737C1 7.57438 1.94267 5.29858 3.62062 3.62062C5.29858 1.94267 7.57438 1 9.94737 1C12.3204 1 14.5962 1.94267 16.2741 3.62062C17.9521 5.29858 18.8947 7.57438 18.8947 9.94737V9.94737Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {showbutton}
                </div>
                </Tooltip>
                  </li>
                <li className="nav-item d-lg-block d-md-none d-sm-none">
                    <NavDropdown  
                    title="T"
                    // title={<img src={Icoaccessibility} alt="Example Image"  style={{width:'30px'}}/>}
                    style={{fontSize: fontSize}} className="mainTranslate initiative-dropdown fontTranslate font-16-19  font-style-normal font-weight-500 d-none d-lg-block" id="basic-nav-dropdown">
                   
                      <div id="style-4">
                        <NavDropdown.Item>
                        <Tooltip title="Increase Font Size">
                          <Link to="" className="button">
                            <div className="row px-2" >
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6 onClick={increaseFontSize}>A+</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                          </Tooltip>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                        <Tooltip title="Normal Font Size">
                          <Link to="" className="button">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-12">
                                  <h6 onClick={handleReset}>A</h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                          </Tooltip>
                        </NavDropdown.Item>
                        <NavDropdown.Item >
                        <Tooltip title="Decrease Font Size">
                          <a  className="button" target="_blank">
                            <div className="row px-2">
                              <div className="d-flex  align-items-center">
                                <div className="col-md-6">
                                  <h6 onClick={decreaseFontSize}>A-</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                          </Tooltip>
                        </NavDropdown.Item>
                       
                       
                      </div>
                    </NavDropdown>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </nav>
      </div>

      
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be Redirected to existing Portal to access your account.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" target="_blank"  href={regUrl}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be Redirected to existing Portal to access your account.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="primary"  target="_blank" href={loginUrl} >
           Ok
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be Redirected to existing Portal to access your account.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" href={regUrl}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be Redirected to existing Portal to access your account.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="primary"  href={loginUrl} >
           Ok
          </Button>
        </Modal.Footer>
      </Modal> */}

    </>
  )
}
