import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";

import Box from "@mui/material/Box";

// import Card from "@mui/material/Card";
// import Landingimg from "../../assets/images/EcoSystem/Mentors/profile01.png";
import { Button } from "react-bootstrap-v5";
import IconButton from "@mui/material/IconButton";
// import Arrow from "../../assets/images/about/arrow.png";
import { BsChevronDown } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsFillXCircleFill } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "react-bootstrap/Card";
import { environment } from "../../api";
const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      background: "#fff !important;",
      padding: "10px",
    },
  })
);

const CustomExpandIcon = () => {
  return (
    <Box
      className="mentorList"
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">
        <div>
          <i className="fa fa-times"></i>
        </div>
        <div>{/* <span>Close</span> */}</div>
      </div>
      <div className="collapsIconWrapper">
        <i className="fa fa-chevron-down"></i>
      </div>
    </Box>
  );
};

export default function MentorList({
  mentorList,
  setSearch,
  setCity,
  setSector,
}) {
  const serverUrl = environment.baseUrl;
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mentorsearch, setMentorsearch] = useState([]);
  const [mentorSector, setMentorSector] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(6);
  const [searchTasks, setSearchTasks] = useState("");

  useEffect(() => {
    fetch(serverUrl + "/mentor/allMentorList")
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          let unique = [];
          let mymap = new Map();
          unique = data.filter(el => {
            const val = mymap.get(el.city);
            if(val) {
                if(el.city < val) {
                    mymap.delete(el.city);
                    mymap.set(el.city, el.city);
                    return true;
                }
                else {
                    return false;
                }
            }
            mymap.set(el.city, el.city);
            return true;
        });

        let unique1 = [];
          let mymap1 = new Map();
          unique1 = data.filter(el => {
            const val1 = mymap1.get(el.sector);
            if(val1) {
                if(el.sector < val1) {
                    mymap1.delete(el.sector);
                    mymap1.set(el.sector, el.sector);
                    return true;
                }
                else {
                    return false;
                }
            }
            mymap1.set(el.sector, el.sector);
            return true;
        });
        setMentorsearch(unique);
        setMentorSector(unique1)
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const hanldeClick = (selectedRec) => {
    setSelectedData(selectedRec);
    setShow(true);
  };
  const [click, setClick] = React.useState(false);
  const handleClick = (selectedRec) => setClick(!click);
  const Close = (selectedRec) => setClick(false);

  const [toggle, setToggle] = React.useState(false);
  const toggleButton = () => setToggle(!toggle);
  const hideModal = () => {
    setShow(false);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="">
      <div className="row mentorsearchDropdown">
        <div className="col-lg-6 col-md-6 col-sm-4 px-2 pb-2">
          <div>
            <input
              type="text"
              className="searchplaceholder search-border-remove form-control search-bar-color"
              // className={styles.search}
              placeholder=" &#61442; Search Mentor by Name"
              onChange={({ currentTarget: input }) => setSearch(input.value)}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 px-2 pb-2">
          <div>
            <select
              class="form-select"
              onChange={({ currentTarget: input }) => setCity(input.value)}
            >
              <option>Select City</option>
              {mentorsearch.map((task) => (
                <option
                  key={task.value}
                  value={({ currentTarget: input }) => setCity(input.value)}
                >
                  {task.city}
                  {/* {uniqueTags.indexOf(task.city) === -1} */}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-4 px-2 pb-2">
          <div>
            <select
              class="form-select"
              onChange={({ currentTarget: input }) => setSector(input.value)}
            >
              <option>Select Sector</option>
              {mentorSector.map((sectorSerch) => (
                <option
                  key={sectorSerch.value}
                  value={({ currentTarget: input }) => setSector(input.value)}
                >
                  {sectorSerch.sector}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="mentorList pad-banner-all">
        <div className="pb-2">
          {/* ------------------web card start-------------------- */}

          <div className="row d-lg-block d-none card-hide">
            <div class="row d-flex justify-content-center">
              {mentorList
                ?.filter((v, i) => {
                  if (searchTasks.length > 0) {
                    return v.name
                      .toLowerCase()
                      .includes(searchTasks.toLowerCase());
                  } else {
                    return true;
                  }
                })
                .map((v) => (
                  <div class="col-lg-3 col-md-3 col-sm-3 p-3">
                    <div className="content-card p-1">
                      <div className="img-mentor d-flex justify-content-center py-3">
                        <div className="mentorPhotos">
                          <img
                            alt="Logo1"
                            src={v.image}
                            className="imgMentor"
                          />
                        </div>
                      </div>
                      <h5 className="d-flex justify-content-center text-title-capital title_plex_font">
                        <b>{v.name}</b>
                      </h5>
                      <div class="content-card-content">
                        <ul className="pl-0">
                          <li className="">{v.university}</li>
                          <li className="">
                            <b>Sector :</b> {v.sector}
                          </li>
                          <li className="">
                            <b>City :</b> {v.city}
                          </li>
                        </ul>
                      </div>
                      <div class="acc-footer-btn">
                        <a class="acc-anchor">
                          <button
                            className="visit-btn btn-primary"
                            onClick={() => hanldeClick(v)}
                          >
                            Learn More
                            <span className="inno-arrow btn-arrow">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="30px"
                                width="30px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                              </svg>
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* Responsive Accordian design */}
          <div className="mentorList pt-2 d-lg-none d-md-block">
            {mentorList.map((v) => (
              <Accordion
                expanded={expanded === v}
                onChange={handleChange(v)}
                className="mb-2"
              >
                <AccordionSummary
                  expandIcon={<CustomExpandIcon />}
                  // <BsChevronDown/>
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "25%", flexShrink: 0 }}>
                    <img alt="Logo1" className="imgMentor" src={v.image} />
                  </Typography>
                  <Typography sx={{ width: "55%", color: "text.secondary" }}>
                    <b className="font-14-18 text-title-capital">{v.name}</b>
                    <p className="font-12-14 justify">{v.university}</p>
                  </Typography>

                  <Typography></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="font-14-17 infoProfileBorder ">
                      <div className="mentorProfileInfo">
                        <h6 className="instituteTxt">
                          <b>Sector :</b> <span> {v.sector}</span>
                        </h6>
                        <h6 className="instituteTxt">
                          <b>City :</b> <span>{v.city}</span>
                        </h6>
                        <h6 className="instituteTxt">
                          <b>Email :</b>{" "}
                          <span>
                            <a className="link-color">{v.email}</a>
                          </span>
                        </h6>
                        <h6 className="instituteTxt">
                          <b>LinkdinUrl :</b>{" "}
                          <span>
                            <a className="link-color">{v.linkdin}</a>
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="mentorProfileInfo">
                      <h5 className="instituteTxt font-20-26">
                        <b>Relevant Work Experience:</b>
                      </h5>
                      <p className="font-14-17 justify"> {v.experience}</p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      {show && <Modal details={selectedData} handleClose={hideModal} />}
    </div>
  );
}

const Modal = ({ handleClose, details }) => {
  return (
    <div className="modal display-block">
      <section className="modal-main scroll-add-horizontal scroll-add-horizontal-hide">
        <div className="">
          <div className="row pt-0">
            <div className="col-sm-12 col-lg-12 col-md-12 ">
              <div className="float-end">
                <div onClick={handleClose} className="cursor-pointer">
                  <svg
                    stroke="currentColor"
                    fill="#C8A37E "
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="25px"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="row p-4 pb-1 pt-1">
            <div className="col-sm-9 col-lg-9 col-md-9">
              <h5 className="font-28-36">{details?.name}</h5>

              <p className="instituteTxt font-22-26 justify">
                {details?.university}
              </p>
              <div className="font-22-26">
                <h6 className="instituteTxt">
                  <b>Sector :</b> <span> {details?.sector}</span>
                </h6>
                <h6 className="instituteTxt">
                  <b>City :</b> <span> {details?.city}</span>
                </h6>
                <h6 className="instituteTxt">
                  <b>Email :</b>{" "}
                  <span>
                    <a className="link-color"  style={{textDecoration:'none'}}>{details?.email}</a>
                  </span>
                </h6>
                <h6 className="instituteTxt">
                  <b>LinkdinUrl :</b>{" "}
                  <span>
                    <a
                      className="link-color cursor-pointer"
                      style={{textDecoration:'none'}}
                      onClick={() => window.open(details?.linkdin)}
                      target="_blank"
                    >
                      {details?.linkdin}
                    </a>
                  </span>
                </h6>
              </div>
            </div>
            <div className="col-sm-3 col-lg-3 col-md-3">
              <div className="mentorPhotos">
                <img
                  alt="Profile01"
                  className="img-fluid"
                  src={details?.image}
                />
              </div>
            </div>
          </div>
          <div className="row p-4 pb-4">
            <div className="col-sm-9 col-lg-9 col-md-9">
              <h5 className="instituteTxt font-20-26">
                <b>Relevant Work Experience:</b>
              </h5>
              <div
                id="alert-dialog-description"
                className="relevantExp font-20-26 justify"
              >
                {details?.experience}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    // --------------------Responsive -------------------
  );
};
