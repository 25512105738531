import React from 'react';
import Nri from './nri';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../component/HeaderAdmin';


function NriMain() {
  return (
      <>
    <Header/>
    <Nri />
    
    </>
  );
}

export default NriMain;
