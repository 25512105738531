import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Navbar from '../component/Navbar';
import Header from '../component/Header';
import BannerNewsPress from './BannersNewsPress';
import FeaturedVideos from './featuredVideos';
import Featuredresources from './featuredresources';
// import SearchResources from './SearchResources';
import Footer from '../component/footer';
// import Guidelines from './guidelines';
import Guidline from './Guidline'





function Resources() {
  
  return (
    <>
    {/* // <Navbar /> */}
    <Header/>
    <BannerNewsPress />
    <FeaturedVideos />
   
    <Featuredresources/>
  
    {/* <Guidelines /> */}
    <Guidline />
    {/* <SearchResources /> */}
    
    <Footer/>
  </>
  );
}

export default Resources;
