import React from 'react';

function InformationIcon() {
  return (
    <>
         <span className='ml-3'>
                         <svg width="14" height="14" viewBox="0 0 19 19" className='infoIconpadding' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23913 16.75C13.1171 16.75 16.2609 13.56 16.2609 9.625C16.2609 5.68997 13.1171 2.5 9.23913 2.5C5.36113 2.5 2.21739 5.68997 2.21739 9.625C2.21739 13.56 5.36113 16.75 9.23913 16.75ZM9.23913 19C14.3418 19 18.4783 14.8027 18.4783 9.625C18.4783 4.44733 14.3418 0.25 9.23913 0.25C4.1365 0.25 0 4.44733 0 9.625C0 14.8027 4.1365 19 9.23913 19Z" fill="#174984"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23896 7C9.85128 7 10.3477 7.50368 10.3477 8.125L10.3477 13.375C10.3477 13.9963 9.85128 14.5 9.23896 14.5C8.62665 14.5 8.13027 13.9963 8.13027 13.375L8.13027 8.125C8.13027 7.50368 8.62665 7 9.23896 7Z" fill="#174984"/>
                            <path d="M10.3478 5.125C10.3478 5.74632 9.85138 6.25 9.23907 6.25C8.62675 6.25 8.13037 5.74632 8.13037 5.125C8.13037 4.50368 8.62675 4 9.23907 4C9.85138 4 10.3478 4.50368 10.3478 5.125Z" fill="#174984"/>
                          </svg>
        </span>
    </>
  )
}

export default InformationIcon