import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from "../api";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InformationIcon from "../startup/informationIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const serverUrl = environment.baseUrl;

const DesignEligibility =(props)=> {
    
  let initialFormObj = {
    do_you_have_IP: "",
  };

  function handleSubmitForm(values) {
    console.log(values, "hii");
    const formData = new FormData();
    formData.append("do_you_have_IP", values.do_you_have_IP);
    fetch(serverUrl + "/ip/addIpDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values, null, 2),
    })
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
      });
    props.setValue(props.value + 1);
  }

  return (
    <div className="container">
    <div className="row ">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Formik
          onSubmit={(values) => handleSubmitForm(values)}
          validationSchema={Yup.object({
            

          })}
          initialValues={
            JSON.parse(localStorage.getItem("ipDetails")) || initialFormObj
          }
          render={({ values }) => (
            <Form>
              <div className="row pb-3">
                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                Eligibility Details
                </h2>
                <p className="label-txt my-2">
                  Note: Is your startup registered in maharashtra? no
                  selection that only maharashtra registered startups are
                  eligible for financial assistance schemes.{" "}
                </p>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                  <label className="pb-2 label-txt">
                    Name of Company <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="company_name"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="do_you_have_IP" />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 pb-4">
                  <label className="pb-2 label-txt">
                    Is your company registered in Maharashtra?
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field component="div" name="do_you_have_IP">
                    <div class="radiobuttons d-flex">
                      <div className="col-lg-2 col-md-1">
                        <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes"
                            // defaultChecked={values.do_you_have_IP === "yes"}
                            name="do_you_have_IP"
                            value="yes"
                          />
                          <label htmlFor="radioYes">Yes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo"
                            // defaultChecked={values.do_you_have_IP === "no"}
                            name="do_you_have_IP"
                            value="no"
                          />
                          <label htmlFor="radioNo">No</label>
                        </div>
                      </div>
                    </div>
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage className="error" name="do_you_have_IP" />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 pb-4">
                  <label className="pb-2 label-txt">
                    Company Website URL(if applicable)
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Company Website URL"
                    className="textInput"
                    name="Company_Website"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="Company_Website"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Upload Proof of being incorporated in Maharashtra
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="file"
                    placeholder="Upload Proof"
                    className="textInput my-4"
                    name="uniqueness_factor"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="uniqueness_factor"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Registered Address as specified on incorporation
                    Certificate<span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Registered Address"
                    className="textInput"
                    name="registered_address"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="registered_address"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                  <label className="pb-2 label-txt">
                    Total Funds Received Since Incorporation
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    name="startup_current_stage"
                  >
                    <option selected>Select </option>
                    {/* <option value="Ideation">Ideation</option>
                    <option value="Scaling Up">Scaling Up</option>
                    <option value="Mature">Mature</option> */}
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="startup_current_stage"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                  <label className="pb-2 label-txt">
                    Communication Address
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="textarea"
                    placeholder="Enter Communication Address"
                    className="textInput"
                    name="Communication_Address"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="Communication_Address"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                  <label className="pb-2 label-txt">
                    Short brief about the company
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="textarea"
                    placeholder="Enter Short brief about the company"
                    className="textInput"
                    name="about_company"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="about_company"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                    Incorporation Details
                  </h2>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Firm Registration Date
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    type="date"
                    placeholder="Enter Registered Address"
                    className="textInput"
                    name="Registration_Date"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="Registration_Date"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Type of entity
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    name="entity_type"
                  >
                    <option selected>Select </option>
                    {/* <option value="Ideation">Ideation</option>
                    <option value="Scaling Up">Scaling Up</option>
                    <option value="Mature">Mature</option> */}
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="entity_type"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                    Founder Details
                  </h2>
                </div>

                <TableContainer component={Paper} className="no-shadow">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="head-bg-color">
                      <TableRow className="d-flex justify-content-between ">
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "10%" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "15%" }}
                          align="left"
                        >
                          Designation
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "15%" }}
                          align="left"
                        >
                          Contact No
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "20%" }}
                          align="left"
                        >
                          Email ID
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "30%" }}
                          align="left"
                        >
                          Linkedin Profile Link
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "10%" }}
                          align="left"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className="data-bg-color d-flex justify-content-between">
                      <TableRow className="w-100 d-flex justify-content-between">
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-flex align-items-center"
                          style={{ width: "10%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="name_of_founder"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="error"
                              name="name_of_founder"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "15%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="designation_founder"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="designation_founder"
                            />
                            <br></br>
                          </div>
                        </TableCell>

                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "15%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="contact_no"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="contact_no"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "20%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="email_ID"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="email_ID"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "20%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="linkdin_url"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="linkdin_url"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "10%" }}
                        >
                          <span //onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                          >
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="14"
                                cy="14"
                                r="13.125"
                                fill="#174984"
                              />
                              <path
                                d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875"
                                stroke="white"
                                stroke-width="2.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <h2 className="my-4 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                  Point of Contact for IPR fillings
                </h2>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    First Name <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter First Name"
                    className="textInput"
                    name="first_name"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Last Name <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Last Name"
                    className="textInput"
                    name="last_name"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="last_name"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Email Address <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Email Address"
                    className="textInput"
                    name="email_address"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="email_address"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Phone Number <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="number"
                    placeholder="Enter Phone Number"
                    className="textInput"
                    name="phone_number"
                  />
                   <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="phone_number"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Address for Communication
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="textarea"
                    placeholder="Enter Communication Address"
                    className="textInput"
                    name="address_communication"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="address_communication"
                    />
                  </div>
                  
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Permanent Address
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="textarea"
                    placeholder="Enter Permanent Address"
                    className="textInput"
                    name="Permanent_Address"

                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="Permanent_Address"
                    />
                  </div>
                </div>

                <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                  Revenue Details
                </h2>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Current Finanacial status of the company
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    name="financial_status"
                  >
                    <option selected>Select </option>
                    {/* <option value="Ideation">Ideation</option>
                    <option value="Scaling Up">Scaling Up</option>
                    <option value="Mature">Mature</option> */}
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="financial_status"
                    />
                  </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Comulative Turnover Since Incorporation
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    name="Comulative_Turnover"
                  >
                    <option selected>Select </option>
                    {/* <option value="Ideation">Ideation</option>
                    <option value="Scaling Up">Scaling Up</option>
                    <option value="Mature">Mature</option> */}
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="Comulative_Turnover"
                    />
                  </div>
                </div>

                <p className="label-txt my-4">
                  Please fill Revenue Details of Last 3 financial year from
                  the date of application (please compulsory fill the
                  financial year Details according to incorporation date
                  otherwise your application will be rejected.)
                </p>

                <TableContainer component={Paper} className="no-shadow">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="head-bg-color">
                      <TableRow className="d-flex justify-content-between ">
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "20%" }}
                        >
                          Financial Year
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "35%" }}
                          align="left"
                        >
                          Audited Financial Numbers (in INR)
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "35%" }}
                          align="left"
                        >
                          Upload Proof (Audited statement for CA)
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "10%" }}
                          align="left"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    </Table>
                    </TableContainer>

                    <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                  DPIIT Registration Details
                </h2>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                    <label className="pb-2 label-txt">
                      DPIIT Number
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="number"
                      placeholder="Enter DPIIT Number"
                      className="textInput my-3"
                      name="DPIIT_Number"
                    />
                    <div className="text-danger">
                      <ErrorMessage
                        className="text-danger"
                        name="DPIIT_Number"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                  <label className="pb-2 label-txt">
                    Upload your DPIIT Certificate
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="file"
                    placeholder="Upload Proof"
                    className="textInput my-4"
                    name="DPIIT_Certificate"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="DPIIT_Certificate"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 pb-4">
                  <label className="pb-2 label-txt">
                    Have you taken benefit of this scheme before?
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field component="div" name="benifit_schema">
                    <div class="radiobuttons d-flex">
                      <div className="col-lg-2 col-md-2">
                        <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes1"
                            // defaultChecked={values.do_you_have_IP === "yes"}
                            name="benifit_schema"
                            value="yes1"
                          />
                          <label htmlFor="radioYes1">Yes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo1"
                            // defaultChecked={values.do_you_have_IP === "no"}
                            name="benifit_schema"
                            value="no1"
                          />
                          <label htmlFor="radioNo1">No</label>
                        </div>
                      </div>
                    </div>
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage  name="benifit_schema" />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <label className="pb-2 label-txt">
                   How did you come to know about this scheme?
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    // placeholder="Upload Proof"
                    className="textInput my-4"
                    name="about_scheme"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="about_scheme"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                  <label className="pb-2 label-txt">
                    If Yes, please provide details of the same
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    as="textarea"
                    // placeholder="Enter Registered Address"
                    className="textInput"
                    name="approve_details"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="approve_details"
                    />
                  </div>
                </div>

                <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                 Bank Details
                </h2>
                <TableContainer component={Paper} className="no-shadow">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="head-bg-color">
                      <TableRow className="d-flex justify-content-between ">
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "25%" }}
                        >
                          Name of Account Holder
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "10%" }}
                          align="left"
                        >
                          Account No.
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center"
                          style={{ width: "15%" }}
                          align="left"
                        >
                          Name of Bank
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "15%" }}
                          align="left"
                        >
                          IFSC Code 
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "15%" }}
                          align="left"
                        >
                          Branch Name
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "30%" }}
                          align="left"
                        >
                          Upload a Cancelled Cheque
                        </TableCell>
                        <TableCell
                          className="table-txt align-items-center "
                          style={{ width: "10%" }}
                          align="left"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className="data-bg-color d-flex justify-content-between">
                      <TableRow className="w-100 d-flex justify-content-between">
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-flex align-items-center"
                          style={{ width: "10%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="name_of_founder"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="error"
                              name="name_of_founder"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "15%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="designation_founder"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="designation_founder"
                            />
                            <br></br>
                          </div>
                        </TableCell>

                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "15%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="contact_no"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="contact_no"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "20%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="email_ID"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="email_ID"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "20%" }}
                        >
                          <Field
                            type="text"
                            className="txt-color"
                            name="linkdin_url"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="linkdin_url"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "20%" }}
                        >
                          <Field
                            type="file"
                            className="txt-color"
                            name="cancelled_cheque"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="cancelled_cheque"
                            />
                            <br></br>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center"
                          style={{ width: "10%" }}
                        >
                          <span //onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                          >
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="14"
                                cy="14"
                                r="13.125"
                                fill="#174984"
                              />
                              <path
                                d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875"
                                stroke="white"
                                stroke-width="2.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>

              <div className="justify-content-center d-flex pt-4">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-draft no-shadow"
                    onClick={() => {
                      localStorage.setItem(
                        "ipDetails",
                        JSON.stringify(values)
                      );
                    }}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Save &amp; Next
                  </Button>
                </Stack>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  </div>
  )
}

export default DesignEligibility