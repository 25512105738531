import { useEffect, useState } from "react";
import axios from "axios";
import { useRef} from "react";
import { BsFiles } from "react-icons/bs";
import AllincubatorList from './IncubatorList'
import Pagination from "../../searchpagination/Pagination/index";
import { environment } from '../../api';
import Grid from "@material-ui/core/Grid";

function IncubatorProfiles() {
    const serverUrl = environment.baseUrl;
    
    const textEl = useRef(null);

    const copyToClipboard = async e => {
      const wdsl =window.getSelection().removeAllRanges();
      console.log(wdsl);
      var range = document.createRange();
      range.selectNode(textEl.current);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    };
    const authToken =sessionStorage.getItem("token");
    const [obj, setObj] = useState({});
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
    const [city, setCity] = useState("");
    
    

	useEffect(() => {
        
		const getAllMovies = async () => {
			try {
				const url = `${serverUrl +"/incubator/allincubatorList"}?page=${page},&title=${search}&city=${city}`;
				const { data } = await axios.get(url);
				setObj(data);
			} catch (err) {
				console.log(err);
			}
		};

		getAllMovies();
	}, [ page, search, city]);
    
  return (
    <>
    <div className="wrapper" style={{backgroundColor:'#fff'}}>
        <h2 className="d-flex justify-content-center title_plex_font font-28-36 font-20-26 topPadding pb-4">
          <b>Incubators Supported</b>
        </h2>
    <div className="container">
        <div className="row d-flex">
        <div className="col-lg-12 col-md-12 col-sm-">
            <div className="row d-flex">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
             </div>
            </div>
            </div>
        </div>
        <div className="body">
            <div className="table_container">
                <AllincubatorList incubatorList={obj.incubatorList ? obj.incubatorList : []} setSearch={(search) => setSearch(search)}  setCity={(city) => setCity(city)}  />
            </div>
        </div>
    </div>
          
                <div className="w-100">
                <Pagination
                    page={page}
                    limit={obj.limit ? obj.limit : 0}
                    total={obj.total ? obj.total : 0}
                    setPage={(page) => setPage(page)}
                />
                </div>
           
</div>
</>

  )
}

export default IncubatorProfiles
