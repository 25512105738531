import React, { useState, useEffect }  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Logo from '../assets/images/photo/image 261.png'
import TestmProfile from '../../assets/images/Initiative/testimonials/image261.png';
import TestmProfile2 from '../../assets/images/Initiative/testimonials/testimonial2.png';
import TestmProfile3 from '../../assets/images/Initiative/testimonials/testimonial2.png';
import { environment } from '../../api';


export default function MahaHackthonPartner() {
    const serverUrl = environment.baseUrl;
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(serverUrl +"/ourPartnerHackthon/allOurHackthon")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setUsers(data.data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])
    return(
        <>
<div className='mahaGrand-main pt-4' id='idPartner'>
        <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="offset-lg-3  col-lg-7 col-md-12 col-sm-7">
                    <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-4">Our Partners</h2> 
                    {users.map(user => (
                    <div className='our-partner-card py-2'>
                     <div className='row  partner-card-header2 align-items-center text-center py-3'>
                      <h5 className='mb-0'>{user.partnerType}</h5> 
                    </div>
                        <div className='row align-items-center partner-card-body p-3'>
                        <div className='col-sm-3 col-md-3 col-lg-3 align-items-center justify-content-center d-flex'>
                        {/* {content} */}<img alt="Logo2" className='img-fluid our-part-img' src={user.image} />
                        </div>
                  </div>
                  
                  </div>
                    ))}
            </div>
            </div>
            </div>
            </div>
        </>
    );
}