import {useEffect,useState, useRef} from 'react';
import { environment } from '../api'
import NavLogo from '../assets/images/navbar/navbar_lg_black.png'
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap-v5'
// import ReCAPTCHA from 'react-google-recaptcha'
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./styles.module.css";
// import validate from './LoginFormValidationRules';
// import validate from '../../component/LoginFormValidationRules'

export default function Superadminlogin(callback) {
  const serverUrl = environment.baseUrl
  let navigate = useNavigate()
  const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = serverUrl + '/AdminSignIn';
			const { data: res } = await axios.post(url, data);
			sessionStorage.setItem("token", res.data);
     console.log( res.data.role ,"oooo");
      sessionStorage.role = ''
      sessionStorage.role = res.data.role
   
      if ( sessionStorage.role == 'ADMIN' ) {
        // alert("Form Submitted Successfully");
        navigate(`/Dashboard`, { replace: true })
        

       } 
			// window.location = "/";
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};
  return (
    <>
   <div className={styles.login_container}>
			<div className={styles.login_form_container}>
				<div className={styles.left}>
        <div className='img-height pb-2'>
        <img src={NavLogo}></img>
        </div>
					<form className={styles.form_container} onSubmit={handleSubmit}>
          <h2>Login Form</h2>
						<input
							type="email"
							placeholder="Email"
							name="email"
							onChange={handleChange}
							value={data.email}
							required
							className={styles.input}
						/>
						<input
							type="password"
							placeholder="Password"
							name="password"
							onChange={handleChange}
							value={data.password}
							required
							className={styles.input}
						/>
					
						{error && <div className={styles.error_msg}>{error}</div>}
						<button type="submit" className={styles.green_btn}>
							Sing In
						</button>
					</form>
				</div>
				
			</div>
		</div>
  </>  
  )
}
