import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const LandingBannerAddM = ({setUsers, setModalIsOpenToFalse3}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  function handleSubmitLandingBannerAddForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("rearrange_number",values.rearrange_number)
    formData.append("image",values.image)
    // formData.append("title",values.title)
    // formData.append("name",values.name)
    // formData.append("designation",values.designation)
    // formData.append("description",values.description)
    // formData.append("icon",values.icon)
    formData.append("link",values.link)

    function fetchlandingBanner(){
      fetch(serverUrl + "/banners/allbanner")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    setUsers(data);
  },
)
}
    console.log(formData)
    fetch(serverUrl +"/banners/addbanner", 
         {
            method: "POST",
            body: formData,
           
            
          })
          .then(res=>res.json())
          .then((res) => fetchlandingBanner())
          .then((res) => setModalIsOpenToFalse3())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          alert("Form Submitted Successfully");
          // const answer = window.confirm("are you sure?");
          // if (answer) {
          //   // Save it!
          //   console.log("Thing was saved to the database.");
          //   alert("Form Submitted Successfully");
            
          // } else {
          //   // Do nothing!
          //   alert("Please fill out all fields");
          //   console.log("Thing was not saved to the database.");
          // }
          
  }
  console.log("called")

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
    onSubmit={(values, {resetForm}
    ) =>
   { console.log(values )
    handleSubmitLandingBannerAddForm(values)
    resetForm({values:''}) 
  }
  }
    
    validationSchema={Yup.object({
      rearrange_number: Yup.string().required('Rearrange Number is Required'),
    image: Yup.string().required('Image  is Required'),
    // title: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Title Name is Required'),
    // name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Name is Required'),
    // designation: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Designation is Required'),
    // description: Yup.string().required('Enter Description is Required'),
    // icon: Yup.string().required('Icon Image is Required'),
    link: Yup.string().required('Link is Required'),
       })}
      initialValues={{ 
      rearrange_number:'',
      image:'',
      // title: '',
      // name: '',
      // designation: '',
      // description:'',
      // icon:'',
      link:''
       }}

      render={({ values, setFieldValue ,handleSubmit}) => (
        <Form onSubmit={(e)=>{
          
          handleSubmit(e)}}>
        <div className="row">
         <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Rearrange Number{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Rearrange Number" className="textInput"  name="rearrange_number" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="rearrange_number" /><br></br>
                    </div>                   
         </div>

        <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("image", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                 Link <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div>
        {/* <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Name" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Title Name" className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Designation <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Designation" className="textInput" name="designation" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="designation" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                       <Field as="textarea" placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" />
                    </div>
                  </div>
                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Icon <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <input id="file" name="icon" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("icon", e.target.files[0]);
                      }} />     
                    <div className="text-danger">
                    <ErrorMessage className="error" name="icon" /><br></br>
                    </div>
                  </div> */}


        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default LandingBannerAddM;
