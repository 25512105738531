import React, { useEffect, useState } from 'react'
import '@mui/x-data-grid-generator'
import { environment } from '../api';
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BallotIcon from '@mui/icons-material/Ballot'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import StartupViewModel from './startupViewMod'
import StartupUpdateModel from './startupUpdateModel'
import StartupApprovalModel from './startupApprovalModel'

function Viewstartup() {
  const authToken =sessionStorage.getItem("token");
  const [modalIsOpen, setModalIsOpen] = useState(false)  


  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true)

  }

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
    
  }

  const [modalIsOpen1, setModalIsOpen1] = useState(false)

  const setModalIsOpenToTrue1 = () => {
    setModalIsOpen1(true)
  }

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false)
  }

  const [modalIsOpen2, setModalIsOpen2] = useState(false)

  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true)
  }

  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false)
  }
  
  const serverUrl = environment.baseUrl;
  const [rows, setUsers] = useState([]);
  const [teamdetails, setteamdetails] = useState([]);
  // const [patentDetails, setpatentdetails] = useState([]);
  const [modelDetails, setmodeldetails] = useState([]);
  // const [funding, setfundingdetails] = useState([]);
  // const [cureent, setcureentdetails] = useState([]);
  const [uploadsdetails, setuploadsdetails] = useState([]);
  // const [location, setlocationdetails] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rowId, setrowId] = useState('');
  const [rowData, setRowData] = useState({
    // personalDetails:[],
    basicDetails: [],
    // location: [],
    teamDetails:[],
    // patentDetails: [],
    modelDetails: [],
    // funding: [],
    // cureent: [],
    uploadsDetails: [],
  })
  useEffect(() => {
      fetch(serverUrl +"/basicDetailsStartup/allBasicDetail",
      {headers: {
        "auth-token":authToken
        
      }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setUsers(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/team/allteamDetail",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setteamdetails(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/upload/alluploadDoc",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setuploadsdetails(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
          fetch(serverUrl +"/businessModel/allBizModelDetail",
          {headers: {
            "auth-token":authToken
            
          }})
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setmodeldetails(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
    }, [])

    useEffect(()=>{
      let tempData = rowData;
      rows.map((el)=>{
        if(el.user_id === rowId)
        tempData.basicDetails = el;
// console.log(tempData)
      })
      teamdetails.map((el)=>{
        if(el.user_id === rowId)
        tempData.teamDetails = el;
          
      })
      uploadsdetails.map((el)=>{
        if(el.user_id === rowId)
        tempData.uploadsDetails = el;

          
      })
      modelDetails.map((el)=>{
        if(el.user_id === rowId)
        tempData.modelDetails = el;

          
      })
      setRowData(tempData)
    },[rowId])

    

  
  const columns = [
     { field: 'user_id', headerName: 'Sr.No.', width: 120 },
    { field: 'product_name', headerName: 'Name', width: 150 },
    { field: 'name_of_startup', headerName: 'Startup Name', width: 150 },
    // { field: 'email_ID', headerName: 'Email', width: 230 },
    // { field: 'contact_no', headerName: 'Phone no.', width: 120 },
    { field: 'state', headerName: 'State', width: 150 },
    { field: 'district', headerName: 'District', width: 150 },
    { field: 'city', headerName: 'City', width: 150 },
    // { field: 'status', headerName: 'Status', width: 120 },
    
    { field: 'action', headerName: 'Action', sortable: false, width: 200,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            )

          return alert(JSON.stringify(thisRow, null, 4))
        }

        return (
          <>
            <Button onClick={setModalIsOpenToTrue} >
              <VisibilityIcon />
            </Button>
            <Button onClick={setModalIsOpenToTrue1}>
              <BallotIcon />
            </Button>
            <Button onClick={setModalIsOpenToTrue2}>
              <ThumbUpIcon />
            </Button>
            {/* <Button onClick={onClick}>
              <ThumbDownIcon />
            </Button> */}
          </>
        )
      },
    },
  ]

  

  return (
    <>
      <div className="bannermain">
        <div className="container">
          <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            StartUp Details
          </h2>
          <div style={{ height: '75vh', width: '100%' }}>
            {/* <div className="d-flex justify-content-end">
              <Button type="submit" name="submit">
                Add
              </Button>
            </div> */}
          
            <DataGrid
              className="pb-3"
               rows={rows}
              columns={columns}
               pageSize={8}
               
               components={{ Toolbar: GridToolbar
            
                 }}
                 onSelectionModelChange={(newSelection)=>{
                  setrowId(newSelection[0])
                 }}
              getRowId ={(rows) => rows.user_id} 
              
              
            />
            
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modalIsOpen} className="ModOverlay">
          <div className="viewMod">
          <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>View Startup Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <StartupViewModel rowData = {rowData}/>
          </div>
        </Modal>
        
      </div>
      <div>
        <Modal isOpen={modalIsOpen1} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Update Startup Details</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse1}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <StartupUpdateModel rowData = {rowData}/>
          </div>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIsOpen2} className="ModOverlay">
          <div className="viewMod">
            <div className="text-center">
              <div className="d-block font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                <h3>Form Approvals</h3>
              </div>
              <div className="d-flex justify-content-end alg-top">
                <Button onClick={setModalIsOpenToFalse2}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <StartupApprovalModel />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Viewstartup
