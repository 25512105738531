import React from 'react'
import Header from '../../component/Header';
import EventContent from './eventMiddleContent'; 
import Footer from '../../component/footer';


function EventDetailsMain() {
  return (
    <>
    <Header/>
    <EventContent/>
    <Footer/>
    </>
  )
}

export default EventDetailsMain