import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;

const StagesAddM = () => {

  function handleStagesAddForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name",values.title)
    fetch(serverUrl +"/stages/addStages", 
         {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              accept: "application/json",
              
            },
            body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Submitted Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }
  

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleStagesAddForm(values)
    }
    validationSchema={Yup.object({
      name: Yup.string().required('Enter Name is Required'),
     
       })}
      initialValues={{ 
        name: '',
        
       }}

      render={({ values, setFieldValue }) => (
        <Form>
        <div className="row">
       
        <div className="col-lg-12 col-md-12 p-2">
                    <label className="pb-2 label-txt">
                    Add Stage{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Stage" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>                      
             </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default StagesAddM