import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const QualityTestingUpdateM = (props, {setModalIsOpenToFalse2}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  function handleSubmitQualityTestingUpdateMUpdateForm(values){
    const formData = new FormData();
    formData.append("question",values.question)
    formData.append("answer",values.answer)
    function fetchData(){
      fetch(serverUrl + "/qualitytest/allQualityTesting")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    fetch(serverUrl +`/qualitytest/${props.rowData.QualityTesting._id}`, 
         {
          method: 'PUT',
            headers: {
              "Content-Type": "application/json",
              accept: "application/json"
            },
            body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .then((res) => fetchData())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
          
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
     
      handleSubmitQualityTestingUpdateMUpdateForm(values)
    }
    validationSchema={Yup.object({
      question: Yup.string().required('Enter Question is Required'),
      answer: Yup.string().required('Enter Answer is Required'),
   
       })}
      initialValues={{ 
        question:props.rowData.QualityTesting.question,
        answer: props.rowData.QualityTesting.answer,
       
       }}


      render={({ values, handleSubmit, handleChange}) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Question{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"                    
                      onChange={handleChange}
                      
                      placeholder="Enter Question" className="textInput" name="question" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="question" /><br></br>
                    </div>                   
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Answer{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Answer" className="textInput" name="answer" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="answer" /><br></br>
                    </div>                   
                  </div>                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default QualityTestingUpdateM;
