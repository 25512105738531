import React from 'react';
import Header from '../component/Header';
import EcoBanner from './Ecobanner';
import FundResource from './FundingResource';
import EcosystemDetails from './ecosystemdetails';
import Footer from '../component/footer';

function EcoSystem() {
  return (
    <>
    {/* <Navbar /> */}
    <Header/>
    {/* <EcoBanner/>
    <EcosystemDetails /> */}
    <FundResource/>
    <Footer />
  </>
  );
}

export default EcoSystem;
