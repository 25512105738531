import React, { useState, useEffect }  from 'react'
import { environment } from '../../api';



export default function MhOurPart() {
    const serverUrl = environment.baseUrl;
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(serverUrl +"/our/allOur")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setUsers(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])
  

  const BuildAllList = () => {
    // Output : ["General", "Tools", "Trash"]
    const categories = users.reduce((names, image) => {
      if (!names.includes(image.partnerType)) names.push(image.partnerType);
      return names;
    }, []);

    const content = categories.map((image, index) => (
      <BuildList key={index} partType={image} />
    ));

    return content;
  };

  const BuildList = ({ partType }) => {
    const content = users
      .filter(o => o.partnerType === partType)

      .map((o, index) => <div key={index}>
      <div className='col-lg-12 col-md-12 col-sm-12'><img src={o.image} className="mobourImg px-3"></img> </div>
      </div>);

    return (


  
      <div className='our-partner-card py-2'>
                     <div className='row  partner-card-header align-items-center text-center py-3'>
                      <h5 className='mb-0'>{partType}</h5> 
                    </div>
        
        <div className='row align-items-center partner-card-body p-3'>
        <div className='' style={{display: "contents"}}>
        {content}
        </div>
      </div>
      </div>
      
    );
  };

  return (
    <div className='mahaGrand-main pt-4' id='ourPartner'>
        <div className="container">
          <div className="row d-flex justify-content-center">
              <div className="offset-lg-3 col-lg-7 col-md-12 col-sm-7">
              <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-4">Our Partners</h2> 
        <BuildList partType="Knowledge" />
        <BuildList partType="Investment" />
        <BuildList partType="Strategy" />
        <BuildList partType="Technology" />
        <BuildList partType="Community" />
        <BuildList partType="Ecosystem" />
        
      </div>

     
    </div>
    </div>
    </div>
  );
}
