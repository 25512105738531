import React from 'react';
import Header from '../component/HeaderAdmin';
import Mentor from './mentor';

import 'bootstrap/dist/css/bootstrap.min.css';

function MentoerMain() {
  return (
      <>
    <Header/>
    <Mentor />
    </>
  );
}

export default MentoerMain;
