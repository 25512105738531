import React from "react";
import startup from "../../assets/images/Initiative/startup-img.png";
export default function startupWinnerBanner() {
  return (
    <div>
      <div className="startupWinnerBanner">
        <div className="container">
          <div className="mx-5">
            <div className="row d-flex justify-content-center ">
              <div className="col-sm-5 p-5">
                <img className="img-fluid" src={startup} />
              </div>
              <div className="col-sm-5 p-4 d-flex justify-content-center align-items-center">
                <h2 className="winnerbannertext">
                  <b>Winner Announcement</b>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
