import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const OurPartnerUpdate = (props, {setModalIsOpenToFalse2}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [partnertypemh, setPartnerTypemh] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(serverUrl + "/partType/allPartnerTypeMh")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUsers(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []) 

  const handleCategory = (values) => {
    const getPartnerTypemh = values.target.value;
    setPartnerTypemh( getPartnerTypemh);
}
function fetchData(){
  fetch(serverUrl + "/our/allOur")
.then((res) => res.json())
.then(
(data) => {
console.log(data, "dataaaaaaaaaaaa")
setIsLoaded(true);
props.setUsers(data);
},
)
}
  
// console.log(props)
const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitOurPartnerUpdateForm(values){
    
    const formData = new FormData();
    formData.append("link",values.link)
    formData.append("image",values.image)
    formData.append("partnertypemh",values.partnertypemh)
   
    fetch(serverUrl +`/our/${props.rowData.ourPartner._id}`,
         {
          method: 'PUT',
          body: formData,
            
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .then((res) => fetchData())
          .then(res=>props.setModalIsOpenToFalse2())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitOurPartnerUpdateForm(values)
    }
    validationSchema={Yup.object({
      link: Yup.string().required('Enter Link is Required'),
      image: Yup.string().required('Image  is Required'),
       })}
      initialValues={{ 
        link: props.rowData.ourPartner.link,
        image:props.rowData.ourPartner.image,
        partnertypemh:props.rowData.ourPartner.partnertypemh,
       }}

      

      render={({ values, setFieldValue, handleSubmit, handleChange}) => (
        <Form onSubmit={handleSubmit}>

<div className="row">
        <h6>Partner Type :  </h6> 
        <select type="select" name="partnertypemh" className="form-control size"
          value={values.partnertypemh}
          onChange={(e) => {
            handleChange(e);
            console.log(e.target.value, "87777777777777777777777777777777777")
            // setPartnerTypemh("partnertypemh", e.target.value)
            handleCategory(e)
          }}
         >
          <option value={""}>--Select Partner Type--</option>
            {
              users.map((user) => {
                    return (
                        <option key={user._id} value={user._id}> {user.name} </option>
                    )
                })
            }
        </select>
        <div className="text-danger">
          <ErrorMessage className="error" name="partnertypemh" /><br></br>
        </div>
        </div>

        <div className="row">
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                 Link <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter Link" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div>

                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img id="imgupdate" src={updatedImage||values.image} style={{width:'60%', height:'50%'}} /><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("image", e.target.files[0]);
                      }} required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default OurPartnerUpdate;
