import React, { useState, useEffect } from "react";
import { Container, Col, Row, Breadcrumb } from "react-bootstrap-v5";
import SIDBI from "../assets/images/FundResourc/sidbi.png";
import IDBI from "../assets/images/FundResourc/idbi.png";
import { Button } from 'react-bootstrap-v5';
import Arrow from '../assets/images/FundResourc/arrow-visit.png';
import { Link } from "react-router-dom";
import { environment } from '../api';
import Card from 'react-bootstrap/Card';

export default function FundingResource() {
  const serverUrl = environment.baseUrl;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(serverUrl + "/ecosystemFunding/allFunding")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUsers(data);
          console.log(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])
  return (
    <>
      <div>
        <div className="fundBanner">
        <h2 className="d-flex justify-content-center title_plex_font font-28-36 font-20-26  pb-2">
          <b>Funding Programs</b>
        </h2>
          {/* <h2 className="font-weight-600 font-34-44 font-18-23 d-flex justify-content-center pb-4">Funding Programs</h2> */}
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-lg-8 col-md-8 col-sm-8 ">
              <div className="row">
                {users.map(user =>
                (
                  <div className="col-lg-4 col-md-4 col-sm-4 fund-card-padding p-2">
                    <div className="fund-card fundCardH">
                      <div className="fund-card-body">
                        <div className="fund-card-imgdiv">
                          <img src={user.logo} className="fund-card-img" />
                        </div>

                        <div>
                          <span className="font-style-normal font-weight-600 font-14-17 fund-text px-1 ellipsis">
                            {user.description}
                          </span>
                        </div>
                        <div className="row justify-content-center py-3">
                          <div className="col-sm-10 d-none d-sm-block">
                            <div className='whitebtn d-none d-sm-block'>
                              <Button className="btn-primary"
                                onClick={() => window.open(user.link)}

                              >
                                {/* {user.link} */}
                                <Link to='/' without rel="noopener noreferrer" target="_blank">
                                  <span className='regular_font font-18-22 font-style-normal font-weight-400'>Visit Website</span>
                                  <span className='inno-arrow'>
                                  <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 16 16"
                                height="25px"
                                width="35px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                              </svg>
                                  </span>
                                </Link>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
