import Header from '../../component/Header';
import QualityTestMiddlecontent from './qualityTestingMiddleContent';
import QualityTestFaq from './QualityTestFaq';
import Footer from '../../component/footer';

function QualityTestingMain() {
  return (
    <>
    <Header/>
    <QualityTestMiddlecontent/>
    <QualityTestFaq/>
    <Footer/>
   </>
  )
}

export default QualityTestingMain