import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Navbar from '../../component/Navbar';
import Header from '../../component/Header';
import MahaGrandBanner from './mahaGrandBanner';
import MahaGrandMiddleContent from './mahaGrandMiddleContent';
import MahaGrandEvent from './mahaGrandEvent';
import MahaGrandFaq from './mahaGrandFaq';
import MahaGrandContactus from './mahaGrandContactus';
import Footer from '../../component/footer';

function MahaGrandMain() {
  return (
    
    <>
   {/* <Navbar/> */}
   <Header/>
   <MahaGrandBanner/>
   <MahaGrandMiddleContent/>
   <MahaGrandEvent/>
   <MahaGrandFaq/>
   <MahaGrandContactus/>
   <Footer/>

  </>
  );
}

export default MahaGrandMain;