import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { environment } from "../../api";
import axios from "axios";

const serverUrl = environment.baseUrl;

const RearrangeBannerId = ({ props, rowData, setModalIsOpenToFalse2 }) => {
  const [listingData, setListingData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(serverUrl + "/banners/allbanner");
        // Add id property to each listing
        const listingsWithIds = response.data.map((listing, index) => {
          return {
            ...listing,
            id: index, // You could use a different property for the id value
          };
        });
        setListingData(listingsWithIds);
        console.log(listingsWithIds, "response.dataresponse.dataresponse.data");
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  async function handleUpdateListing(id, newData) {
    try {
      const response = await axios.put(
        serverUrl + `/banners/rearrangeid/${id}`,
        newData
      );
      // Handle the response as needed
      console.log("Update successful:", response);
      alert(
        `Rearrange number ${newData.rearrange_number} updated successfully!`
      );
    } catch (error) {
      // Handle errors as needed
      console.error(error);
    }
  }

  function handleUpdateClick(index, rearrange_number) {
    // Get the id from the selected listing
    const id = listingData[index]._id;
    console.log(id, "fsddddddddddddddddddd");
    const newData = {
      id: id,
      rearrange_number: rearrange_number,
    };
    console.log(newData, "newDatanewDatanewData");
    handleUpdateListing(id, newData);
  }

  return (
    <div>
      {listingData.map(({ id, image, rearrange_number }, index) => (
        <>
          <div key={id}>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="row">
                  <label>Image</label>
                </div>
                <div className="row">
                  <img src={image} style={{ width: "40%", height: "80%" }} />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <p>Rearrange Id: {rearrange_number}</p>
                <input
                  type="text"
                  defaultValue={rearrange_number}
                  required
                  onChange={(e) =>
                    setListingData(
                      listingData.map((data, i) =>
                        i === index
                          ? { ...data, rearrange_number: e.target.value }
                          : data
                      )
                    )
                  }
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
                <sapn>
                  <Button
                    style={{ backgroundColor: "#174984" }}
                    className="btn btn-sm"
                    variant="contained"
                    type="submit"
                    onClick={() => handleUpdateClick(index, rearrange_number)}
                  >
                    Update
                  </Button>
                </sapn>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default RearrangeBannerId;
