import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from "../api";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InformationIcon from "../startup/informationIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddMember from "../assets/images/Incubator/Add.svg";
import axios from "axios";




const serverUrl = environment.baseUrl;

const Ipform = (props) => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
   const ifsc =/^[A-Z]{4}\d{7}$/;
const authToken =sessionStorage.getItem("token");
const [sector, setSector] = useState();
const [error, setError] = useState(null);

  let initialFormObj = {
    // incorporated_proof_url:"",
    firm_name:"",
    is_reg_mah:"",
    website:"",
    incorporation_address:"",
    funding_received_id:"",
    comm_address:"",
    pitch_deck:"",
    foundation_date:"",
    entity_id:"",
  poc_ipr_first_name:"",
    poc_ipr_last_name:"",
    poc_ipr_email_id:"",
    poc_ipr_contact_no:"",
    poc_ipr_perm_address:"",
     current_funding_status_id:"",
    max_turnover_id:"",
     dpiit_no:"",
      is_scheme_benefit_taken:"",
    scheme_details:"",
     scheme_source:"",
    account_name:"",
    account_number:"",
    bank_name:"",
    ifsc_code:"",
    branch_name:"",
    cheque:"",
    incorporation_date:"",   
    dpiit_image:"",
   

    bankDetails: [{
      name:'',
      designation:'',
      email_id:'',
      contact_no:'',
      linkdin_link:'',

      }],
      testName:'',
   }
    
const url ="/iprFounderDetails/addIprFounderDetails";
  
const [flag, setFlag] = useState("no")
  const btnClick = (e) => {
   setFlag(e.target.value);
  };

  function handleSubmitForm(values) {
    const formDataFinal = {}
    console.log(values, "hii");
    const formData = new FormData();
    formData.append("incorporated_proof_url", values.incorporated_proof_url);
    formData.append("firm_name", values.firm_name);
    formData.append("is_reg_mah", values.is_reg_mah);
    formData.append("incorporation_address", values.incorporation_address);
    formData.append("funding_received_id", values.funding_received_id);
    formData.append("comm_address", values.comm_address);
    formData.append("pitch_deck", values.pitch_deck);
    formData.append("foundation_date", values.foundation_date);
    formData.append("entity_id", values.entity_id);
    formData.append("poc_ipr_first_name", values.poc_ipr_first_name);
    formData.append("poc_ipr_last_name", values.poc_ipr_last_name);
    formData.append("poc_ipr_email_id", values.poc_ipr_email_id);
    formData.append("poc_ipr_contact_no", values.poc_ipr_contact_no);
    formData.append("poc_ipr_perm_address", values.poc_ipr_perm_address);
    formData.append("poc_ipr_comm_address", values.poc_ipr_comm_address);
    formData.append("current_funding_status_id", values.current_funding_status_id);
    formData.append("max_turnover_id", values.max_turnover_id);
    formData.append("dpiit_no", values.dpiit_no);
     formData.append("is_scheme_benefit_taken", values.is_scheme_benefit_taken);
    formData.append("scheme_details", values.scheme_details);
    formData.append("scheme_source", values.scheme_source);
    formData.append("account_name", values.account_name);
    formData.append("account_number", values.account_number);
    formData.append("bank_name", values.bank_name);
    formData.append("ifsc_code", values.ifsc_code);
    formData.append("branch_name", values.branch_name);
    formData.append("cheque", values.cheque);
  
    formDataFinal['testName']= values.testName

   

    let basicDetailsCount = values.bankDetails;
    console.log("basicDetailsCount");
    console.log(basicDetailsCount);
    let basicDetailsCountFinal = basicDetailsCount.length;

    
    formDataFinal['basicDetailsCount']= basicDetailsCountFinal
    let i=0;

    //formData.append("basicDetailsCount",basicDetailsCountFinal)
    for (i = 0; i < basicDetailsCountFinal; i++) {
      console.log('imagesCount[i]');
      console.log(basicDetailsCount[i]);
      console.log("name in this ");
      console.log(basicDetailsCount[i].name);
      formDataFinal['name'+i]= basicDetailsCount[i].name
      formDataFinal['designation'+i]= basicDetailsCount[i].designation
      formDataFinal['email_id'+i]= basicDetailsCount[i].email_id
      formDataFinal['contact_no'+i]= basicDetailsCount[i].contact_no
      formDataFinal['linkdin_link'+i]= basicDetailsCount[i].linkdin_link
    }
console.log('/iprFounderDetails/addIprFounderDetails');
console.log(formDataFinal);
    // formData.append("name", values.name);
    // formData.append("designation", values.designation);
    // formData.append("email_id", values.email_id);
    // formData.append("contact_no", values.contact_no);
    // formData.append("linkdin_link", values.linkdin_link);
   
   
    fetch(serverUrl + "/IprStartupCheck/addIprStartupCheck", {
      method: "POST",
      body: formData,
      headers: {
        "auth-token":authToken
      },

    })

    fetch(serverUrl + "/iprFounderDetails/addIprFounderDetails", {
      method: "POST",
     // body:formDataFinal,
     body: JSON.stringify(formDataFinal),
      headers: {
        'Content-Type': 'application/json',
        "auth-token":authToken
      },

    
      // body:formData,
      
    })


    // axios.post(serverUrl + url, formData
      
      
      
    //   ,{

    //   // "headers": {
      
    //   //   'content-type': 'multipart/form-data'
      
    //   // },
      
    //   })



    .then((response) => {
      sessionStorage.type = ''
      sessionStorage.setItem("user", JSON.stringify(response.data.data));
      sessionStorage.setItem("token", response.data.token);
      sessionStorage.setItem("applicationId", response.data.applicationId);
    }
)
.catch((error) => {
  if (
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500
  ) {
    setError(error.response.data.message);
  }
})
    // props.setValue(props.value + 1);
  }
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Formik
              onSubmit={(values) => handleSubmitForm(values)}
              validationSchema={Yup.object({              
                firm_name: Yup.string().required("Please enter Name of Company"),
                is_reg_mah: Yup.string().required("Please select"),
                incorporation_address: Yup.string().required("Please enter Registered Address as on incorporation certificate"),
                funding_received_id: Yup.string().required(),
                comm_address: Yup.string().required("Please Enter Address for Communication"),
                pitch_deck: Yup.string().required("Please provide short brief about the company"),
               
                foundation_date: Yup.string().required("Select Date"),
                entity_id: Yup.string().required("Select Entity "),
                poc_ipr_first_name: Yup.string().required("Please enter First Name"),
                poc_ipr_last_name: Yup.string().required("Please enter Last Name"),
                poc_ipr_email_id: Yup.string().email("Please Enter valid Email").required(
                  "Please Enter your Email-Id"
                ),
                poc_ipr_contact_no: Yup.string().matches(phoneRegExp,'Phone number is not valid').required(
                  "Please enter Phone Number"
                ),
                poc_ipr_comm_address: Yup.string().required("Please Enter Address for Communication"),  
                 current_funding_status_id: Yup.string().required("Please enter Startup’s Current Funding Status"), 
                max_turnover_id: Yup.string().required("Please enter Cumulative Turnover Since Incorporation"), 
                dpiit_no: Yup.string().required("Please enter DPIIT No."), 
                is_scheme_benefit_taken: Yup.string().required("Select Option"),
                scheme_details: Yup.string().required("Please details for the same"),
                 scheme_source: Yup.string().required("Please enter Scheme Source"),
                 account_name: Yup.string().required("Required"),
                 account_number: Yup.string().required("Required"),
                 bank_name: Yup.string().required("Required"),
                 
                 ifsc_code: Yup.string().matches(ifsc,'Please Enter valid IFSC Code').required("Required"),
                 branch_name: Yup.string().required("Required"),
                 cheque: Yup.string().required("Required"),
                 name: Yup.string().required("Please enter name"),                
                designation: Yup.string().required("Please enter description"),
                contact_no: Yup.string().required("Please enter Phone Number"),
                email_id: Yup.string().required("Please enter email"),
                incorporated_proof_url: Yup.string().required("Please select file"),

               
               
                
                // dpiit_image: Yup.string().required("Please select file"),
                   
              })}
              initialValues={
                JSON.parse(localStorage.getItem("teamDetails")) ||
                initialFormObj
              }
              render={({ values, setFieldValue }) => (
                <Form>
                 <div className="row pb-3">
                    <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                      Eligibility Details
                    </h2>
                    <p className="label-txt my-2">
                      Note: Is your startup registered in maharashtra? no
                      selection that only maharashtra registered startups are
                      eligible for financial assistance schemes.{" "}
                    </p>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                    <label className="pb-2 label-txt">
                      Name of Company <span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter Name of Company"
                      className="textInput"
                      name="firm_name"
                    />
                    <div className="text-danger">
                      <ErrorMessage className="error" name="firm_name" />
                    </div>
                  </div> 

                   <div className="col-lg-6 col-md-6 pb-4">
                    <label className="pb-2 label-txt">
                      Is your company registered in Maharashtra?
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field
                      component="div"
                      name="is_reg_mah"
                      onChange={btnClick}
                    >
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-2 col-md-1">
                          <div class="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              id="radioYes"
                              // defaultChecked={values.is_reg_mah === "yes"}
                              name="is_reg_mah"
                              value="yes"
                            />
                            <label htmlFor="radioYes">Yes</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div class="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              id="radioNo"
                              defaultChecked
                              name="is_reg_mah"
                              value="no"
                            />
                            <label htmlFor="radioNo">No</label>
                          </div>
                        </div>
                      </div>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="error" name="is_reg_mah" />
                    </div>
                  </div> 
                  {/* <span className=""> */}
                  {flag == "yes" && ( 
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 pb-4">
                        <label className="pb-2 label-txt">
                          Company Website URL(if applicable)
                          
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Company Website URL"
                          className="textInput"
                          name="website"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="website"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Upload Proof of being incorporated in Maharashtra
                          <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="file"
                          placeholder="Upload Proof"
                          className="textInput my-4"
                          name="incorporated_proof_url"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="incorporated_proof_url"
                          />
                        </div>
                      </div> 

                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Registered Address as specified on incorporation
                          Certificate<span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter Registered Address"
                          className="textInput"
                          name="incorporation_address"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="incorporation_address"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                        <label className="pb-2 label-txt">
                          Total Funds Received Since Incorporation
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="select"
                          className="form-select"
                          name="funding_received_id"
                        >
                          <option selected>Select </option>
                          <option value="1">0 To 3 Cr</option>
                          <option value="2">3 To 10 Cr</option>
                          <option value="3">Above 10 Cr</option>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="funding_received_id"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                        <label className="pb-2 label-txt">
                          Communication Address
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="textarea"
                          placeholder="Enter Communication Address"
                          className="textInput"
                          name="comm_address"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="comm_address"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                        <label className="pb-2 label-txt">
                          Short brief about the company
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="textarea"
                          placeholder="Enter Short brief about the company"
                          className="textInput"
                          name="pitch_deck"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="pitch_deck"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                          Incorporation Details
                        </h2>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Firm Registration Date
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          type="date"
                          placeholder="Enter Registered Address"
                          className="textInput"
                          name="foundation_date"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="foundation_date"
                          />
                        </div>
                      </div> 

                       <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Type of entity
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="select"
                          className="form-select"
                          name="entity_id"
                        >
                          <option value="Private_Limited_Company">
                            Private Limited Company
                          </option>
                          <option value="Limited_Liability_Partnership">
                            Limited Liability Partnership
                          </option>
                          <option value="MatRegistered_Partnership_Formure">
                            Registered Partnership Form
                          </option>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="entity_id"
                          />
                        </div>
                      </div>  
                     <div>
                     <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                        <label className="pb-2 label-txt">
                        testName
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="textarea"
                          placeholder="Enter Short brief about the company"
                          className="textInput"
                          name="testName"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="testName"
                          />
                        </div>
                      </div>
                     </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                          Founder Details
                        </h2>
                      </div>
                       <FieldArray
                        name="bankDetails"
                        render={(arrayHelpers) => (
                          <div className="">
                            <div className="d-flex justify-content-end mb-3">
                              <span className="add-btn-text label-txt">
                                <img
                                  src={AddMember}
                                  className="img-fluid"
                                  alt="Add button"
                                  onClick={() => arrayHelpers.push("")}
                                ></img>
                                &nbsp;&nbsp;&nbsp; Add member &nbsp;&nbsp;&nbsp;
                              </span>
                            </div>
                            <TableContainer
                              component={Paper}
                              className="no-shadow"
                            >
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead className="head-bg-color">
                                  <TableRow className="d-flex justify-content-between ">
                                    <TableCell
                                      className="table-txt align-items-center"
                                      style={{ width: "10%" }}
                                    >
                                      Name
                                    </TableCell>
                                    <TableCell
                                      className="table-txt align-items-center"
                                      style={{ width: "15%" }}
                                      align="left"
                                    >
                                      Designation
                                    </TableCell>
                                    <TableCell
                                      className="table-txt align-items-center"
                                      style={{ width: "15%" }}
                                      align="left"
                                    >
                                      Contact No
                                    </TableCell>
                                    <TableCell
                                      className="table-txt align-items-center "
                                      style={{ width: "20%" }}
                                      align="left"
                                    >
                                      Email ID
                                    </TableCell>
                                    <TableCell
                                      className="table-txt align-items-center "
                                      style={{ width: "30%" }}
                                      align="left"
                                    >
                                      Linkedin Profile Link
                                    </TableCell>
                                    <TableCell
                                      className="table-txt align-items-center "
                                      style={{ width: "10%" }}
                                      align="left"
                                    >
                                      Action
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                {values.bankDetails &&
                                values.bankDetails.length > 0 ? (
                                  values.bankDetails.map(
                                    (friend, index) => (
                                      <TableBody className="data-bg-color d-flex justify-content-between">
                                        <TableRow
                                          key={index}
                                          className="w-100 d-flex justify-content-between"
                                        >
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            className="d-flex align-items-center"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              type="text"
                                              className="txt-color"
                                              name={`bankDetails.${index}.name`}
                                            />
                                            <div className="text-danger">
                                              <ErrorMessage
                                                className="error"
                                                name={`bankDetails.${index}.name`}
                                              />
                                              <br></br>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className="d-flex align-items-center"
                                            style={{ width: "15%" }}
                                          >
                                            <Field
                                              type="text"
                                              className="txt-color"
                                              name={`bankDetails.${index}.designation`}
                                            />
                                            <div className="text-danger">
                                              <ErrorMessage
                                                className="text-danger"
                                                name={`bankDetails.${index}.designation`}
                                              />
                                              <br></br>
                                            </div>
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            className="d-flex align-items-center"
                                            style={{ width: "15%" }}
                                          >
                                            <Field
                                              type="text"
                                              className="txt-color"
                                              name={`bankDetails.${index}.contact_no`}
                                            />
                                            <div className="text-danger">
                                              <ErrorMessage
                                                className="text-danger"
                                                name={`bankDetails.${index}.contact_no`}
                                              />
                                              <br></br>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className="d-flex align-items-center"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              type="text"
                                              className="txt-color"
                                              name={`bankDetails.${index}.email_id`}
                                            />
                                            <div className="text-danger">
                                              <ErrorMessage
                                                className="text-danger"
                                                name={`bankDetails.${index}.email_id`}
                                              />
                                              <br></br>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className="d-flex align-items-center"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              type="text"
                                              className="txt-color"
                                              name={`bankDetails.${index}.linkdin_link`}
                                            />
                                            <div className="text-danger">
                                              <ErrorMessage
                                                className="text-danger"
                                                name={`bankDetails.${index}.linkdin_link`}
                                              />
                                              <br></br>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className="d-flex align-items-center"
                                            style={{ width: "10%" }}
                                          >
                                            <span
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              } // remove a friend from the list
                                            >
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <circle
                                                  cx="14"
                                                  cy="14"
                                                  r="13.125"
                                                  fill="#174984"
                                                />
                                                <path
                                                  d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875"
                                                  stroke="white"
                                                  stroke-width="2.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </span>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                              </Table>
                            </TableContainer>
                          </div>
                        )}
                      /> 

                      <h2 className="my-4 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        Point of Contact for IPR fillings
                      </h2>

                     <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          First Name <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter First Name"
                          className="textInput"
                          name="poc_ipr_first_name"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="poc_ipr_first_name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Last Name <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter Last Name"
                          className="textInput"
                          name="poc_ipr_last_name"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="poc_ipr_last_name"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Email Address <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter Email Address"
                          className="textInput"
                          name="poc_ipr_email_id"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="poc_ipr_email_id"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Phone Number <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="tel"
                          placeholder="Enter Phone Number"
                          className="textInput"
                          name="poc_ipr_contact_no"
                          pattern="[789]{1}[0-9]{9}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" minlength="10"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="poc_ipr_contact_no"
                          />
                        </div>
                      </div> 

                     <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Address for Communication
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="textarea"
                          placeholder="Enter Communication Address"
                          className="textInput"
                          name="poc_ipr_comm_address"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="poc_ipr_comm_address"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Permanent Address
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="textarea"
                          placeholder="Enter Permanent Address"
                          className="textInput"
                          name="poc_ipr_perm_address"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="poc_ipr_perm_address"
                          />
                        </div>
                      </div>  

                      <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        Revenue Details
                      </h2>

                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Current Finanacial status of the company
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="select"
                          className="form-select"
                          name="current_funding_status_id"
                        >
                          <option value="1">Equity</option>
                          <option value="2">Debt</option>
                          <option value="3">Awards</option>
                          <option value="4">Bootstrapped</option>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="current_funding_status_id"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          Comulative Turnover Since Incorporation
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="select"
                          className="form-select"
                          name="max_turnover_id"
                        >
                          <option selected>Select </option>
                          <option value="1">Less than 1 Cr</option>
                          <option value="2">Between 1 Cr & 5 Cr</option>
                          <option value="3">Between 5 Cr & 10 Cr</option>
                          <option value="4">Between 10 Cr & 25 Cr</option>
                          <option value="5">Between 25-100 Crore</option>
                          <option value="6">Over 100 Crore</option>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="max_turnover_id"
                          />
                        </div>
                      </div>  

                      <p className="label-txt my-4">
                        Please fill Revenue Details of Last 3 financial year
                        from the date of application (please compulsory fill the
                        financial year Details according to incorporation date
                        otherwise your application will be rejected.)
                      </p>

                     <TableContainer component={Paper} className="no-shadow">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead className="head-bg-color">
                            <TableRow className="d-flex justify-content-between ">
                              <TableCell
                                className="table-txt align-items-center"
                                style={{ width: "20%" }}
                              >
                                Financial Year
                              </TableCell>
                              <TableCell
                                className="table-txt align-items-center"
                                style={{ width: "35%" }}
                                align="left"
                              >
                                Audited Financial Numbers (in INR)
                              </TableCell>
                              <TableCell
                                className="table-txt align-items-center"
                                style={{ width: "35%" }}
                                align="left"
                              >
                                Upload Proof (Audited statement for CA)
                              </TableCell>
                              <TableCell
                                className="table-txt align-items-center "
                                style={{ width: "10%" }}
                                align="left"
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>

                      <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        DPIIT Registration Details
                      </h2>
                      <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                        <label className="pb-2 label-txt">
                          DPIIT Number
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="tel"
                          placeholder="Enter DPIIT Number"
                          className="textInput my-3"
                          name="dpiit_no"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="dpiit_no"
                          />
                        </div>
                      </div> 

                     <div className="col-lg-6 col-md-6 col-sm-6">
                        <label className="pb-2 label-txt">
                          Upload your DPIIT Certificate
                          <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="file"
                          placeholder="Upload Proof"
                          className="textInput my-4"
                          name="dpiit_image"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="dpiit_image"
                          />
                        </div>
                      </div> 

                   <div className="col-lg-6 col-md-6 pb-4">
                        <label className="pb-2 label-txt">
                          Have you taken benefit of this scheme before?
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field component="div" name="is_scheme_benefit_taken">
                          <div class="radiobuttons d-flex">
                            <div className="col-lg-2 col-md-2">
                              <div class="rdio rdio-primary radio-inline">
                                <input
                                  type="radio"
                                  id="radioYes1"
                                  name="is_scheme_benefit_taken"
                                  value="yes1"
                                />
                                <label htmlFor="radioYes1">Yes</label>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <div class="rdio rdio-primary radio-inline">
                                <input
                                  type="radio"
                                  id="radioNo1"
                                  name="is_scheme_benefit_taken"
                                  value="no1"
                                />
                                <label htmlFor="radioNo1">No</label>
                              </div>
                            </div>
                          </div>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage name="is_scheme_benefit_taken" />
                        </div>
                      </div> 

                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <label className="pb-2 label-txt">
                          How did you come to know about this scheme?
                          <span className="text-danger">*</span>{" "}
                          <InformationIcon />
                        </label>
                        <Field
                          type="text"
                          className="textInput my-4"
                          name="scheme_source"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="scheme_source"
                          />
                        </div>
                      </div> 

                     <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                        <label className="pb-2 label-txt">
                          If Yes, please provide details of the same
                          <span className="text-danger">*</span>
                          <InformationIcon />
                        </label>
                        <Field
                          as="textarea"
                          className="textInput"
                          name="scheme_details"
                        />
                        <div className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name="scheme_details"
                          />
                        </div>
                      </div>   

                     <h2 className="my-3 font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        Bank Details
                      </h2> 
                      <TableContainer component={Paper} className="no-shadow">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
               <TableHead className="head-bg-color">
                  <TableRow className="d-flex justify-content-between ">
                    <TableCell className="table-txt align-items-center"  style={{width:"26%"}}>Name of Account Holder</TableCell>
                    <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left"> Account No.</TableCell>
                    <TableCell className="table-txt align-items-center"  style={{width:"15%"}} align="left">Name of Bank</TableCell>
                    <TableCell className="table-txt align-items-center "  style={{width:"15%"}} align="left"> IFSC Code</TableCell>  
                    <TableCell className="table-txt align-items-center "  style={{width:"15%"}} align="left">Branch Name</TableCell>  
                  <TableCell className="table-txt align-items-center "  style={{width:"27%"}} align="left">Upload a Cancelled Cheque</TableCell>   
                  </TableRow>
                </TableHead>
             
              <TableBody  className="data-bg-color d-flex justify-content-between">
                 <TableRow className="w-100 d-flex justify-content-between" >
                    <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"26%"}}>
                        <Field type="text" className="txt-color" name="account_name" />
                          <div className="text-danger">
                          <ErrorMessage className="error" name="account_name"  /><br></br>
                          </div>
                    </TableCell>
                    <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                                <Field type="text" className="txt-color" name="account_number" />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name="account_number" /><br></br>
                              </div>
                    </TableCell>

                    <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                           <Field type="text" className="txt-color" name="bank_name" />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name="bank_name" /><br></br>
                              </div>
                  </TableCell>
                  <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                           <Field type="text" className="txt-color" name="ifsc_code" />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name="ifsc_code" /><br></br>
                              </div>
                  </TableCell>
                  <TableCell align="center" className="d-flex align-items-center" style={{width:"15%"}}>
                           <Field type="text" className="txt-color" name="branch_name" />
                                <div className="text-danger">
                                <ErrorMessage className="text-danger" name="branch_name" /><br></br>
                              </div>
                  </TableCell>
                  <TableCell align="center" className="d-flex align-items-center" style={{width:"27%"}}>
                  <input id="file" name="cheque" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                        setFieldValue(`name="cheque`, e.target.files[0]);
                      }} /> 
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name="cheque" /><br></br>
                        </div>
             </TableCell> 
              </TableRow>
              </TableBody>    
                  
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("teamDetails", JSON.stringify(values))}}  
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>
                                         
                  </div>
                  )} 
                  {/* </span> */}
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ipform;
