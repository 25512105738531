import React from "react";
import Header from "../component/Header";
import Footer from "../component/footer";

function accessibility_help() {
  return (
    <>
      <Header />
      <div className="mahaGrand-main intellectual-main">
        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                  Accessibility Help
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                  Use the accessibility options provided by this Web site to
                  control the screen display. These options allow changing the
                  text size and contrast scheme for clear visibility and better
                  readability.
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                  Changing the Text Size
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                  Changing the size of the text refers to making the text
                  appearing smaller or bigger from its standard size. There are
                  three options provided to you to set the size of the text that
                  affect readability. These are:
                  <ol>
                    <li>Larger: Displays information in a font size larger than the standard font size.</li>
                    <li>Standard: Displays information in a standard font size, which is the default size.</li>
                    <li>Smaller: Displays information in a font size smaller than the standard font size.</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                Text size Icons
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                Following different options are provided in the form of icons which are available on the top of each page:
                  <ol>
                    <li>A- Decrease text size: Allows to decrease the text size to next level</li>
                    <li>A Normal text size: Allows to set default text size</li>
                    <li>A+ Increase text size: Allows to increase the text size to next level</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" id="granchallenge">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2 d-flex justify-content-center">
                Contrast Scheme Icons
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">
                Two contrast scheme options are provided in the form of icons which are available on the top of each page:
                  <ol>
                    <li>High Contrast view: Applies black colour to the background and suitable colours to the foreground</li>
                    <li>Standard contrast view: Brings the screen back to its original appearance.</li>
                    
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default accessibility_help;
