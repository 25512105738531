import { useEffect, useState } from "react";
// import styles from "./styles.module.css";
// import riidl from "../../assets/images/Incubator/riidl-img.png";
// import Win700181tractor from "../../../assets/images/Initiative/winners/700181tractor.png"
//import startup from "../../assets/images/Incubator/startup-img.png"
//import { BsArrowUpRight } from "react-icons/bs";
import { environment } from '../../api';
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText
} from "react-bootstrap-v5";

function WinnersTable({winnerList, setSearch, setCity}) {


  const serverUrl = environment.baseUrl;
 
  return (
    <>
    
 <div >
 <div className="row mentorsearchDropdown">
        {/* <div className="col-lg-6 col-md-6 col-sm-4 px-2 pb-2">
          <div>
            <input
              type="text"
              className="searchplaceholder search-border-remove form-control search-bar-color"
              // className={styles.search}
              placeholder=" &#61442; Search Mentor by Name"
              onChange={({ currentTarget: input }) => setSearch(input.value)}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 px-2 pb-2">
          <div>
            <select
              class="form-select"
              onChange={({ currentTarget: input }) => setCity(input.value)}
            >
              <option>Select City</option>
              {mentorsearch.map((task) => (
                <option
                  key={task.value}
                  value={({ currentTarget: input }) => setCity(input.value)}
                >
                  {task.city}
                </option>
              ))}
            </select>
          </div>
        </div> */}

        {/* <div className="col-lg-3 col-md-3 col-sm-4 px-2 pb-2">
          <div>
            <select
              class="form-select"
              onChange={({ currentTarget: input }) => setSector(input.value)}
            >
              <option>Select Sector</option>
              {mentorSector.map((sectorSerch) => (
                <option
                  key={sectorSerch.value}
                  value={({ currentTarget: input }) => setSector(input.value)}
                >
                  {sectorSerch.sector}
                </option>
              ))}
            </select>
          </div>
        </div> */}
      </div>


      <div className="container startupwinnercard">
        <div className="">
          <div className="pb-4 ">
            <div className="row">
            {winnerList.map(task => (
                <>
                <div className="col-lg-3 col-md-6 px-2 py-3">
                <div className='winnerMainCard'>
                <div className=" winner-card text-center p-3" >
                    <div className="">
                      <img className="img-fluid p-2 img-height" src={task.image} />
                    </div>
                    <div className="card-details">
                      <h5 className="pb-2 pt-2 title_plex_font font-style-normal font-weight-700 font-18-23">
                        <b>{task.title}</b>
                      </h5>
                      <p className="">
                        <b>City : </b>{task.city}
                      </p>
                      <div className="row " >
                      <span><b>Sectors : </b>{task.category}</span>
                          {/* <button className="winnerSectorButton AgriButton">{task.category}</button> */}
                      </div>
                    </div>
                </div>
               {/* --------------- */}
               <div className='webwebsitebtn d-none d-sm-block'>
                <div className='pinkbtn d-flex justify-content-center pb-3' style={{backgroundColor:"#f6f6f6"}}>
                    <Button className="btn-primary regular_font font-style-normal font-weight-normal font-20-24 ">
                    <a href={task.link} target="_blank">
                        <span className='regular_font font-18-23 font-style-normal font-weight-400'>Visit Website</span>                                   
                        <span className='inno-arrow'>
                        <svg 
                                stroke="currentColor"
                                fill="currentColor"
                                width="20" 
                                height="19"
                                viewBox="0 0 20 19" 
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.44156 17.8085L18.0586 1.19149M18.0586 1.19149L6.11219 1.11706M18.0586 1.19149L18.133 13.1379" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> 
                            </svg>
                            </span>
                        </a>
                        </Button>
                  </div>
                 </div>
                 <div className='mobwebsitebtn d-lg-none d-md-none'>
                  <div>
                     <div className='pinkbtn' >
                        <a href={task.link} target="_blank">
                            <span className='regular_font font-18-23 font-style-normal font-weight-400'>Visit Website</span>                                   
                            <span className='inno-arrow-winnerMob'>
                            <svg 
                                    stroke="currentColor"
                                    fill="currentColor"
                                    width="20" 
                                    height="19"
                                    viewBox="0 0 20 19" 
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.44156 17.8085L18.0586 1.19149M18.0586 1.19149L6.11219 1.11706M18.0586 1.19149L18.133 13.1379" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> 
                                </svg>
                                </span>
                            </a>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
            </>
            ))}
            </div>
            </div>
            </div>
            </div>
          </div>
        </>
  )
}

export default WinnersTable
