import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom"
import axios from 'axios';
import { environment } from '../api';
// import Eventimg from "../assets/images/Initiative/EventDetails/617281MainhomepageStartupweek.png";
import { Button } from "react-bootstrap-v5";
import {FaCalendarDay} from 'react-icons/fa';
import {FaMapMarker} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaTwitterSquare} from 'react-icons/fa';
import { Link } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/footer';

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Test() {

 
  // const createMarkup = (html) => {
  //   return {
  //     __html: DOMPurify.sanitize(html)
  //   };
  // };


    const [myStyle, setMyStyle]= useState({
        paddingTop:'20px'
      
      })
      
      const [btntext, setBtnText]= useState("Enable Dark Mode");
      
      const toggleStyle = ()=>{
        if(myStyle.color == 'white' ){
        setMyStyle({
          paddingTop:'0px'
        })
      setBtnText("enable light mode")
      
      }
      else{
        setMyStyle({
          paddingTop:'20px'
        })
        setBtnText('enable Dark mode')
      }
      }

  const serverUrl = environment.baseUrl;
  const { id } = useParams(); // <-- access id match param here
console.log(id, "@@@@@@@@@@@@@@@")
const [data, setData] = useState(null);
useEffect(() => {
  fetchProduct();
}, []);
const fetchProduct = () => {
  axios
    .get(serverUrl +`/menubar/${id}`
    )
    .then((res) => {
      setData(res.data.data);
      console.log("data",res.data.data);
    })
    .catch((err) => console.log(err));
};

const [editorState, setEditorState] = useState(() =>
EditorState.createEmpty()
);
const [convertedContent, setConvertedContent] = useState(null);
const handleEditorChange = (state) => {
setEditorState(state);
convertContentToHTML();
};
const convertContentToHTML = () => {
let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
setConvertedContent(currentContentAsHTML);
};
  return (
    <> 
     <Header/>
      <div className="mahaGrand-main intellectual-main">
      {data?

<>
<div className="mahaGrand-main pt-4">
        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center">
              <div className="card col-lg-10 col-md-10 col-sm-10 apply-head  border-2">
                <div className="card-body d-flex justify-content-center  align-items-center">
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <span className="regular_font font-20-24 font-style-normal font-weight-400">
                      Apply now
                    </span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <span className="Arrow-apply align-items-end">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="list-group list-group-flush">
                  <a
                    href="#firstRow"
                    className="list-group-item list-group-item-action"
                  >
                    <span className="regular_font font-18-23 font-style-normal font-weight-400">
                    {data[0].titleFirst}
                    </span>
                  </a>
                  <a
                    href="#secondRow"
                    className="list-group-item list-group-item-action"
                  >
                    <span className="regular_font font-18-23 font-style-normal font-weight-400">
                    {data[0].titleSecond}
                    </span>
                  </a>
                </div>
                
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7">
              <div className="row" id="firstRow">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                {data[0].titleFirst}
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">{data[0].descriptionFirst}
                </p>
              </div>

              <div className="row" id="secondRow">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                {data[0].titleSecond}
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17">{data[0].descriptionSecond}
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>







       
        </>
        : "loading"
}
      </div>
      <Footer />
    </>
  );
}

export default Test;




