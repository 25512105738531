import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function StudentApplicantListViewM(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: "220%" }}>
            <Box sx={{ borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >

                    <Tab className="txt-main-style" label="Personal Details" {...a11yProps(0)} />
                    <Tab className="txt-main-style" label="Complete Details" {...a11yProps(1)} />
                    <Tab className="txt-main-style" label="Contact Details" {...a11yProps(2)} />
                    <Tab className="txt-main-style" label="Other Details" {...a11yProps(3)} />
                    <Tab className="txt-main-style" label="Uploads" {...a11yProps(4)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Form ID :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>First Name :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Last Name :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Email :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Contact No. :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>




            </TabPanel>


            <TabPanel value={value} index={1}>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Student/Team Name :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>School/College Name :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Date of Birth &#40;DD/MM/YYYY&#41; :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Date of Birth Proof :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Residential Address :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Residential Address Proof :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Institute Address :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Institute Address Proof:</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Place of Registration &#40;District&#41; :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b> Please Select the Sectors for which the proposal is submitted :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>




            </TabPanel>


            <TabPanel value={value} index={2}>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Name :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>E-mail Address :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Phone Number :</b>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </TabPanel>


            <TabPanel value={value} index={3}>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Briefly Describe the Solutions Offered :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Before We Read Your Deck, Is There Anything
                                    You Wish To Highlight about Your Proposal :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Describe the Impact You Seek to Deliver
                                    through Collaboration with the Government of
                                    Maharashtra :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </TabPanel>


            <TabPanel value={value} index={4}>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Upload Document or Presentation &#40;pdf&#41;
                                   <br /> of up to 10 Pages :</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <label>
                                {" "}
                                <b>Upload Video &#40;mp4,wav&#41; of up to 5
                                    minutes:</b>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="">
                            <span>{props.rowData.businessmodel.name}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </TabPanel>
        </Box>
    )
}
