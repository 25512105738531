// import React from 'react';
import React, { useState, useEffect } from 'react'
import GrandChallenge from '../../assets/images/Initiative/gradchallenge/Maharashtra_Grand_Challenge.png'
import Arrow from '../../assets/images/innovation/arrow.png'
import { Button } from 'react-bootstrap-v5'

function MahaGrandBanner() {
  const [dropdownState, setDropdownState] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('')

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState)
  }
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value)
    setDropdownState(!dropdownState)
  }
  return (
    <>
      <div className="mahagranddropdown d-lg-none d-xl-none d-sm-block d-md-block pt-5" id='mahadropdown'>
        <div className="container">
          <div className={`dropdown pt-2`}>
            <button onClick={handleDropdownClick} className="dropdown-btn d-flex align-items-center">
             <span className='w-75'>
             {dropdownValue === '' ? 'Menu' : dropdownValue}
             </span>
             {/* <span className='w-25 d-flex justify-content-end'>
              <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 -5.24537e-07L12 6L0 6L6 -5.24537e-07Z" fill="black"/>
</svg>
</span> */}
            </button>
           
            <div
              className={`dropdown-items ${
                dropdownState ? 'isVisible' : 'isHidden'
              }`}
            >
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Grand Challenge')}
                >
                  <a href="#granchallenge">1. Grand Challenge</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Eligibility')}
                >
                  <a href="#idEligibility">2. Eligibility</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() =>
                    handleSetDropdownValue('Problem Statement and Prize Money')
                  }
                >
                  <a href="#idProblem">3. Problem Statement and Prize Money</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Event Schedule')}
                >
                  <a href="#idEventSch">4. Event Schedule</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('FAQs')}
                >
                  <a href="#idFAQ">5. FAQs</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Contact')}
                >
                  <a href="#idContact">6. Contact</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="maGrandBaneer">
        <img
          src={GrandChallenge}
          className="maGrandBaneer-img"
          alt="MaharashtraGrandBaneer-img"
        />
      </div> */}
    </>
  )
}

export default MahaGrandBanner
