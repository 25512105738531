import React from 'react';
import DistrictMiddle from './DistrictLoginMiddle';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../component/HeaderAdmin';

function DistrictLoginMain() {
  return (
    <>
    <Header/>
    <DistrictMiddle/>
    </>
  )
}

export default DistrictLoginMain