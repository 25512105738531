import React, { useState, useEffect }  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Logo from '../assets/images/photo/image 261.png'
import TestmProfile from '../../assets/images/Initiative/testimonials/image261.png';
import TestmProfile2 from '../../assets/images/Initiative/testimonials/testimonial2.png';
import TestmProfile3 from '../../assets/images/Initiative/testimonials/testimonial2.png';
import { environment } from '../../api';


export default function MaharashtraStartupTestimonials() {
    const serverUrl = environment.baseUrl;
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(serverUrl +"/testimonials/allTestimonials")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setUsers(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])
    return(
        <>

<div className='mahaGrand-main pt-4' id="testimonials">
        <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="offset-lg-3 col-lg-7 col-md-12 col-sm-7">
                    <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-4">Testimonials</h2>
                    {users.map(user => (
                    <div className='py-2'>
                    <div className="card tcard bg-light">
                        <div className="card-body">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <img className='timg' src={user.image} style={{ margin: 0, height: 110, width: 110, borderRadius:50 }}/>
                                    </div>
                                    <div className='col-md-9'>
                                        <p className='font-style-normal font-weight-400 font-22-26 font-14-17 justify'>{user.description}</p>
                                        <p  className='font-style-normal font-weight-700 font-24-29 font-14-17'><b> {user.name}</b></p>
                                        <p className='font-style-normal font-weight-400 font-18-22 font-14-17'>Commissioner, Pimpri Chinchwad Municipal Corporation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    ))}
                  </div>
                </div>
        </div>
</div>
            
        </>
    );
}