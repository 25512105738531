import React, { useState, useEffect } from 'react'
import MahaStartupBanner from '../../assets/images/Initiative/InitiativeMaharashtraStartup/MahaStartupBanner.png';

function MaharashtraStartupBaneer() {
  const [dropdownState, setDropdownState] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('')

  

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState)
  }
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value)
    setDropdownState(!dropdownState)
  }
  return (
    <>
    <div className="mahagranddropdown d-lg-none d-xs-block d-md-block d-sm-block" id='mahadropdown'>
        <div className="container">
          <div className={`dropdown pt-5`}>
            <button onClick={handleDropdownClick} className="dropdown-btn">
              {dropdownValue === '' ? 'Menu' : dropdownValue}
            </button>
            <div
              className={`dropdown-items ${
                dropdownState ? 'isVisible' : 'isHidden'
              }`}
            >
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Maharashtra Startup Week')}
                >
                  <a href="#mahaStartupWeek">1. Maharashtra Startup Week</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Testimonials')}
                >
                  <a href="#testimonials">2. Testimonials</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() =>
                    handleSetDropdownValue('FAQ')
                  }
                >
                  <a href="#idFAQ">3. FAQs</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Our Partner')}
                >
                  <a href="#ourPartner">4. Our Partner</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Contact')}
                >
                  <a href="#idContact">5. Contact</a>
                </div>
              </div>
              {/* <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Contact')}
                >
                  <a href="#idContact">6. Contact</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    {/* <div className='maStartUpBaneer'>
        <div className='row'>
        <div className='col-6 offset-md-3 py-5'>
  
   <img 
   src={MahaStartupBanner}
   className='maStartUpBaneer-img' alt="MaharashtraGrandBaneer-img" />
  </div>

        </div>
 </div> */}
    </>
  )
}

export default MaharashtraStartupBaneer