import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const FeaturedResourseaftersearchUpdateM = (props, {setModalIsOpenToFalse2}) => {
  const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitFeaturedResourseUpdateMForm(values){
    const formData = new FormData();
    formData.append("title",values.title)
    formData.append("link",values.link)
    formData.append("date",values.date)
    formData.append("description",values.description)
    fetch(serverUrl +`/featureResource/${props.rowData.resourceSerach._id}`, 
            {
              method: "PUT",
              body: formData,
            
              
            })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
          
  }

 

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
     
      handleSubmitFeaturedResourseUpdateMForm(values)
    }
    validationSchema={Yup.object({
      title: Yup.string().required('Enter title is Required'),
      link: Yup.string().required('Upload Pdf is Required'),
      date: Yup.string().required('Enter date is Required'),
      // description: Yup.string().required('Enter Description is Required'),
       })}
      initialValues={{ 
        title:props.rowData.resourceSerach.title,
        link: props.rowData.resourceSerach.link,
        date: props.rowData.resourceSerach.date,
        description:props.rowData.resourceSerach.description,
       }}


      render={({ values, handleSubmit, setFieldValue, handleChange}) => (
        <Form onSubmit={handleSubmit}>
        <div className="row">
         
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Title{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"                    
                      onChange={handleChange}
                      
                      placeholder="Enter Name" className="textInput" name="title" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="title" /><br></br>
                    </div>                   
                  </div>

                  {/* <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Link{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                       onChange={handleChange}
                     placeholder="Enter Link Name" className="textInput" name="link" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>                   
                  </div> */}

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Pdf Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    {/* <img id="imgupdate" 
                    src={Rectangle136}
                     src={updatedImage||values.link} 
                    /><br></br> */}
                    {/* <p>{values.link}</p> */}
                    <input id="file" name="link" accept="application/pdf, application/vnd.ms-excel"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("link", e.target.files[0]);
                      }} required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="link" /><br></br>
                    </div>
                  </div> 

                 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Date <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="date"
                    onChange={handleChange}
                     placeholder="Enter Date Name" className="textInput" name="date" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="date" /><br></br>
                    </div>
                  </div>

                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Description <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Description"  className="textInput" name="description" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="description" /><br></br>
                    </div>
                  </div> 
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default FeaturedResourseaftersearchUpdateM;
