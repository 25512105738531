import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from '../startup/informationIcon';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const serverUrl = environment.baseUrl;
const BasicDetails = (props) => {
  const authToken =sessionStorage.getItem("token");
  let initialFormObj={ 
    name_of_startup: '',
  product_name: '',
  website: '',
  firm_register_date: '',
  is_ur_register_mh: '',
  mh_incorparate_doc: '',
  company_address: '',
  state: '',
  district: '',
  city: '',
  biz_model_type: '',
  // other_biz_model_type: '',
  firm_type:'',
  sector: '',
  technology_used: '',
  desc_ur_startup: '',
  general_pitch_deck_file: '',
  other_detail: '',
  team_size: '',
  awardsAchievements: [{
    awards_Achievements:''
    }],
  have_you_incubated: '',
  nameOfIncubator:'',
  How_you_hear_aboutus: '',
  name_of_Acc_holder: '',
  acc_no: '',
  name_of_bank: '',
  IFSC_code: '',
  branch_name: '',
  cancelled_cheque: '',
  
   }
   const [users, setUsers] = useState([]);
    const [sector, setSector] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
      fetch(serverUrl + "/sectors/allSectors")
        .then(res => res.json())
        .then(
          (data) => {
            setIsLoaded(true);
            setUsers(data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, []) 
  
    const handleSector = (values) => {
      const getSector = values.target.value;
      setSector( getSector);
  }


  const [userstate, setUserstate] = useState([]);
  const [state, setState] = useState();
  useEffect(() => {
    fetch(serverUrl + "/stateList/allState")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUserstate(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []) 

  const handleState = (values) => {
    const getState = values.target.value;
    setState(getState);
}


const [userdistrict, setUserDistrict] = useState([]);
const [district, setDistrict] = useState();
useEffect(() => {
  fetch(serverUrl + "/districtList/allDistricts")
    .then(res => res.json())
    .then(
      (data) => {
        setIsLoaded(true);
        setUserDistrict(data);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
}, []) 

const handleDistrict= (values) => {
  const getState = values.target.value;
  setDistrict(getState);
}

const [userbussinesModel, setUserBussinesModel] = useState([]);
const [bussinesModel, setBussinesModel] = useState();
useEffect(() => {
  fetch(serverUrl + "/businessM/allbusinessModel")
    .then(res => res.json())
    .then(
      (data) => {
        setIsLoaded(true);
        setUserBussinesModel(data);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
}, []) 

const handleBussinesModel= (values) => {
  const getBussinesModel = values.target.value;
  setBussinesModel(getBussinesModel);
}

const [nameOfIncubator, setFlag] = useState("no")
  const btnClick = (e) => {
   setFlag(e.target.value);
  };
  function handleSubmitMentorApplicationDetailsForm(values){
    console.log(values, "hii")
     const formData = new FormData();
    formData.append("name_of_startup",values.name_of_startup)
    formData.append("product_name",values.product_name)
    formData.append("website",values.website)
    formData.append("firm_register_date",values.firm_register_date)
    formData.append("is_ur_register_mh",values.is_ur_register_mh)
    formData.append("mh_incorparate_doc",values.mh_incorparate_doc)
    formData.append("company_address",values.company_address)
    formData.append("state",values.state)
    formData.append("district",values.district)
    formData.append("city",values.city)
    formData.append("biz_model_type",values.biz_model_type)
    // formData.append("other_biz_model_type",values.other_biz_model_type)
    formData.append("firm_type",values.firm_type)
    formData.append("sector",values.sector)
    formData.append("mh_incorparate_doc",values.mh_incorparate_doc)
    formData.append("technology_used",values.technology_used)
    formData.append("desc_ur_startup",values.desc_ur_startup)
    formData.append("general_pitch_deck_file",values.general_pitch_deck_file)
    formData.append("other_detail",values.other_detail)
    formData.append("team_size",values.team_size)
    formData.append("have_you_incubated",values.have_you_incubated)
    formData.append("nameOfIncubator",values.nameOfIncubator)
    formData.append("How_you_hear_aboutus",values.How_you_hear_aboutus)
    formData.append("name_of_Acc_holder",values.name_of_Acc_holder)
    formData.append("acc_no",values.acc_no)
    formData.append("name_of_bank",values.name_of_bank)
    formData.append("IFSC_code",values.IFSC_code)
    formData.append("branch_name",values.branch_name)
    formData.append("cancelled_cheque",values.cancelled_cheque)
    fetch(serverUrl +"/basicDetailsStartup/addBasicDetail", 
         {

          method: "POST",
          body: formData,
         headers: {
              "auth-token":authToken
            },

            // method: "POST",
            // body: formData,
            // headers: {
            //   "auth-token":authToken
            // },
            // body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          props.setValue(props.value+1)
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitMentorApplicationDetailsForm(values)
    }
    validationSchema={Yup.object({
      name_of_startup: Yup.string().required('Start up Name is required'),
    product_name: Yup.string().required('Product Name is required'),
    // website: Yup.string().required('Website is required'),
    firm_register_date: Yup.string().required('Firm Register Date is required'),
    // is_ur_register_mh: Yup.string().required('company registered in Maharashtra is required'),
    mh_incorparate_doc: Yup.string().required('Incorparate Document is required'),
    company_address: Yup.string().required('Company Address is required'),
    state: Yup.string().required('State is required'),
    district: Yup.string().required('District is required'),
    city: Yup.string().required('City is required'),   
    biz_model_type: Yup.string().required('Select Business model type is required'),
    // other_biz_model_type: Yup.string().required('Other Business model type  is required'),
    firm_type: Yup.string().required('Firml Type  is required'),
    sector: Yup.string().required('Select Startup Sector is required'),
    technology_used: Yup.string().required('Technology used is required'),
    desc_ur_startup: Yup.string().required('Describe your Startup is required'),
    general_pitch_deck_file:Yup.string().required('Upload your General Pitch Deck is required'),
    // other_detail: Yup.string().required('Other Detail is required'),
    team_size: Yup.string().required('Team Size is required'),
    have_you_incubated: Yup.string().required('Have you ever been incubated is required'),
    nameOfIncubator: Yup.string().required('Name Of Incubator is required'),
    How_you_hear_aboutus: Yup.string().required('How you Hear About us is required'),
    name_of_Acc_holder: Yup.string().required('Name of Account Holder is required'),
    acc_no: Yup.string().required('Account Number is required'),
    name_of_bank: Yup.string().required('Name of Bank is required'),
    IFSC_code: Yup.string().required('IFSC Code is required'),
    branch_name: Yup.string().required('Branch Name is required'),
    cancelled_cheque: Yup.string().required('Cancelled Cheque is required'),           
       })}

       initialValues={JSON.parse(localStorage.getItem('basicDetails'))||initialFormObj} 
      render={({ values, setFieldValue, handleChange }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Basic Details
            </h2>
        </div>

        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name of Startup/Organization{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Organization Name" className="textInput" name="name_of_startup" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name_of_startup" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Product Name  <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Product Name" className="textInput" name="product_name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="product_name" /><br></br>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Website 
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Website" className="textInput" name="website" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="website" /><br></br>
                    </div> */}
                  </div>


                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Firm Registration Date <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="date" placeholder="Select Date" className="textInput" name="firm_register_date" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="firm_register_date" /><br></br>
                    </div>
                  </div>
                  </div>
                  <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd pt-2">
                Location of Registration
                </h2>
                  <div className="row">
                  <div className="col-lg-6 col-md-6 p-2">
                      <label className="pb-2 label-txt">
                      Is your company registered in Maharashtra?<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field component="div" name="is_ur_register_mh">
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-3 col-md-3">
                          <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes"
                            defaultChecked={values.is_ur_register_mh === "Yes"}
                            name="is_ur_register_mh"
                            value="Yes"
                          />
                          <label htmlFor="radioYes">Yes</label>
                          </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                                            <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo"
                            defaultChecked={values.is_ur_register_mh === "No"}
                            name="is_ur_register_mh"
                            value="No"
                          />
                          <label htmlFor="radioNo">No</label>
                          </div>
                          </div>
                          </div>
                        </Field>
                        {/* <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`is_ur_register_mh`} />
                        </div> */}
                    </div>
                    <div class="col-lg-6 col-md-6 pt-4">
                    <label className="label-txt">
                    Upload Proof of being incorporated in Maharashtra
                      <InformationIcon />
                    </label>
                    <div class="form mt-1 ml-5 pl-5 col-lg-12 col-md-12">
                    <div class="btn float-left imgbackground form-control">
                    <input id="file" name="mh_incorparate_doc" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("mh_incorparate_doc", e.target.files[0]);
                      }} />   
                      </div> 
                      <div className="text-danger">
                          <ErrorMessage className="text-danger" name="mh_incorparate_doc" />
                           </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Registered Company Address <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="company_address" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="company_address" /><br></br>
                    </div>
                  </div>
                 </div>
                <div className="row">
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      State <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <select type="select" name="state" className="form-control form-select size"
                    value={values.state}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("state", e.target.value)
                      handleState(e)
                    }}
                  >
                    <option value={""}>Select State</option>
                      {
                        userstate.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="state" />
                    </div>
                  </div>
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      District<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <select type="select" name="district" className="form-control form-select size"
                    value={values.district}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("district", e.target.value)
                      handleDistrict(e)
                    }}
                  >
                    <option value={""}>Select District</option>
                      {
                        userdistrict.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="district" />
                    </div>
                  </div>
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      City <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter City" className="textInput" name="city" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="city" /><br></br>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      Business model type<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>

                    <select type="select" name="biz_model_type" className="form-control form-select size"
                    value={values.biz_model_type}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("biz_model_type", e.target.value)
                      handleBussinesModel(e)
                    }}
                  >
                    <option value={""}>Select Business Model Type</option>
                      {
                        userbussinesModel.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                   
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="biz_model_type" />
                    </div>
                  </div>
                  {/* <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      Other Business model type{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Other Business model type" className="textInput" name="other_biz_model_type" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="other_biz_model_type" /><br></br>
                    </div>
                  </div> */}
                  <div className="col-md-4 pt-4">
                    <label className="pb-2 label-txt">
                      Firm Type <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Firm Type" className="textInput" name="firm_type" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="firm_type" /><br></br>
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      Startup Sector<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <select type="select" name="sector" className="form-control form-select size"
                    value={values.sector}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("sector", e.target.value)
                      handleSector(e)
                    }}
                  >
                    <option value={""}>Select Interested Sector</option>
                      {
                        users.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                    <ErrorMessage className="error" name="sector" /><br></br>
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      Technology used <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Technology used" className="textInput" name="technology_used" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="technology_used" /><br></br>
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      Describe your Startup
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="desc_ur_startup" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="desc_ur_startup" /><br></br>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 pt-4">
                    <label className="label-txt">
                      Upload your General Pitch Deck
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <div class="form mt-1 ml-5 pl-5 col-lg-12 col-md-12">
                    <div class="btn float-left imgbackground form-control">
                    <input id="file" name="general_pitch_deck_file" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("general_pitch_deck_file", e.target.files[0]);
                      }} /> 
                      </div> 
                      <div className="text-danger">
                          <ErrorMessage className="text-danger" name="general_pitch_deck_file" />
                           </div>
                    </div>
                  </div>

                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      Other Details <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="other_detail" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="other_detail" /><br></br>
                    </div> */}
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      Team Size<span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"  placeholder="Enter Team Size" className="textInput" name="team_size" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="team_size" /><br></br>
                    </div>
                  </div>
                  <FieldArray
            name="awardsAchievements"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>
            <TableContainer component={Paper} className="no-shadow">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="head-bg-color">
                  <TableRow className="d-flex justify-content-between ">
                    <TableCell className="table-txt align-items-center"  style={{width:"90%"}}>Awards/Achievements</TableCell>
                   
                    <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action</TableCell>            
                  </TableRow>
                </TableHead>
                {values.awardsAchievements && values.awardsAchievements.length > 0 ? (
                  values.awardsAchievements.map((friend, index) => (
              <TableBody  className="data-bg-color d-flex justify-content-between">
                 <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
                    <TableCell component="th" scope="row" className="d-flex align-items-center"  style={{width:"90%"}}>
                        <Field type="text" className="txt-color" name={`awardsAchievements.${index}.awards_Achievements`} />
                          <div className="text-danger">
                          <ErrorMessage className="error" name={`awardsAchievements.${index}.awards_Achievements`} /><br></br>
                          </div>
                    </TableCell>
                  
                    <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                      <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                      >
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                        <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </span>
                    </TableCell>  
              </TableRow>
              </TableBody> 
               
                  ))
                ) : (
                 <></>
                )}
                 
                </Table>
      </TableContainer>
               
              </div>
            )}
          />
                  <div className="col-lg-6 col-md-12 pt-4">
                  <div className="col-lg-6 col-md-12 pt-4">
                <label className="pb-2 label-txt">
                Have you ever been incubated?<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field component="div" name="have_you_incubated">
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-3 col-md-3">
                          <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes3"
                            defaultChecked={values.have_you_incubated === "Yes"}
                            name="have_you_incubated"
                            value="Yes"
                          />
                          <label htmlFor="radioYes3">Yes</label>
                          </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                                            <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo3"
                            defaultChecked={values.have_you_incubated === "No"}
                            name="have_you_incubated"
                            value="No"
                          />
                          <label htmlFor="radioNo3">No</label>
                          </div>
                          </div>
                          </div>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`have_you_incubated`} />
                        </div>
                    </div>
                  </div>
                  {nameOfIncubator == "yes" && (
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Please mention the name of the Incubator: [Max 255 characters] <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="nameOfIncubator" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="nameOfIncubator" /><br></br>
                    </div>
                  </div>
                  )}
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      How did you hear about us?{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select" name="How_you_hear_aboutus">
                      <option selected>Select</option>
                      <option value="Newspaper">Newspaper</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Linkedin">Linkedin</option>
                    </Field>
                    <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`How_you_hear_aboutus`} />
                        </div>
                  </div>
                </div>
                <h3 className="locationreg pt-5">Bank Details</h3>   
                <div className="row py-2">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <label className="pt-4 label-txt">
                  Name of Account Holder<span className="text-danger">*</span> <InformationIcon />
                </label>
                <Field type="text"  placeholder="Enter Name of Account Holder" className="textInput" name="name_of_Acc_holder" />
                <div className="text-danger">
                <ErrorMessage className="error" name="name_of_Acc_holder" /><br></br>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <label className="pt-4 label-txt">
                Account Number<span className="text-danger">*</span> <InformationIcon />
              </label>
              <Field type="text"  placeholder="Enter Account Number" className="textInput" name="acc_no" />
              <div className="text-danger">
              <ErrorMessage className="error" name="acc_no" /><br></br>
              </div>
          </div>
        </div>

<div className="row py-2">
  <div className="col-lg-6 col-md-6 col-sm-6">
    <label className="pt-4 label-txt">
      Name of Bank <span className="text-danger">*</span><InformationIcon />
    </label>
    <Field type="text"  placeholder="Enter Name of Bank" className="textInput" name="name_of_bank" />
    <div className="text-danger">
    <ErrorMessage className="error" name="name_of_bank" /><br></br>
    </div>
  </div>
  <div className="col-lg-6 col-md-6 col-sm-6">
    <label className="pt-4 label-txt">
      IFSC Code<span className="text-danger">*</span> <InformationIcon />
    </label>
    <Field type="text"  placeholder="Enter IFSC Code" className="textInput" name="IFSC_code" />
    <div className="text-danger">
    <ErrorMessage className="error" name="IFSC_code" /><br></br>
    </div>   
  </div>
</div>

<div className="row py-2">
  <div className="col-lg-6 col-md-6 col-sm-6">
    <label className="pt-4 label-txt">
      Branch Name<span className="text-danger">*</span> <InformationIcon />
    </label>
    <Field type="text"   placeholder="Enter Branch Name" className="textInput" name="branch_name" />
    <div className="text-danger">
    <ErrorMessage className="error" name="branch_name" /><br></br>
    </div>   
  </div>
  <div className="col-lg-6 col-md-6 col-sm-6">
  <label className="label-txt pt-4">
  Upload a Cancelled Cheque
      <span className="text-danger">*</span>
      <InformationIcon />
    </label>
  <div class="form mt-1 ml-5 pl-5 col-lg-12 col-md-12">
  <div class="btn float-left imgbackground form-control">
  <input id="file" name="cancelled_cheque" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("cancelled_cheque", e.target.files[0]);
                      }} /> 
    </div> 
    <div className="text-danger">
        <ErrorMessage className="text-danger" name="cancelled_cheque" />
          </div>
      </div>
  </div>
</div>
        <div className="justify-content-center d-flex py-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow"
                          onClick={()=>{localStorage.setItem("basicDetails", JSON.stringify(values))}}  
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow" 
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default BasicDetails;
