import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../startup/informationIcon";
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Upload from "../assets/images/navbar/upload.png";

const serverUrl = environment.baseUrl;
const Infrastructure = () => {
  const authToken =sessionStorage.getItem("token");
 // console.log(JSON.parse(sessionStorage.getItem('infrastucDetails')))

 let initialFormObj={ 
  program_duration: '',
  What_type_infrastructure_provide: '',
 }
  
  function handleSubmitFinacialForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("program_duration",values.program_duration)
    formData.append("What_type_infrastructure_provide",values.What_type_infrastructure_provide)
    formData.append("category",values.category)

    fetch(serverUrl +"/infrastucture/addInfraDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken,
            },
            // body:JSON.stringify(values, null, 2)
            
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          .alert.show('Oh look, an alert!') 
         
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik


      onSubmit={(values) =>
      handleSubmitFinacialForm(values)
      
    }
    validationSchema={Yup.object({
    program_duration: Yup.string().required('Program Duration is required'),
    What_type_infrastructure_provide: Yup.string().required('What Type Infrastructure Provide is required'),
   
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('infrastucDetails'))||initialFormObj}

      render={({ values, setFieldValue }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
           Infrastructure
            </h2>
        </div>
        <div className="row">
        <div className="col-lg-12 col-md-12 p-2">
        <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Program duratione <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Program duration" className="textInput" name="program_duration" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="program_duration" /><br></br>
                    </div>
                    </div>
                  </div>
        <div className="col-lg-12 col-md-12 pb-4">
                      <label className="pb-2 label-txt">
                      What type of infrastructure you provided?<span className="text-danger">*</span>
                        <InformationIcon />
                      </label>
                      <Field component="div" name="What_type_infrastructure_provide">
                      <div class="radiobuttons d-flex">
                        <div className="col-lg-3 col-md-3">
                          <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes"
                            defaultChecked={values.What_type_infrastructure_provide === "Physical"}
                            name="What_type_infrastructure_provide"
                            value="Physical"
                          />
                          <label htmlFor="radioYes">Physical</label>
                          </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                                            <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo"
                            defaultChecked={values.What_type_infrastructure_provide === "Virtual"}
                            name="What_type_infrastructure_provide"
                            value="Virtual"
                          />
                          <label htmlFor="radioNo">Virtual</label>
                          </div>
                          </div>
                          </div>
                        </Field>
                        <div className="text-danger">
                          <ErrorMessage className="text-danger" name={`What_type_infrastructure_provide`} />
                        </div>
                    </div>
        </div>
     
<div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("infrastucDetails", JSON.stringify(values))}}   

                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
        </Form>
      )}
    />
  </div>
  </div>
  </div>
  
};

export default Infrastructure;
