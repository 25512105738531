import React, { useEffect, useState } from 'react'
import "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal';
import ViewPayment from './ViewPaymentMod';
import ViewApplyDomestic from './ApplyDomesticPatent'; 

export default function Domesticpatent() {
    

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const setModalIsOpenToTrue = () => {
      setModalIsOpen(true);
    };
  
    const setModalIsOpenToFalse = () => {
      setModalIsOpen(false);
    };
  
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
  
    const setModalIsOpenToTrue1 = () => {
      setModalIsOpen1(true);
    };
  
    const setModalIsOpenToFalse1 = () => {
      setModalIsOpen1(false);
    };
  
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
  
    const setModalIsOpenToTrue2 = () => {
      setModalIsOpen2(true);
    };
  
    const setModalIsOpenToFalse2 = () => {
      setModalIsOpen2(false);
    };
    const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
  };

    const [showData,setShowData] = useState('');
    // const serverUrl = environment.baseUrl;
    const [rows, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    
      
  const columns = [
    { field: "_id", headerName: "Sr.No.", width: 280 },
    { field: "mentor_name", headerName: "Appliaction No", width: 150 },
    { field: "email", headerName: "Application Date", width: 150 },
    { field: "phone_number", headerName: "proposed Title of Invention", width: 200 },
    { field: "company_name", headerName: "status", width: 150 },
    
    {
        field: "Action",
        headerName: "Action",
        sortable: false,
        width: 200,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
  
            const api = params.api;
            const thisRow = {};
  
            api
              .getAllColumns()
              .filter((c) => c.field !== "__check__" && !!c)
              .forEach(
                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
              );
  
            return alert(JSON.stringify(thisRow, null, 4));
          };
          return (
            <>
              {/* <Button onClick={setModalIsOpenToTrue}>
                <VisibilityIcon />
              </Button> */}
              {/* <Button onClick={onClick}>
        <BallotIcon />
      </Button> */}
              <Button onClick={setModalIsOpenToTrue2}>
        <ThumbUpIcon />
      </Button>
              {/* <Button onClick={onClick}>
        <ThumbDownIcon />
      </Button> */}
            </>
          );
        },
      },
    ];
    return (
      <>
        <div>
            <div className="bannermain">
            { !isShown && (
                <div className="container">
                    <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
                        Domestic Patent <Button className="addbtn" onClick={handleClick}>Apply for Domestic Patent</Button> <Button className="addbtn" onClick={setModalIsOpenToTrue}>View Payment Details </Button>
                    </h2>
                    <div style={{ height: "75vh", width: "100%" }}>
            
            <DataGrid
              className="pb-3"
              rows={rows}
              columns={columns}
               pageSize={8}
              //  onSelectionModelChange={(newSelection)=>{
              //   setrowId(newSelection[0])
              //  }}
               components={{ Toolbar: GridToolbar
                 }}
              
                          />
          </div>
                </div>
                )}
                {isShown &&(
                  <ViewApplyDomestic/>
                  )}
                

            </div>
        </div>
        <div>
         <Modal isOpen={modalIsOpen} className="ModOverlay">
         <div className="viewMod">
           <div className="d-flex justify-content-end">
             <Button onClick={setModalIsOpenToFalse}>
               <CloseIcon />
             </Button>
           </div>
           <ViewPayment/>
         </div>
       </Modal>
       </div>
       </>
    )
}
