import React, { useState, useEffect } from 'react'
import Table1 from "../../assets/images/Initiative/financialAssistance/Table1.png"
import Table2 from "../../assets/images/Initiative/financialAssistance/Table2.png"
import Pdf from "../../assets/images/Initiative/financialAssistance/230548MSInS- IPR.pdf"
import StartupGuideline from "../../assets/images/Initiative/financialAssistance/MSInS_Startup_Registration.pdf"
import IntellectualExternalLink from './IntellectualExternalLink'
import { environment } from '../../api'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function IntellectualPRMiddleContent() {
  const loginUrl = environment.LoginLink
  const [dropdownState, setDropdownState] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('')

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState)
  }
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value)
    setDropdownState(!dropdownState)
  }

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
    {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be Redirected to existing Portal to access your account.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="primary"  target="_blank" href={loginUrl} >
           Ok
          </Button>
        </Modal.Footer>
      </Modal> */}


    <div className="mahagranddropdown d-lg-none d-xl-none d-md-block d-sm-block" id='mahadropdown'>
        <div className="container">
          <div className={`dropdown pt-2`}>
            <button onClick={handleDropdownClick} className="dropdown-btn">
              {dropdownValue === '' ? 'Menu' : dropdownValue}
            </button>
            <div
              className={`dropdown-items ${dropdownState ? 'isVisible' : 'isHidden'
                }`}
            >
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Scheme Description')}
                >
                  <a href="#idScheme">1. Scheme Description</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Eligibility')}
                >
                  <a href="#idEligibility">2. Eligibility Criteria</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() =>
                    handleSetDropdownValue('Funding Support')
                  }
                >
                  <a href="#idFunding">3. Funding Support</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Summary of Support')}
                >
                  <a href="#idSummary">4. Summary of Support</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Application Process')}
                >
                  <a href="#idAppProcess">5. Application Process</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Documents/Information')}
                >
                  <a href="#idDoc">6. Documents/Information</a>
                </div>
              </div>
              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Resources about IPR')}
                >
                  <a href="#idResourcesIPR">7. Resources about IPR</a>
                </div>
              </div>

              <div className="dropdown-item">
                <div
                  className="dropdown__link"
                  onClick={() => handleSetDropdownValue('Resources about IPR')}
                >
                  <a href="#idQualityTesting">8. How to Apply</a>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="mahaGrand-main intellectual-main1">
        <div className="row">
          <div className="col-md-12 title-align pb-3">
            <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
              Intellectual Property Rights Scheme
            </h2>
          </div>
        </div>

        <div className="container">
          <div className="row d-flex justify-content-center my-3">
            <div className="col-lg-3 col-md-3 col-sm-3 d-sm-none d-md-none d-lg-block justify-content-center">
              <div className="card col-lg-10 col-md-10 col-sm-10 apply-head border-warning border-2">
                <div 
                // onClick={handleShow1} 
                className="card-body d-flex justify-content-center  align-items-center cursor-pointer px-0">
                  <div className="col-lg-12 col-md-12 col-sm-12 px-0">
                    <span className="regular_font font-18-23 font-style-normal font-weight-400 ">
                    Intellectual Property Rights
                    </span>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <span className="Arrow-apply align-items-end">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20px"
                        width="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </div> */}
                </div>
                <div className="list-group list-group-flush">
                  <a
                    href="#idScheme"
                    className="list-group-item list-group-item-action"
                  >
                    <span className="regular_font font-18-23 font-style-normal font-weight-400">
                      Scheme Description
                    </span>
                  </a>
                  <a
                    href="#idEligibility"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Eligibility Criteria
                    </span>
                  </a>
                  <a
                    href="#idFunding"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Funding Support
                    </span>
                  </a>
                
                  <a
                    href="#idDoc"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Documents/Information
                    </span>
                  </a>
                  <a
                    href="#idResourcesIPR"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <span className="regular_font font-18-22 font-style-normal font-weight-400">
                      Resources about IPR
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-7">

            {/* <IntellectualExternalLink /> */}
              <div className="row" id="idScheme">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Scheme Description
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                Intellectual property (IP) refers to intangible property, such as inventions, literary,
                 artistic works, designs, symbols, names, images, etc. used in commerce. IP laws aim at
                  safeguarding creators and other producers of intellectual goods and services by
                   granting them certain time-limited rights to control the use made of those productions
                    In order to support startups protect and effectively commercialise their technologies 
                    and original work, Maharashtra State Innovation Society (MSInS) has launched the
                     Intellectual Property Rights (IPR) Scheme. In this scheme, financial assistance will
                      be provided to startups to protect their proprietary technology in competitive 
                      domestic and global markets. Following Intellectual Property Rights (IPR) filings 
                      will be supported under this scheme:
                  <ul className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">Patent</li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">Industry Design Patent</li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">Trademark</li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">Copyright (limited to computer code)</li>
                  </ul>
                </p>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                Under this scheme, MSInS is supporting reimbursement for granted patent/IPR as mentioned above. (Both domestic and international)
                 
                    <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    Startups may choose to get the filing done through an agency/IP firm of their choice. They can avail the reimbursement benefit one the IP is granted by submitting the required
                     details such as official fee and professional fee invoices among other details.
                    </p>
                </p>
              </div>
              <div className="row" id="idEligibility">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Eligibility Criteria
                </h2>
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  In order to be eligible for benefits under the IPR Scheme, a
                  startup should meet following norms:

                  <ul>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                      <b>DPIIT Recognised</b> : DPIIT Recognised: Startup should be recognised by the Department for Promotion of Industry and Internal Trade (DPIIT), Government of India. 
                    </li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                      <b>Maharashtra incorporated</b> : Startup should be incorporated in Maharashtra
                    </li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                      <b>Revenue Cap</b> :
                      <ol type="a">
                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                        For domestic applications, startup’s cumulative lifetime revenue (since inception) should be less than INR 5 crore
                        </li>
                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                        For international applications, startup’s cumulative lifetime revenue (since inception) should be less than INR 100 crore
                        </li>
                      </ol>
                    </li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                      <b>Application Date</b>: ​ The IPR application should be granted post issue of IPR Scheme (i.e. 18th September 2019)
                    </li>
                  </ul>
                </p>
              </div>
              <div className="row" id="idFunding">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                  Funding Support
                </h2>
                <p>
                  <ul className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    Maximum INR 2 Lakhs will be supported for domestic IP application (per start-up, covering all forms of IPR).
                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    Maximum INR 10 Lakhs will be supported for international IP application (per startup, covering all forms of IPR)
                        </li>
                    </li>
                  </ul>
                  <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">The reimbursement will be done 100% as per the qualifying invoices excluding tax up to the cap of INR 2 and 10 lakhs respectively. However, the cap for out-of-pocket expenses per startup will be INR 20,000.</p>
                </p>
              </div>
              {/* <div className="row">
                <p className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                  Foreign national, team, institution, companies and
                  organisation shall abide by the rules and regulations of
                  Government of India and Government of Maharashtra. They also
                  must fulfil all the tax norms prescribed by the Government of
                  India and Government of Maharashtra.
                </p>
              </div> */}
              <div className="row" id="idSummary">
                <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 pb-2">
                Documents/Information Required to Apply for IPR Scheme
                </h2>
                <p>
                  
                    In order to complete the application, you will need the following documents:
                      <ul>
                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                        	Company Incorporation Certificate
                        </li>
                        <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                        Audited Financial statements (since inception) or a CA declaration on the cumulative funding and revenue since inception till application date with MSInS.
                        </li>
                      
                    
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    	DPIIT Recognition Certificate
                    </li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                      	Granted Patent/design or registered Trademark/copyright certificate
                    </li>
                    <li className="font-style-normal font-weight-400 font-18-22 font-14-17 justify">
                    	A self-declaration confirming that you have not received any funding for the patent for which you are claiming reimbursement. 
                    </li>
                    </ul>
                </p>
                <img src={Table1} className="img-fluid">
                </img>
                <div className='pt-3'>
                  <h2 className="font-22-26 font-18-23 font-20-26 font-weight-600 pb-2 externallinkcolour">Apply here :</h2>
             Get started with the application here to avail the benefit : <a href="https://forms.gle/zqocmHfXGnNUNrSx6" target="_blank">Application Link</a>
             </div>
              </div>
             </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntellectualPRMiddleContent;
