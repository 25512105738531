import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// import "./Header.css";

// import Navigation from "../Navigation/Navigation";
import Navigation from "./NavigationAdmin";
// import Nav1 from './nav1';

export default function HeaderAdmin() {
  const [formData, setFormData] = useState("");
  const path = useLocation().pathname;
  const location = path.split("/")[1];

  return (
  <>
    
    <div className={"headerAdmin px-3"}>
      <Navigation />
      {/* <Nav1/> */}

      {/* <div className="header-content">
        {location === "home" ? (
          <>
            <h2>This h2 in home page only</h2>
            <input
              type="text"
              value={formData}
              placeholder="Input in home..."
              onChange={e => setFormData(e.target.value)}
            />
          </>
        ) : (
          <h4>{location}</h4>
        )}
      </div> */}
      
    </div>
  </>
  );
}
