import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";
import { environment } from '../api';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from '../startup/informationIcon';
import AddMember from "../assets/images/Incubator/Add.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const serverUrl = environment.baseUrl;
const Investor = (props) => {
  const authToken =sessionStorage.getItem("token");
   console.log(JSON.parse(sessionStorage.getItem('user_id')))

   let initialFormObj={ 
    // investor_name: '',
      firm_name: '',
      // phone_number: '',
      // email: '',
      linkedIn_url: '',
      website_url: '',
      choice_startup_stage_invest: '',
      invest_capacity: '',
      portfolio_desc: '',
      sector: '',
      reg_address:'',
      district: '',
      city: '',
      success_stories: '',
      upload_logo: '',
    
        relevant_doc: [{
          relevantDoc:''
          }]
       }

       
    const [users, setUsers] = useState([]);
    const [sector, setSector] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
      fetch(serverUrl + "/sectors/allSectors")
        .then(res => res.json())
        .then(
          (data) => {
            setIsLoaded(true);
            setUsers(data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, []) 
  
    const handleSector = (values) => {
      const getSector = values.target.value;
      setSector( getSector);
  }


  const [userstate, setUserstate] = useState([]);
  const [state, setState] = useState();
  useEffect(() => {
    fetch(serverUrl + "/stateList/allState")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUserstate(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []) 

  const handleState = (values) => {
    const getState = values.target.value;
    setSector(getState);
}


const [userdistrict, setUserDistrict] = useState([]);
const [district, setDistrict] = useState();
useEffect(() => {
  fetch(serverUrl + "/districtList/allDistricts")
    .then(res => res.json())
    .then(
      (data) => {
        setIsLoaded(true);
        setUserDistrict(data);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
}, []) 

const handleDistrict= (values) => {
  const getState = values.target.value;
  setDistrict(getState);
}

const userId = sessionStorage.getItem("_id");
     const [usersinvestor, setusersinvestor] = useState([]);
     useEffect(() => {
       fetch(serverUrl +`/auth/userdetail/${userId}`,
       {headers: {
        "auth-token":authToken
        
      }}
       )
         .then(res => res.json())
         .then(
           data => {
             setIsLoaded(true);
             console.log(data, "testuuuuuuuuuuuuuuuuuuuuuuuuuuu");
             setusersinvestor(data);
           },
           error => {
             setIsLoaded(true);
             setError(error);
           }
         );
     }, []);

     
  function handleSubmitInvestorForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    // formData.append("investor_name",values.investor_name)
    formData.append("firm_name",values.firm_name)
    // formData.append("phone_number",values.phone_number)
    // formData.append("email",values.email)
    formData.append("linkedIn_url",values.linkedIn_url)
    formData.append("website_url",values.website_url)
    formData.append("choice_startup_stage_invest",values.choice_startup_stage_invest)
    formData.append("invest_capacity",values.invest_capacity)
    formData.append("portfolio_desc",values.portfolio_desc)
    formData.append("sector",values.sector)
    formData.append("reg_address",values.reg_address)
    formData.append("state",values.state)
    formData.append("district",values.district)
    formData.append("city",values.city)
    formData.append("success_stories",values.success_stories)
    formData.append("upload_logo",values.upload_logo)
    formData.append("relevantDoc",values.relevantDoc)

    fetch(serverUrl +"/investor/addinvestorDetail", 
         {
            method: "POST",
            body: formData,
            headers: {
              "auth-token":authToken
            },
            // body:JSON.stringify(values, null, 2)
            
          })
          .then((response) => {
            sessionStorage.type = ''
            console.log("RESPONSE", response)
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("applicationId", response.data.applicationId);
             console.log("roleee",  response.data.applicationId);            
          })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      })
      //  props.setValue(props.value-1)
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Submitted Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
         

          // setMessage(`thank you  for your message`);
          
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
   
    <Formik
      onSubmit={(values
      ) =>
      handleSubmitInvestorForm(values)
    }
    validationSchema={Yup.object({
    investor_name: Yup.string().required('Investor’s Name is required'),
    firm_name: Yup.string().required('Firm Name is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    linkedIn_url: Yup.string().required('LinkedIn URL is required'),
    website_url: Yup.string().required('Website URL is required'),
    choice_startup_stage_invest: Yup.string().required('Choice of startup stage for Investing is required'),
    invest_capacity: Yup.string().required('Investing Capacity is required'),
    portfolio_desc: Yup.string().required('Portfolio Description is required'),
    sector: Yup.string().required('Interested Sector  is required'),
    reg_address: Yup.string().required('Registration Address  is required'),
    state: Yup.string().required('State is required'),
    district: Yup.string().required('District is required'),
    city: Yup.string().required('City is required'),   
    success_stories: Yup.string().required('Success Stories is required'),
    upload_logo: Yup.string().required('Upload Logo is required'),
       
        relevant_doc: Yup.array().of(
          Yup.object().shape({
            relevantDoc: Yup.string().required('Relevant Documnet is required'),
           
              
          })
        )
           
       })}

       initialValues={JSON.parse(sessionStorage.getItem('user_id'))||initialFormObj} 

      render={({ values, setFieldValue, handleChange }) => (
        <Form >
        <div className="row pb-3">
            <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
            Investor Details
            </h2>
        </div>

        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Investor’s Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Investor’s Name" disabled readOnly={true} value={usersinvestor.first_name} className="textInputDisable" name="investor_name" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="investor_name" /><br></br>
                    </div>                    */}
                  </div>
                 
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                    E-Mail Address  <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="email" placeholder="Enter Email" className="textInputDisable" disabled readOnly={true} value={usersinvestor.email} name="email" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="email" /><br></br>
                    </div> */}
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Phone Number <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="tel" placeholder="Enter Phone number" pattern="[789]{1}[0-9]{9}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" minlength="10" disabled readOnly={true} value={usersinvestor.mobile_no} className="textInputDisable" name="phone_number" />
                    {/* <div className="text-danger">
                    <ErrorMessage className="error" name="phone_number" /><br></br>
                    </div> */}
                  </div>
                  <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                   Firm Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Firm Name" className="textInput" name="firm_name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="firm_name" /><br></br>
                    </div>                   
                  </div>
                </div>
                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd pt-5">
                Location of Registeration
                </h2>
                <div className="row">
                  <div className="col-md-12 pt-4">
                    <label className="pb-2 label-txt">
                      Registration Address<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="reg_address" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="reg_address" /><br></br>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 pt-4">
                  <label className="pb-2 label-txt">
                      State <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                  <select type="select" name="state" className="form-control form-select size"
                    value={values.state}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("state", e.target.value)
                      handleState(e)
                    }}
                  >
                    <option value={""}>Select State</option>
                      {
                        userstate.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="state" />
                    </div>
                  </div>
                  <div className="col-md-4 pt-4">
                  <label className="pb-2 label-txt">
                      District<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                     <select type="select" name="district" className="form-control form-select size"
                    value={values.district}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("district", e.target.value)
                      handleDistrict(e)
                    }}
                  >
                    <option value={""}>Select District</option>
                      {
                        userdistrict.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="district" />
                    </div>
                  </div>
                  <div className="col-md-4 pt-4">
                  <label className="pb-2 label-txt">
                  City <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter City" className="textInput" name="city" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="city" /><br></br>
                    </div>
                  </div>
                  </div>

                  <div className="row">
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Website URL
                      <InformationIcon />
                    </label>
                       <Field type="text"  placeholder="Enter website URL" className="textInput" name="website_url" />
                       <div className="text-danger">
                      <ErrorMessage className="text-danger" name="website_url" />
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    LinkedIn URL
                      <InformationIcon />
                    </label>
                    <Field type="text"   placeholder="Enter LinkedIn URL" className="textInput" name="linkedIn_url" />
                       <div className="text-danger">
                      <ErrorMessage className="text-danger" name="linkedIn_url" />
                  </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                      Interested Sector<span className="text-danger">*</span>{" "}
                      <InformationIcon />
                    </label>
                  <select type="select" name="sector" className="form-control form-select size"
                    value={values.sector}
                    onChange={(e) => {
                      handleChange(e);
                      // console.log(e.target.value, "87777777777777777777777777777777777")
                      // setSector("sector", e.target.value)
                      handleSector(e)
                    }}
                  >
                    <option value={""}>Select Interested Sector</option>
                      {
                        users.map((user) => {
                              return (
                                  <option key={user._id} value={user._id}> {user.name} </option>
                              )
                          })
                      }
                  </select>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="sector" />
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Choice of startup stage for Investing
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="select" className="form-select" name="choice_startup_stage_invest">
                    <option selected>Select Stage</option>
                      <option value="Idaetion">Ideation</option>
                      <option value="Scaling Up"> Scaling Up</option>
                      <option value="Mature">Mature</option>
                    </Field>
                    <div className="text-danger">
                      <ErrorMessage className="text-danger" name="choice_startup_stage_invest" />
                    </div>

                  
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Investing Capacity
                      <InformationIcon />
                    </label>
                    <Field type="text"     placeholder="Enter investing capacity " className="textInput" name="invest_capacity" />
                       <div className="text-danger">
                      <ErrorMessage className="text-danger" name="invest_capacity" />
                  </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Portfolio Description
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="portfolio_desc" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="portfolio_desc" /><br></br>
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <label className="pb-2 label-txt">
                    Success Stories 
                     
                      <InformationIcon />
                    </label>
                    <Field as="textarea" placeholder="Enter Address"  className="textInput" name="success_stories" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="success_stories" /><br></br>
                  </div>
                    </div>               
                    <div class="col-lg-6 col-md-6 pt-4">
                    <label className="label-txt">
                    Upload Logo
                     
                      <InformationIcon />
                    </label>
                    <div class="form mt-1 ml-5 pl-5 col-lg-12 col-md-12">
                      <div class="btn float-left imgbackground form-control">
                 <input id="file" name="upload_logo" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue("upload_logo", e.target.files[0]);
                      }} /> 
                      </div>
                      <div className="text-danger">
                          <ErrorMessage className="text-danger" name="upload_logo" />
                           </div>
                    </div>
                  </div>
                  </div>
                  
          <FieldArray
            name="relevant_doc"
            render={(arrayHelpers) => (
              <div>
                <div className="d-flex justify-content-end mb-3">
                     <span className="add-btn-text label-txt">
                        <img
                        src={AddMember}
                        className="img-fluid"
                        alt="Add button"
                        onClick={() => arrayHelpers.push("")}
                        ></img>
                        &nbsp;&nbsp;&nbsp;
                        Add member
                        &nbsp;&nbsp;&nbsp;
                    </span>
                </div>



                <TableContainer component={Paper} className="no-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="head-bg-color">
            <TableRow className="d-flex justify-content-between ">
              <TableCell className="table-txt align-items-center"  style={{width:"90%"}}>Upload relevant documents</TableCell>
              <TableCell className="table-txt align-items-center "  style={{width:"10%"}} align="left">Action </TableCell>             
            </TableRow>
          </TableHead>

                {values.relevant_doc && values.relevant_doc.length > 0 ? (

                  values.relevant_doc.map((friend, index) => (

                    <TableBody  className="data-bg-color d-flex justify-content-between">

              <TableRow  key={index}  className="w-100 d-flex justify-content-between" >
              <TableCell align="center" className="d-flex align-items-center" style={{width:"90%"}}>
              <input id="file" name={`relevant_doc.${index}.relevantDoc`} accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                        setFieldValue(`relevant_doc.${index}.relevantDoc`, e.target.files[0]);
                      }} /> 
                     <div className="text-danger">
                          <ErrorMessage className="text-danger"  name={`relevant_doc.${index}.relevantDoc`} /><br></br>
                        </div>
             </TableCell>
              <TableCell align="center" className="d-flex align-items-center" style={{width:"10%"}}>
                <span onClick={() => arrayHelpers.remove(index)}// remove a friend from the list
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="13.125" fill="#174984"/>
                  <path d="M7.875 20.125L14 14L20.125 7.875M20.125 20.125L7.875 7.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span>
              </TableCell>  
              </TableRow>
              </TableBody>  
                  ))
                ) : (
                 <></>
                )}
                <div className="justify-content-center d-flex pt-4">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-draft no-shadow" 
                          onClick={()=>{sessionStorage.setItem("user_id", JSON.stringify(values))}} 
                        >
                          Save as Draft
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="btn-color-next no-shadow"
                        >
                          Save &amp; Next
                        </Button>
                      </Stack>
                    </div>
                </Table>
      </TableContainer>

              </div>
            )}
          />
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default Investor;
