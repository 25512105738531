import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Startuppostgrantymod(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab className="txt-main-style" label="Basic Details" {...a11yProps(0)} />
          <Tab className="txt-main-style" label="Incorporation Details" {...a11yProps(1)} />
          <Tab className="txt-main-style" label="Founder Details" {...a11yProps(2)} />
          <Tab className="txt-main-style" label="Point of Contact for IPR filings" {...a11yProps(3)} />
          {/* <Tab className="txt-main-style" label="Patent Details" {...a11yProps(4)} />*/}
          <Tab className="txt-main-style" label="Revenue Details" {...a11yProps(4)} />
          {/*<Tab className="txt-main-style" label="Funding" {...a11yProps(6)} />
          <Tab className="txt-main-style" label="Current statistics" {...a11yProps(7)} /> */}
          <Tab className="txt-main-style" label="Revenue Details(Last 3 financial year from the date of appliaction)" {...a11yProps(5)} />
          <Tab className="txt-main-style" label="DPIIT Registration Details" {...a11yProps(6)} />
          <Tab className="txt-main-style" label="Patent Application Form" {...a11yProps(7)} />
          <Tab className="txt-main-style" label="Financial Information " {...a11yProps(8)} />
          <Tab className="txt-main-style" label="Legal Information Relating To Invention " {...a11yProps(9)} />
          <Tab className="txt-main-style" label="Application Action" {...a11yProps(10)} />
         
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> Name of Company :</b>
              </label>
              <span> Vaibhav</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Is your Company registered in Maharashtra:</b> 
              </label>
              <span> No</span>
              </div>
            <hr></hr>
            </div>
          </div>
       

        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Website URL :</b>
                <span> vaibhav@VPsolutions.com</span>
              </label>
              </div>
            <hr></hr>
            </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload Proof of being incorporated in Maharashtra:</b> <span> 9876543210</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Registered Address as specified  on incorporation Certificate:</b> <span> Mayuri</span>
              </label>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Communication Address:</b> <span> Mayuri</span>
            </label>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Short brief about the Company:</b> <span> Mayuri</span>
              </label>
            </div>
            <hr></hr>
          </div>
          
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Firm Registration Date:</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.name_of_startup}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Type of entity:</b>
            </label>
            <span className="text-justify">
              {/* {props.rowData.basicDetails.product_name} */}
            </span>
          </div>
          <hr></hr>
          </div>
          </div>
          
        
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Designation :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Contact :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Email :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Linkedin Link :</b>{" "}
               
              </label>
              <span> {props.rowData.basicDetails.biz_model_type}</span>
            </div>
            <hr></hr>
          </div>
          
        </div>
      
        
        
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Pitch Deck :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pitch uploadsDetails :</b>
            </label>
            <span> 06/09/2022</span>
            </div>
            <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Other Description :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Explainer Video :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Roadblocks :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
         
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Awards/Achievements :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Source of information :</b>{" "}
               
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Please specify Source of information :</b>{" "}
             
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>First Name :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Last Name :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>E-mail Address :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Phone Number :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Address for Communication:</b>{" "}
               
              </label>
              {/* <span> {props.rowData.basicDetails.biz_model_type}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Parmanant Address:</b>{" "}
               
              </label>
              {/* <span> {props.rowData.basicDetails.biz_model_type}</span> */}
            </div>
            <hr></hr>
          </div>
          
        </div>
      
         
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Financial year :</b> 
              </label>
              {/* <span> {props.rowData.uploadsDetails.company_logo}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Audited Financial Number :</b> 
            </label>
            {/* <span> {props.rowData.uploadsDetails.company_PAN_card}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Proof(Audited Statment from CA) :</b>{" "}
                
              </label>
              {/* <span> {props.rowData.uploadsDetails.company_PAN_card}</span> */}
            </div>
            <hr></hr>
          </div>
          
       </div>
        
        
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Company Registration ID Number :</b>{" "}
               
              </label> */}
              {/* <span> {props.rowData.uploadsDetails.company_registration_ID}</span> */}
            {/* </div>
            <hr></hr>
          </div> */}
          {/* <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>DPIIT Number :</b> 
            </label>
            <span> {props.rowData.uploadsDetails.}</span>
          </div>
          <hr></hr>
        </div> */}
        {/* </div> */}
        {/* <div className="row"> */}
          {/* <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>DPIIT Certificate :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div> */}
          {/* <div className="col-md-6">
          <div className="">
            <label>
              {" "} */}
              {/* <span> {props.rowData.uploadsDetails.relevant_doc}</span> */}
            {/* </label> */}
            {/* <span> 06/09/2022</span> */}
          {/* </div>
          <hr></hr>
        </div>
        
        </div> */}
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Have you patented any product? :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Product Name :</b> 
              <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patenting description :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Patent No :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patented In :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Patenting Authority :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
        </div>
        
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Patent Validity :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Upload Patent Certificate :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
          </div>
        </div>
      </TabPanel> */}
      <TabPanel value={value} index={4}>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Targeted customer segment :</b>{" "}
                
              </label>
              <span> {props.rowData.modelDetails.company_registration_ID}</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Market Size :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Value Proposition :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Competitors :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div> */}
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b> maximum turnover of any of the previous Three finantial years :</b>{" "}
                
              </label>
              {/* <span> {props.rowData.modelDetails.startup_current_stage}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Current finantial status of the company :</b> 
            </label>
            {/* <span> {props.rowData.modelDetails.uniqueness_factor}</span> */}
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={6}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Revenue Inflow :</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Pricing Model :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Annual gross revenue :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Annual Gross expenditure :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Margin (%):</b> 
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Customer acquisition :</b> <span> 06/09/2022</span>
            </label>
          </div>
          <hr></hr>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Total contract value :</b>{" "}
                
              </label>
              <span> http://sivigeoinfra.com</span>
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Total addressable market :</b> 
            </label>
            <span> 06/09/2022</span>
          </div>
          <hr></hr>
        </div>
        </div>
      </TabPanel> */}
      <TabPanel value={value} index={6}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>DPIIT No :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Uploaded DPIIT certificate :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Have you taken benefit of this schema before?  :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>How did you come to know about this scheme?:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
     </TabPanel>
     <TabPanel value={value} index={7}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Date of grant of the invention  :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Jurisdiction of the grant of patent:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>patent Registration No :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>country of the Grant:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Please give a corresponding Indian patent Appliaction No :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Please explain the reason for applying in the above country .How does it help your startup? How is it part of your business model? :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      
      
        
      
         
      </TabPanel>
      <TabPanel value={value} index={8}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Total Expenditure Incurred  :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Professional fees:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Official (Government) fees:</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
           
          </div>
          
         
      </div>
      <h5>Govt Invoice Detail</h5>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name of the document :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Invoice :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
          
      </div>
      <h5>Professional Invoice Details</h5>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>Name of the document :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>Professional Invoice:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
          
      </div>
      
        
      
         
      </TabPanel>
      <TabPanel value={value} index={9}>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>When did you first conceive the idea and begin working on your invention? :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.company_address}</span> */}
            </div>
            <hr></hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>What is current development stage of your invention?:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.state}</span> */}
          </div>
          <hr></hr>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>If ready for commercial launch,please mention the projected launch date:</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>please provide the details of production if any:</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>please provide approximate date of commerical production :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          <div className="col-md-6">
          <div className="">
            <label>
              {" "}
              <b>is the invention going to be a joint development between your Institute and any other third party
              (sponsors co-investigator)? :</b> 
            </label>
            {/* <span> {props.rowData.basicDetails.city}</span> */}
          </div>
          <hr></hr>
          </div>
      
      </div>
      <div className="row">
          <div className="col-md-6">
            <div className="">
              <label>
                {" "}
                <b>if YES Please provide details of the third party and the nature of its contribution to the invention :</b> 
              </label>
              {/* <span> {props.rowData.basicDetails.district}</span> */}
            </div>
            <hr>
            </hr>
          </div>
          
      
      </div>
      
      
        
      
         
      </TabPanel>
      <TabPanel value={value} index={10}>
      <table class="table table-bordered mt-5">
                <thead>
                  <tr>
                    <th className="justify-content-center d-flex">Sr No.</th>
                    <th scope="col">Action Taken By</th>
                    <th scope="col">Status</th>
                    
                    <th scope="col">Comment</th>
                    <th scope="col">Date</th>
                    <th scope="col">Uploaded Report</th>
                  </tr>
                </thead>
                {/* {users.map((user,num) => ( */}
                <tbody>
                  <tr>
                    <th scope="row" className="justify-content-center d-flex">
                      1
                    </th>
                    <td> Process Partner </td>
                    <td> </td>
                    
                    <td> </td>
                    <td>02-11-2021 16:00</td>
                    <td>Rejected portal Uploaded</td>
                  </tr>
                  
                  
                  
                 
                </tbody>
               
              </table>
      
      
        
      
         
      </TabPanel>
      
     
    </Box>
  );
}

export default Startuppostgrantymod;