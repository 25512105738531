import React, { useState, useEffect }  from 'react';
import {Link} from "react-router-dom";
import Slider from 'react-slick';
import { Col, Row } from 'react-bootstrap-v5';
// import Card1 from '../assets/images/about/card1.png';
// import Card2 from '../assets/images/about/card2.png';
// import Card3 from '../assets/images/about/card3.png';
import Lefticon from '../assets/images/about/lefticon.png';
import Righticon from '../assets/images/about/righticon.png';
import { environment } from '../api';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';



var slider;

const styles = {
  prev_arrow: {
    // transform: 'rotate(-180deg)',
    cursor: 'pointer',
    '&:hover': {
      border: '3px solid #174984',
    },
  },
  next_arrow: {
    cursor: 'pointer',
    '&:hover': {
      border: '3px solid #174984',
    },
    // transform: 'rotate(-180deg)',
  },
};

const FeaturedVideos = () => {
  const serverUrl = environment.baseUrl;
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    fetch(serverUrl +"/video/allvideo")
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setUsers(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
  }, [])

  function next() {
    slider.slickNext();
  }
  function previous() {
    slider.slickPrev();
  }
  const settings = {
    dots: true,
    arrows: true,
    centerMode: true,
    infinite: true,
    centerPadding: '10px',
    Padding: '10px',
    marginBottom: '20px',
    slidesToShow: 3,
    slidesToScroll: 3,
    className: 'center',
    initialSlide: 0,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          centerPadding: '60px',
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10px',
        },
      },
    ],
  };


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
  // let timeOfDayColor = 'blue';
  // const [bgColor, setBGColor] = useState({background});
  return (
    // <div className="py-2 fea">
    //  <h2 className='font-28-36  font-20-26 font-style-normal font-weight-600 d-flex  justify-content-center pt-2 pb-4'>Announcements</h2>
      
     
     <div className="py-2" style={{backgroundColor:'#fff'}}>
     <h2 className='font-28-36 font-18-23 font-20-26 font-weight-600 d-flex justify-content-center pb-2 '>Featured Videos</h2>
      
       <div className="">
      <Row className="d-flex hidden-md-up">
        <Slider ref={(c) => (slider = c)} {...settings}>

        {users.map(user => (
      <div className="">
          <div className="feacturedVd-card">
          <Row className="d-flex align-items-center ">
          <Col lg={12} md={12} sm={12} xs={12} className="px-5">


          {/* <video id="videoPlayer" width="650" controls muted="muted" autoplay>
        <source src={user.video} 
        // src="/video" 
        type="video/mp4" />
      </video> */}












          {/* {users.video=== 'path' ?  
        <video width="750" height="500" controls >
      <source src={user.video} type="video/mp4"/>
     </video>
:  <iframe 
// src={user.video}  
width="100%" height="100%"
src={`https://www.youtube.com/embed/${user.video}`}  > 
  
 </iframe>  } */}


           {/* <h1>video : {user.video}</h1> */}
              <iframe 
              width="100%" height="100%"
            src={`https://www.youtube.com/embed/${user.video}`}  > 
                
               </iframe> 

{/* 
               <div>
      {video ? (
        
        <>
        </>
      ) : (
        <LoginButton onClick={this.handleLoginClick} />
      )}
    </div> */}
             
          </Col>
          </Row>
          </div>
        </div>
        ))}
   </Slider>
  </Row>
 </div>
 <div className="container d-flex justify-content-between">
      <img
        src={Lefticon}
          alt="slider arrow"
          style={styles.prev_arrow}
          onClick={previous}
        />
        <img
        src={Righticon}
          alt="slider arrow"
          style={styles.next_arrow}
          onClick={next}
        />
 </div>      
</div>
  );
};

export default FeaturedVideos
