import React, { useState, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import skilllogo from '../assets/images/login/skill-logo.png'
import loginlogo from '../assets/images/login/Login.png'
import loginMoboImg from '../assets/images/login/Login.png'
import styles from '../../src/assets/scss/component/register.scss'

// import ForgetPW from './ForgetPW'
import {useParams} from "react-router-dom"
import apple from '../assets/images/login/Apple.png'
import facebook from '../assets/images/login/facebook.png'
import Windows from '../assets/images/login/Windows.png'
import Slack from '../assets/images/login/Slack.png'
import Gmail from '../assets/images/login/gmail.png'
import Recaptcha from 'react-recaptcha'
import { Button } from 'react-bootstrap-v5'
import ReCAPTCHA from 'react-google-recaptcha'
// import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
// import { Redirect } from 'react-router-dom'
import { environment } from '../api'
// import StartUp from '../startup/LandingMain'
import validate from './LoginFormValidationRules';
// import GoogleLogin from "./GoogleLogin";

window.recaptchaOptions = {
  useRecaptchaNet: true,
}



function Login(callback, props) {
 

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStatus, setLoggedIn] = useState();


  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);
  const serverUrl = environment.baseUrl
  let navigate = useNavigate()

  const recaptchaRef = React.useRef()
  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync()
  }
  const [user, setUser] = useState({ email: '', password: '' })
  const { email, password } = user // destructing

  const [error, setError] = useState("");
	const [msg, setMsg] = useState("");

  const handleChange = (e) => {

    setUser({ ...user, [e.target.name]: e.target.value })
  }
  const [isShown, setIsShown] = useState(false);
  const [id, setuserId] = useState();

  const handleClick = event => {
    setIsShown(current => !current);
  };
  const captchaRef = useRef(null)
  // const [isShown1, setIsShown1] = useState(false);

  // const handleClick1 = event => {
  //   setIsShown1(current => !current);
  // };
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    console.log("Handle Submit")
    setErrors(validate(user));
    setuserId(user);
    setIsSubmitting(true);

    axios
      .post(serverUrl + '/auth', user)
      .then((response) => {
        sessionStorage.type = ''
        console.log("RESPONSE", response)
        sessionStorage.setItem("user", JSON.stringify(response.data.data));
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem('refresh_token', response.data.data.refresh);

        sessionStorage.type = response.data.data.type
        sessionStorage.role = response.data.data.role
        sessionStorage._id = response.data.data._id

        const isStatus = response.data.data.isStatus;
        const email = response.data.data.email;

        //id =response.data.data._id
        // alert("startup vsisble", userId);
        // sessionStorage._id == userId  &&
        // sessionStorage._id == '62cd75b31ddd86e5b4e90fb1' && 
        console.log("roleee", response.data.data.role);
        console.log("sdfghjwswfdegthyjyuj", response.data.data._id);
        console.log("kkkkkkkkk", sessionStorage._id);
        
        // some code omitted for brevity
        console.log({isStatus})
        if (isStatus === "Inactive") {
          // write inactive logic here
          
          alert("Please Verify Admin Side")
          
          return;
          
        }
        
        alert("Form Submitted Successfully");
        if ( sessionStorage.type == 'Startup') {
          // navigate(`/personalinf/62cd75b31ddd86e5b4e90fb1`, { replace: true })
          navigate(`/Startup`, { replace: true })
        } else if (sessionStorage.type == 'Incubator') {
          {
            navigate('/Incubator_admin', { replace: true })
          }
        } else if (sessionStorage.type == 'Mentor' ) {
          {
            navigate('/Mentor_admin', { replace: true })
          }
        } else if (sessionStorage.type == 'Investor') {
          {
            navigate('/Investor_admin', { replace: true })
          }
        } else if (sessionStorage.type == 'NRI') {
          {
            navigate('/NRI_admin', { replace: true })
          }
        }
      })

      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      })
      
  }

  return (
    <>
      <div className="login">
        <span className="back-head gobacklogBtn d-lg-none ">
          <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            &nbsp;&nbsp;Go Back
          </Link>
        </span>

        <div className="loginMoboImg d-lg-none d-flex justify-content-center">
          <img className="loginImg" src={loginMoboImg} />
        </div>

        <div className="row">
          
            <div className="col-sm-12 col-md-12 col-lg-6 loginFormCol">
            
                <div className="">
                <div className="loginData ">
                  <span className="back-head gobackRegBtn font-18-22 d-lg-block d-none">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>
                    <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
                      <span className="gobackMoboSpace"> Go Back</span>
                    </Link>
                  </span>

                  <h5 className="py-3 font-28-36 font-20-26 loginTitle">
                    Login to your account
                  </h5>
                  <div className="container">
                    <div className="containMoboRegister">
                      <div className="row">
                        <div className='col-md-4 col-sm-3 col-4 col-lg-4 align-self-sm-center'>
                        <div className="registertxtImgPanel">
                          <div className="white-block ">
                            <p className="font-18-22 py-2 px-1 font-13-16">
                              Login With
                              {/* <div className='d-lg-none d-md-block d-sm-block'>
                              <Link to="https://www.startupindia.gov.in"> 
                            <img
                              className="
                           register-img pt-1 "
                              src={skilllogo}
                            />
                            </Link>
                            </div> */}
                            </p>
                            
                          </div>
                        </div>
                        
                        </div>
                        <div className='col-md-8 col-sm-9 col-8 col-lg-8 align-self-sm-center'>
                        <div className="registerLogopanel">
                          
                        <Link to="https://www.startupindia.gov.in"> 
                            <img
                              className="
                           register-img pt-1 "
                              src={skilllogo}
                            />
                            </Link>
                           
                        {/* <a href="https://www.startupindia.gov.in/"> */}
                          
                          {/* </a> */}
                        <Link to="/">
                            <img
                              className=" imgLoginIcons"
                              src={Gmail}
                            />
                             {/* <GoogleLogin /> */}
                          </Link>
                          <Link to="/">
                          <img
                            className="
                           imgLoginIcons"
                            src={Windows}
                          />
                          </Link>
                          <Link to="/">
                          <img
                            className="
                           imgLoginIcons"
                            src={apple}
                          />
                          </Link>
                          {/* <a href=""> */}
                          <Link to="https://www.facebook.com/MSInSociety">
                            <img
                              className="
                           imgLoginIcons"
                              src={facebook}
                            />
                          </Link>
                         
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-4">
                    
                      <form onSubmit={handleSubmit} method="POST">
                        <div className="containMoboRegister">
                          <div className="py-1">
                            <label
                              for="exampleInputEmail1"
                              className=" font-18-22 log-label"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control input-width  formInputMobile input-click my-1"
                              id="inputemail"
                              placeholder="Enter Email"
                              name="email"
                              value={user.email || ''}
                              onChange={handleChange}
                            />
                            {errors.email && (
                              <p className="help is-danger invalid-feedback ">{errors.email}</p>


                            )}
                          </div>

                          <div className="py-1">
                            <label
                              for="exampleInputEmail1"
                              className="font-18-22 log-label"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control input-width  formInputMobile  input-click my-1"
                              id="inputemail"
                              placeholder="Enter Password"
                              name="password"
                              value={user.password || ''}
                              onChange={handleChange}
                            />
                            {<errors className="password"></errors> && (
                              <p className="help is-danger invalid-feedback ">{errors.password}</p>


                            )}
                          </div>

                          <div className="py-2 col-sm-6">
                          <ReCAPTCHA
                            ref={captchaRef}
                              // ref={recaptchaRef}
                              sitekey="6LcpfFgjAAAAADx_jeWYdtPZM3d3-l-RXsZEjEU6"
                              // sitekey={process.env.REACT_APP_SITE_KEY}
                            />
                          </div>
                          <div className="col-sm-6"></div>
                          <div className="bluebtn py-2 d-none d-lg-block">
                          {/* <Link to={`/personalinformation/${userId}`}> */}
                          {error && <div className={styles.error_msg}><div className="text-danger p-1 mb-3 d-flex justify-content-left">{error}</div></div>}
					               	{msg && <div className={styles.success_msg}>{msg}</div>}
                            <Button className="btn-primary" type="submit">
                              {/* <Link to=""> */}
                              <span className="regular_font font-20-24 font-style-normal font-weight-400">
                                {' '}
                                Submit
                              </span>
                              <span className="inno-arrow">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 16 16"
                                  height="25px"
                                  width="35px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                  />
                                </svg>
                              </span>
                              {/* </Link> */}
                            </Button>
                            {/* </Link> */}
                          </div>

                          <div className="py-3 row">
                            <div className="col-sm-5 webLoginforgotLink">
                              <span className="">
                                {' '}
                                {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="20px"
                                width="20px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                ></path>
                              </svg> */}
                              </span>
                              <Link to="/forgot-password">
                              <a onClick={handleClick} className="pl-2 login-link link-hover  font-18-22 font-14-17 ">
                                Forgot Password
                              </a>
                              </Link>


                              <div className="col-sm-8 webLoginregisterLink">
                                <span className="reg font-18-22 font-14-17">
                                  Don't have an account?&nbsp;
                                  <Link to="/Register"><a className="login-link link-hover arrow-on-hover">
                                    Register
                                  </a></Link>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                       
                      </form>
                      
                    </div>
                  </div>
                  <div className="mobobluebtnpx loginbtnMobo">
                          <div className="mob-primarybtn  d-lg-none flex-item pt-4">
                            <Link to="">
                              <div className="d-flex justify-content-center align-items-center py-2">
                                <span className="regular_font font-18-22 font-style-normal font-weight-400">
                                  {' '}
                                  Login
                                </span>
                                <div className="inno-arrow">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 16 16"
                                    height="25px"
                                    width="35px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                </div>
                </div>
             
             
            </div>
          
          <div className="col-sm-6 ">
            <div className="d-lg-block d-md-none d-sm-none d-none">
              <img className="img-fluid loginImg" src={loginlogo} />
            </div>
          </div>
          
        </div>
        {/* </div> */}
        <div className="log-footer d-none d-sm-none d-md-none d-lg-block">
          <div className="curve-mask">
            <div className="curve-up ">
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
