import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { environment } from "../api";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InformationIcon from "../startup/informationIcon";
const serverUrl = environment.baseUrl;

const QualityTestingTab2 =(props)=> {
    let initialFormObj = {
      startup_name: "",
      product_certification:"",
      reimbursement:"",
      testing_lab:"",
      bis_nasl:"",
      bis_nabl_proof:"",
      testing_date:"",
      types_of_testing_certification:"",
      test_certificate:"",
      testing_certification_detail:'',
      amount_incurred:'',
      reimbursable_amount:'',
      approved_reimbursable_amount:'',
      upload_proof_amount_incurred:'',
      Sectors:'',
      audited_financial_statement:'',

      };
    
      function handleSubmitForm(values) {
        console.log(values, "hii");
        const formData = new FormData();
        formData.append("do_you_have_IP", values.do_you_have_IP);
        fetch(serverUrl + "/ip/addIpDetail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(values, null, 2),
        })
          .then((res) => res.json())
          .then((res) => console.log(res))
          .catch((err) => {
            console.log(err);
          });
        props.setValue(props.value + 1);
      }
  return (
    <div className="container">
    <div className="row ">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Formik
          onSubmit={(values) => handleSubmitForm(values)}
          validationSchema={Yup.object({
            startup_name:Yup.string().required('Startup name is required'),
            product_certification:Yup.string().required('Product certification is required'),
            reimbursement:Yup.string().required('Reimbursement opted is required'),
            testing_lab:Yup.string().required('Testing lab Certification Body required'),
            bis_nasl:Yup.string().required('Please select'),
            bis_nabl_proof:Yup.string().required('Please Upload Proof of BIS/NABL Approval'),
            testing_date:Yup.string().required('Please Enter Date of Testing/certificate'),
            types_of_testing_certification:Yup.string().required('Please Enter Type of Testing/Certification'),
            test_certificate:Yup.string().required('Please Upload Test Certificate'),
            testing_certification_detail:Yup.string().required('Please Enter Testing/Certification Detail'),
            amount_incurred:Yup.string().required('Please Enter Amount Incurred'),
            reimbursable_amount:Yup.string().required('Please Enter Requested Reimbursable Amount'),
            approved_reimbursable_amount:Yup.string().required('Please Enter Approved Reimbursable Amount'),
            upload_proof_amount_incurred:Yup.string().required('Please Upload Proof of Amount Incurred'),
            Sectors:Yup.string().required('Please Enter Sectors'),
            audited_financial_statement:Yup.string().required('Please Upload Latest Audited Financial Statement'),
            

          })}
          initialValues={
            JSON.parse(localStorage.getItem("ipDetails")) || initialFormObj
          }
          render={({ values }) => (
            <Form>
              <div className="row pb-3">
                <h2 className="font-24-31 font-20-26 font-style-normal font-weight-600 colorformhrading titlewadd">
               Quality Testing
                </h2>
                <p className="label-txt my-2">
                  Note: Is your startup registered in maharashtra? no
                  selection that only maharashtra registered startups are
                  eligible for financial assistance schemes.{" "}
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                  Startup Name <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="startup_name"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="startup_name" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                  Reimbursement opted for <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="reimbursement"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="reimbursement" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                <label className="pb-2 label-txt">
                Product Name/Certification <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="product_certification"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="product_certification" />
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                <label className="pb-2 label-txt">
                Testing Lab/Certification Body <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="testing_lab"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="testing_lab" />
                  </div>
                  </div>
                <div className="col-lg-6 col-md-6 pb-4">
                  <label className="pb-2 label-txt">
                  Is it BIS/NABL Approved?
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field component="div" name="do_you_have_IP">
                    <div class="radiobuttons d-flex">
                      <div className="col-lg-2 col-md-1">
                        <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioYes"
                            // defaultChecked={values.do_you_have_IP === "yes"}
                            name="bis_nasl"
                            value="yes"
                          />
                          <label htmlFor="radioYes">Yes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div class="rdio rdio-primary radio-inline">
                          <input
                            type="radio"
                            id="radioNo"
                            // defaultChecked={values.do_you_have_IP === "no"}
                            name="bis_nasl"
                            value="no"
                          />
                          <label htmlFor="radioNo">No</label>
                        </div>
                      </div>
                    </div>
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage className="error" name="bis_nasl" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                  Upload Proof of BIS/NABL Approval
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="file"
                    placeholder="Upload Proof"
                    className="textInput my-4"
                    name="bis_nabl_proof"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="bis_nabl_proof"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                  Date of Testing/Certification
                    <span className="text-danger">*</span>
                    <InformationIcon />
                  </label>
                  <Field
                    type="date"
                    placeholder="Enter Registered Address"
                    className="textInput"
                    name="testing_date"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="testing_date"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                <label className="pb-2 label-txt">
                Type of Testing/Certification <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="types_of_testing_certification"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="types_of_testing_certification" />
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                  Upload Test Certificate
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="file"
                    placeholder="Upload Proof"
                    className="textInput my-4"
                    name="test_certificate"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="test_certificate"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                <label className="pb-2 label-txt">
                Testing/Certification Detail <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="testing_certification_detail"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="testing_certification_detail" />
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                <label className="pb-2 label-txt">
                Amount Incurred (Excluding GST) <span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Name of Company"
                    className="textInput"
                    name="amount_incurred"
                  />
                   <div className="text-danger">
                    <ErrorMessage className="error" name="amount_incurred" />
                  </div>
                  </div>
                <div className="col-lg-6 col-md-6 col-sm-6 pb-4">
                  <label className="pb-2 label-txt">
                  Requested Reimbursable Amount(in Rupees)
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Company Website URL"
                    className="textInput"
                    name="reimbursable_amount"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="reimbursable_amount"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 pb-4">
                  <label className="pb-2 label-txt">
                  Approved reimbursable amount
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Company Website URL"
                    className="textInput"
                    name="approved_reimbursable_amount"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="approved_reimbursable_amount"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                  Upload Proof of Amount Incurred
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="file"
                    placeholder="Upload Proof"
                    className="textInput my-4"
                    name="upload_proof_amount_incurred"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="upload_proof_amount_incurred"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                  Upload Latest Audited Financial Statement
                    <span className="text-danger">*</span> <InformationIcon />
                  </label>
                  <Field
                    type="file"
                    placeholder="Upload Proof"
                    className="textInput my-4"
                    name="audited_financial_statement"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="audited_financial_statement"
                    />
                  </div>
                </div>


                <div className="col-lg-6 col-md-6 col-sm-6 py-2">
                  <label className="pb-2 label-txt">
                    Sectors<span className="text-danger">*</span>{" "}
                    <InformationIcon />
                  </label>
                  <Field
                    type="text"
                    placeholder="Enter Registered Address"
                    className="textInput"
                    name="Sectors"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="Sectors"
                    />
                  </div>
                </div>

               

              </div>

              <div className="justify-content-center d-flex pt-4">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-draft no-shadow"
                    onClick={() => {
                      localStorage.setItem(
                        "ipDetails",
                        JSON.stringify(values)
                      );
                    }}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Save &amp; Next
                  </Button>
                </Stack>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  </div>
  )
}

export default QualityTestingTab2