import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Speaker from '../../assets/images/Initiative/gradchallenge/speaker.jpg'

function MahaHackthonEvent() {
    return (
        <>
            <div className='mahaGrand-main pt-4' id="idEventSch">
                <div className="container">
                    <div className="row d-flex justify-content-center my-3">
                        <div className="offset-lg-3  col-lg-7 col-md-12 col-sm-7">
                            <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600">Event Schedule</h2>
                            <div className='card eventBackColor my-3 border-dark'>
                                <ul className='round topic'>
                                    <li>
                                        <p className='regular_font font-weight-700 font-22-26 font-14-17'>
                                            23rd April 2021, Friday
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            03:30 PM - Onboarding
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            06:00 PM - Keynotes
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            07:15 PM - Intro to Hacking Medicine Team
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            08:00 PM - Problem Pitching
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div className='card eventBackColor my-3 border-dark'>
                                <ul className='round topic'>
                                    <li>
                                        <p className='regular_font font-weight-700 font-22-26 font-14-17'>
                                            24th April 2021, Saturday
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>

                                            08:00 AM - Mentors Available

                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            10:00 AM - Team Registration Deadline

                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            07:00 PM - Practice Pitches
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div className='card eventBackColor my-3 border-dark'>
                                <ul className='round topic'>
                                    <li>
                                        <p className='regular_font font-weight-700 font-22-26 font-14-17'>
                                            25th April 2021, Sunday
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            10:00 AM - Final Presentations Due
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            11:00 AM - Final Pitch Review By Judges
                                        </p>
                                        <p className='regular_font font-weight-400 mb-1 font-22-26 font-14-17'>
                                            02:00 PM - Awards &#38; Prizes
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className='text-center'>
                                <p><a className='mahaHackA' href="https://docs.google.com/presentation/d/1ft7qO08Gf1iPVWR-jsI2nP2oT9s_xnQ40fakw3S-9cY/edit?ts=608118a6#slide=id.gd41ada0c22_2_108">Click here for detailed schedule</a></p>
                            </div>
                            <div id='speaker'>
                            <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600 py-2">Speaker</h2>
                            <div className='speakimg'>
                                <img src={Speaker} className="img-fluid" alt="Speaker" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MahaHackthonEvent