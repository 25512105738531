import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray,ErrorMessage } from "formik";

import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;
const MentorUpdateM = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [updatedImage,setUpdatedImage]=useState(null)
  function handleSubmitMentorUpdateForm(values){
    console.log(values, "hii")
    const formData = new FormData();
    formData.append("name",values.name)
    formData.append("email",values.email)
    formData.append("sector",values.sector)
    formData.append("university",values.university)
    formData.append("city",values.city)
    formData.append("linkdin",values.linkdin)
    formData.append("experience",values.experience)
    formData.append("image",values.image)
    console.log(formData)
    function fetchData(){
      fetch(serverUrl + "/mentor/allmentor")
.then((res) => res.json())
.then(
  (data) => {
    console.log(data, "dataaaaaaaaaaaa")
    setIsLoaded(true);
    props.setUsers(data);
  },
)
}
    fetch(serverUrl +`/mentor/${props.rowData.Mentor._id}`,
         {
          method: 'PUT',
          body: formData,
            
          })
          .then(res=>res.json())
          .then(res=>{setUpdatedImage(null)})
          .then((res) => fetchData())
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Updated Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

  return  <div className="container">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values, {resetForm}
      ) =>
     { console.log(values)
      handleSubmitMentorUpdateForm(values)
      resetForm({values:''}) 
    }
    }
    validationSchema={Yup.object({
    name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Enter Name is Required'),
    email: Yup.string().required('Enter Email is Required'),
    sector: Yup.string().required('Enter Sector is Required'),
    university: Yup.string().required('Enter University is Required'),
    city: Yup.string().required('Enter City is Required'),
    linkdin: Yup.string().required('Enter Linkdin is Required'),
    experience: Yup.string().required('Enter Experience is Required'),
    // image: Yup.string().required('Image  is Required'),
       })}
      initialValues={{ 
      name: props.rowData.Mentor.name,
      email: props.rowData.Mentor.email,
      sector:props.rowData.Mentor.sector,
      university: props.rowData.Mentor.university,
      city: props.rowData.Mentor.city,
      linkdin: props.rowData.Mentor.linkdin,
      experience: props.rowData.Mentor.experience,
      image:props.rowData.Mentor.image,
       }}

       render={({ values, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
        <Form onSubmit={(e)=>{
          
          handleSubmit(e)}}>
        <div className="row">
        <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Name{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" 
                    onChange={handleChange}
                    placeholder="Enter Name" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div>

                   <div className="col-lg-6 col-md-6 p-2">
                    <label className="pb-2 label-txt">
                    Email{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="email"
                    onChange={handleChange}
                     placeholder="Enter Name" className="textInput" name="email" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="email" /><br></br>
                    </div>                   
                  </div>        

                 
                  
                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Sector <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter Sector" className="textInput" name="sector" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="sector" /><br></br>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  University <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter University" className="textInput" name="university" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="university" /><br></br>
                    </div>
                  </div>  

                   <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  City <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter City" className="textInput" name="city" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="city" /><br></br>
                    </div>
                  </div>  

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Linkd In <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text"
                    onChange={handleChange}
                     placeholder="Enter Linkd In" className="textInput" name="linkdin" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="linkdin" /><br></br>
                    </div>
                  </div>  

                   <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Experience <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" 
                    onChange={handleChange}
                    placeholder="Enter Experience" className="textInput" name="experience" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="experience" /><br></br>
                    </div>
                  </div>    

                  <div className="col-lg-6 col-md-6 p-2">
                  <label className="pb-2 label-txt">
                  Image Upload <span className="text-danger">*</span>
                      <InformationIcon />
                    </label><br></br>
                    <img id="imgupdate" src={updatedImage||values.image} style={{width:'20%', height:'50%'}} /><br></br>
                    <input id="file" name="image" accept="image/png,image/jpeg"
                     type="file" onChange={(e) => {
                      console.log(e.target.files[0])
                      setUpdatedImage(URL.createObjectURL(e.target.files[0]))
                        setFieldValue("image", e.target.files[0]);
                      }} required/>   
               
                    <div className="text-danger">
                    <ErrorMessage className="error" name="image" /><br></br>
                    </div>
                  </div>
                                
        </div>
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                  Update
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>

  
};

export default MentorUpdateM;
