import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function MahaGrandEvent() {
  return (
      <>

<div className='mahaGrand-main pt-4' id="idEventSch">
        <div className="container">
                <div className="row d-flex justify-content-center my-3">
                    <div className="offset-lg-3 col-lg-7 col-md-12 col-sm-7">
                      <h2 className="font-28-36 font-18-23 font-20-26 font-weight-600">Event Schedule</h2> 
                          <div className='card eventBackColor disable my-3 border-dark'>
                            <ul className='round topic  opacity-25'>
                              <del><li>
                                   <span className='regular_font font-style-normal font-weight-700 font-22-26 font-14-17'>
                                      10th July 2021
                                   </span>
                                   <br/>
                                   <span className='regular_font font-style-normal font-weight-400 font-22-26 font-14-17'>
                                   Announcement of Grand Challenge
                                   </span>
                              </li></del>
                            </ul>
                          </div>

                          <div className='card eventBackColor disable my-3 border-dark'>
                            <ul className='round topic opacity-25'>
                              <li>
                              <span className='regular_font font-style-normal font-weight-700 font-22-26 font-14-17'>
                              10th July - 12th October 2021
                              </span>
                                   <br/>
                                   <span className='regular_font font-style-normal font-weight-400 font-22-26 font-14-17'>
                                   Application Window
                                   </span>
                                                        
                              </li>
                            </ul>
                          </div>

                          <div className='card eventBackColor disable my-3 border-dark'>
                            <ul className='round topic opacity-25'>
                              <li>
                                  <span className='regular_font font-style-normal font-weight-700 font-22-26 font-14-17'>
                                  1th August - 1th October 2021
                                  </span>
                                   <br/>
                                   <span className='regular_font font-style-normal font-weight-400 font-22-26 font-14-17'>
                                   Webinars on queries + Knowledge sessions
                                   </span>
                              </li>
                            </ul>
                          </div>

                          <div className='card eventBackColor disable my-3 border-dark'>
                            <ul className='round topic opacity-25'>
                              <li>
                                    <span className='regular_font font-style-normal font-weight-700 font-22-26 font-14-17 '>
                                        10th July 2021
                                     </span>
                                   <br/>
                                   <span className='regular_font font-style-normal font-weight-400 font-22-26 font-14-17'>
                                   Announcement of Grand Challenge
                                   </span>
                              </li>
                            </ul>
                          </div>
                  </div>
                </div>
        </div>
</div>
      </>
    
  );
}
