import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InformationIcon from "../../startup/informationIcon";
import { environment } from '../../api';
const serverUrl = environment.baseUrl;

const StateAddM = () => {
    const [users, setUsers] = useState([]);
    const [country, setcountry] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
      fetch(serverUrl + "/countryList/allCountry")
        .then(res => res.json())
        .then(
          (data) => {
            setIsLoaded(true);
            setUsers(data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, []) 
  
    const handleCategory = (values) => {
      const getcountry = values.target.value;
      setcountry( getcountry);
  }
  

function handleStateAddForm(values){
    // console.log(values, "hii")
    const formData = new FormData();
    formData.append("name",values.title)
    fetch(serverUrl +"/stateList/addState", 
         {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              accept: "application/json",
              
            },
            body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})

          fetch(serverUrl +"/countryList/allCountry", 
         {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              accept: "application/json",
              
            },
            body:JSON.stringify(values, null, 2)
            
          })
          .then(res=>res.json())
          .then(res=>console.log(res))
          .catch((err)=>{console.log(err)})
          const answer = window.confirm("are you sure?");
          if (answer) {
            // Save it!
            console.log("Thing was saved to the database.");
            alert("Form Submitted Successfully");
            
          } else {
            // Do nothing!
            alert("Please fill out all fields");
            console.log("Thing was not saved to the database.");
          }
  }

  return  <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12">
    {/* {message} */}
    <Formik
      onSubmit={(values
      ) =>
      handleStateAddForm(values)
    }
    validationSchema={Yup.object({
      name: Yup.string().required('Enter Name is Required'),
     
       })}
      initialValues={{ 
        name: '',
        
       }}

      render={({ values, setFieldValue }) => (
        <Form>
        
        <div className="row">
        <h6>Select Country :  </h6> 
        <select name="country" className="form-control size"
          value={country}
          onChange={(e) => {
            console.log(e.target.value, "8777")
            // setcountry("country", e.target.value)
            handleCategory(e)
          }}
         >
          <option>--Select Country--</option>
            {
              users.map((user) => {
                    return (
                        <option key={user._id} value={user._id}> {user.name} </option>
                    )
                })
            }
        </select>
        <div className="text-danger">
          <ErrorMessage className="error" name="country" /><br></br>
        </div>                                   
        </div>
       
        <div className="col-lg-12 col-md-12 p-2">
                    <label className="pb-2 label-txt">
                    Add State{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter State Name" className="textInput" name="name" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="name" /><br></br>
                    </div>                   
                  </div> 
                  <div className="col-lg-12 col-md-12 p-2">
                    <label className="pb-2 label-txt">
                    Add State Code{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter State Code" className="textInput" name="state_code" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="state_code" /><br></br>
                    </div>                   
                  </div>
                  <div className="col-lg-12 col-md-12 p-2">
                    <label className="pb-2 label-txt">
                    Add Code{" "}
                      <span className="text-danger">*</span>
                      <InformationIcon />
                    </label>
                    <Field type="text" placeholder="Enter Code" className="textInput" name="code" />
                    <div className="text-danger">
                    <ErrorMessage className="error" name="code" /><br></br>
                    </div>                   
                  </div>                      
            
        <div className="justify-content-center d-flex py-4">
                <Stack direction="row" spacing={2}>
                  {/* <Button variant="contained">Previous</Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn-color-next no-shadow"
                  >
                    Add
                  </Button>
                </Stack>
              </div>
         
        </Form>
      )}
    />
  </div>
  </div>
  </div>
};
export default StateAddM